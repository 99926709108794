import React from 'react';
import { StockLocationRole, StockLocationRoleAssignment } from '../types/stockLocationRoleAssignment';

export interface StockLocationRoleAssignmentContextProps {
  stockLocationRoles: Map<string, StockLocationRoleAssignment[]>;
  setStockLocationRoles: (stockLocationRoles: Map<string, StockLocationRoleAssignment[]>) => void;
  refreshStockLocationRoles: (companyId: string) => Promise<void>;
  currentStockLocationRole: (stockLocationId: string) => StockLocationRole | undefined;
  hasStockLocationRole: (
    companyId: string,
    stockLocationId: string,
    role: StockLocationRole,
    slRole?: StockLocationRole,
  ) => boolean;
}

const defaultContext: StockLocationRoleAssignmentContextProps = {
  stockLocationRoles: new Map(),
  setStockLocationRoles: () => {},
  refreshStockLocationRoles: async () => {},
  currentStockLocationRole: () => undefined,
  hasStockLocationRole: () => false,
};

export const StockLocationRoleAssignmentContext =
  React.createContext<StockLocationRoleAssignmentContextProps>(defaultContext);
