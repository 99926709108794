import React from 'react';
import { Translation } from '../types/translation/translation';

export interface TranslationContextProps {
  translations: Map<string, Translation>;
  setTranslations: (translations: Map<string, Translation>) => void;
}

const defaultContext: TranslationContextProps = {
  translations: new Map(),
  setTranslations: () => {},
};

export const TranslationContext = React.createContext<TranslationContextProps>(defaultContext);
