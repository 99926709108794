import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { AlertFragment } from '../../graphql/alert.graphql';
import { Alert } from '../../types/alert';
import { AlertContext } from '../../context/AlertContext';

const alertSubscription = gql`
  subscription alertNotification($companyId: String!) {
    alertNotification(companyId: $companyId) {
      companyId
      entities {
        ...Alert
      }
      topic
      date
    }
  }
  ${AlertFragment.alert}
`;

export class AlertNotificationResponse extends forNotification<Alert>('alertNotification') {}

export default function AlertNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { alerts, setAlerts } = useContext(AlertContext);

  if (!currentCompany) return null;

  const { data: alertNotification } = useSubscription<AlertNotificationResponse>(alertSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleAlertNotification = (notification?: AlertNotificationResponse) => {
    if (!notification?.alertNotification) return;
    const alertNotification = notification.alertNotification;
    switch (alertNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        alertNotification.entities.forEach(alert => alerts.set(alert.id, new Alert(alert)));
        break;
    }
    setAlerts(new Map(alerts));
  };

  useEffect(() => {
    handleAlertNotification(alertNotification);
  }, [alertNotification]);

  return <></>;
}
