import React from 'react';
import { ProductMasterData } from '../types/productMasterData';

export interface ProductMasterDataContextProps {
  productMasterData: Map<string, ProductMasterData>;
  setProductMasterData: (productMasterData: Map<string, ProductMasterData>) => void;
  productMasterDataLoading: boolean;
}

const defaultContext: ProductMasterDataContextProps = {
  productMasterData: new Map(),
  setProductMasterData: () => {},
  productMasterDataLoading: false,
};

export const ProductMasterDataContext = React.createContext<ProductMasterDataContextProps>(defaultContext);
