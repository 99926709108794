import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { ProductTransactionTableSettings } from '../types/productTransactionTableSettings';

const productTransactionTableSettings = gql`
  fragment ProductTransactionTableSettings on ProductTransactionTableSettings {
    id
    version
    companyId
    type
    columns {
      index
      column
      label
    }
  }
`;

const get = gql`
  query ProductTransactionTableSettings($companyId: String!, $page: Float) {
    productTransactionTableSettings(companyId: $companyId, page: $page) {
      data {
        ...ProductTransactionTableSettings
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${productTransactionTableSettings}
`;

export interface GetProductTransactionTableSettingsVariables {
  companyId: string;
  page?: number;
}

export interface GetProductTransactionTableSettingsResponse {
  productTransactionTableSettings: PaginatedProductTransactionTableSettings;
}

class PaginatedProductTransactionTableSettings extends forPaginated<ProductTransactionTableSettings>() {}

const create = gql`
  mutation CreateProductTransactionTableSettings(
    $productTransactionTableSettings: [CreateProductTransactionTableSettingsInput!]!
  ) {
    createProductTransactionTableSettings(productTransactionTableSettings: $productTransactionTableSettings) {
      ...ProductTransactionTableSettings
    }
  }
  ${productTransactionTableSettings}
`;

export interface CreateProductTransactionTableSettingsVariables {
  productTransactionTableSettings: ProductTransactionTableSettings[];
}

export interface CreateProductTransactionTableSettingsResponse {
  createProductTransactionTableSettings: ProductTransactionTableSettings[];
}

const update = gql`
  mutation UpdateProductTransactionTableSettings(
    $productTransactionTableSettings: [UpdateProductTransactionTableSettingsInput!]!
  ) {
    updateProductTransactionTableSettings(productTransactionTableSettings: $productTransactionTableSettings) {
      ...ProductTransactionTableSettings
    }
  }
  ${productTransactionTableSettings}
`;

export interface UpdateProductTransactionTableSettingsVariables {
  productTransactionTableSettings: ProductTransactionTableSettings[];
}

export interface UpdateProductTransactionTableSettingsResponse {
  updateProductTransactionTableSettings: ProductTransactionTableSettings[];
}

export const ProductTransactionTableSettingsQueries = {
  get,
};

export const ProductTransactionTableSettingsMutations = {
  create,
  update,
};
