import { gql } from '@apollo/client';
import { FeatureRequest, IncidentReport } from '../types/support';

const featureRequest = gql`
  fragment FeatureRequest on FeatureRequest {
    userId
  }
`;

const incidentReport = gql`
  fragment IncidentReport on IncidentReport {
    userId
  }
`;

const submitFeatureRequest = gql`
  mutation SubmitFeatureRequest($featureRequest: CreateFeatureRequestInput!) {
    submitFeatureRequest(featureRequest: $featureRequest) {
      ...FeatureRequest
    }
  }
  ${featureRequest}
`;

export interface SubmitFeatureRequestVariables {
  featureRequest: FeatureRequest;
}

export interface SubmitFeatureRequestResponse {
  submitFeatureRequest: {
    userId: string;
  };
}

const submitIncidentReport = gql`
  mutation SubmitIncidentReport($incidentReport: CreateIncidentReportInput!) {
    submitIncidentReport(incidentReport: $incidentReport) {
      ...IncidentReport
    }
  }
  ${incidentReport}
`;

export interface SubmitIncidentReportVariables {
  incidentReport: IncidentReport;
}

export interface SubmitIncidentReportResponse {
  submitIncidentReport: {
    userId: string;
  };
}

export const SupportQueries = {};

export const SupportMutations = {
  submitFeatureRequest,
  submitIncidentReport,
};

export const SupportFragments = {
  featureRequest,
};
