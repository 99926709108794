import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function ImportIcon({ className }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        stroke='currentColor'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.833 9V9.8C12.833 10.9201 12.833 11.4802 12.615 11.908C12.4233 12.2843 12.1173 12.5903 11.741 12.782C11.3132 13 10.7531 13 9.63301 13H4.03301C2.9129 13 2.35285 13 1.92503 12.782C1.5487 12.5903 1.24274 12.2843 1.05099 11.908C0.833008 11.4802 0.833008 10.9201 0.833008 9.8V9M10.1663 5.66667L6.83301 9M6.83301 9L3.49967 5.66667M6.83301 9V1'
          stroke='#667085'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
