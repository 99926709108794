import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { ReorderRuleContext } from '../../../../context/ReorderRuleContext';
import {
  DeleteReorderRuleResponse,
  DeleteReorderRuleVariables,
  ReorderRuleMutations,
} from '../../../../graphql/reorderRule.graphql';
import { DeleteReorderRuleInput } from '../../../../types/reorderRule';
import DeleteModal from '../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteReorderRuleModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupIds: string[];
}

export default function DeleteReorderRuleModal({ open, setOpen, groupIds }: DeleteReorderRuleModalProps) {
  const { reorderRules, groupedReorderRules, setReorderRules, setGroupedReorderRules } = useContext(ReorderRuleContext);

  const [error, setError] = useState('');

  const [remove, { loading: removeLoading }] = useMutation<DeleteReorderRuleResponse, DeleteReorderRuleVariables>(
    ReorderRuleMutations.remove,
    {
      onCompleted: response => {
        response.deleteReorderRule.forEach(rule => {
          reorderRules.delete(rule.id);
          const rules = groupedReorderRules.get(rule.groupId);

          if (rules) {
            groupedReorderRules.set(
              rule.groupId,
              rules.filter(gr => gr.id !== rule.id),
            );
            if (groupedReorderRules.get(rule.groupId)?.length === 0) groupedReorderRules.delete(rule.groupId);
          }
        });
      },
    },
  );

  async function handleDelete() {
    try {
      const deletedReorderRules: DeleteReorderRuleInput[] = [];
      groupIds.forEach(id =>
        deletedReorderRules.push(...(groupedReorderRules.get(id)?.map(rule => rule.forDelete()) || [])),
      );

      let deleted = 0;
      do {
        await remove({
          variables: {
            reorderRules: deletedReorderRules.slice(deleted, deleted + 3000),
          },
        });
        deleted += 3000;
      } while (deleted < deletedReorderRules.length);
    } catch (e) {
      setError(String(e));
    }

    setGroupedReorderRules(new Map(groupedReorderRules));
    setReorderRules(new Map(reorderRules));
    setError('');
    setOpen(false);
  }

  return (
    <DeleteModal
      open={open}
      onClose={() => setOpen(false)}
      error={error}
      text={t().veryifyDeleteReorderRules.singular.upper}
      onConfirm={handleDelete}
      loading={removeLoading}
    />
  );
}
