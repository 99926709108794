import { gql } from '@apollo/client';
import { AuthenticationSettings } from '../types/authenticationSettings';
import { forPaginated } from './common/paginated.graphql';

const authenticationSettings = gql`
  fragment AuthenticationSettings on AuthenticationSettings {
    id
    version
    companyId
    type
    expiryInHours
    userId
  }
`;

const get = gql`
  query AuthenticationSettings($companyId: String!) {
    authenticationSettings(companyId: $companyId) {
      data {
        ...AuthenticationSettings
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${authenticationSettings}
`;

export interface GetAuthenticationSettingsVariables {
  companyId: string;
}

export interface GetAuthenticationSettingsResponse {
  authenticationSettings: PaginatedAuthenticationSettings;
}

class PaginatedAuthenticationSettings extends forPaginated<AuthenticationSettings>() {}

const getForUser = gql`
  query AuthenticationSettingsForUser($userId: String!) {
    authenticationSettingsForUser(userId: $userId) {
      ...AuthenticationSettings
    }
  }
  ${authenticationSettings}
`;

export interface GetAuthenticationSettingsForUserVariables {
  userId: string;
}

export interface GetAuthenticationSettingsForUserResponse {
  authenticationSettingsForUser: AuthenticationSettings;
}

const create = gql`
  mutation CreateAuthenticationSettings($authenticationSettings: CreateAuthenticationSettingsInput!) {
    createAuthenticationSettings(authenticationSettings: $authenticationSettings) {
      ...AuthenticationSettings
    }
  }
  ${authenticationSettings}
`;

export interface CreateAuthenticationSettingsVariables {
  authenticationSettings: AuthenticationSettings;
}

export interface CreateAuthenticationSettingsResponse {
  createAuthenticationSettings: AuthenticationSettings;
}

const update = gql`
  mutation UpdateAuthenticationSettings($authenticationSettings: UpdateAuthenticationSettingsInput!) {
    updateAuthenticationSettings(authenticationSettings: $authenticationSettings) {
      ...AuthenticationSettings
    }
  }
  ${authenticationSettings}
`;

export interface UpdateAuthenticationSettingsVariables {
  authenticationSettings: AuthenticationSettings;
}

export interface UpdateAuthenticationSettingsResponse {
  updateAuthenticationSettings: AuthenticationSettings;
}

export const AuthenticationSettingsQueries = {
  get,
  getForUser,
};

export const AuthenticationSettingsMutations = {
  create,
  update,
};
