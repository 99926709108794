import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { Address } from './common/address';

enum ContactValidationError {}

export class ShippingLocation {
  name!: string;
  address?: Address;
  stockLocationId?: string;
  email?: string;

  constructor(obj: any) {
    Object.assign(this, cloneDeep(obj));
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withEmail(email?: string) {
    this.email = email;
    return cloneDeep(this);
  }

  withAddress(address: Address) {
    this.address = address;
    return this;
  }

  withAddressLine1(line: string) {
    if (!this.address) this.address = {};
    this.address.addressLine1 = line;
    return cloneDeep(this);
  }

  withAddressLine2(line: string) {
    if (!this.address) this.address = {};
    this.address.addressLine2 = line;
    return cloneDeep(this);
  }

  withCity(city: string) {
    if (!this.address) this.address = {};
    this.address.city = city;
    return cloneDeep(this);
  }

  withRegion(region: string) {
    if (!this.address) this.address = {};
    this.address.region = region;
    return cloneDeep(this);
  }

  withPostalCode(postalCode: string) {
    if (!this.address) this.address = {};
    this.address.postalCode = postalCode;
    return cloneDeep(this);
  }

  withCountryCode(countryCode?: string) {
    if (!this.address) this.address = {};
    this.address.countryCode = countryCode;
    return cloneDeep(this);
  }
}

export enum ContactType {
  public = 'public',
  internal = 'internal',
}

export class Contact extends CompanyEntity {
  name!: string;
  address?: Address;
  shippingLocations: ShippingLocation[] = [];
  contactType: ContactType = ContactType.public;
  email?: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateContactInput {
    return UpdateContactInput.from(this, UpdateContactInput);
  }

  override forDelete(): DeleteContactInput {
    return DeleteContactInput.from(this, DeleteContactInput);
  }

  override validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof Contact)[], (field: keyof Contact) => {
      return null;
    });
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withEmail(email?: string) {
    this.email = email;
    return cloneDeep(this);
  }
}

export class CreateContactInput extends forCreate(Contact) {}

export class UpdateContactInput extends forUpdate(Contact) {}

export class DeleteContactInput extends forDelete(Contact) {}
