import { t } from '../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function CreateButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.create,
    text: t().create.singular.label,
    onClick: onClick,
    style: 'secondary',
    ...props,
  };
}

interface CreateButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function CreateButton({ onClick, ...props }: CreateButtonProps) {
  return <Button {...CreateButtonTemplate(onClick, props)} />;
}
