import { t } from '../../../types/translation/Translator';
import { TaskContext } from '../../../context/TaskContext';
import { useContext, useState } from 'react';
import { DeleteTaskResponse, DeleteTaskVariables, TaskMutations } from '../../../graphql/task.graphql';
import { useMutation } from '@apollo/client';
import { DeleteTaskInput } from '../../../types/task';
import React from 'react';
import DeleteModal from '../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteTaskModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  taskIds: Set<string>;
}

export default function DeleteTaskModal({ open, setOpen, taskIds }: DeleteTaskModalProps) {
  const { tasks, setTasks } = useContext(TaskContext);

  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteTaskResponse, DeleteTaskVariables>(TaskMutations.remove, {
    onCompleted: res => {
      res.deleteTask.forEach(task => tasks.delete(task.id));
      setTasks(new Map(tasks));
      handleClose();
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      const deleteTask: DeleteTaskInput[] = [];
      for (const id of taskIds) {
        const task = tasks.get(id);
        if (task) deleteTask.push(task.forDelete());
      }

      let deleted = 0;
      do {
        await remove({
          variables: {
            tasks: deleteTask.slice(deleted, deleted + 3000),
          },
        });
        deleted += 3000;
      } while (deleted < deleteTask.length);
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      open={open}
      error={error}
      onClose={handleClose}
      text={t().verifyDeleteTasks.singular.label}
      loading={loading}
      onConfirm={handleDelete}
    />
  );
}
