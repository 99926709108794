import React from 'react';
import { User } from '../types/user';

export interface UserContextProps {
  currentUser: User | undefined | null;
  setCurrentUser: (user: User | null) => void;
  companyUsers: Map<string, User>;
  setCompanyUsers: (users: Map<string, User>) => void;
  refreshCompanyUsers: (companyId: string) => Promise<void>;
  signOut: () => void | Promise<void>;
}

const defaultContext: UserContextProps = {
  currentUser: null,
  setCurrentUser: () => {},
  companyUsers: new Map(),
  setCompanyUsers: () => {},
  refreshCompanyUsers: async () => {},
  signOut: () => {},
};

export const UserContext = React.createContext<UserContextProps>(defaultContext);
