import { Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TestIdIdentifier } from '../../util/identifiers/identifiers.util';

// Currently negative numbers are not allowed anywhere, could change in future.
const invalidNumberCharacters = new Set(['e', 'E', '+', '-']);

interface TextInputProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  testId?: TestIdIdentifier;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  mandatory?: boolean;
  dynamicUpdate?: boolean;
  width?: string;
  autoComplete?: string;
  draggable?: boolean;
  onDragStart?: (event: any) => void;
  min?: number;
  max?: number;
  tooltip?: string;
  heightClass?: string;
  suffix?: string;
}

export default function TextInput({
  value,
  onChange,
  placeholder = '',
  disabled,
  testId,
  type,
  label,
  mandatory = false,
  dynamicUpdate = false,
  width,
  autoComplete,
  draggable = false,
  min,
  max,
  tooltip,
  onDragStart,
  heightClass = 'h-10',
  suffix,
}: TextInputProps) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string>(value || '');

  const [focus, setFocus] = useState<boolean>(false);

  const setChange = (value: string) => {
    setText(value);
    onChange(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;

    if (type === 'number') {
      const parsed = parseFloat(value);

      if (value !== '' && Number.isNaN(parsed)) return;

      if (min !== undefined && parsed < min) {
        return setChange(min.toString());
      }

      if (max !== undefined && parsed > max) {
        return setChange(max.toString());
      }
    }

    setChange(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (dynamicUpdate) setText(value || '');
  }, [value]);

  return (
    <Grid container width={width || 'auto'} marginX={'auto'}>
      <Grid item display='flex' marginY={'auto'}>
        {label ? <p className='mb-1 text-sm font-medium text-slate-800'>{label}</p> : null}
        {mandatory ? <p className='ml-1 text-sm font-medium text-red-500'>*</p> : null}
      </Grid>
      {tooltip ? (
        <Grid ml={0.5} className='hover:cursor-pointer' item display='flex' marginY={'auto'}>
          <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={tooltip}>
            <InfoOutlinedIcon sx={{ width: '25px' }} />
          </Tooltip>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {suffix ? ( // For auto complete reasons
          <Grid
            container
            className={`${
              disabled ? 'bg-gray-100 text-gray-400' : 'bg-white'
            } border ${heightClass} my-auto rounded   border shadow-sm border-slate-300 placeholder-slate-400 inline-block w-full sm:text-sm ${
              focus ? 'border-sky-500 ring-sky-500 ring-1' : ''
            }`}
            justifyContent={'flex-end'}
          >
            <Grid item xs={suffix ? 8 : 12} marginY={'auto'}>
              <input
                type={type}
                data-testid={testId?.name}
                draggable={draggable}
                onDragStart={onDragStart}
                autoComplete={autoComplete}
                min={min}
                value={text}
                disabled={disabled}
                onChange={handleChange}
                onKeyDown={event => {
                  if (type === 'number' && invalidNumberCharacters.has(event.key.toLowerCase())) event.preventDefault();
                }}
                placeholder={placeholder}
                className={`w-full h-full focus:outline-none pl-3 sm:text-sm disabled:bg-gray-100 disabled:text-gray-400 rounded placeholder-slate-400`}
                onFocus={event => setFocus(true)}
                onBlur={event => setFocus(false)}
              />
            </Grid>
            <Grid item xs={4} my={'auto'} textAlign={'end'}>
              <p className='text-gray-400 select-none pr-1'>{suffix}</p>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={`${heightClass}`} my={'auto'}>
            <input
              data-testid={testId?.name}
              draggable={draggable}
              onDragStart={onDragStart}
              autoComplete={autoComplete}
              type={type}
              min={min}
              value={text}
              onKeyDown={event => {
                if (type === 'number' && invalidNumberCharacters.has(event.key.toLowerCase())) event.preventDefault();
              }}
              disabled={disabled}
              onChange={handleChange}
              className={`px-3 py-2 h-full rounded disabled:bg-gray-100 disabled:text-gray-400 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full sm:text-sm focus:ring-1`}
              placeholder={placeholder}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
