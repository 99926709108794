import { gql } from '@apollo/client';
import { TranslationRecord } from '../../types/translation/common';
import { CreateCustomTranslationInput, CustomTranslation } from '../../types/translation/customTranslation';
import { TranslationKey } from '../../types/translation/TranslationKey';
import { forPaginated } from '../common/paginated.graphql';

const customTranslation = gql`
  fragment CustomTranslation on CustomTranslation {
    id
    version
    companyId
    updatedAt
    createdAt
    updatedBy
    createdBy
    name
    translations
  }
`;

const get = gql`
  query CustomTranslations($page: Float!, $companyId: String!) {
    customTranslations(page: $page, companyId: $companyId) {
      data {
        ...CustomTranslation
      }
      hasNext
      page
      pageSize
    }
  }
  ${customTranslation}
`;

export interface GetCustomTranslationsVariables {}

export interface GetCustomTranslationsResponse {
  customTranslations: PaginatedCustomTranslations;
}

class PaginatedCustomTranslations extends forPaginated<CustomTranslation>() {}

const create = gql`
  mutation CreateCustomTranslation($customTranslation: CreateCustomTranslationInput!) {
    createCustomTranslation(customTranslation: $customTranslation) {
      ...CustomTranslation
    }
  }
  ${customTranslation}
`;

export interface CreateCustomTranslationVariables {
  customTranslation: CreateCustomTranslationInput;
}

export interface CreateCustomTranslationResponse {
  createCustomTranslation: CustomTranslation;
}

const updateRecord = gql`
  mutation UpdateCustomTranslationRecord(
    $record: TranslationRecordInput!
    $key: TranslationKey!
    $companyId: String!
    $customTranslationId: String!
  ) {
    updateCustomTranslationRecord(
      record: $record
      key: $key
      companyId: $companyId
      customTranslationId: $customTranslationId
    ) {
      ...CustomTranslation
    }
  }
  ${customTranslation}
`;

export interface UpdateCustomTranslationRecordVariables {
  customTranslationId: string;
  companyId: string;
  record: TranslationRecord;
  key: TranslationKey;
}

export interface UpdateCustomTranslationRecordResponse {
  updateCustomTranslationRecord: CustomTranslation;
}

export const CustomTranslationQueries = {
  get,
};

export const CustomTranslationMutations = {
  create,
  updateRecord,
};
