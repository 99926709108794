import { Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { t } from '../../../../types/translation/Translator';
import { CreateTaskInput, Task } from '../../../../types/task';
import Selector from '../../../Common/Selector';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { UserContext } from '../../../../context/UserContext';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { CompanyRole } from '../../../../types/companyRoleAssignment';
import { CompanyContext } from '../../../../context/CompanyContext';
import { TaskSettingsContext } from '../../../../context/TaskSettingsContext';
import { TaskSettings } from '../../../../types/taskSettings';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import { BackButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { CancelButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { SaveButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import { NextButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/NextButton';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';

interface CreateTaskAssigneesPaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next?: () => void;
  back?: () => void;
  save?: () => void;
  cancel?: () => void;
}

export function CreateTaskAssigneesPane({
  task,
  setTask,
  next,
  back,
  save,
  cancel,
}: CreateTaskAssigneesPaneInputProps) {
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { companyUsers } = useContext(UserContext);
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);
  const { currentCompany } = useContext(CompanyContext);
  const { taskSettings } = useContext(TaskSettingsContext);
  const taskSetting = new TaskSettings(
    taskSettings.size ? [...taskSettings.values()][0] : { companyId: currentCompany.id },
  );

  const { possibleUsers, disabledUsers } = useMemo(() => {
    let possibleUsers: string[] = [];
    let disabledUsers: string[] = [];

    const companyAdmins = [...companyRoles.values()]
      .flat()
      .filter(r => r.companyId === currentCompany.id && r.role === CompanyRole.administrator)
      .map(u => u.userId);

    if (taskSetting.companyRolesForInitialCount.includes(CompanyRole.administrator)) {
      possibleUsers.push(...companyAdmins);
    } else {
      disabledUsers.push(...companyAdmins);
    }

    if (taskSetting.companyRolesForInitialCount.includes(CompanyRole.employee)) {
      for (const stockLocationRole of stockLocationRoles.get(task.stockLocationId) ?? []) {
        if (
          taskSetting.stockLocationRolesForInitialCount.includes(stockLocationRole.role) &&
          (!taskSetting.requireUniqueRecountUser || !task.assignedForRecount.includes(stockLocationRole.userId))
        ) {
          possibleUsers.push(stockLocationRole.userId);
        } else {
          disabledUsers.push(stockLocationRole.userId);
        }
      }
    }

    return { possibleUsers, disabledUsers };
  }, [taskSetting, stockLocationRoles, companyRoles]);

  const buttons = useMemo(() => {
    const shown = [];

    if (back) shown.push(BackButtonTemplate(back));
    if (cancel) shown.push(CancelButtonTemplate(cancel));
    if (save) shown.push(SaveButtonTemplate(save, { disabled: !task.assignedTo.length }));
    if (next) shown.push(NextButtonTemplate(next, { disabled: !task.assignedTo.length }));

    return shown;
  }, [back, cancel, save, next]);

  return (
    <ModalPane footerButtons={buttons} testId={testIds.createTaskUserPane}>
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} marginBottom={'0.5rem'}>
              <p className={'text-sm font-semibold'}>{t().selectTaskAssignees.singular.label}</p>
            </Grid>
            <Grid item xs={12}>
              <Selector
                testId={testIds.users}
                placeholder={t().filterUsers.singular.label}
                values={[...possibleUsers, ...disabledUsers]}
                disabledValues={disabledUsers}
                checkedValues={task.assignedTo}
                filterFn={(item, filter) => {
                  const user = companyUsers.get(item);
                  if (!user) return false;

                  if (
                    toFilterString(removeDiacritics(user.email)).includes(filter) ||
                    toFilterString(removeDiacritics(user.firstName)).includes(filter) ||
                    toFilterString(removeDiacritics(user.lastName)).includes(filter) ||
                    toFilterString(removeDiacritics(`${user.firstName} ${user.lastName}`)).includes(filter)
                  ) {
                    return true;
                  }
                  return false;
                }}
                toText={item => companyUsers.get(item)?.email || 'Unknown user'}
                toElement={item => (
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12}>
                      <p className={`text-normal font-normal`}>{`${companyUsers.get(item)?.firstName} ${
                        companyUsers.get(item)?.lastName
                      }`}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className={`text-sm text-gray-400`}>{`${companyUsers.get(item)?.email}`}</p>
                    </Grid>
                  </Grid>
                )}
                onChange={checked => setTask(task.withAssignedTo(checked))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
