import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { Product } from '../../types/product';
import { ProductFragments } from '../../graphql/product.graphql';
import { ProductContext } from '../../context/ProductContext';

const productSubscription = gql`
  subscription productNotification($companyId: String!) {
    productNotification(companyId: $companyId) {
      companyId
      entities {
        ...Product
      }
      topic
      date
    }
  }
  ${ProductFragments.product}
`;

export class ProductNotificationResponse extends forNotification<Product>('productNotification') {}

export default function ProductNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { products, setProducts } = useContext(ProductContext);

  if (!currentCompany) return null;

  const { data: productNotification } = useSubscription<ProductNotificationResponse>(productSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleProductNotification = (notification?: ProductNotificationResponse) => {
    if (!notification?.productNotification) return;
    const productNotification = notification.productNotification;

    switch (productNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        productNotification.entities.forEach(product =>
          products.set(
            product.productMasterDataId,
            (products.get(product.productMasterDataId) || new Map()).set(product.id, product),
          ),
        );
        break;
      case 'DELETE':
        productNotification.entities.forEach(product => {
          products.has(product.productMasterDataId)
            ? (products.get(product.productMasterDataId) || new Map()).delete(product.id)
            : null;
          if (!products.get(product.productMasterDataId)?.size) products.delete(product.productMasterDataId);
        });
    }
    setProducts(new Map(products));
  };

  useEffect(() => {
    handleProductNotification(productNotification);
  }, [productNotification]);

  return <></>;
}
