import React from 'react';
import { ReorderRule } from '../types/reorderRule';

export interface ReorderRuleContextProps {
  reorderRules: Map<string, ReorderRule>;
  setReorderRules: (reorderRules: Map<string, ReorderRule>) => void;
  groupedReorderRules: Map<string, ReorderRule[]>;
  setGroupedReorderRules: (groupedReorderRules: Map<string, ReorderRule[]>) => void;
  reorderRulesLoading: boolean;
}

const defaultContext: ReorderRuleContextProps = {
  reorderRules: new Map(),
  setReorderRules: () => {},
  reorderRulesLoading: false,
  groupedReorderRules: new Map(),
  setGroupedReorderRules: () => {},
};

export const ReorderRuleContext = React.createContext<ReorderRuleContextProps>(defaultContext);
