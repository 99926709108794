import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function GlobeIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1.33301 8.49992H14.6663M1.33301 8.49992C1.33301 12.1818 4.31778 15.1666 7.99967 15.1666M1.33301 8.49992C1.33301 4.81802 4.31778 1.83325 7.99967 1.83325M14.6663 8.49992C14.6663 12.1818 11.6816 15.1666 7.99967 15.1666M14.6663 8.49992C14.6663 4.81802 11.6816 1.83325 7.99967 1.83325M7.99967 1.83325C9.66719 3.65882 10.6148 6.02794 10.6663 8.49992C10.6148 10.9719 9.66719 13.341 7.99967 15.1666M7.99967 1.83325C6.33215 3.65882 5.38451 6.02794 5.33301 8.49992C5.38451 10.9719 6.33215 13.341 7.99967 15.1666'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
