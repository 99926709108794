import React, { useContext } from 'react';
import Modal from '../Modal/Modal';
import { EntityFilter, FilterEntity } from '../../filters/filter.util';
import { CompanyContext } from '../../../../context/CompanyContext';
import Color from 'color';
import { DynamicEntityFilterType } from '../../filters/DynamicEntityFilter/DynamicEntityFilterType';
import { Divider, Grid } from '@mui/material';
import { t } from '../../../../types/translation/Translator';
import { Button } from '../Button/Button';
import FilterIcon from '../../../icons/Filter/FilterIcon';

import { Colors, VentoryColor } from '../../../util/color.util';
import ModalPane from '../Modal/ModalPane';

interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  filterEntity: FilterEntity;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  useFilterFn?: (filter: string) => boolean;
}

function splitArrayInHalf<T>(arr: T[]) {
  if (!arr.length) return { left: [], right: [] };

  const half = Math.ceil(arr.length / 2);
  const left = arr.slice(0, half);
  const right = arr.slice(half);

  return { left, right };
}

export default function FilterModal({
  open,
  onClose,
  filterEntity,
  filter,
  setFilter,
  useFilterFn = () => true,
}: FilterModalProps) {
  const { currentCompany } = useContext(CompanyContext);

  const color = Color(Colors.getAccentColor(currentCompany)).toString();

  const items = [...DynamicEntityFilterType.forEntity(filterEntity)].filter(useFilterFn);

  const { left, right } = splitArrayInHalf([...items]);

  const showDivider = (index: number, columnSize: number) => {
    return index < columnSize - 1;
  };

  const filterItem = (item: string, index: number, columnSize: number) => {
    return (
      <Grid item xs={12} key={item}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} key={item}>
            {DynamicEntityFilterType.toComponent(item, filter, setFilter, color)}
          </Grid>
          {showDivider(index, columnSize) ? (
            <Grid item xs={12} key={'divider' + item}>
              <Divider style={{ borderColor: VentoryColor.grey200 }} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const content = () => {
    if (items.length <= 3) {
      return (
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            {items.map((item, index) => filterItem(item, index, items.length))}
          </Grid>
        </Grid>
      );
    }

    const leftColumn = left.map((item, index) => filterItem(item, index, left.length));
    const rightColumn = right.map((item, index) => filterItem(item, index, right.length));

    return (
      <>
        <Grid item xs={6} pr={3}>
          <Grid container rowSpacing={2}>
            {leftColumn}
          </Grid>
        </Grid>
        {rightColumn.length ? (
          <>
            <Grid item xs={6} pl={3} className={'border-l ventory-light-border'}>
              <Grid container rowSpacing={2}>
                {rightColumn}
              </Grid>
            </Grid>
          </>
        ) : null}
      </>
    );
  };

  return (
    <Modal
      title={t().filter.singular.label}
      open={open}
      onClose={onClose}
      icon={<FilterIcon height={24} width={24} color={Colors.getAccentColor(currentCompany)} />}
      width={items.length <= 3 ? '600px' : undefined}
    >
      <ModalPane
        footer={
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <Button
                text={t().clearAll.singular.label}
                onClick={() => setFilter(new EntityFilter())}
                style='primary'
                color={{ textColor: VentoryColor.deleteRed, backgroundColor: VentoryColor.deleteRedBackground }}
              />
            </Grid>
            <Grid item>
              <Button text={t().done.singular.label} onClick={onClose} style='secondary' />
            </Grid>
          </Grid>
        }
      >
        <Grid container alignContent={'space-between'} rowSpacing={2}>
          <Grid item xs={12}>
            <Grid container>{content()}</Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
