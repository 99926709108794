import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import { CompanyContext } from '../../../../context/CompanyContext';
import { CustomField } from '../../../../types/customField';
import CustomFieldItem from '../Common/CustomFieldItem';
import { useMutation } from '@apollo/client';
import {
  CreateCustomFieldResponse,
  CreateCustomFieldVariables,
  CustomFieldMutations,
} from '../../../../graphql/customField.graphql';
import { CustomFieldContext } from '../../../../context/CustomFieldContext';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import CancelButton from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import CreateButton from '../../../../VentoryUI/components/common/Button/Templates/CreateButton';

interface CreateCustomFieldModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function CreateCustomFieldModal({ open, setOpen }: CreateCustomFieldModalProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { customFields, setCustomFields } = useContext(CustomFieldContext);

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateCustomFieldResponse, CreateCustomFieldVariables>(
    CustomFieldMutations.create,
    {
      onCompleted: res => {
        const field = res.createCustomField[0];
        customFields.set(field.id, new CustomField(field));
        setCustomFields(new Map(customFields));
        handleClose();
      },
      onError: res => setError(res.message),
    },
  );

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleCreate = async (field: CustomField) => {
    try {
      field.validate();

      await create({
        variables: {
          customFields: [field],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const footer = (field: CustomField) => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <CancelButton disabled={loading} onClick={handleClose} />
      </Grid>
      <Grid item>
        <CreateButton loading={loading} onClick={() => handleCreate(field)} />
      </Grid>
    </Grid>
  );

  return (
    <Modal open={open} width='60%' onClose={handleClose} title={t().createCustomField.singular.label} error={error}>
      <CustomFieldItem
        setError={setError}
        customField={new CustomField({ companyId: currentCompany.id })}
        footer={footer}
      />
    </Modal>
  );
}
