import { Grid } from '@mui/material';
import React, { useState } from 'react';
import TextInput from './TextInput';
import { t } from '../../types/translation/Translator';
import { HexColorPicker } from 'react-colorful';
import { classes, TestIdIdentifier, testIds } from '../../util/identifiers/identifiers.util';

import { Button } from '../../VentoryUI/components/common/Button/Button';

interface ColorPickerInputProps {
  color: string;
  defaultColor?: string;
  setColor: (value: string) => void;
  testId?: TestIdIdentifier;
}

export default function ColorPicker({ color, defaultColor, setColor, testId }: ColorPickerInputProps) {
  const handleInputChange = (value: string) => {
    if (value[0] !== '#') value = `#${value}`;
    setColor(value);
    setColorInput(value);
  };

  const [colorInput, setColorInput] = useState<string>(color);

  return (
    <Grid
      container
      rowSpacing={1}
      className={classes.colorPicker.name}
      data-testid={testId?.name}
      width={'200px'}
      mx='auto'
    >
      <Grid item xs={12}>
        <TextInput
          width={'200px'}
          dynamicUpdate
          value={colorInput}
          placeholder={t().hexColorCode.singular.label}
          onChange={value => handleInputChange(value)}
          testId={testIds.colorInput}
        />
      </Grid>
      <Grid item xs={12}>
        <HexColorPicker
          style={{ marginRight: 'auto', marginLeft: 'auto' }}
          color={colorInput}
          onChange={handleInputChange}
        />
      </Grid>
      {defaultColor ? (
        <Grid item xs={12} maxWidth='200px'>
          <Button text={t().resetToDefault.singular.label} onClick={() => handleInputChange(defaultColor)} />
        </Grid>
      ) : null}
    </Grid>
  );
}
