import { Grid } from '@mui/material';
import React from 'react';

interface IconProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export interface IconInputProps {
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  color?: string;
  width?: string | number;
  height?: string | number;
}

export default function Icon({ children, className, onClick }: IconProps) {
  return (
    <Grid item className={className} alignContent={'center'} onClick={onClick}>
      {children}
    </Grid>
  );
}
