import React from 'react';
import { Comment } from '../types/comment';

export interface CommentContextProps {
  comments: Map<string, Map<string, Comment>>;
  setComments: (comments: Map<string, Map<string, Comment>>) => void;
  commentsLoading: boolean;
}

const defaultContext: CommentContextProps = {
  comments: new Map(),
  setComments: () => {},
  commentsLoading: false,
};

export const CommentContext = React.createContext<CommentContextProps>(defaultContext);
