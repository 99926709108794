import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CsvUploadType } from '../../../../../types/csvUploadConfiguration';
import CsvUploadConfigurationsScreen from '../../../../Common/CsvUploadConfigurationsScreen';
import CsvUploadConfigurationUpdatePane from '../../../../Common/CsvUploadConfigurationUpdatePane';

import { t } from '../../../../../types/translation/Translator';
import Pane from '../../../../../VentoryUI/components/common/Pane/Pane';

const tabs = [
  {
    text: t().importConfigurations.singular.label,
    path: '/settings/stock_location/bins/advanced/import_configurations',
    key: 'import_configurations',
  },
];

export default function BinAdvancedSettingsPane() {
  const navigate = useNavigate();

  return (
    <Pane tabs={tabs}>
      <Routes>
        <Route
          path={'import_configurations/*'}
          element={<CsvUploadConfigurationsScreen type={CsvUploadType.bin} backPath='/settings/stock_location' />}
        />
        <Route
          path={'import_configurations/:id/update/*'}
          element={
            <CsvUploadConfigurationUpdatePane
              type={CsvUploadType.bin}
              onDone={() => navigate('import_configurations')}
            />
          }
        />
      </Routes>
    </Pane>
  );
}
