import dayjs from 'dayjs';
import { Alert } from '../../../../types/alert';
import { TriggerEntityType } from '../../../../types/trigger';
import { toFilterString } from '../../../../util/string.util';
import { EntityFilter } from '../filter.util';

export namespace AlertFilter {
  export function search(item: Alert, textFilter: string) {
    if (toFilterString(item.alertString).includes(textFilter)) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: Alert[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      if (filter.stockLocation?.size && !item.stockLocationIds.some(sl => filter.stockLocation?.has(sl))) return false;
      if (
        filter.timeFrame &&
        (dayjs(filter.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filter.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (filter.product?.size) {
        if (item.entityType !== TriggerEntityType.pmd) return false;
        return filter.product.has(item.triggeredValue);
      }

      if (filter.lot?.size) {
        if (item.entityType !== TriggerEntityType.lot) return false;
        return filter.lot.has(item.triggeredValue);
      }

      return onFilter(item, textFilter);
    });

    return result;
  }
}
