import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export enum AuthenticationType {
  none = 'none',
  code = 'code',
}

export class AuthenticationSettings extends CompanyEntity {
  type: AuthenticationType = AuthenticationType.none;
  expiryInHours: number = 24;
  userId?: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateAuthenticationSettingsInput {
    return UpdateAuthenticationSettingsInput.from(this, UpdateAuthenticationSettingsInput);
  }

  override forDelete(): DeleteAuthenticationSettingsInput {
    return DeleteAuthenticationSettingsInput.from(this, DeleteAuthenticationSettingsInput);
  }

  override validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof AuthenticationSettings)[],
      (field: keyof AuthenticationSettings) => {
        return null;
      },
    );
  }

  withType(type: AuthenticationType) {
    this.type = type;
    return cloneDeep(this);
  }

  withExpiryInHours(hours: number) {
    this.expiryInHours = hours;
    return cloneDeep(this);
  }
}

export class CreateAuthenticationSettingsInput extends forCreate(AuthenticationSettings) {}

export class UpdateAuthenticationSettingsInput extends forUpdate(AuthenticationSettings) {}

export class DeleteAuthenticationSettingsInput extends forDelete(AuthenticationSettings) {}

export function authenticationTypeToString(type: string) {
  switch (type) {
    case AuthenticationType.code:
      return 'Code';
    case AuthenticationType.none:
      return 'None';
  }

  return type;
}
