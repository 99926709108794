import React, { useEffect, useRef, useState } from 'react';
import { EntityFilter } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { removeDiacritics } from '../../../../../util/string.util';
import { OrderType, orderTypeToLocalizedString } from '../../../../../types/order';

interface OrderTypeFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

const orderTypeFilter = (type: string, text: string, filter: EntityFilter) => {
  const filterText = removeDiacritics(text.toLowerCase());
  if (removeDiacritics(type.toLowerCase()).includes(filterText)) return true;
  return false;
};

export default function OrderTypeFilterContent({ className, filter, color, setFilter }: OrderTypeFilterContentProps) {
  const [textFilter, setTextFilter] = useState<string>('');

  const filterOrderType = () => {
    return new Set(
      [...Object.keys(OrderType)].filter(item => orderTypeFilter(item, textFilter, filter)).map(item => item),
    );
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (!ref.current) return;
    const topFromBottom = document.documentElement.clientHeight - ref.current.getBoundingClientRect().top;
    setMaxHeight(topFromBottom < 500 ? topFromBottom - 16 : undefined);
  }, [ref.current]);

  const [values, setValues] = useState<Set<string>>(filterOrderType());

  const handleChange = (selected: Set<string>) => {
    filter.orderType = selected;
    if (!filter.orderType?.size) filter.orderType = undefined;
    setFilter({ ...filter });
  };

  const handleRemove = () => {
    filter.orderType = undefined;
    setFilter({ ...filter });
  };

  useEffect(() => {
    setValues(filterOrderType());
  }, [textFilter]);

  return (
    <Grid item xs={12} className='w-full' ref={ref} style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().orderType.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filter.orderType}
            values={values}
            onChange={handleChange}
            toText={id => orderTypeToLocalizedString(id as OrderType)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
