import { EntityFilter } from '../filter.util';
import { Product } from '../../../../types/product';

export namespace ProductFilter {
  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: Product[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      if (filter.stockLocation && filter.stockLocation.size && !filter.stockLocation.has(item.stockLocationId)) {
        return false;
      }

      return onFilter(item, textFilter);
    });

    return result;
  }
}
