import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import UploadCsvInput from '../../../Common/UploadCsvInput';
import { Grid } from '@mui/material';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { useLazyQuery } from '@apollo/client';
import {
  GetCsvUploadConfigurationForFileResponse,
  GetCsvUploadConfigurationForFileVariables,
  CsvUploadConfigurationQueries,
} from '../../../../graphql/csvUploadConfiguration';
import { CompanyContext } from '../../../../context/CompanyContext';
import { Base64 } from 'js-base64';
import CreateCsvUploadConfigurationMapping from './CreateCsvUploadConfigurationMapping';
import { CsvUploadConfiguration, CsvUploadType } from '../../../../types/csvUploadConfiguration';
import { CsvUploadConfigurationSelect } from './CsvUploadConfigurationSelect';
import { useParams } from 'react-router-dom';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import CsvUploadConfigurationInspect from './CsvUploadConfigurationInspect';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';

enum BinImportStatus {
  fileUpload,
  selectConfig,
  createConfig,
  inspect,
}

interface BinImportModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleUploadOverride?: (csvFileAsBase64Data: string, configurationId: string) => Promise<void>;
  errorOverride?: string;
}

export default function BinImportModal({
  open,
  setOpen,
  handleUploadOverride,
  errorOverride,
}: BinImportModalInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const stockLocationId = useParams()['id'] || '';

  const [file, setFile] = useState<File | null>(null);
  const [status, setStatus] = useState<BinImportStatus>(BinImportStatus.fileUpload);

  const [configurations, setConfigurations] = useState<CsvUploadConfiguration[]>([]);
  const [otherConfigurations, setOtherConfigurations] = useState<CsvUploadConfiguration[]>([]);
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [requiredHeaders, setRequiredHeaders] = useState<string[]>([]);
  const [optionalHeaders, setOptionalHeaders] = useState<string[]>([]);
  const [fileAsBase64, setFileAsBase64] = useState<string | null>(null);
  const [deleteExisting, setDeleteExisting] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [inspectId, setInspectId] = useState<string>();

  const [getConfigurations, { loading: getConfigurationsLoading }] = useLazyQuery<
    GetCsvUploadConfigurationForFileResponse,
    GetCsvUploadConfigurationForFileVariables
  >(CsvUploadConfigurationQueries.getForFile, {
    onCompleted: res => {
      setConfigurations(res.csvConfigurationsForFile.configurations.map(c => new CsvUploadConfiguration(c)));
      setFileHeaders(res.csvConfigurationsForFile.fileHeaders);
      setRequiredHeaders(res.csvConfigurationsForFile.requiredHeaders);
      setOptionalHeaders(res.csvConfigurationsForFile.optionalHeaders);
      setOtherConfigurations(res.csvConfigurationsForFile.otherConfigurations);
      if (
        !res.csvConfigurationsForFile.configurations.length &&
        !res.csvConfigurationsForFile.otherConfigurations.length
      ) {
        setStatus(BinImportStatus.createConfig);
      } else {
        setStatus(BinImportStatus.selectConfig);
      }
    },
    onError: err => setError(err.message),
  });

  const handleFile = (files: FileList | null) => {
    if (!files) {
      setFile(null);
      return;
    }
    setFile(files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    let asBase64 = Base64.encode(await file.text());

    await getConfigurations({
      variables: {
        companyId: currentCompany.id,
        type: CsvUploadType.bin,
        csvFileAsBase64Data: asBase64,
      },
    });
    setFileAsBase64(asBase64);
  };

  const handleClose = () => {
    setFile(null);
    setStatus(BinImportStatus.fileUpload);
    setConfigurations([]);
    setOtherConfigurations([]);
    setFileHeaders([]);
    setRequiredHeaders([]);
    setOptionalHeaders([]);
    setFileAsBase64(null);
    setOpen(false);
    setDeleteExisting(false);
    setError('');
  };

  const handleBack = () => {
    setError('');
    switch (status) {
      case BinImportStatus.fileUpload:
        return;
      case BinImportStatus.selectConfig:
        return setStatus(BinImportStatus.fileUpload);
      case BinImportStatus.createConfig:
        return setStatus(BinImportStatus.fileUpload);
    }
  };

  const content = () => {
    if (status === BinImportStatus.createConfig && fileAsBase64) {
      return (
        <CreateCsvUploadConfigurationMapping
          onClose={handleClose}
          onBack={handleBack}
          type={CsvUploadType.bin}
          required={requiredHeaders}
          optional={optionalHeaders}
          headers={fileHeaders}
          stockLocationId={stockLocationId}
          csvFileAsBase64Data={fileAsBase64}
          setError={setError}
          handleUploadOverride={handleUploadOverride}
        />
      );
    }

    if (status === BinImportStatus.selectConfig && fileAsBase64) {
      return (
        <CsvUploadConfigurationSelect
          otherConfigurations={otherConfigurations}
          onClose={handleClose}
          onBack={handleBack}
          onCreate={() => setStatus(BinImportStatus.createConfig)}
          csvFileAsBase64Data={fileAsBase64}
          type={CsvUploadType.bin}
          stockLocationId={stockLocationId}
          configurations={configurations}
          deleteExisting={deleteExisting}
          setError={setError}
          handleUploadOverride={handleUploadOverride}
          onInspect={id => {
            setInspectId(id);
            setStatus(BinImportStatus.inspect);
          }}
        />
      );
    }

    if (status === BinImportStatus.inspect) {
      return (
        <CsvUploadConfigurationInspect
          headers={fileHeaders}
          configuration={otherConfigurations.find(c => c.id === inspectId)}
          onBack={handleBack}
        />
      );
    }

    return (
      <ModalPane
        testId={testIds.importBinFileUploadPane}
        footer={
          <Grid container>
            <Grid item>
              <a
                style={{ textDecoration: 'none' }}
                href={`${process.env.REACT_APP_STORAGE_URL}/ventory/bin_template.csv`}
                download='import_template.csv'
              >
                <Button onClick={() => {}} text={t().downloadTemplate.singular.label} />
              </a>
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                <Grid item>
                  <Button
                    disabled={getConfigurationsLoading}
                    style={'primary'}
                    onClick={handleClose}
                    testId={testIds.cancel}
                    text={t().cancel.singular.label}
                  />
                </Grid>
                <Grid item>
                  <Button
                    loading={getConfigurationsLoading}
                    disabled={!file}
                    onClick={handleFileUpload}
                    style={'secondary'}
                    testId={testIds.next}
                    text={t().next.singular.label}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      >
        <Grid container height={'100%'} alignContent={'space-between'} data-testid={testIds.importBinFileUploadPane}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <UploadCsvInput file={file} onFile={handleFile} />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  value={deleteExisting}
                  label={t().deleteAllExistingBins.singular.label}
                  onChange={v => setDeleteExisting(v)}
                  testId={testIds.deleteExisting}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    );
  };

  return (
    <Modal
      open={open}
      error={error || errorOverride}
      width='70%'
      onClose={handleClose}
      height='650px'
      title={t().importBins.singular.label}
      testId={testIds.importBinModal}
    >
      {content()}
    </Modal>
  );
}
