type Key = 'Enter' | 'ArrowDown' | 'ArrowUp' | 'Escape' | 'Tab' | 'Shift';

export const handleKeyEvent = async (
  event: React.KeyboardEvent | KeyboardEvent,
  key: Key,
  handler: (event: React.KeyboardEvent | KeyboardEvent) => any | Promise<any>,
) => {
  if (event.key !== key) return;
  return handler(event);
};
