import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { StockLocationAggregateType } from '../../../../../types/common/stockLocationAggregateType';
import {
  CreateTriggerInput,
  Trigger,
  validStockLocationAggregateTypesForTriggerEntityType,
} from '../../../../../types/trigger';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';
import Selector from '../../../../Common/Selector';
import ToggleButton from '../../../../Common/ToggleButton';

interface CreateTriggerStockLocationInputProps {
  trigger: CreateTriggerInput;
  setTrigger: (trigger: Trigger) => void;
  next: () => void;
  back: () => void;
  loading: boolean;
}

export function CreateTriggerStockLocationPane({
  trigger,
  setTrigger,
  next,
  back,
  loading,
}: CreateTriggerStockLocationInputProps) {
  const { stockLocations } = useContext(StockLocationContext);

  return (
    <ModalPane
      testId={testIds.createTriggerStockLocationPane}
      footerButtons={[
        BackButtonTemplate(back, { disabled: loading }),
        NextButtonTemplate(next, {
          disabled: !validStockLocationAggregateTypesForTriggerEntityType(trigger.entityType).includes(
            trigger.stockLocationAggregateType,
          ),
          loading: loading,
        }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <ToggleButton
                testId={testIds.triggerStockLocationAggregateType}
                onChange={value => setTrigger(trigger.withStockLocationAggregate(value as StockLocationAggregateType))}
                selected={trigger.stockLocationAggregateType}
                values={validStockLocationAggregateTypesForTriggerEntityType(trigger.entityType)}
                text={value => {
                  switch (value as StockLocationAggregateType) {
                    case StockLocationAggregateType.all:
                      return t().all.singular.label;
                    case StockLocationAggregateType.allOf:
                      return t().allOf.singular.label;
                    case StockLocationAggregateType.any:
                      return t().any.singular.label;
                    case StockLocationAggregateType.anyOf:
                      return t().anyOf.singular.label;
                  }
                }}
              />
            </Grid>
            {trigger.stockLocationAggregateType === StockLocationAggregateType.allOf ||
            trigger.stockLocationAggregateType === StockLocationAggregateType.anyOf ? (
              <Grid item xs={12}>
                <Selector
                  testId={testIds.stockLocation}
                  placeholder={t().triggerStockLocationText.singular.label}
                  values={[...[...stockLocations.values()].map(sl => sl.id)]}
                  checkedValues={trigger.stockLocationIds}
                  toText={item => stockLocations.get(item)?.name || 'Unknown'}
                  onChange={checked => setTrigger(trigger.withStockLocationIds(checked))}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
