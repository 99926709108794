import React from 'react';
import { ProductMasterData } from '../../../../types/productMasterData';
import ReorderRulesOverviewPane from '../../../Common/ReorderRules/ReorderRulesOverviewPane';

interface ProductMasterDataReorderRulesPane {
  productMasterData?: ProductMasterData;
}

export default function ProductMasterDataReorderRulesPane({ productMasterData }: ProductMasterDataReorderRulesPane) {
  return <ReorderRulesOverviewPane productMasterDataId={productMasterData?.id} />;
}
