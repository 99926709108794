import React from 'react';
import { taskStatusToString } from '../../../../../types/task';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function TaskStatusFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.taskStatus || [])]}
      text={item => taskStatusToString(item, item === 'overdue')}
      onRemove={i => {
        filter.taskStatus?.delete(i);
        if (!filter.taskStatus?.size) filter.taskStatus = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
