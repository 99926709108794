import { Grid } from '@mui/material';
import './Content.scss';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface ContentProps {
  children: JSX.Element | JSX.Element[];
}

export function Content({ children }: ContentProps) {
  const location = useLocation();

  return (
    <Grid
      container
      className='ventory-content'
      height={location.pathname.includes('/admin/') ? 'calc(100vh - 40px)' : '100vh'}
    >
      {children}
    </Grid>
  );
}
