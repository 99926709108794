import { Address } from '../../../../types/common/address';
import { toFilterString } from '../../../../util/string.util';

export namespace AddressFilter {
  export function search(item: Address, textFilter: string) {
    if (
      toFilterString(item.addressLine1).includes(textFilter) ||
      toFilterString(item.addressLine2).includes(textFilter) ||
      toFilterString(item.city).includes(textFilter) ||
      toFilterString(item.region).includes(textFilter) ||
      toFilterString(item.postalCode).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }
}
