import React from 'react';
import { EntityFilter } from '../../filter.util';
import { Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import { toFilterString } from '../../../../../util/string.util';

interface ManageTypeFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

const manageTypeFilter = (type: string, textFilter: string, filter: EntityFilter) => {
  return toFilterString(type).includes(textFilter);
};

export type ManageType = 'serial' | 'lpn' | 'lot';
const MANAGE_TYPES: Set<ManageType> = new Set(['serial', 'lpn', 'lot']);
export function manageTypeToString(manageType: ManageType) {
  switch (manageType) {
    case 'serial':
      return t().serialManaged.singular.label;
    case 'lpn':
      return t().lpnManaged.singular.label;
    case 'lot':
      return t().lotManaged.singular.label;
  }
}
export function filterToManageTypes(filter: EntityFilter) {
  const result = new Set<ManageType>();
  if (filter.serialManaged) result.add('serial');
  if (filter.lpnManaged) result.add('lpn');
  if (filter.lotManaged) result.add('lot');
  return result;
}

export default function ManageTypeFilterContent({ className, filter, color, setFilter }: ManageTypeFilterContentProps) {
  const handleChange = (selected: Set<ManageType>) => {
    filter.serialManaged = selected.has('serial');
    filter.lpnManaged = selected.has('lpn');
    filter.lotManaged = selected.has('lot');

    setFilter({ ...filter });
  };

  const handleRemove = () => {
    filter.serialManaged = undefined;
    filter.lpnManaged = undefined;
    filter.lotManaged = undefined;
    setFilter({ ...filter });
  };

  return (
    <Grid item xs={12} className='w-full'>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().bin.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filterToManageTypes(filter)}
            values={MANAGE_TYPES}
            onChange={selected => handleChange(selected as Set<ManageType>)}
            toText={type => manageTypeToString(type as ManageType)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
