import React, { useContext, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { TaskContext } from '../../../context/TaskContext';
import LoadingPackage from '../../Common/LoadingPackage';
import TaskInfoPane from './TaskInfoPane';
import { useMutation } from '@apollo/client';
import {
  DeleteTaskResponse,
  DeleteTaskVariables,
  OrderByTaskResponse,
  OrderByTaskVariables,
  TaskMutations,
} from '../../../graphql/task.graphql';
import { Task, TaskStatus } from '../../../types/task';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import TaskUsersPane from './TaskUsersPane';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import { CompanyRole } from '../../../types/companyRoleAssignment';
import UpdateTaskAssigneesModal from '../Modals/UpdateTaskAssigneesModal';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Button } from '../../../VentoryUI/components/common/Button/Button';
import BackButton from '../../../VentoryUI/components/common/Button/Templates/BackButton';
import DeleteButton from '../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import { ColorStyleType } from '../../../VentoryUI/util/color.util';
import { CompanyContext } from '../../../context/CompanyContext';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const tabs = () => [
  {
    text: t().info.singular.label,
    path: 'info',
    key: 'info',
  },
  {
    text: t().user.plural.label,
    path: 'users',
    key: 'users',
  },
];

export default function TaskDetailPane() {
  const id = useParams()['id'] || '';

  const { currentCompany } = useContext(CompanyContext);
  const { tasks, setTasks, tasksLoading } = useContext(TaskContext);
  const task = tasks.get(id);
  if (!task) return null;

  const navigate = useNavigate();

  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);

  const [taskInput, setTaskInput] = useState(new Task(task));
  const [updateTaskAssigneesModalOpen, setUpdateTaskAssigneesModalOpen] = useState(false);

  const [error, setError] = useState<string>('');

  const [remove, { loading: removeLoading }] = useMutation<DeleteTaskResponse, DeleteTaskVariables>(
    TaskMutations.remove,
    {
      onCompleted: res => {
        const task = res.deleteTask[0];
        tasks.delete(task.id);
        setTasks(new Map(tasks));
        navigate('/tasks');
      },
      onError: err => setError(err.message),
    },
  );

  const [moveOrder, { loading: moveOrderLoading }] = useMutation<OrderByTaskResponse, OrderByTaskVariables>(
    TaskMutations.orderByTask,
    {
      onCompleted: res => {
        const task = res.orderByTask;
        tasks.set(task.id, new Task(task));
        setTasks(tasks);
        navigate('/operations/orders');
      },
      onError: err => setError(err.message),
    },
  );

  const handleDelete = async () => {
    try {
      await remove({
        variables: {
          tasks: [taskInput.forDelete()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleMoveOrder = async () => {
    try {
      await moveOrder({
        variables: {
          taskId: taskInput.id,
          companyId: taskInput.companyId,
          stockLocationId: taskInput.stockLocationId,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const loading = removeLoading || moveOrderLoading;

  const footer = () => (
    <Grid container>
      {hasStockLocationRole(task.companyId, task.stockLocationId, StockLocationRole.STOCK_LOCATION_MANAGER) ||
      hasCompanyRole(task.companyId, CompanyRole.administrator) ? (
        <>
          <Grid item>
            <DeleteButton onClick={handleDelete} loading={removeLoading} disabled={loading} />
          </Grid>
        </>
      ) : null}

      <Grid item flexGrow={1}>
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          {hasCompanyRole(taskInput.companyId, CompanyRole.administrator) ||
          hasStockLocationRole(
            taskInput.companyId,
            taskInput.stockLocationId,
            StockLocationRole.STOCK_LOCATION_SUPERVISOR,
          ) ? (
            <>
              {task.status === TaskStatus.complete &&
              currentCompany.settings.featureToggles.tasks.taskOrders.taskOrders ? (
                <Grid item>
                  <Button
                    style={ColorStyleType.secondary}
                    text={t().createOrder.plural.label}
                    onClick={handleMoveOrder}
                    loading={moveOrderLoading}
                    disabled={loading}
                    startIcon={<NoteAddIcon />}
                  />
                </Grid>
              ) : null}
              <Grid item>
                <Button
                  disabled={loading}
                  onClick={() => setUpdateTaskAssigneesModalOpen(true)}
                  testId={testIds.assignUsers}
                  text={t().reassignUsers.singular.label}
                  startIcon={<GroupAddIcon />}
                />
              </Grid>
            </>
          ) : null}
          <Grid item>
            <BackButton onClick={() => navigate('/tasks')} disabled={loading} style='secondary' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <UpdateTaskAssigneesModal
        task={taskInput}
        setTask={setTaskInput}
        open={updateTaskAssigneesModalOpen}
        setOpen={setUpdateTaskAssigneesModalOpen}
      />

      <Pane error={error} tabs={tabs()} testId={testIds.taskDetailPane}>
        {tasksLoading && !tasks.get(id) ? (
          <Paper>
            <Grid container alignItems={'center'}>
              <Grid item className='fill-gray-300 ' marginX={'auto'}>
                <LoadingPackage />
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Routes>
            <Route path='info' element={<TaskInfoPane task={taskInput} footer={footer} />} />
            <Route path='users' element={<TaskUsersPane task={taskInput} footer={footer} />} />
          </Routes>
        )}
      </Pane>
    </>
  );
}
