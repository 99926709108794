import React, { useContext, useState } from 'react';
import { t } from '../../../../../../types/translation/Translator';
import { Bin } from '../../../../../../types/bin';
import BinInfoPane from '../Panes/BinInfoPane';
import { Grid } from '@mui/material';
import { BinMutations, CreateBinResponse, CreateBinVariables } from '../../../../../../graphql/bin.graphql';
import { useMutation } from '@apollo/client';
import { BinContext } from '../../../../../../context/BinContext';
import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';
import BackButton from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import CreateButton from '../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';

interface CreateBinModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bin?: Bin;
}

export default function CreateBinModal({ open, setOpen, bin }: CreateBinModalInputProps) {
  const { bins, setBins } = useContext(BinContext);

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateBinResponse, CreateBinVariables>(BinMutations.create, {
    onCompleted: res => {
      const bin = res.createBin[0];
      bins.set(bin.id, new Bin(bin));
      setBins(new Map(bins));
      handleClose();
    },
    onError: res => setError(res.message),
  });

  if (!bin) return null; // TODO: Not found

  const handleCreate = async (bin: Bin) => {
    try {
      bin.validate();

      await create({
        variables: {
          bins: [bin],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const footer = (bin: Bin) => (
    <Grid container marginTop={'auto'} columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={handleClose} />
      </Grid>
      <Grid item>
        <CreateButton loading={loading} onClick={() => handleCreate(bin)} />
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      height='250px'
      error={error}
      onClose={handleClose}
      title={t().createBin.singular.label}
      testId={testIds.createBinModal}
    >
      <BinInfoPane bin={bin} footer={footer} />
    </Modal>
  );
}
