import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function CheckIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg
        width='12'
        height='9'
        viewBox='0 0 12 9'
        fill='none'
        stroke={color || 'currentColor'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M10.6667 1L4.25004 7.41667L1.33337 4.5' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </Icon>
  );
}
