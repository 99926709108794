import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { ChartData } from '../Chart';
import React from 'react';

export interface VerticalAxisProps {
  width: number;
  height: number;
  data: ChartData[];
  margin: { left: number; bottom: number };
}

export default function VerticalAxis({ width, height, data, margin }: VerticalAxisProps) {
  const ref = useRef<SVGGElement>(null);

  const transform = `translate(${margin.left + margin.left}, -${margin.bottom / 2})`;

  const scale = d3
    .scaleLinear()
    .domain([0, Math.max(...data.map(({ value }) => value))])
    .range([height - margin.bottom / 2, 60]);

  const axisColor = 'transparant';
  const gridColor = '#F2F4F7';
  const showGrid = true;

  useEffect(() => {
    if (ref.current) {
      const svg = d3.select(ref.current).attr('class', 'yAxis');

      svg.call(d3.axisLeft(scale));
      svg.selectAll('path').attr('stroke', axisColor);
      svg.selectAll('line').attr('stroke', axisColor);
      svg.selectAll('text').attr('fill', 'rgba(71, 84, 103, 1)').attr('font-size', `12px`);
      svg.selectAll('text').attr('font-weight', '400');

      if (showGrid) {
        svg
          .selectAll('g.yAxis g.tick')
          .append('line')
          .attr('class', 'gridline')
          .attr('x1', 0)
          .attr('y1', 0)
          .attr('x2', width - margin.left - 2 * margin.left)
          .attr('y2', 0)
          .attr('stroke', gridColor)
          .attr('strokeWidth', 1);
      }
    }
  }, [scale, margin.left, showGrid, width]);

  return <g ref={ref} transform={transform} />;
}
