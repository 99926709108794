import React from 'react';

interface FileNotFoundProps {}

export default function FileNotFound({}) {
  return (
    <div className='flex items-center justify-center'>
      <img src='?' />
    </div>
  );
}
