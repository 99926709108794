import { t } from '../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function SaveButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.save,
    text: t().save.singular.label,
    onClick: onClick,
    style: 'secondary',
    ...props,
  };
}

interface SaveButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function SaveButton({ onClick, ...props }: SaveButtonProps) {
  return <Button {...SaveButtonTemplate(onClick, props)} />;
}
