import { ClickAwayListener, Divider, Grid } from '@mui/material';
import React, { useState } from 'react';
import { t } from '../../types/translation/Translator';
import { classes } from '../../util/identifiers/identifiers.util';
import SearchBar from './SearchBar';
import DropdownLabel from './DropdownLabel';
import { removeDiacritics, toFilterString } from '../../util/string.util';

interface SelectItem<K> {
  id: string;
  value: K;
}

interface MultiSelectValue<K> {
  item: SelectItem<K>;
  value: string;
  index: number;
  checked: boolean;
}

interface SelectorWithDropDownInputProps<K> {
  values: SelectItem<K>[];
  checkedValues: SelectItem<K>[];
  selectAll?: boolean;
  toText: (item: SelectItem<K>) => string;
  toDropdownText: (item: K) => string;
  onChange: (checked: SelectItem<K>[]) => void;
  placeholder?: string;
  disabled?: (item: SelectItem<K>) => boolean;
  id?: string;
  toValue: (item: string) => K;
  dropdownValues: K[];
  maxHeight?: string;
}

function valuesToMultiSelectValues<K>(
  values: SelectItem<K>[],
  checkedValues: SelectItem<K>[],
  toString: (item: SelectItem<K>) => string,
) {
  const stringValueMap: Map<string, SelectItem<K>> = new Map(values.map(value => [String(toString(value)), value]));
  const stringCheckedValueMap: Map<string, SelectItem<K>> = new Map(
    checkedValues.map(value => [String(toString(value)), value]),
  );

  return [...new Set([...stringValueMap.values(), ...stringCheckedValueMap.values()]).values()].map((item, idx) => ({
    item,
    value: toString(item),
    index: idx,
    checked: stringCheckedValueMap.has(toString(item)) ? true : false,
  }));
}

export default function SelectorWithDropdown<K>({
  values,
  checkedValues,
  selectAll = true,
  onChange,
  toText,
  toDropdownText,
  placeholder,
  disabled = item => false,
  dropdownValues,
  toValue,
  id,
  maxHeight = '300px',
}: SelectorWithDropDownInputProps<K>) {
  const [hidden, setHidden] = useState<boolean>(true);
  const [items, setItems] = useState<MultiSelectValue<K>[]>(valuesToMultiSelectValues(values, checkedValues, toText));
  const [filter, setFilter] = useState<string>('');

  const [scrollTop, setScrollTop] = useState<number>(0);

  const checkedItems = new Set(items.filter(i => i.checked).map(i => i.value));
  const filteredItems = new Set(
    items.filter(i => removeDiacritics(toFilterString(i.value)).includes(filter)).map(i => i.value),
  );

  const selectAllState =
    checkedItems.size === filteredItems.size &&
    [...checkedItems.values()].filter(i => filteredItems.has(i)).length === filteredItems.size;

  const handleChange = (item: MultiSelectValue<K>, v?: string) => {
    if (disabled(item.item)) return;
    if (!v) item.checked = !item.checked;
    else item.item.value = toValue(v);
    setItems([...items]);
    onChange(items.filter(i => i.checked).map(i => i.item));
  };

  const handleSelectAll = () => {
    items.forEach(i => {
      if (disabled(i.item)) return;
      if (removeDiacritics(toFilterString(i.value)).includes(filter)) i.checked = !selectAllState;
      else i.checked = false;
    });
    setItems([...items]);
    onChange(items.filter(i => i.checked).map(i => i.item));
  };

  const handleScroll = (event?: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(event?.currentTarget.scrollTop || 0);
  };

  const dropdownContent = () => {
    if (!filteredItems.size) {
      return (
        <Grid item xs={12} textAlign={'center'}>
          <p className='font-semibold text-gray-300'>{t().noItemsFound.singular.label}</p>
        </Grid>
      );
    }

    const visibleItems = items
      .filter(i => removeDiacritics(toFilterString(i.value)).includes(filter))
      .splice(0, 15 + scrollTop / 20);

    return (
      <Grid container id={id} className={classes.selector.name}>
        {selectAll ? (
          <Grid item xs={12} key={`selectAll`}>
            <Grid container marginY={'auto'}>
              <Grid
                item
                display='flex'
                justifyContent={'flex-start'}
                marginY={'auto'}
                width={'30px'}
                className={classes.selectAll.name}
              >
                <input
                  readOnly
                  checked={selectAllState}
                  type='checkbox'
                  name='bordered-checkbox'
                  className='w-4 h-4 accent-ventory-blue-900 rounded'
                  onClick={handleSelectAll}
                  // onKeyDown={event => handleKeyEvent(event, 'Enter', handleSelectAll)}
                />
              </Grid>
              <Grid item marginY={'auto'}>
                <p className='text-sm select-none' onClick={handleSelectAll}>
                  {`${t().selectAll.singular.label} (${checkedValues.length})`}
                </p>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {selectAll ? (
          <Grid item my={1} xs={12}>
            <Divider />
          </Grid>
        ) : null}
        <Grid container maxHeight={maxHeight} onScroll={handleScroll} className='overflow-auto'>
          {visibleItems.map((item, index, arr) => {
            return (
              <Grid item mb={1} xs={12} key={`${item}${index}`} className={classes.selectorItem.name}>
                <Grid container justifyContent={'space-between'} columnSpacing={1} marginY={'auto'}>
                  <Grid item>
                    <Grid container>
                      <Grid item display='flex' marginY={'auto'} width={'30px'}>
                        <input
                          disabled={disabled(item.item)}
                          readOnly
                          checked={item.checked}
                          type='checkbox'
                          name='bordered-checkbox'
                          className='w-4 h-4 accent-ventory-blue-900 rounded'
                          onClick={() => handleChange(item)}
                          // onKeyDown={event => handleKeyEvent(event, 'Enter', () => handleChange(item))}
                        />
                      </Grid>
                      <Grid item marginY={'auto'}>
                        <p className='text-sm select-none' onClick={() => handleChange(item)}>
                          {item.value}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  {item.checked ? (
                    <Grid item marginY={'auto'}>
                      <DropdownLabel
                        ml={0}
                        items={dropdownValues.map(item => toDropdownText(item))}
                        value={toDropdownText(item.item.value)}
                        toText={item => item}
                        onChange={v => handleChange(item, v)}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setHidden(true)}>
      <Grid container width={'100%'} onClick={() => setHidden(false)}>
        <Grid container>
          <Grid item flexGrow={1} my={'auto'}>
            <SearchBar placeholder={placeholder} onChange={v => setFilter(removeDiacritics(toFilterString(v)))} />
          </Grid>
        </Grid>
        <Grid item xs={12} className='relative inline-block w-full'>
          <Grid container py={2} px={1} alignContent={'flex-start'}>
            {dropdownContent()}
          </Grid>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
