import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import TextInput from '../../../Common/TextInput';
import { t } from '../../../../types/translation/Translator';
import CachedIcon from '@mui/icons-material/Cached';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useMutation, useQuery } from '@apollo/client';
import {
  GenerateVentoryApiKeyResponse,
  GenerateVentoryApiKeyVariables,
  GetVentoryApiKeyResponse,
  GetVentoryApiKeyVariables,
  VentoryApiMutations,
  VentoryApiQueries,
} from '../../../../graphql/api.graphql';
import { CompanyContext } from '../../../../context/CompanyContext';

import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface DeveloperSettingsPaneProps {
  setError: (error: string) => void;
}

export default function DeveloperSettingsPane({ setError }: DeveloperSettingsPaneProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [visible, setVisible] = useState<boolean>(false);
  const [key, setKey] = useState<string>('');

  const { loading: loadingGet } = useQuery<GetVentoryApiKeyResponse, GetVentoryApiKeyVariables>(VentoryApiQueries.get, {
    variables: {
      companyId: currentCompany.id,
    },
    onCompleted: res => {
      setKey(res.ventoryApiKey?.key || '');
    },
    onError: err => setError(err.message),
  });

  const [generate, { loading: loadingGenerate }] = useMutation<
    GenerateVentoryApiKeyResponse,
    GenerateVentoryApiKeyVariables
  >(VentoryApiMutations.generate, {
    variables: {
      companyId: currentCompany.id,
    },
    onCompleted: res => {
      setKey(res.generateApiKey.key);
      setVisible(true);
    },
    onError: err => setError(err.message),
  });

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={6}>
              <TextInput
                dynamicUpdate
                label={t().apiKey.singular.label}
                placeholder={t().generateApiKey.singular.label}
                disabled
                value={visible ? key : '*******************'}
                onChange={() => {}}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <Button
                    disabled={loadingGet || loadingGenerate || !key}
                    startIcon={visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    onClick={() => setVisible(!visible)}
                    text={visible ? t().hide.singular.label : t().show.singular.label}
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={loadingGet}
                    loading={loadingGenerate}
                    startIcon={<CachedIcon />}
                    onClick={() => generate()}
                    text={t().generateApiKey.singular.label}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
