import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateOrderExportConfigurationInput,
  DeleteOrderExportConfigurationInput,
  OrderExportConfiguration,
  UpdateOrderExportConfigurationInput,
} from '../types/orderExportConfiguration';

const orderExportConfiguration = gql`
  fragment OrderExportConfiguration on OrderExportConfiguration {
    id
    version
    companyId
    name
    filename
    prefix
    delimiter
    quote
    splitByOrder
    default
    fieldMapping {
      index
      customField
      field
    }
  }
`;

const get = gql`
  query OrderExportConfigurations($companyId: String!, $page: Float) {
    orderExportConfigurations(companyId: $companyId, page: $page) {
      data {
        ...OrderExportConfiguration
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${orderExportConfiguration}
`;

export interface GetOrderExportConfigurationsVariables {
  companyId: string;
  page?: number;
}

export interface GetOrderExportConfigurationsResponse {
  orderExportConfigurations: PaginatedOrderExportConfigurations;
}

class PaginatedOrderExportConfigurations extends forPaginated<OrderExportConfiguration>() {}

const create = gql`
  mutation CreateOrderExportConfiguration($orderExportConfigurations: [CreateOrderExportConfigurationInput!]!) {
    createOrderExportConfiguration(orderExportConfigurations: $orderExportConfigurations) {
      ...OrderExportConfiguration
    }
  }
  ${orderExportConfiguration}
`;

export interface CreateOrderExportConfigurationVariables {
  orderExportConfigurations: CreateOrderExportConfigurationInput[];
}

export interface CreateOrderExportConfigurationResponse {
  createOrderExportConfiguration: OrderExportConfiguration[];
}

const update = gql`
  mutation UpdateOrderExportConfiguration($orderExportConfigurations: [UpdateOrderExportConfigurationInput!]!) {
    updateOrderExportConfiguration(orderExportConfigurations: $orderExportConfigurations) {
      ...OrderExportConfiguration
    }
  }
  ${orderExportConfiguration}
`;

export interface UpdateOrderExportConfigurationVariables {
  orderExportConfigurations: UpdateOrderExportConfigurationInput[];
}

export interface UpdateOrderExportConfigurationResponse {
  updateOrderExportConfiguration: OrderExportConfiguration[];
}

const remove = gql`
  mutation DeleteOrderExportConfiguration($orderExportConfigurations: [DeleteOrderExportConfigurationInput!]!) {
    deleteOrderExportConfiguration(orderExportConfigurations: $orderExportConfigurations) {
      ...OrderExportConfiguration
    }
  }
  ${orderExportConfiguration}
`;

export interface DeleteOrderExportConfigurationVariables {
  orderExportConfigurations: DeleteOrderExportConfigurationInput[];
}

export interface DeleteOrderExportConfigurationResponse {
  deleteOrderExportConfiguration: OrderExportConfiguration[];
}

export const OrderExportConfigurationQueries = {
  get,
};

export const OrderExportConfigurationMutations = {
  create,
  update,
  remove,
};
