import { Grid, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

interface DropdownLabelProps {
  items: string[];
  toText: (item: string) => string;
  onChange: (item: string) => void;
  value: string;
  ml?: number;
}

export default function DropdownLabel({ items, toText, onChange, value, ml = 0.6 }: DropdownLabelProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const [input, setInput] = useState<string>(value);

  const handleClose = (item?: string) => {
    setAnchorEl(null);
    if (!item) return;
    onChange(item);
    setInput(item);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid container ml={ml}>
      <Grid item display={'flex'} marginY={'auto'} className='cursor-pointer select-none' onClick={handleClick}>
        <p className='text-sm text-gray-600'>{toText(input)}</p>
        <ArrowDropDownOutlinedIcon className='text-gray-400' sx={{ fontSize: '1.2rem', marginY: 'auto' }} />
      </Grid>
      <Grid item>
        <Menu
          sx={{ marginTop: '0.2rem' }}
          id='filter-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
        >
          {items.map(item => {
            return (
              <MenuItem key={item} disableRipple onClick={() => handleClose(item)}>
                <Grid container columnSpacing={1}>
                  <Grid item marginY={'auto'}>
                    <p className='text-sm font-sm'>{toText(item)}</p>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </Menu>
      </Grid>
    </Grid>
  );
}
