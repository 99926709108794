import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { OrderType } from './order';

export class OrderSettings extends CompanyEntity {
  prefix?: string;
  suffix?: string;
  digits: number = 5;
  type: OrderType = OrderType.inbound;
  sendPushNotificationToAssignees: boolean = false;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateOrderSettingsInput {
    return UpdateOrderSettingsInput.from(this, UpdateOrderSettingsInput);
  }

  override forDelete(): DeleteOrderSettingsInput {
    return DeleteOrderSettingsInput.from(this, DeleteOrderSettingsInput);
  }

  override validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof OrderSettings)[],
      (field: keyof OrderSettings) => {
        return null;
      },
    );
  }

  withPrefix(prefix: string) {
    this.prefix = prefix;
    return cloneDeep(this);
  }

  withSuffix(suffix: string) {
    this.suffix = suffix;
    return cloneDeep(this);
  }

  withDigits(value: string) {
    (this.digits as any) = parseFloat(value) || 0;
    if (this.digits < 3) this.digits = 3;
    return cloneDeep(this);
  }

  withSendPushNotificationToAssignees(value: boolean) {
    this.sendPushNotificationToAssignees = value;
    return cloneDeep(this);
  }
}

export class CreateOrderSettingsInput extends forCreate(OrderSettings) {}

export class UpdateOrderSettingsInput extends forUpdate(OrderSettings) {}

export class DeleteOrderSettingsInput extends forDelete(OrderSettings) {}
