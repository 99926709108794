import React, { useState } from 'react';
import { StockLocation } from '../../../../types/stockLocation';
import { Grid } from '@mui/material';
import { t } from '../../../../types/translation/Translator';
import TextInput from '../../../Common/TextInput';
import DropdownSelect from '../../../Common/DropdownSelect';
import { countries } from '../../../../util/country';
import ClickableMap from '../../../Common/ClickableMap';
import * as service from 'azure-maps-rest';
import { addressToString } from '../../../../types/common/address';
import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface StockLocationMapInputProps {
  stockLocation?: StockLocation;
  footer: (input: StockLocation) => JSX.Element;
}

export default function StockLocationMapPane({ stockLocation, footer }: StockLocationMapInputProps) {
  if (!stockLocation) return null; // TODO: Not found

  const [stockLocationInput, setStockLocationInput] = useState<StockLocation>(new StockLocation(stockLocation));
  const [loading, setLoading] = useState<boolean>(false);

  const hasInvalidAddress =
    !stockLocationInput.address?.addressLine1 &&
    !stockLocationInput.address?.addressLine2 &&
    !stockLocationInput.address?.city &&
    !stockLocationInput.address?.countryCode &&
    !stockLocationInput.address?.region;

  const getCoordinates = async () => {
    if (hasInvalidAddress) {
      stockLocationInput.withCoordinates(undefined);
      return;
    }

    setLoading(true);
    const key = process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY || 'no_key';
    const pipeline = service.MapsURL.newPipeline(new service.SubscriptionKeyCredential(key));
    const searchURL = new service.SearchURL(pipeline);
    await searchURL
      .searchAddress(service.Aborter.timeout(3000), addressToString(stockLocationInput.address))
      .then(results => {
        if (!results.results?.length) {
          if (stockLocationInput.address) {
            stockLocationInput.withCoordinates(undefined);
          }
          return;
        }
        const { position } = results.results[0];
        if (position && position.lat && position.lon && stockLocationInput.address) {
          stockLocationInput.withCoordinates({
            latitude: position.lat,
            longitude: position.lon,
          });
        }
      });
    setLoading(false);
  };

  return (
    <FlexPane
      content={
        <Paper>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6}>
              <ClickableMap
                coordinates={stockLocationInput.address?.coordinates}
                zoom={12}
                onClick={coord => {
                  setStockLocationInput(stockLocationInput.withCoordinates(coord));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    disabled={loading}
                    value={stockLocationInput.address?.addressLine1}
                    label={t().addressLine1.singular.label}
                    placeholder={t().addressLine1.singular.label}
                    onChange={v => stockLocationInput.withAddressLine1(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={loading}
                    value={stockLocationInput.address?.addressLine2}
                    label={t().addressLine2.singular.label}
                    placeholder={t().addressLine2.singular.label}
                    onChange={v => stockLocationInput.withAddressLine2(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={loading}
                    value={stockLocationInput.address?.postalCode}
                    label={t().postalCode.singular.label}
                    placeholder={t().postalCode.singular.label}
                    onChange={v => stockLocationInput.withPostalCode(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={loading}
                    value={stockLocationInput.address?.city}
                    label={t().city.singular.label}
                    placeholder={t().city.singular.label}
                    onChange={v => stockLocationInput.withCity(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={loading}
                    value={stockLocationInput.address?.region}
                    label={t().region.singular.label}
                    placeholder={t().region.singular.label}
                    onChange={v => stockLocationInput.withRegion(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropdownSelect
                    placeholder={t().country.singular.label}
                    disabled={loading}
                    label={t().country.singular.label}
                    selectedValue={countries.find(c => c.cca2 === stockLocationInput.address?.countryCode) || null}
                    maxHeight='150px'
                    values={countries}
                    toText={(item: any) => item.name.common}
                    onChange={(item: any) =>
                      setStockLocationInput(stockLocationInput.withCountryCode(item ? item.cca2 : undefined))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={'flex-end'}>
                    <Grid item>
                      <Button
                        disabled={hasInvalidAddress && !stockLocationInput.address?.coordinates}
                        style='secondary'
                        onClick={getCoordinates}
                        loading={loading}
                        text={t().syncAddress.singular.label}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={footer(stockLocationInput)}
    />
  );
}
