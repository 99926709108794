import React from 'react';
import { CustomField } from '../types/customField';

export interface CustomFieldContextProps {
  customFields: Map<string, CustomField>;
  setCustomFields: (fields: Map<string, CustomField>) => void;
  customFieldsLoading: boolean;
}

const defaultContext: CustomFieldContextProps = {
  customFields: new Map(),
  setCustomFields: () => {},
  customFieldsLoading: false,
};

export const CustomFieldContext = React.createContext<CustomFieldContextProps>(defaultContext);
