import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { StockLocationAggregateType } from './common/stockLocationAggregateType';
import { TriggerType } from './trigger';
import { CompanyRole } from './companyRoleAssignment';
import { StockLocationRole } from './stockLocationRoleAssignment';

export class ReorderRule extends CompanyEntity {
  stockLocationId?: string;
  productMasterDataId?: string | null;
  stockLocationAggregateType: StockLocationAggregateType = StockLocationAggregateType.anyOf;
  excludedBinStatusIds: string[] = [];
  excludedBinIds: string[] = [];
  automaticallyCreateOrder: boolean = false;
  reorderQuantity!: string;
  replenishQuantity!: string;
  groupId!: string;
  name!: string;
  triggerTypes: TriggerType[] = [TriggerType.alert];
  notificationCompanyRoles: CompanyRole[] = [CompanyRole.employee];
  notificationStockLocationRoles: StockLocationRole[] = [StockLocationRole.STOCK_LOCATION_MANAGER];
  notificationUserIds: string[] = [];

  constructor(obj: any) {
    super(obj.companyId);
    if (!obj.companyId) return;
    Object.assign(this, cloneDeep(obj));
  }

  get anyOrAll(): boolean {
    return (
      this.stockLocationAggregateType === StockLocationAggregateType.any ||
      this.stockLocationAggregateType === StockLocationAggregateType.all
    );
  }

  toHash() {
    return `${this.productMasterDataId || ''}:${this.stockLocationId || ''}`;
  }

  forUpdate() {
    return UpdateReorderRuleInput.from(this, UpdateReorderRuleInput);
  }
  forDelete() {
    return DeleteReorderRuleInput.from(this, DeleteReorderRuleInput);
  }

  validate(fields: any[]) {
    throw new Error('Method not implemented.');
  }

  withProductMasterDataId(value?: string | null) {
    this.productMasterDataId = value;
    return cloneDeep(this);
  }

  withStockLocationAggregateType(value: StockLocationAggregateType) {
    this.stockLocationAggregateType = value;
    return cloneDeep(this);
  }

  withExcludedBinStatusIds(value: string[]) {
    this.excludedBinStatusIds = value;
    return cloneDeep(this);
  }

  withExcludedBinIds(value: string[]) {
    this.excludedBinIds = value;
    return cloneDeep(this);
  }

  withReorderQuantity(value: string) {
    this.reorderQuantity = value;
    return cloneDeep(this);
  }

  withReplenishQuantity(value: string) {
    this.replenishQuantity = value;
    return cloneDeep(this);
  }

  withAutomaticallyCreateOrder(value: boolean) {
    this.automaticallyCreateOrder = value;
    return cloneDeep(this);
  }

  withName(value: string) {
    this.name = value;
    return cloneDeep(this);
  }

  withNotificationCompanyRoles(value: CompanyRole[]) {
    if (!value.includes(CompanyRole.employee)) {
      this.notificationStockLocationRoles = [];
    }
    this.notificationCompanyRoles = value;
    return cloneDeep(this);
  }

  withNotificationStockLocationRoles(value: StockLocationRole[]) {
    this.notificationStockLocationRoles = value;
    return cloneDeep(this);
  }

  withNotificationUsers(value: string[]) {
    this.notificationUserIds = value;
    return cloneDeep(this);
  }

  withTriggerTypes(value: TriggerType[]) {
    this.triggerTypes = value;
    return cloneDeep(this);
  }
}

export class CreateReorderRuleInput extends forCreate(ReorderRule) {}

export class UpdateReorderRuleInput extends forUpdate(ReorderRule) {}

export class DeleteReorderRuleInput extends forDelete(ReorderRule) {}
