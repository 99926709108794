import React, { useContext, useMemo, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { t } from '../../../../types/translation/Translator';
import { handleKeyEvent } from '../../../../util/events.util';
import { classes } from '../../../../util/identifiers/identifiers.util';
import { FileEntity, FileEntityType } from '../../../../types/file';
import FileViewLoader from './FileViewLoader';
import FileUploadIcon from '../../../icons/File/FileUploadIcon';
import { CompanyContext } from '../../../../context/CompanyContext';
import { FileContext } from '../../../../context/FileContext';
import { LoadingSpinner } from '../../../../components/Common/LoadingSpinner';
import CloseIcon from '../../../icons/Close/CloseIcon';
import { Colors } from '../../../util/color.util';

const IMAGE_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.svg', '.webp', '.ico'];
const VIDEO_EXTENSIONS = ['.mp4', '.gif', '.webm'];

const ACCEPT_IMAGE = IMAGE_EXTENSIONS.join(',');
const ACCEPT_VIDEO = VIDEO_EXTENSIONS.join(',');

type AcceptType = 'image' | 'video' | 'csv' | string;

interface UploadFileInputProps {
  onFile: (file: FileList | null) => void;
  file?: FileEntity | string;
  height?: string;
  loading: boolean;
  id?: string;
  className?: string;
  acceptType?: AcceptType;
  fileDisplay?: React.ReactNode;
  subText?: string;
  multiple?: boolean;
  fileEntityType?: FileEntityType;
}

export default function UploadFileInput({
  onFile,
  file,
  height = '300px',
  loading,
  id,
  acceptType,
  className,
  fileDisplay,
  subText,
  multiple = false,
  fileEntityType,
}: UploadFileInputProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { currentCompany } = useContext(CompanyContext);
  const { files, filesLoading } = useContext(FileContext);

  const [dragging, setDragging] = useState<boolean>(false);

  const accept = useMemo(() => {
    switch (acceptType) {
      case 'image':
        return ACCEPT_IMAGE;
      case 'video':
        return ACCEPT_VIDEO;
      case 'csv':
        return '.csv';
      default:
        return acceptType;
    }
  }, [acceptType]);

  const fileEntity = useMemo(() => (typeof file === 'string' && !fileDisplay ? files.get(file) : file), [file, files]);

  const openFilePicker = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFilePickerChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    onFile(event.target.files);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(false);
    onFile(event.dataTransfer.files);
  };

  const handleRemoveFile = () => {
    onFile(null);
  };

  loading ||= filesLoading;

  const browseContent = (
    <Grid
      container
      height={height}
      alignContent={'center'}
      className='border-dashed border-ventory-grey-300 border-2 rounded select-none cursor-pointer'
    >
      {loading ? (
        <div className='m-auto'>
          <LoadingSpinner style='primary' size='24px' />
        </div>
      ) : (
        <>
          <Grid item xs={12} display='flex' justifyContent={'center'} marginBottom={'12px'}>
            <div className='bg-white h-[40px] w-[40px] rounded-[8px] flex items-center justify-center text-ventory-grey-600'>
              <FileUploadIcon />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className='text-center'>
              <p>
                <span style={{ color: Colors.companyDarkText(currentCompany), fontWeight: 500 }}>
                  {t().click.singular.label}
                </span>{' '}
                to browse or{' '}
                <span style={{ color: Colors.companyDarkText(currentCompany), fontWeight: 500 }}>
                  {t().dragAndDrop.singular.label}
                </span>{' '}
                a file here
              </p>
              {subText || accept ? (
                <p className='text-sm text-ventory-grey-500 font-normal'>
                  ({subText || accept?.split(',').join(', ')})
                </p>
              ) : null}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );

  const dragContent = (
    <Grid
      container
      height={height}
      alignContent={'center'}
      className='border-dashed border-2 border-blue-600 rounded select-none cursor-pointer'
    >
      {loading ? (
        <div className='m-auto'>
          <LoadingSpinner style='primary' size='24px' />
        </div>
      ) : (
        <>
          <Grid item xs={12} display='flex' justifyContent={'center'} marginBottom={'12px'}>
            <div className='bg-white h-[40px] w-[40px] rounded-[8px] flex items-center justify-center text-blue-600'>
              <FileUploadIcon />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className='text-center'>
              <p>
                <span style={{ color: Colors.companyDarkText(currentCompany), fontWeight: 500 }}>
                  {t().drop.singular.label}
                </span>{' '}
                to upload a file
              </p>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <Grid
      height={height}
      id={id}
      alignItems={'center'}
      justifyContent={'center'}
      className={`${classes.fileInput.name} ${className} relative`}
      tabIndex={0}
      container
      onDrop={handleDrop}
      onDragOver={e => {
        e.preventDefault();
        setDragging(true);
      }}
      onDragEnter={e => setDragging(true)}
      onDragLeave={e => setDragging(false)}
      onClick={openFilePicker}
      onKeyDown={event => handleKeyEvent(event, 'Enter', openFilePicker)}
    >
      {!fileEntity ? (
        <Grid item xs={12}>
          {dragging ? dragContent : browseContent}
          <input
            ref={fileInputRef}
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              event.currentTarget.value = '';
            }}
            accept={accept}
            onChange={handleFilePickerChange}
            style={{ display: 'none' }}
            type='file'
          />
        </Grid>
      ) : (
        <Grid
          container
          height={height}
          alignContent={'center'}
          className='border-dashed border-ventory-grey-300 border-2 rounded select-none cursor-pointer p-4'
        >
          <CloseIcon className='absolute top-[12px] right-[12px] cursor-pointer' onClick={handleRemoveFile} />
          <div className='h-[95%] w-full'>
            {fileDisplay || (
              <FileViewLoader
                fileId={typeof fileEntity === 'string' ? fileEntity : fileEntity.id}
                fileEntityType={fileEntityType}
              />
            )}
          </div>
        </Grid>
      )}
    </Grid>
  );
}
