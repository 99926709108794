import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { Bin } from '../../types/bin';
import { BinContext } from '../../context/BinContext';
import { BinFragment } from '../../graphql/bin.graphql';

const binSubscription = gql`
  subscription binNotification($companyId: String!) {
    binNotification(companyId: $companyId) {
      companyId
      entities {
        ...Bin
      }
      topic
      date
    }
  }
  ${BinFragment.bin}
`;

export class BinNotificationResponse extends forNotification<Bin>('binNotification') {}

export default function BinNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { bins, setBins } = useContext(BinContext);

  if (!currentCompany) return null;

  const { data: binNotification } = useSubscription<BinNotificationResponse>(binSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleBinNotification = (notification?: BinNotificationResponse) => {
    if (!notification?.binNotification) return;
    const binNotification = notification.binNotification;

    switch (binNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        binNotification.entities.forEach(bin => bins.set(bin.id, new Bin(bin)));
        break;
      case 'DELETE':
        binNotification.entities.forEach(bin => (bins.has(bin.id) ? bins.delete(bin.id) : null));
    }
    setBins(new Map(bins));
  };

  useEffect(() => {
    handleBinNotification(binNotification);
  }, [binNotification]);

  return <></>;
}
