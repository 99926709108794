import React from 'react';
import { StockLocation } from '../types/stockLocation';

export interface StockLocationContextProps {
  stockLocations: Map<string, StockLocation>;
  filteredStockLocations: Map<string, StockLocation>;
  setStockLocations: (stockLocations: Map<string, StockLocation>) => void;
  setFilteredStockLocations: (stockLocations: Map<string, StockLocation>) => void;
}

const defaultContext: StockLocationContextProps = {
  stockLocations: new Map(),
  setStockLocations: () => {},
  filteredStockLocations: new Map(),
  setFilteredStockLocations: () => {},
};

export const StockLocationContext = React.createContext<StockLocationContextProps>(defaultContext);
