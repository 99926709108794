import * as atlas from 'azure-maps-control';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { StockLocation } from '../types/stockLocation';
import { Coordinates } from '../types/common/address';
import { MobileStockLocationEvent, MobileStockLocationEventType } from '../graphql/mobileStockLocation.graphql';

dayjs.extend(relativeTime);

export class StockLocationWithProductQuantityMarker {
  private constructor(
    public readonly coordinates: Coordinates,
    public readonly stockLocation: StockLocation,
    public readonly quantity: number,
    public readonly event?: MobileStockLocationEvent,
  ) {}

  public static fromEvent(
    event: MobileStockLocationEvent,
    stockLocation: StockLocation,
    quantity: number,
  ): StockLocationWithProductQuantityMarker {
    return new StockLocationWithProductQuantityMarker(event.coordinates, stockLocation, quantity, event);
  }

  public static fromStockLocation(
    stockLocation: StockLocation,
    quantity: number,
  ): StockLocationWithProductQuantityMarker | undefined {
    if (!stockLocation.address?.coordinates) return;
    return new StockLocationWithProductQuantityMarker(stockLocation.address.coordinates, stockLocation, quantity);
  }

  public createMapFeature() {
    return new atlas.data.Feature(
      new atlas.data.Point([this.coordinates.longitude, this.coordinates.latitude]),
      {
        name: this.stockLocation.name || '',
        description: `Quantity: ${this.quantity}`,
        quantity: this.quantity,
        image: MarkerImage.orange,
        status: this.event ? mobileStockLocationEventToMarkerStatus(this.event) : '',
      },
      this.stockLocation.id,
    );
  }
}

export enum MarkerImage {
  red = 'marker-red',
  orange = 'marker-orange',
  green = 'marker-green',
  blue = 'marker-blue',
}

const tenMinutesInMilliseconds = 10 * 60 * 1000;
export const mobileSockLocationEventToMarkerImage = (event: MobileStockLocationEvent) => {
  switch (event.type) {
    case MobileStockLocationEventType.start:
      return MarkerImage.green;
    case MobileStockLocationEventType.stop:
      return MarkerImage.red;
    case MobileStockLocationEventType.update:
      if (new Date().getTime() - new Date(event.timestamp).getTime() > tenMinutesInMilliseconds)
        return MarkerImage.orange;
      return MarkerImage.green;
  }
};

export const mobileStockLocationEventToMarkerStatus = (event: MobileStockLocationEvent) => {
  const timeago = dayjs(event.timestamp).fromNow();
  switch (event.type) {
    case MobileStockLocationEventType.start:
      return `Service started ${timeago}`;
    case MobileStockLocationEventType.stop:
      return `Service stopped ${timeago}`;
    case MobileStockLocationEventType.update:
      return `Last update received ${timeago}`;
  }
};
