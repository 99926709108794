import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as VentoryWhiteLogo } from '../../assets/img/Ventory-WhiteNoBack.svg';
import TextInput from '../Common/TextInput';
import { t } from '../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { SignUpResponse, SignUpVariables, UserMutations } from '../../graphql/user.graphql';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { handleKeyEvent } from '../../util/events.util';

import { Button } from '../../VentoryUI/components/common/Button/Button';

interface SignUpScreenProps {}

export default function SignUpScreen({}: SignUpScreenProps) {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [signedUp, setSignedUp] = useState<boolean>(false);

  const [signUp, { loading }] = useMutation<SignUpResponse, SignUpVariables>(UserMutations.signUp, {
    onCompleted: res => setSignedUp(true),
    onError: err => setError(err.message),
  });

  const handleSignUp = async () => {
    setError('');

    if (!firstName) return setError('Please enter a valid first name');
    if (!lastName) return setError('Please enter a valid last name');
    if (!email) return setError('Please enter a valid email');
    if (!password) return setError('Please enter a valid password');
    if (password !== confirmPassword) return setError('Please confirm your password');

    await signUp({
      variables: {
        user: {
          firstName,
          lastName,
          email,
          password,
        },
      },
    });
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
      className='bg-ventory-blue-900'
      onKeyDown={event => handleKeyEvent(event, 'Enter', handleSignUp)}
    >
      <Grid container width={'calc(100vw/8)'} mb={2}>
        <Grid item xs={12}>
          <VentoryWhiteLogo />
        </Grid>
      </Grid>

      {!signedUp ? (
        <Grid
          width={'350px'}
          container
          paddingX={3}
          paddingY={2}
          justifyContent={'center'}
          rowSpacing={1}
          className='bg-white'
        >
          <Grid item xs={12} textAlign={'center'} justifyItems={'center'}>
            <p className='text-2xl font-bold'>{'Sign Up'}</p>
          </Grid>
          {error ? (
            <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
              <p className='border-red-400 border-2 text-sm rounded-sm bg-red-100 py-1 text-red-500'>{error}</p>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextInput
              disabled={loading}
              placeholder={t().firstName.singular.label}
              label={t().firstName.singular.label}
              onChange={value => setFirstName(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              disabled={loading}
              placeholder={t().lastName.singular.label}
              label={t().lastName.singular.label}
              onChange={value => setLastName(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              disabled={loading}
              placeholder={t().email.singular.label}
              label={t().email.singular.label}
              onChange={value => setEmail(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              disabled={loading}
              type='password'
              placeholder={t().password.singular.label}
              label={t().password.singular.label}
              onChange={value => setPassword(value)}
              tooltip={t().passwordRequirementInfo.singular.upper}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              disabled={loading}
              type='password'
              placeholder={t().confirmPassword.singular.label}
              label={t().confirmPassword.singular.label}
              onChange={value => setConfirmPassword(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container rowSpacing={0.5}>
              <Grid item xs={12}>
                <Button loading={loading} onClick={handleSignUp} text={t().signUp.singular.label} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Grid container rowSpacing={0.5}>
              <Grid item xs={12} textAlign={'center'}>
                <p>{t().alreadyHaveAccountQuestion.singular.upper}</p>
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={loading}
                  style='secondary'
                  onClick={() => navigate('/signIn')}
                  text={t().signIn.singular.label}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          width={'350px'}
          container
          paddingX={3}
          paddingY={2}
          justifyContent={'center'}
          rowSpacing={1}
          className='bg-white'
        >
          <Grid item xs={12} textAlign={'center'}>
            <MarkEmailReadIcon
              style={{
                width: '60px',
                height: '60px',
              }}
              className='text-ventory-light-green'
            />
          </Grid>
          <Grid item xs={12} mt={2} textAlign={'center'} justifyItems={'center'}>
            <p className='text-sm font-semibold'>{`An email has been send to ${email}, please verify your account before proceeding`}</p>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
