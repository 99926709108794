import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { getBackgroundColor, getBorderColor, getTextColor } from '../../util/color.util';
import { CompanyContext } from '../../context/CompanyContext';
import DropdownSelect from '../Common/DropdownSelect';
import { UserContext } from '../../context/UserContext';
import { t } from '../../types/translation/Translator';
import {
  IncidentPlatform,
  IncidentDeviceType,
  IncidentElementMobile,
  IncidentElementWeb,
  IncidentEnvironment,
  IncidentNetworkConnection,
  IncidentPriorityLevel,
  IncidentReport,
  incidentApplicationToString,
  incidentDeviceTypeToString,
  incidentElementMobileToString,
  incidentElementWebToString,
  incidentEnvironmentToString,
  incidentNetworkConnectionToString,
  incidentPriorityLevelToString,
} from '../../types/support';
import TextArea from '../Common/TextArea';
import { useMutation } from '@apollo/client';
import {
  SubmitIncidentReportResponse,
  SubmitIncidentReportVariables,
  SupportMutations,
} from '../../graphql/support.graphql';
import TextInput from '../Common/TextInput';

import { ColorStyleType } from '../../VentoryUI/util/color.util';
import Pane from '../../VentoryUI/components/common/Pane/Pane';
import Paper from '../../VentoryUI/components/common/Paper/Paper';
import ErrorBox from '../Common/ErrorBox';
import ButtonRow from '../../VentoryUI/components/common/Button/ButtonRow';
import { FlexPane } from '../../VentoryUI/components/common/FlexPane/FlexPane';

import { UploadImagesInput } from '../Common/UploadImageInput';

export default function ReportIncidentRequestScreen() {
  const { currentCompany } = useContext(CompanyContext);
  const { companyUsers } = useContext(UserContext);

  const [info, setInfo] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [incidentInput, setRequestInput] = useState<IncidentReport>(
    new IncidentReport({ companyId: currentCompany.id }),
  );

  const [attachments, setAttachments] = useState<string[]>([]);

  const [submit, { loading }] = useMutation<SubmitIncidentReportResponse, SubmitIncidentReportVariables>(
    SupportMutations.submitIncidentReport,
    {
      onCompleted: res => {
        setError('');
        setInfo('Thank you for submitting your incident');
        setRequestInput(new IncidentReport({ companyId: currentCompany.id }));
      },
      onError: err => setError(err.message),
    },
  );

  const handleSubmit = async () => {
    try {
      await submit({
        variables: {
          incidentReport: incidentInput,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleFile = async (files: FileList | null, index: number) => {
    if (!files) {
      attachments.splice(index, 1);
      return setAttachments([...attachments]);
    }

    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        incidentInput.withAttachements([
          {
            fileName: file?.name || 'attachment',
            mimeType: 'image/png',
            data: (reader.result as string).replace('data:', '').replace(/^.+,/, ''),
          },
          ...incidentInput.attachments,
        ]);
        setAttachments([...attachments, reader.result as string]);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Pane>
      <FlexPane
        content={
          <Paper>
            <Grid container rowSpacing={1} columnSpacing={1} alignContent={'flex-start'}>
              <Grid item xs={12}>
                <p className='text-xl font-semibold'>Report an incident</p>
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    backgroundColor: getBackgroundColor(currentCompany, ColorStyleType.primary),
                    color: getTextColor(currentCompany, ColorStyleType.primary),
                    borderColor: getBorderColor(currentCompany, ColorStyleType.primary),
                  }}
                  className='font-semibold text-sm p-2 rounded-lg'
                >
                  We’re sorry to hear you’re experiencing a problem. Please tell us more about it and we will get right
                  on it.
                </p>
              </Grid>
              {info ? (
                <Grid item xs={12} textAlign={'center'}>
                  <p className='text-sm font-semibold p-2 border border-green-600 bg-green-100 rounded-sm text-green-400'>
                    {info}
                  </p>
                </Grid>
              ) : null}
              {error ? (
                <Grid item xs={12}>
                  <ErrorBox error={error} />
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().reportOnBehalfOf.singular.label}
                      placeholder={t().reportOnBehalfOf.singular.label}
                      values={[...companyUsers.values()]}
                      selectedValue={companyUsers.get(incidentInput.userId || '') || null}
                      toText={user => user.email}
                      onChange={user => setRequestInput(incidentInput.withUserId(user?.id))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      placeholder={t().optionalPhoneNumber.singular.label}
                      label={t().optionalPhoneNumber.singular.label}
                      onChange={v => setRequestInput(incidentInput.withPhone(v))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().priority.singular.label}
                      placeholder={t().priority.singular.label}
                      values={Object.keys(IncidentPriorityLevel)}
                      selectedValue={incidentInput.priority || null}
                      toText={type => incidentPriorityLevelToString(type as IncidentPriorityLevel)}
                      onChange={type =>
                        setRequestInput(incidentInput.withPriority((type as IncidentPriorityLevel) || undefined))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().networkConnectionOccurrence.singular.label}
                      placeholder={t().networkConnection.singular.label}
                      values={Object.keys(IncidentNetworkConnection)}
                      selectedValue={incidentInput.network || null}
                      toText={type => incidentNetworkConnectionToString(type as IncidentNetworkConnection)}
                      onChange={type =>
                        setRequestInput(incidentInput.withNetwork((type as IncidentNetworkConnection) || undefined))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().environment.singular.label}
                      placeholder={t().environment.singular.label}
                      values={Object.keys(IncidentEnvironment)}
                      selectedValue={incidentInput.environment || null}
                      toText={type => incidentEnvironmentToString(type as IncidentEnvironment)}
                      onChange={type =>
                        setRequestInput(incidentInput.withEnvironment((type as IncidentEnvironment) || undefined))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().incidentApplication.singular.label}
                      placeholder={t().incidentApplication.singular.label}
                      values={Object.keys(IncidentPlatform)}
                      selectedValue={incidentInput.platform || null}
                      toText={type => incidentApplicationToString(type as IncidentPlatform)}
                      onChange={type =>
                        setRequestInput(incidentInput.withPlatform((type as IncidentPlatform) || undefined))
                      }
                      maxHeight='100px'
                    />
                  </Grid>
                  {incidentInput.platform === IncidentPlatform.web ? (
                    <Grid item xs={12}>
                      <DropdownSelect
                        mandatory
                        label={t().applicationPartIncident.singular.label}
                        placeholder={t().incidentApplicationPart.singular.label}
                        values={Object.keys(IncidentElementWeb)}
                        selectedValue={incidentInput.elementWeb || null}
                        toText={type => incidentElementWebToString(type as IncidentElementWeb)}
                        onChange={type =>
                          setRequestInput(incidentInput.withElementWeb((type as IncidentElementWeb) || undefined))
                        }
                        maxHeight='100px'
                      />
                    </Grid>
                  ) : incidentInput.platform === IncidentPlatform.mobile ? (
                    <>
                      <Grid item xs={12}>
                        <DropdownSelect
                          mandatory
                          label={t().applicationPartIncident.singular.label}
                          placeholder={t().incidentApplicationPart.singular.label}
                          values={Object.keys(IncidentElementMobile)}
                          selectedValue={incidentInput.elementMobile || null}
                          toText={type => incidentElementMobileToString(type as IncidentElementMobile)}
                          onChange={type =>
                            setRequestInput(
                              incidentInput.withElementMobile((type as IncidentElementMobile) || undefined),
                            )
                          }
                          maxHeight='100px'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DropdownSelect
                          mandatory
                          label={t().device.singular.label}
                          placeholder={t().device.singular.label}
                          values={Object.keys(IncidentDeviceType)}
                          selectedValue={incidentInput.device || null}
                          toText={type => incidentDeviceTypeToString(type as IncidentDeviceType)}
                          onChange={type =>
                            setRequestInput(incidentInput.withDevice((type as IncidentDeviceType) || undefined))
                          }
                          maxHeight='80px'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          mandatory
                          label={t().operatingSystemVersion.singular.label}
                          placeholder={t().operatingSystemVersion.singular.label}
                          onChange={v => setRequestInput(incidentInput.withOperatingSystemVersion(v))}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                  <Grid item xs={12} mb={0.3}>
                    <TextArea
                      mandatory
                      rows={8}
                      label={t().tellUsMoreIncident.singular.label}
                      placeholder={t().tellUsMore.singular.label}
                      onChange={v => setRequestInput(incidentInput.withDescription(v))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className='text-sm font-semibold mb-1'>{t().attachements.singular.label}</p>
                    <UploadImagesInput loading={false} height='240px' images={attachments} onFile={handleFile} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        }
        footer={
          <ButtonRow
            buttons={[
              {
                text: t().send.singular.label,
                onClick: handleSubmit,
                style: 'secondary',
                loading,
                disabled:
                  !incidentInput.userId ||
                  !incidentInput.priority ||
                  !incidentInput.network ||
                  !incidentInput.environment ||
                  !incidentInput.platform ||
                  !incidentInput.description ||
                  (incidentInput.platform === IncidentPlatform.mobile &&
                    (!incidentInput.device || !incidentInput.operatingSystemVersion)),
              },
            ]}
          />
        }
      />
    </Pane>
  );
}
