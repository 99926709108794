import { SignInErrorCode } from './signInError.util';

export function errorCodeToString(errorCode: string): string {
  switch (errorCode) {
    // === Authentication ===
    case SignInErrorCode.invalidCredentials:
      return 'Invalid credentials provided';
    case SignInErrorCode.unverified:
      return 'Please verify email';
    case SignInErrorCode.backendUnavailable:
      return 'Unable to reach server';

    default:
      return errorCode;
  }
}
