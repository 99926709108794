import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CsvUploadType } from '../../../types/csvUploadConfiguration';
import CsvUploadConfigurationsScreen from '../../Common/CsvUploadConfigurationsScreen';
import CsvUploadConfigurationUpdatePane from '../../Common/CsvUploadConfigurationUpdatePane';
import { t } from '../../../types/translation/Translator';

import Pane from '../../../VentoryUI/components/common/Pane/Pane';

const tabs = [
  {
    text: t().importConfigurations.singular.label,
    path: '/settings/users/advanced/import_configurations',
    key: 'import_configurations',
  },
];

export default function UserAdvancedSettingsPane() {
  const navigate = useNavigate();

  return (
    <Pane tabs={tabs}>
      <Routes>
        <Route
          path={'import_configurations/*'}
          element={<CsvUploadConfigurationsScreen type={CsvUploadType.user} backPath='/settings/users' />}
        />
        <Route
          path={'import_configurations/:id/update/*'}
          element={
            <CsvUploadConfigurationUpdatePane
              type={CsvUploadType.user}
              onDone={() => navigate('import_configurations')}
            />
          }
        />
      </Routes>
    </Pane>
  );
}
