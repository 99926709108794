import React from 'react';
import { ProductTransactionTableSettings } from '../types/productTransactionTableSettings';

export interface ProductTransactionTableSettingsContextProps {
  productTransactionTableSettings: Map<string, ProductTransactionTableSettings>;
  setProductTransactionTableSettings: (fields: Map<string, ProductTransactionTableSettings>) => void;
  productTransactionTableSettingsLoading: boolean;
}

const defaultContext: ProductTransactionTableSettingsContextProps = {
  productTransactionTableSettings: new Map(),
  setProductTransactionTableSettings: () => {},
  productTransactionTableSettingsLoading: false,
};

export const ProductTransactionTableSettingsContext =
  React.createContext<ProductTransactionTableSettingsContextProps>(defaultContext);
