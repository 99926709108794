import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export enum ProductMasterDataExportField {
  productName = 'productName',
  productNumber = 'productNumber',
  lotManaged = 'lotManaged',
  serialManaged = 'serialManaged',
  lpnManaged = 'lpnManaged',
  countryOfOrigin = 'countryOfOrigin',
  grossWeight = 'grossWeight',
  netWeight = 'netWeight',
  height = 'height',
  width = 'width',
  depth = 'depth',
  purchasePrice = 'purchasePrice',
  sellingPrice = 'sellingPrice',
  manufacturer = 'manufacturer',
  originalEquipmentManufacturer = 'originalEquipmentManufacturer',
  unit = 'unit',
  unitSystem = 'unitSystem',
  unitType = 'unitType',
}

export class ProductMasterDataExportConfigurationCustomField {
  index: number;

  customField: string;

  constructor(customField: string, index: number) {
    this.index = index;
    this.customField = customField;
  }
}

export class ProductMasterDataExportConfigurationMapping extends ProductMasterDataExportConfigurationCustomField {
  field: ProductMasterDataExportField;

  constructor(customField: string, index: number, field: ProductMasterDataExportField) {
    super(customField, index);
    this.field = field;
  }
}

export class ProductMasterDataExportConfiguration extends CompanyEntity {
  name!: string;
  filename: string = 'ventory_product_references_${TIMESTAMP}.csv';
  prefix?: string;
  delimiter: string = ',';
  quote?: string;
  fieldMapping: ProductMasterDataExportConfigurationMapping[] = [...DEFAULT_MAPPING().values()];
  default: boolean = false;

  forUpdate(): UpdateProductMasterDataExportConfigurationInput {
    return UpdateProductMasterDataExportConfigurationInput.from(this, UpdateProductMasterDataExportConfigurationInput);
  }

  forDelete(): DeleteProductMasterDataExportConfigurationInput {
    return DeleteProductMasterDataExportConfigurationInput.from(this, DeleteProductMasterDataExportConfigurationInput);
  }

  validate(fields: any[]) {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof ProductMasterDataExportConfiguration)[],
      (field: keyof ProductMasterDataExportConfiguration) => {
        return null;
      },
    );
  }

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    this.default = false;
    Object.assign(this, cloneDeep(obj));
    this.default = obj.default || false;
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withFilename(filename: string) {
    this.filename = filename;
    return cloneDeep(this);
  }

  withPrefix(prefix: string) {
    this.prefix = prefix;
    return cloneDeep(this);
  }

  withDelimiter(delimiter: string) {
    this.delimiter = delimiter;
    return cloneDeep(this);
  }

  withDefault(value: boolean) {
    this.default = value;
    return cloneDeep(this);
  }
}
export class CreateProductMasterDataExportConfigurationInput extends forCreate(ProductMasterDataExportConfiguration) {}

export class UpdateProductMasterDataExportConfigurationInput extends forUpdate(ProductMasterDataExportConfiguration) {}

export class DeleteProductMasterDataExportConfigurationInput extends forDelete(ProductMasterDataExportConfiguration) {}

const DEFAULT_MAPPING = () => {
  const map = new Map<ProductMasterDataExportField, ProductMasterDataExportConfigurationMapping>();
  map.set(ProductMasterDataExportField.productName, {
    field: ProductMasterDataExportField.productName,
    customField: 'Product Name',
    index: 1,
  });
  map.set(ProductMasterDataExportField.productNumber, {
    field: ProductMasterDataExportField.productNumber,
    customField: 'Product Number',
    index: 2,
  });
  map.set(ProductMasterDataExportField.lotManaged, {
    field: ProductMasterDataExportField.lotManaged,
    customField: 'Lot Managed',
    index: 3,
  });
  map.set(ProductMasterDataExportField.serialManaged, {
    field: ProductMasterDataExportField.serialManaged,
    customField: 'Serial Managed',
    index: 4,
  });
  map.set(ProductMasterDataExportField.lpnManaged, {
    field: ProductMasterDataExportField.lpnManaged,
    customField: 'LPN Managed',
    index: 5,
  });
  map.set(ProductMasterDataExportField.manufacturer, {
    field: ProductMasterDataExportField.manufacturer,
    customField: 'Manufacturer',
    index: 6,
  });
  return map;
};

export function productMasterDataExportFieldToString(item: ProductMasterDataExportField): string {
  switch (item) {
    case ProductMasterDataExportField.productName:
      return 'Product Name';
    case ProductMasterDataExportField.productNumber:
      return 'Product Number';
    case ProductMasterDataExportField.lotManaged:
      return 'Lot Managed';
    case ProductMasterDataExportField.serialManaged:
      return 'Serial Managed';
    case ProductMasterDataExportField.lpnManaged:
      return 'LPN Managed';
    case ProductMasterDataExportField.countryOfOrigin:
      return 'Country of Origin';
    case ProductMasterDataExportField.grossWeight:
      return 'Gross Weight';
    case ProductMasterDataExportField.netWeight:
      return 'Net Weight';
    case ProductMasterDataExportField.height:
      return 'Height';
    case ProductMasterDataExportField.width:
      return 'Width';
    case ProductMasterDataExportField.depth:
      return 'Depth';
    case ProductMasterDataExportField.purchasePrice:
      return 'Purchase Price';
    case ProductMasterDataExportField.sellingPrice:
      return 'Selling Price';
    case ProductMasterDataExportField.manufacturer:
      return 'Manufacturer';
    case ProductMasterDataExportField.originalEquipmentManufacturer:
      return 'Original Equipment Manufacturer';
    case ProductMasterDataExportField.unit:
      return 'Unit';
    case ProductMasterDataExportField.unitSystem:
      return 'Unit System';
    case ProductMasterDataExportField.unitType:
      return 'Unit Type';
  }
}

export function stringToProductMasterDataExportField(item: string): string {
  switch (item) {
    case 'Product Name':
      return ProductMasterDataExportField.productName;
    case 'Product Number':
      return ProductMasterDataExportField.productNumber;
    case 'Lot Managed':
      return ProductMasterDataExportField.lotManaged;
    case 'Serial Managed':
      return ProductMasterDataExportField.serialManaged;
    case 'LPN Managed':
      return ProductMasterDataExportField.lpnManaged;
    case 'Country of Origin':
      return ProductMasterDataExportField.countryOfOrigin;
    case 'Gross Weight':
      return ProductMasterDataExportField.grossWeight;
    case 'Net Weight':
      return ProductMasterDataExportField.netWeight;
    case 'Height':
      return ProductMasterDataExportField.height;
    case 'Width':
      return ProductMasterDataExportField.width;
    case 'Depth':
      return ProductMasterDataExportField.depth;
    case 'Purchase Price':
      return ProductMasterDataExportField.purchasePrice;
    case 'Selling Price':
      return ProductMasterDataExportField.sellingPrice;
    case 'Manufacturer':
      return ProductMasterDataExportField.manufacturer;
    case 'Original Equipment Manufacturer':
      return ProductMasterDataExportField.originalEquipmentManufacturer;
    case 'Unit':
      return ProductMasterDataExportField.unit;
    case 'Unit System':
      return ProductMasterDataExportField.unitSystem;
    case 'Unit Type':
      return ProductMasterDataExportField.unitType;
  }

  return '';
}
