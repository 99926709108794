import { gql } from '@apollo/client';

export const pagination = gql`
  fragment Pagination on Pagination {
    page
    pageSize
    batchSize
    hasNext
  }
`;

export const forPaginated = <T>() => {
  class PaginatedTrait {
    page!: number;
    pageSize!: number;
    batchSize!: number;
    hasNext!: boolean;
    data!: T[];
  }

  return PaginatedTrait;
};
