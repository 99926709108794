import React from 'react';
import { Task } from '../types/task';

export interface TaskContextProps {
  tasks: Map<string, Task>;
  setTasks: (tasks: Map<string, Task>) => void;
  tasksLoading: boolean;
}

const defaultContext: TaskContextProps = {
  tasks: new Map(),
  setTasks: () => {},
  tasksLoading: false,
};

export const TaskContext = React.createContext<TaskContextProps>(defaultContext);
