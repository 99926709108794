import React from 'react';
import { ReorderUserRule } from '../../../../../../../types/reorderUserRule';
import { Grid } from '@mui/material';
import Selector from '../../../../../../Common/Selector';
import { CompanyRole, companyRoleToString } from '../../../../../../../types/companyRoleAssignment';
import { StockLocationRole, stockLocationRoleToString } from '../../../../../../../types/stockLocationRoleAssignment';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface ReorderUserRuleReplenishRolesPaneProps {
  rule: ReorderUserRule;
  setRule: (rule: ReorderUserRule) => void;
  next: () => void;
  back: () => void;
}

export default function ReorderUserRuleReplenishRolesPane({
  rule,
  setRule,
  next,
  back,
}: ReorderUserRuleReplenishRolesPaneProps) {
  return (
    <ModalPane
      overflow='auto'
      footerButtons={[
        BackButtonTemplate(back),
        NextButtonTemplate(next, {
          disabled:
            rule.replenishOrderCompanyRoles.includes(CompanyRole.employee) &&
            !rule.replenishOrderStockLocationRoles.length,
        }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <p className='text-sm'>{'Select company roles that should be assigned for replenishment'}</p>
              <Selector
                showSearch={false}
                checkedValues={rule.replenishOrderCompanyRoles}
                values={Object.values(CompanyRole)}
                onChange={value => setRule(rule.withReplenishOrderCompanyRoles(value))}
                toText={value => companyRoleToString(value)}
              />
            </Grid>
            {rule.replenishOrderCompanyRoles.includes(CompanyRole.employee) ? (
              <Grid item xs={12}>
                <p className='text-sm'>{'Select stock location roles that should be assigned for replenishment'}</p>
                <Selector
                  showSearch={false}
                  checkedValues={rule.replenishOrderStockLocationRoles}
                  values={Object.values(StockLocationRole).filter(
                    role => role !== StockLocationRole.STOCK_LOCATION_VIEWER,
                  )}
                  onChange={value => setRule(rule.withReplenishOrderStockLocationRoles(value))}
                  toText={value => stockLocationRoleToString(value)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
