import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ReorderRuleScreen from './ReorderRuleScreen';
import { ReorderRuleContext } from '../../../context/ReorderRuleContext';
import { Grid } from '@mui/material';
import LoadingPackage from '../LoadingPackage';

export default function UpdateReorderRuleScreen() {
  const groupId = useParams()['id'] || '';

  const { groupedReorderRules, reorderRulesLoading } = useContext(ReorderRuleContext);

  if (reorderRulesLoading && !groupedReorderRules.get(groupId))
    return (
      <Grid container alignItems={'center'}>
        <Grid item className='fill-gray-300 ' marginX={'auto'}>
          <LoadingPackage />
        </Grid>
      </Grid>
    );

  return <ReorderRuleScreen groupId={groupId} />;
}
