export const DEV_BEACON_ID = 'c6102951-2ed9-4978-bdb1-45ddd26756e5';
export const STAGING_BEACON_ID = '952e4333-463d-46db-ab0c-39756c08836e';
export const PRODUCTION_BEACON_ID = '7d24dee7-05d5-40f7-b6b5-e60ff50c3bc7';
export const NETWORK_RAIL_BEACON_ID = '428d1d20-87a6-48ab-94fc-c1f5edc21896';

export function beaconInfo() {
  return Beacon('info');
}

export function destroyBeacon() {
  Beacon('destroy');
}

export function identifyBeacon(identification) {
  Beacon('identify', identification);
}

export function initBeacon(beaconId) {
  Beacon('init', beaconId);
}

export function openBeacon() {
  Beacon('open');
}

export function closeBeacon() {
  Beacon('close');
}

export function setBeaconConfig(config) {
  Beacon('config', config);
}

export function setBeaconColor(color) {
  Beacon('config', { color: color });
}

// export function onBeaconEvent(event, cb) {
//   Beacon('on', event, cb);
// }
