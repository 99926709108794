import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { CompanyRole } from './companyRoleAssignment';
import { StockLocationRole } from './stockLocationRoleAssignment';

export enum ReorderUserRuleEntityType {
  lot = 'lot',
  productMasterData = 'productMasterData',
}

export class ReorderUserRule extends CompanyEntity {
  groupId!: string;
  name!: string;
  stockLocationId?: string | null;
  productMasterDataId?: string | null;
  replenishOrderCompanyRoles: CompanyRole[] = [];
  replenishOrderStockLocationRoles: StockLocationRole[] = [];
  replenishOrderUserIds: string[] = [];
  entityType: ReorderUserRuleEntityType = ReorderUserRuleEntityType.productMasterData;

  constructor(obj: any) {
    super(obj.companyId);
    if (!obj.companyId) return;
    Object.assign(this, cloneDeep(obj));
  }

  toHash() {
    return `${this.productMasterDataId}:${this.stockLocationId}`;
  }

  forUpdate() {
    return UpdateReorderUserRuleInput.from(this, UpdateReorderUserRuleInput);
  }
  forDelete() {
    return {
      ...DeleteReorderUserRuleInput.from(this, DeleteReorderUserRuleInput),
      stockLocationId: this.stockLocationId,
    };
  }

  validate(fields: any[]) {
    throw new Error('Method not implemented.');
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withReplenishOrderCompanyRoles(value: CompanyRole[]) {
    if (!value.includes(CompanyRole.employee)) {
      this.replenishOrderStockLocationRoles = [];
    }
    this.replenishOrderCompanyRoles = value;
    return cloneDeep(this);
  }

  withReplenishOrderStockLocationRoles(value: StockLocationRole[]) {
    this.replenishOrderStockLocationRoles = value;
    return cloneDeep(this);
  }

  withReplenishOrderUsers(value: string[]) {
    this.replenishOrderUserIds = value;
    return cloneDeep(this);
  }
}

export class CreateReorderUserRuleInput extends forCreate(ReorderUserRule) {}

export class UpdateReorderUserRuleInput extends forUpdate(ReorderUserRule) {}

export class DeleteReorderUserRuleInput extends forDelete(ReorderUserRule) {}
