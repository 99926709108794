import { CompanyEntity, forCreate } from '../common/entity';
import { Translations, translationsFromJson } from './common';

export class CustomTranslation extends CompanyEntity {
  name!: string;
  translations!: Translations;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, obj);
    this.translations = translationsFromJson(JSON.parse(obj.translations));
  }

  forUpdate() {
    throw new Error('Method not implemented.');
  }
  forDelete() {
    throw new Error('Method not implemented.');
  }
  validate(fields: any[]) {
    throw new Error('Method not implemented.');
  }
}

export class CreateCustomTranslationInput extends forCreate(CustomTranslation) {}
