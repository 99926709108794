import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export enum FileEntityType {
  pmd = 'pmd',
  company = 'company',
  user = 'user',
}

export type FileTypeCategory = 'image' | 'video' | 'document' | 'other';

export class FileEntity extends CompanyEntity {
  name!: string;
  type!: string;
  size!: number;
  entityType!: FileEntityType;
  entityId!: string;
  stockLocationId!: string;

  forUpdate(): UpdateFileInput {
    return UpdateFileInput.from(this, FileEntity);
  }

  forDelete(): DeleteFileInput {
    return {
      id: this.id,
      version: this.version,
      companyId: this.companyId,
    };
  }

  validate(fields: any[]) {}

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  get primaryFileType() {
    return this.type.split('/')[0];
  }

  get fileTypeCategory() {
    const category = this.primaryFileType;
    if (category === 'image' || category === 'video') return category;

    if (category === 'text' || category === 'application') return 'document';

    return 'other';
  }

  get fileType() {
    return this.type.split('/')[1] || '';
  }
}

export class FileWithSas extends FileEntity {
  signature!: string;
  expirationDate!: Date;

  constructor(obj: any) {
    super(obj);
    Object.assign(this, cloneDeep(obj));
  }
}

export class CreateFileInput extends forCreate(FileEntity) {
  static fromFile(
    file: File,
    entityId: string,
    entityType: FileEntityType,
    companyId: string,
    onDone: (fileInput: CreateFileInput) => void,
  ) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = async () => {
      const result = reader.result;
      if (!result || typeof result !== 'string') return;

      onDone(
        new CreateFileInput({
          entityType,
          entityId,
          companyId: companyId,
          name: file.name,
          size: file.size,
          type: file.type,
          data: btoa(result),
        }),
      );
    };
  }
}

export class UpdateFileInput extends forUpdate(FileEntity) {}

export class DeleteFileInput extends forDelete(FileEntity) {}

type FileSizeType = 'B' | 'KB' | 'MB' | 'GB' | 'TB';

export function calculateFormattedFileSize(size: number): { size: number; sizeType: FileSizeType } {
  if (size < 1024) {
    return { size, sizeType: 'B' };
  }

  if (size < 1024 ** 2) {
    return { size: size / 1024, sizeType: 'KB' };
  }

  if (size < 1024 ** 3) {
    return { size: size / 1024 ** 2, sizeType: 'MB' };
  }

  if (size < 1024 ** 4) {
    return { size: size / 1024 ** 3, sizeType: 'GB' };
  }

  return { size: size / 1024 ** 4, sizeType: 'TB' };
}
