import React, { useContext, useState } from 'react';
import { Bin } from '../../../../../../types/bin';
import { Grid } from '@mui/material';
import { t } from '../../../../../../types/translation/Translator';
import TextInput from '../../../../../Common/TextInput';
import DropdownSelect from '../../../../../Common/DropdownSelect';
import { BinStatusContext } from '../../../../../../context/BinStatusContext';
import { BinStatus } from '../../../../../../types/binStatus';
import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/ModalPane';

interface BinInfoPaneInputProps {
  bin: Bin;
  footer: (input: Bin) => JSX.Element;
}

export default function BinInfoPane({ bin, footer }: BinInfoPaneInputProps) {
  const { binStatuses } = useContext(BinStatusContext);

  const [binInput, setBinInput] = useState<Bin>(new Bin(bin));

  return (
    <ModalPane footer={footer(binInput)}>
      <Grid container height={'100%'} alignContent={'space-between'} data-testid={testIds.binInfoPane}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={6}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <TextInput
                  mandatory
                  value={binInput.name}
                  label={t().binName.singular.label}
                  placeholder={t().binName.singular.label}
                  onChange={v => binInput.withName(v)}
                  testId={testIds.name}
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelect
                  testId={testIds.status}
                  mandatory
                  label={t().binStatus.singular.label}
                  selectedValue={binStatuses.get(binInput.binStatusId) || null}
                  maxHeight='150px'
                  values={[...binStatuses.values()]}
                  toText={(item: BinStatus) => item.status}
                  onChange={(item: BinStatus | null) => setBinInput(binInput.withBinStatusId(item?.id))}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
