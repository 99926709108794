import { Grid, Popover, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import EventIcon from '@mui/icons-material/Event';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/br';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface DatePickerProps {
  value?: Date;
  placeholder?: string;
  onChange: (value?: Date) => void;
  mandatory?: boolean;
  label?: string;
  disabled?: boolean;
  dynamicUpdate?: boolean;
}

export default function DatePicker({
  value,
  placeholder,
  onChange,
  mandatory = false,
  label,
  disabled,
  dynamicUpdate = false,
}: DatePickerProps) {
  dayjs.extend(customParseFormat);

  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dateString, setDateString] = useState<string>(
    value && !isNaN(Date.parse(value.toString())) ? value.toLocaleDateString() : '',
  );

  const handleClick = () => {
    const el = document.getElementById('date_picker');
    if (!el) return;
    setAnchorEl(el);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setDateString(value);
    if (!value) return onChange(undefined);
    let store = dayjs(value);
    if (store.isValid()) return onChange(store.toDate());
    store = dayjs(value, 'DD/MM/YYYY');
    if (store.isValid()) return onChange(store.toDate());
  };

  useEffect(() => {
    if (dynamicUpdate) setDateString(value && !isNaN(Date.parse(value.toString())) ? value.toLocaleDateString() : '');
  }, [value]);

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} display={'flex'}>
            {label ? <p className='text-sm font-medium text-slate-800'>{label}</p> : null}
            {mandatory ? <p className='ml-1 text-sm font-medium text-red-500'>*</p> : null}
          </Grid>
        </Grid>
        <Grid
          container
          className={`border rounded mt-1 px-3 py-2 h-10 ${
            disabled ? 'bg-gray-100 text-gray-400' : ''
          } shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none block w-full sm:text-sm`}
        >
          <Grid item flexGrow={1} marginY={'auto'}>
            <input
              disabled={disabled}
              type='text'
              className={`${disabled ? 'bg-gray-100 text-gray-400 outline-none' : 'outline-none'}`}
              onChange={handleChange}
              value={dateString}
              autoComplete={'false'}
              placeholder={placeholder}
              id={'date_picker'}
            />
          </Grid>
          {!disabled ? (
            <Grid item display='flex' onClick={handleClick} marginY={'auto'} px={1}>
              <EventIcon className={'cursor-pointer'} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ marginTop: '1rem', marginLeft: '0' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs='desktop'
            openTo='day'
            minDate={new Date()}
            value={value}
            onChange={(date, context) => {
              if (date) {
                onChange(new Date(date));
                setDateString(date.toLocaleDateString());
              }

              setOpen(false);
            }}
          />
        </LocalizationProvider>
      </Popover>
    </Grid>
  );
}
