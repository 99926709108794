import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateReorderRuleInput,
  DeleteReorderRuleInput,
  ReorderRule,
  UpdateReorderRuleInput,
} from '../types/reorderRule';

const reorderRule = gql`
  fragment ReorderRule on ReorderRule {
    id
    version
    companyId
    stockLocationId
    productMasterDataId
    name
    stockLocationAggregateType
    excludedBinStatusIds
    excludedBinIds
    automaticallyCreateOrder
    reorderQuantity
    replenishQuantity
    triggerTypes
    notificationCompanyRoles
    notificationStockLocationRoles
    notificationUserIds
    groupId
  }
`;

const get = gql`
  query reorderRules($companyId: String!, $page: Float) {
    reorderRules(companyId: $companyId, page: $page) {
      data {
        ...ReorderRule
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${reorderRule}
`;

export interface GetReorderRulesVariables {
  companyId: string;
  page?: number;
}

export interface GetReorderRulesResponse {
  reorderRules: PaginatedReorderRules;
}

class PaginatedReorderRules extends forPaginated<ReorderRule>() {}

const update = gql`
  mutation UpdateReorderRule(
    $toCreate: [CreateReorderRuleInput!]!
    $toUpdate: [UpdateReorderRuleInput!]!
    $toDelete: [DeleteReorderRuleInput!]!
  ) {
    updateReorderRule(toCreate: $toCreate, toUpdate: $toUpdate, toDelete: $toDelete) {
      created {
        ...ReorderRule
      }
      updated {
        ...ReorderRule
      }
      deleted {
        ...ReorderRule
      }
    }
  }
  ${reorderRule}
`;

export interface UpdateReorderRuleVariables {
  toCreate: CreateReorderRuleInput[];
  toUpdate: UpdateReorderRuleInput[];
  toDelete: DeleteReorderRuleInput[];
}

export interface UpdateReorderRuleResponse {
  updateReorderRule: {
    created: ReorderRule[];
    updated: ReorderRule[];
    deleted: ReorderRule[];
  };
}

const remove = gql`
  mutation DeleteReorderRule($reorderRules: [DeleteReorderRuleInput!]!) {
    deleteReorderRule(reorderRules: $reorderRules) {
      ...ReorderRule
    }
  }
  ${reorderRule}
`;

export interface DeleteReorderRuleVariables {
  reorderRules: DeleteReorderRuleInput[];
}

export interface DeleteReorderRuleResponse {
  deleteReorderRule: ReorderRule[];
}

const create = gql`
  mutation CreateReorderRule($reorderRules: [CreateReorderRuleInput!]!) {
    createReorderRule(reorderRules: $reorderRules) {
      ...ReorderRule
    }
  }
  ${reorderRule}
`;

export interface CreateReorderRuleVariables {
  reorderRules: CreateReorderRuleInput[];
}

export interface CreateReorderRuleResponse {
  createReorderRule: ReorderRule[];
}

export const ReorderRuleMutations = {
  update,
  remove,
  create,
};

export const ReorderRuleQueries = {
  get,
};

export const ReorderRuleFragments = {
  reorderRule,
};
