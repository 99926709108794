export function forNotification<T>(name: string) {
  class ClassTrait {
    [name: string]: {
      companyId: string;
      entities: T[];
      topic: string;
      date: Date;
    };

    constructor(companyId: string, entities: T[], topic: string, date: Date) {
      this[name].companyId = companyId;
      this[name].entities = entities;
      this[name].topic = topic;
      this[name].date = date;
    }
  }

  return ClassTrait;
}
