import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CsvUploadType } from '../../../types/csvUploadConfiguration';
import CsvUploadConfigurationsScreen from '../../Common/CsvUploadConfigurationsScreen';
import CsvUploadConfigurationUpdatePane from '../../Common/CsvUploadConfigurationUpdatePane';

import { t } from '../../../types/translation/Translator';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';

const tabs = [
  {
    text: t().importConfigurations.singular.label,
    path: '/settings/stock_location/advanced/import_configurations',
    key: 'import_configurations',
  },
];

export default function StockLocationAdvancedSettingsPane() {
  const navigate = useNavigate();

  return (
    <Pane tabs={tabs}>
      <Paper>
        <Routes>
          <Route
            path={'import_configurations/*'}
            element={
              <CsvUploadConfigurationsScreen type={CsvUploadType.stockLocation} backPath='/settings/stock_location' />
            }
          />
          <Route
            path={'import_configurations/:id/update/*'}
            element={
              <CsvUploadConfigurationUpdatePane
                type={CsvUploadType.stockLocation}
                onDone={() => navigate('import_configurations')}
              />
            }
          />
        </Routes>
      </Paper>
    </Pane>
  );
}
