import { gql, useSubscription } from '@apollo/client';
import { StockLocationFragments } from '../../graphql/stockLocation.graphql';
import { StockLocation } from '../../types/stockLocation';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import { StockLocationContext } from '../../context/StockLocationContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';

const stockLocationSubscription = gql`
  subscription StockLocationNotification($companyId: String!) {
    stockLocationNotification(companyId: $companyId) {
      companyId
      entities {
        ...StockLocation
      }
      topic
      date
    }
  }
  ${StockLocationFragments.stockLocation}
`;

export class StockLocationNotificationResponse extends forNotification<StockLocation>('stockLocationNotification') {}

export default function StockLocationNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { stockLocations, setStockLocations, setFilteredStockLocations } = useContext(StockLocationContext);

  if (!currentCompany) return null;

  const { data: stockLocationNotification } = useSubscription<StockLocationNotificationResponse>(
    stockLocationSubscription,
    { variables: { companyId: currentCompany.id } },
  );

  const handleStockLocationNotification = (notification?: StockLocationNotificationResponse) => {
    if (!notification?.stockLocationNotification) return;
    const stockLocationNotification = notification.stockLocationNotification;

    switch (stockLocationNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        stockLocationNotification.entities.forEach(sl => stockLocations.set(sl.id, new StockLocation(sl)));
        break;
      case 'DELETE':
        stockLocationNotification.entities.forEach(sl =>
          stockLocations.has(sl.id) ? stockLocations.delete(sl.id) : null,
        );
    }
    setStockLocations(new Map(stockLocations));
    setFilteredStockLocations(new Map(stockLocations));
  };

  useEffect(() => {
    handleStockLocationNotification(stockLocationNotification);
  }, [stockLocationNotification]);

  return <></>;
}
