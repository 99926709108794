import { gql } from '@apollo/client';

const coordinates = gql`
  fragment Coordinates on Coordinates {
    latitude
    longitude
  }
`;

const address = gql`
  fragment Address on Address {
    addressLine1
    addressLine2
    city
    region
    postalCode
    countryCode
    coordinates {
      ...Coordinates
    }
  }
  ${coordinates}
`;

export const AddressFragments = {
  address,
  coordinates,
};
