import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import CreateTriggerModal from './Modals/CreateTriggerModal';
import { TriggerContext } from '../../../context/TriggerContext';
import { Trigger, getTextForTrigger } from '../../../types/trigger';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { StockLocationContext } from '../../../context/StockLocationContext';
import DeleteTriggerModal from './Modals/DeleteTriggerModal';
import { LotContext } from '../../../context/LotContext';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { CustomFieldContext } from '../../../context/CustomFieldContext';
import { CompanyContext } from '../../../context/CompanyContext';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { FilterEntity } from '../../../VentoryUI/components/filters/filter.util';
import { TriggerFilter } from '../../../VentoryUI/components/filters/Filter/TriggerFilter';
import { DeleteButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { AddButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates/AddButton';
import Table, { TableHeader } from '../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';

export default function TriggersSettingsPane() {
  const { currentCompany } = useContext(CompanyContext);
  const { triggers, triggersLoading } = useContext(TriggerContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { lots } = useContext(LotContext);
  const { customFields } = useContext(CustomFieldContext);

  const [filter, setFilter] = useState<string>('');
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<Set<string>>(new Set());

  const defaultTriggers = [
    Trigger.defaultLotTrigger(currentCompany.id),
    Trigger.defaultPmdTrigger(currentCompany.id),
    Trigger.defaultTaskTrigger(currentCompany.id),
  ];
  const allTriggers = useMemo(() => {
    return [...triggers.values(), ...defaultTriggers];
  }, [triggers]);

  const [items, setItems] = useState(allTriggers);

  const triggerTexts = useMemo(() => {
    const texts = new Map();

    for (const [key, value] of triggers) {
      texts.set(key, getTextForTrigger(value, productMasterData, stockLocations, lots, customFields));
    }

    for (const trigger of defaultTriggers) {
      texts.set(trigger.id, getTextForTrigger(trigger, productMasterData, stockLocations, lots, customFields));
    }

    return texts;
  }, [triggers]);

  const headers: TableHeader<Trigger>[] = [
    {
      key: 'trigger',
      name: 'Trigger',
      text: (item: Trigger) => triggerTexts.get(item.id) || 'Unknown Trigger',
      position: 'start',
    },
  ];

  const buttons = useMemo(() => {
    const shown = [];

    if (selected.size) shown.push(DeleteButtonTemplate(() => setOpenDeleteModal(true)));
    shown.push(AddButtonTemplate(() => setOpenCreateModal(true)));

    return shown;
  }, [selected]);

  return (
    <>
      <CreateTriggerModal open={openCreateModal} setOpen={setOpenCreateModal} />
      <DeleteTriggerModal open={openDeleteModal} setOpen={setOpenDeleteModal} idsToDelete={selected} />
      <FlexPane
        testId={testIds.triggersSettingsPane}
        header={
          <SearchBarWithFilter
            placeholder={t().filterTriggers.singular.label}
            testId={testIds.triggers}
            entity={FilterEntity.TRIGGER}
            items={allTriggers}
            onFilter={(item, filter) => TriggerFilter.search(item, filter, triggerTexts)}
            buttons={buttons}
            setItems={setItems}
          />
        }
        content={
          <Table
            loading={triggersLoading}
            items={items}
            headers={headers}
            onSelected={items => setSelected(new Set(items))}
            disabled={item => item.id.length === 1}
          />
        }
      />
    </>
  );
}
