import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { GS1ApplicationIdentifier } from './product';
import { t } from './translation/Translator';
import { CustomFieldEntitySubType, CustomFieldValue } from './customField';
import { toMap } from '../util/map.util';

import { VentoryColor } from '../VentoryUI/util/color.util';

enum ProductTransactionValidationError {}

export enum ProductTransactionType {
  inbound = 'inbound',
  outbound = 'outbound',
  move = 'move',
  replenish = 'replenish',
}

export enum ProductTransactionStatus {
  created = 'created',
  processed = 'processed',
  failed = 'failed',
  cancelled = 'cancelled',
  rollback = 'rollback',
}

export function productTransactionStatusToString(status: ProductTransactionStatus): string {
  switch (status) {
    case ProductTransactionStatus.created:
      return 'Created';
    case ProductTransactionStatus.processed:
      return 'Processed';
    case ProductTransactionStatus.rollback:
    case ProductTransactionStatus.cancelled:
      return 'Cancelled';
    case ProductTransactionStatus.failed:
      return 'Failed';
  }
}
export function productTransactionStatusToLocalizedString(status: ProductTransactionStatus): string {
  switch (status) {
    case ProductTransactionStatus.created:
      return t().created.singular.label;
    case ProductTransactionStatus.processed:
      return t().processed.singular.label;
    case ProductTransactionStatus.rollback:
      return t().rollback.singular.label;
    case ProductTransactionStatus.cancelled:
      return t().cancelled.singular.label;
    case ProductTransactionStatus.failed:
      return t().failed.singular.label;
  }
}
export function productTransactionStatusToColorClass(status: ProductTransactionStatus) {
  switch (status) {
    case ProductTransactionStatus.created:
      return {
        color: VentoryColor.blue700,
        backgroundColor: VentoryColor.blue50,
      };
    case ProductTransactionStatus.processed:
      return {
        color: VentoryColor.green700,
        backgroundColor: VentoryColor.green50,
      };
    case ProductTransactionStatus.rollback:
      return {
        color: VentoryColor.orange700,
        backgroundColor: VentoryColor.orange50,
      };
    case ProductTransactionStatus.cancelled:
    case ProductTransactionStatus.failed:
      return {
        color: VentoryColor.red700,
        backgroundColor: VentoryColor.red50,
      };
  }
}
export const productTransactionTypeToCustomFieldEntitySubtype = (type: ProductTransactionType) => {
  switch (type) {
    case ProductTransactionType.inbound:
      return CustomFieldEntitySubType.inbound;
    case ProductTransactionType.move:
      return CustomFieldEntitySubType.move;
    case ProductTransactionType.outbound:
      return CustomFieldEntitySubType.outbound;
  }
};

export enum ProductTransactionParentType {
  order = 'order',
  task = 'task',
  quickAction = 'quickAction',
  transaction = 'transaction', // For rollback
  container = 'container',
  upload = 'upload',
}

export function productTransactionParentTypeToString(type?: ProductTransactionParentType) {
  switch (type) {
    case ProductTransactionParentType.upload:
      return 'Upload';
    case ProductTransactionParentType.order:
      return 'Order';
    case ProductTransactionParentType.quickAction:
      return 'Quick Action';
    case ProductTransactionParentType.task:
      return 'Task';
    case ProductTransactionParentType.transaction:
      return 'Rollback';
  }
  return '';
}

export class ProductTransactionSpecifiers {
  id?: string;
  toStockLocationId?: string;
  fromStockLocationId?: string;
  toProductId?: string;
  fromProductId?: string;
  lotId?: string;
  pmdId?: string;
  serialNbr?: string;
  lpn?: string;
  toBinId?: string;
  fromBinId?: string;
  fromContainerId?: string;
  toContainerId?: string;
  countryOfOrigin?: string;
  gs1ApplicationIdentifiers: Map<GS1ApplicationIdentifier, string> = new Map();
  quantity!: number;
  processedQuantity?: number;
  customFields: CustomFieldValue[] = [];

  constructor(obj: any) {
    Object.assign(this, cloneDeep(obj));
  }

  withCustomField(customField: CustomFieldValue) {
    for (let i = 0; i < this.customFields.length; i++) {
      if (this.customFields[0].id === customField.id) {
        this.customFields[0] = customField;
        return cloneDeep(this);
      }
    }

    this.customFields.push(customField);
    return cloneDeep(this);
  }
}

interface ProductTransactionMetaData {
  toQuantity?: string;
  fromQuantity?: string;
}

export class ProductTransaction extends CompanyEntity {
  status!: ProductTransactionStatus;
  product!: ProductTransactionSpecifiers;
  reasonForFailure?: string;
  parentId?: string;
  parentType?: ProductTransactionParentType;
  type!: ProductTransactionType;
  processedAt?: string;
  processedBy?: string;
  metadata?: ProductTransactionMetaData;
  customFields: Map<string, CustomFieldValue> = new Map();

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));

    if (obj.customFields && Array.isArray(obj.customFields)) {
      this.customFields = new Map(toMap((obj as any).customFields || [], 'id'));
    } else if (obj.customFields && obj.customFields instanceof Map) {
      this.customFields = new Map(obj.customFields);
    }

    this.product.processedQuantity = this.product.quantity;
  }

  forCreate(): CreateProductTransactionInput {
    return {
      ...this,
      customFields: [...this.customFields.values()].map(p => p),
    };
  }

  override forUpdate(): UpdateProductTransactionInput {
    const pt = UpdateProductTransactionInput.from(this, UpdateProductTransactionInput);
    delete pt.metadata;
    return {
      ...pt,
      customFields: [...(this.customFields?.values() || [])],
    } as any as UpdateProductTransactionInput; // TODO: How is this done in order.ts?
  }

  override forDelete(): DeleteProductTransactionInput {
    return DeleteProductTransactionInput.from(this, DeleteProductTransactionInput);
  }

  override validate(fields: (keyof ProductTransaction)[]) {
    return this.validateEntity(fields, field => {
      return null;
    });
  }

  withType(type: ProductTransactionType) {
    this.type = type;
    if (type === ProductTransactionType.inbound) {
      this.product.fromStockLocationId = undefined;
      this.product.fromBinId = undefined;
    } else if (type === ProductTransactionType.outbound) {
      this.product.toStockLocationId = undefined;
      this.product.toBinId = undefined;
    }
    return cloneDeep(this);
  }

  withToStockLocationId(stockLocationId?: string | null) {
    this.product.toStockLocationId = stockLocationId || undefined;
    this.product.toBinId = undefined;
    return cloneDeep(this);
  }

  withFromStockLocationId(stockLocationId?: string | null) {
    this.product.fromStockLocationId = stockLocationId || undefined;
    this.product.fromBinId = undefined;
    return cloneDeep(this);
  }

  withToBinId(binId?: string) {
    this.product.toBinId = binId;
    return cloneDeep(this);
  }

  withFromBinId(binId?: string) {
    this.product.fromBinId = binId;
    return cloneDeep(this);
  }

  withSerial(serial?: string) {
    this.product.serialNbr = serial;
    return cloneDeep(this);
  }

  withLPN(lpn?: string) {
    this.product.lpn = lpn;
    return cloneDeep(this);
  }

  withLotId(lotId?: string) {
    this.product.lotId = lotId;
    return cloneDeep(this);
  }

  withQuantity(quantity: number) {
    this.product.quantity = quantity;
    return cloneDeep(this);
  }

  withQuantityToProcess(quantity: number) {
    this.product.processedQuantity = quantity;
    return cloneDeep(this);
  }

  withCustomField(customFieldValue: CustomFieldValue) {
    this.customFields.set(customFieldValue.id, customFieldValue);
    return cloneDeep(this);
  }
}

export class CreateProductTransactionInput extends forCreate(ProductTransaction) {}

export class UpdateProductTransactionInput extends forUpdate(ProductTransaction) {}

export class DeleteProductTransactionInput extends forDelete(ProductTransaction) {}
