import React from 'react';
import { VentorySubscription } from '../types/billing';

export interface BillingContextProps {
  subscription: VentorySubscription;
  setSubscription: (subscription: VentorySubscription) => void;
  billingLoading: boolean;
}

const defaultContext: BillingContextProps = {
  subscription: new VentorySubscription({ companyId: '00000000-0000-0000-0000-000000000000' }),
  setSubscription: () => {},
  billingLoading: false,
};

export const BillingContext = React.createContext<BillingContextProps>(defaultContext);
