import React from 'react';
import { filterToManageTypes, ManageType, manageTypeToString } from '../Content/ManageTypeFilterContent';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function ManageTypeFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...filterToManageTypes(filter)]}
      text={item => manageTypeToString(item as ManageType)}
      onRemove={i => {
        const type = i as ManageType;
        switch (type) {
          case 'lot':
            filter.lotManaged = undefined;
          case 'lpn':
            filter.lpnManaged = undefined;
          case 'serial':
            filter.serialManaged = undefined;
        }
        setFilter({ ...filter });
      }}
    />
  );
}
