import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdministratorCompanyPane from './Company/AdministratorCompanyPane';
import AdministratorCustomerPane from './Customer/AdministratorCustomerPane';
import AdministratorSuperUserPane from './SuperUser/AdministratorSuperUserPane';
import { Header } from '../../VentoryUI/components/common/Header/Header';
import { Content } from '../../VentoryUI/components/common/Content/Content';
import Pane from '../../VentoryUI/components/common/Pane/Pane';
import TranslationsScreen from './Translations/TranslationsScreen';
import { SuperUserContext } from '../../context/SuperUserContext';
import { SuperUserRole } from '../../types/superUser';

export default function AdministratorScreen() {
  const { superUser } = useContext(SuperUserContext);

  const tabs = [
    {
      text: 'My Companies',
      path: '/admin/company',
      key: 'company',
    },
    {
      text: 'Customers',
      path: '/admin/customers',
      key: 'customers',
    },
    {
      text: 'Super Users',
      path: '/admin/users',
      key: 'users',
    },
    {
      text: 'Translations',
      path: '/admin/translations',
      key: 'translations',
    },
  ].filter(item => {
    if (!superUser) return false;

    if (
      item.key === 'customers' &&
      (superUser.role === SuperUserRole.external || superUser.role === SuperUserRole.translator)
    ) {
      return false;
    }

    if (
      item.key === 'users' &&
      (superUser.role === SuperUserRole.external || superUser.role === SuperUserRole.translator)
    ) {
      return false;
    }

    if (item.key === 'company' && superUser.role === SuperUserRole.translator) {
      return false;
    }

    return true;
  });

  return (
    <Grid container>
      <Header />
      <Grid item xs={12} justifyContent={'center'}>
        <Content>
          <Pane tabs={tabs}>
            <Routes>
              <Route path='company' element={<AdministratorCompanyPane />} />
              <Route path='customers' element={<AdministratorCustomerPane />} />
              <Route path='users' element={<AdministratorSuperUserPane />} />
              <Route path='translations' element={<TranslationsScreen />} />
              <Route path='*' element={<Navigate to={'company'} />} />
            </Routes>
          </Pane>
        </Content>
      </Grid>
    </Grid>
  );
}
