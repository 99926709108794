import { t } from '../translation/Translator';

export enum StockLocationAggregateType {
  all = 'all',
  allOf = 'allOf',
  any = 'any',
  anyOf = 'anyOf',
}

export function stockLocationAggregateTypeToString(type: StockLocationAggregateType): string {
  switch (type) {
    case StockLocationAggregateType.all:
      return t().all.singular.label;
    case StockLocationAggregateType.allOf:
      return t().allOf.singular.label;
    case StockLocationAggregateType.any:
      return t().any.singular.label;
    case StockLocationAggregateType.anyOf:
      return t().anyOf.singular.label;
  }
}
