import React from 'react';
import { TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { FooterButton } from '../Button/Button';
import { ModalFooter } from './ModalFooter';

interface ModalPaneProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  footerButtons?: FooterButton[];
  overflow?: 'visible' | 'auto';
  testId?: TestIdIdentifier;
}

export default function ModalPane({ children, footer, footerButtons, overflow = 'visible', testId }: ModalPaneProps) {
  return (
    <div className='h-full flex flex-col' data-testid={testId?.name}>
      <div className={`p-6 grow overflow-${overflow}`}>{children}</div>

      {footer || footerButtons?.length ? <ModalFooter children={footer} buttons={footerButtons} /> : null}
    </div>
  );
}
