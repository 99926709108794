import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import LoadingPackage from '../../../Common/LoadingPackage';
import ContactInfoPane from './ContactInfoPane';
import { ContactContext } from '../../../../context/ContactContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { Contact } from '../../../../types/contact';
import Pane from '../../../../VentoryUI/components/common/Pane/Pane';

export default function UpdateContactPane() {
  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const { contacts, contactsLoading } = useContext(ContactContext);
  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');

  return (
    <Pane error={error}>
      {contactsLoading && !contacts.get(id) ? (
        <Grid container alignItems={'center'}>
          <Grid item className='fill-gray-300 ' marginX={'auto'}>
            <LoadingPackage />
          </Grid>
        </Grid>
      ) : (
        <ContactInfoPane
          contact={contacts.get(id) || new Contact({ companyId: currentCompany.id })}
          setError={setError}
        />
      )}
    </Pane>
  );
}
