import React from 'react';
import { AuthenticationSettings } from '../types/authenticationSettings';

export interface AuthenticationSettingsContextProps {
  authenticationSettings: AuthenticationSettings | undefined;
  setAuthenticationSettings: (settings: AuthenticationSettings | undefined) => void;
  authenticationSettingsLoading: boolean;
}

const defaultContext: AuthenticationSettingsContextProps = {
  authenticationSettings: undefined,
  setAuthenticationSettings: () => {},
  authenticationSettingsLoading: false,
};

export const AuthenticationSettingsContext = React.createContext<AuthenticationSettingsContextProps>(defaultContext);
