import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import { OrderMutations, UpdateOrderResponse, UpdateOrderVariables } from '../../../../graphql/order.graphql';
import { OrderContext } from '../../../../context/OrderContext';
import { Order } from '../../../../types/order';
import CreateOrderUserPane from './Panes/CreateOrderUserPane';
import { testIds } from '../../../../util/identifiers/identifiers.util';

import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface AssignOrderUserModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  order: Order;
  setOrder: (order: Order) => void;
}

export default function AssignOrderUserModal({ open, setOpen, order, setOrder }: AssignOrderUserModalProps) {
  if (!open) return null;

  const { orders, setOrders } = useContext(OrderContext);

  const [error, setError] = useState<string>('');
  const [orderInput, setOrderInput] = useState<Order>(new Order(order));

  const [update, { loading }] = useMutation<UpdateOrderResponse, UpdateOrderVariables>(OrderMutations.update, {
    onCompleted: res => {
      const o = res.updateOrder[0];
      orders.set(o.id, new Order(o));
      setOrders(new Map(orders));
      setOrderInput(new Order(o));
      setOrder(new Order(o));
      handleClose();
    },
  });

  const handleSave = async () => {
    try {
      const input = orderInput.forUpdate();

      await update({
        variables: {
          orders: [input],
        },
      });
    } catch (e: any) {
      setError(String(e.message));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      height='550px'
      title={t().reassignUsers.singular.label}
      testId={testIds.createOrderModal}
    >
      <CreateOrderUserPane
        loading={loading}
        order={orderInput}
        setOrder={setOrderInput}
        save={handleSave}
        cancel={handleClose}
      />
    </Modal>
  );
}
