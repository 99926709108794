import { t } from '../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { AddIcon } from '../../../../icons/Add/AddIcon';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function AddButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.add,
    startIcon: <AddIcon />,
    style: 'secondary',
    text: t().add.singular.label,
    onClick: onClick,
    ...props,
  };
}

interface AddButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function AddButton({ onClick, ...props }: AddButtonProps) {
  return <Button {...AddButtonTemplate(onClick, props)} />;
}
