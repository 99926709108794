import { Grid } from '@mui/material';
import { StockLocation } from '../../../../types/stockLocation';
import TextInput from '../../../Common/TextInput';
import React, { useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import DropdownSelect from '../../../Common/DropdownSelect';
import { countries } from '../../../../util/country';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface StockLocationInfoInputProps {
  stockLocation?: StockLocation;
  footer: (input: StockLocation) => JSX.Element;
  title?: string;
}

export default function StockLocationInfoPane({ stockLocation, footer, title }: StockLocationInfoInputProps) {
  if (!stockLocation) return null; // TODO: Not found

  const [stockLocationInput, setStockLocationInput] = useState<StockLocation>(new StockLocation(stockLocation));

  return (
    <FlexPane
      testId={testIds.stockLocationInfoPane}
      content={
        <Paper>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {title ? (
              <Grid item xs={12}>
                <p className='font-semibold text-xl'>{title}</p>
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    value={stockLocationInput.name}
                    label={t().stockLocationName.singular.label}
                    placeholder={t().stockLocationName.singular.label}
                    onChange={v => stockLocationInput.withName(v)}
                    testId={testIds.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={stockLocationInput.identifier}
                    label={t().stockLocationIdentifier.singular.label}
                    placeholder={t().stockLocationIdentifier.singular.label}
                    onChange={v => stockLocationInput.withIdentifier(v)}
                    testId={testIds.identifier}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label={t().mobileStockLocation.singular.label}
                    onChange={v => stockLocationInput.withMobile(v)}
                    value={stockLocationInput.mobile || false}
                    testId={testIds.mobile}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    value={stockLocationInput.address?.addressLine1}
                    label={t().addressLine1.singular.label}
                    placeholder={t().addressLine1.singular.label}
                    onChange={v => stockLocationInput.withAddressLine1(v)}
                    testId={testIds.addressLine1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={stockLocationInput.address?.addressLine2}
                    label={t().addressLine2.singular.label}
                    placeholder={t().addressLine2.singular.label}
                    onChange={v => stockLocationInput.withAddressLine2(v)}
                    testId={testIds.addressLine2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={stockLocationInput.address?.postalCode}
                    label={t().postalCode.singular.label}
                    placeholder={t().postalCode.singular.label}
                    onChange={v => stockLocationInput.withPostalCode(v)}
                    testId={testIds.postalCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={stockLocationInput.address?.city}
                    label={t().city.singular.label}
                    placeholder={t().city.singular.label}
                    onChange={v => stockLocationInput.withCity(v)}
                    testId={testIds.city}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={stockLocationInput.address?.region}
                    label={t().region.singular.label}
                    placeholder={t().region.singular.label}
                    onChange={v => stockLocationInput.withRegion(v)}
                    testId={testIds.region}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropdownSelect
                    testId={testIds.country}
                    placeholder={t().country.singular.label}
                    label={t().country.singular.label}
                    selectedValue={countries.find(c => c.cca2 === stockLocationInput.address?.countryCode) || null}
                    maxHeight='150px'
                    values={countries}
                    toText={(item: any) => item.name.common}
                    onChange={(item: any) =>
                      setStockLocationInput(stockLocationInput.withCountryCode(item ? item.cca2 : undefined))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={footer(stockLocationInput)}
    />
  );
}
