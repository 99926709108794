import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../context/CompanyContext';
import { useContext } from 'react';
import { Grid } from '@mui/material';
import CompanySelectFrame from '../Common/CompanySelectFrame';
import { storeCurrentCompanyId } from '../../util/token.util';
import React from 'react';

export default function CompanySelectScreen() {
  const navigate = useNavigate();
  const { companies, refreshCompany } = useContext(CompanyContext);

  return (
    <Grid container height={'100vh'} alignContent={'center'} marginX={'auto'} width={'30%'} justifyContent={'center'}>
      {[...companies.values()].map(company => (
        <Grid item>
          <CompanySelectFrame
            key={company.id}
            company={company}
            onClick={async () => {
              storeCurrentCompanyId(company.id);
              await refreshCompany(company.id);
              navigate('/dashboard');
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
