import { Grid } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';

interface ScrollableFilterContentProps {
  children: React.ReactNode;
}

export default function ScrollableFilterContent({ children }: ScrollableFilterContentProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (!ref.current) return;
    const topFromBottom = document.documentElement.clientHeight - ref.current.getBoundingClientRect().top;
    setMaxHeight(topFromBottom < 500 ? topFromBottom - 16 : undefined);
  }, [ref.current]);

  return (
    <Grid item xs={12} className='w-full' ref={ref} style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
      {children}
    </Grid>
  );
}
