import React from 'react';
import { TaskSettings } from '../types/taskSettings';

export interface TaskSettingsContextProps {
  taskSettings: Map<string, TaskSettings>;
  setTaskSettings: (tasks: Map<string, TaskSettings>) => void;
  taskSettingsLoading: boolean;
}

const defaultContext: TaskSettingsContextProps = {
  taskSettings: new Map(),
  setTaskSettings: () => {},
  taskSettingsLoading: false,
};

export const TaskSettingsContext = React.createContext<TaskSettingsContextProps>(defaultContext);
