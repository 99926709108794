import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import TextInput from '../../../Common/TextInput';
import { t } from '../../../../types/translation/Translator';
import DropdownSelect from '../../../Common/DropdownSelect';
import {
  CsvProductUploadHeaders,
  CsvUploadConfiguration,
  CsvUploadType,
  ventoryFieldToText,
} from '../../../../types/csvUploadConfiguration';
import { CompanyContext } from '../../../../context/CompanyContext';
import { useMutation } from '@apollo/client';
import {
  CreateCsvUploadConfigurationResponse,
  CreateCsvUploadConfigurationVariables,
  CsvUploadConfigurationMutations,
  CsvUploadResponse,
  CsvUploadVariables,
} from '../../../../graphql/csvUploadConfiguration';
import { getBackgroundColor, getBorderColor, getTextColor } from '../../../../util/color.util';
import { TestIdIdentifier, testIds } from '../../../../util/identifiers/identifiers.util';
import { CsvUploadConfigurationContext } from '../../../../context/CsvUploadConfigurationContext';
import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { ColorStyleType } from '../../../../VentoryUI/util/color.util';

interface CreateCsvUploadConfigurationMappingInputProps {
  required: string[];
  optional: string[];
  headers: string[];
  type: CsvUploadType;
  onClose: () => void;
  onBack: () => void;
  stockLocationId?: string;
  csvFileAsBase64Data: string;
  setError: (err: string) => void;
  handleUploadOverride?: (csvFileAsBase64Data: string, configurationId: string) => Promise<void>;
  setRequired?: (required: string[]) => void;
}

export default function CreateCsvUploadConfigurationMapping({
  required,
  optional,
  headers,
  type,
  onClose,
  onBack,
  stockLocationId,
  csvFileAsBase64Data,
  setError,
  setRequired,
  handleUploadOverride,
}: CreateCsvUploadConfigurationMappingInputProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { csvUploadConfigurations, setCsvUploadConfigurations } = useContext(CsvUploadConfigurationContext);

  const [upload, { loading: uploadLoading }] = useMutation<CsvUploadResponse, CsvUploadVariables>(
    CsvUploadConfigurationMutations.upload,
    {
      onCompleted: res => (res.csvUpload.upload === true ? onClose() : null),
      onError: res => setError(res.message),
    },
  );

  const handleUpload = async (configurationId: string) => {
    await upload({
      variables: {
        configurationId,
        input: {
          companyId: currentCompany.id,
          stockLocationId: stockLocationId || '',
          type,
          csvFileAsBase64Data,
        },
      },
    });
  };

  const [create, { loading: createLoading }] = useMutation<
    CreateCsvUploadConfigurationResponse,
    CreateCsvUploadConfigurationVariables
  >(CsvUploadConfigurationMutations.create, {
    onCompleted: async res => {
      const createdConfig = res.createCsvUploadConfiguration[0];
      if (!createdConfig) {
        return setError(t().somethingUnexpectedHappened.singular.label);
      }
      csvUploadConfigurations.set(createdConfig.id, createdConfig);
      setCsvUploadConfigurations(new Map(csvUploadConfigurations));
      if (handleUploadOverride) handleUploadOverride(csvFileAsBase64Data, createdConfig.id);
      else handleUpload(createdConfig.id);
    },
    onError: err => setError(err.message),
  });

  const [csvUploadConfigurationInput, setCsvUploadConfigurationInput] = useState<CsvUploadConfiguration>(
    new CsvUploadConfiguration({ companyId: currentCompany.id, type: type, headerNames: headers }),
  );

  const [alreadySelectedHeaders, setAlreadySelectedHeaders] = useState<Set<string>>(new Set());

  const handleCreate = async () => {
    await create({
      variables: {
        csvUploadConfigurations: [csvUploadConfigurationInput.forCreate()],
      },
    });
  };

  const handleMappingChange = (value: string | null, item: string) => {
    if (setRequired && !stockLocationId) {
      if (item === CsvProductUploadHeaders.stockLocationName) {
        if (!value) {
          setRequired([...required, CsvProductUploadHeaders.stockLocationIdentifier]);
        } else {
          required.splice(required.indexOf(CsvProductUploadHeaders.stockLocationIdentifier), 1);
          setRequired([...required]);
        }
      } else if (item === CsvProductUploadHeaders.stockLocationIdentifier) {
        if (!value) {
          setRequired([...required, CsvProductUploadHeaders.stockLocationIdentifier]);
        } else {
          required.splice(required.indexOf(CsvProductUploadHeaders.stockLocationIdentifier), 1);
          setRequired([...required]);
        }
      }
    }

    if (value) {
      csvUploadConfigurationInput.withMapping(item, value);
      setAlreadySelectedHeaders(new Set(alreadySelectedHeaders.add(value)));
    } else {
      const oldValue = csvUploadConfigurationInput.mapping.get(item);
      if (!oldValue) return;
      csvUploadConfigurationInput.removeMapping(item);
      alreadySelectedHeaders.delete(oldValue);
      setAlreadySelectedHeaders(new Set(alreadySelectedHeaders));
    }
  };

  return (
    <ModalPane
      testId={testIds.createCsvUploadConfigurationMapping}
      overflow='auto'
      footer={
        <Grid container columnSpacing={1} height={'100%'} justifyContent={'flex-end'}>
          <Grid item marginTop={'auto'}>
            <Button
              style={'primary'}
              onClick={onBack}
              testId={testIds.back}
              text={t().back.singular.label}
              disabled={createLoading || uploadLoading}
            />
          </Grid>
          <Grid item marginTop={'auto'}>
            <Button
              style={'secondary'}
              onClick={handleCreate}
              testId={testIds.next}
              text={t().next.singular.label}
              loading={createLoading || uploadLoading}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        height={'100%'}
        justifyItems={'space-between'}
        data-testid={testIds.createCsvUploadConfigurationMapping}
      >
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <p
                style={{
                  backgroundColor: getBackgroundColor(currentCompany, ColorStyleType.primary),
                  color: getTextColor(currentCompany, ColorStyleType.primary),
                  borderColor: getBorderColor(currentCompany, ColorStyleType.primary),
                }}
                className='font-semibold text-sm p-2 rounded-lg'
              >
                {'No existing CSV configurations were found for this file, please create one instead.'}
              </p>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                mandatory
                label={t().name.singular.label}
                placeholder={t().csvUploadConfigurationNamePlaceholder.singular.label}
                onChange={v => csvUploadConfigurationInput.withName(v)}
                value={csvUploadConfigurationInput.name}
                testId={testIds.name}
              />
            </Grid>
            <Grid item xs={12}>
              <p className='text-sm font-semibold'>{t().mapTheFollowingFields.singular.label}</p>
            </Grid>
            <Grid item xs={12} ml={1} height={'395px'} overflow={'auto'}>
              <Grid container>
                <Grid item xs={10}>
                  {required.map(item => (
                    <Grid key={item} container>
                      <Grid item width={'250px'} marginY={'auto'}>
                        <Grid container>
                          <Grid item>
                            <p className='text-sm'>{ventoryFieldToText(item)}</p>
                          </Grid>
                          <Grid item>
                            <p className='ml-1 text-sm font-medium text-red-500'>*</p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item width={'300px'} marginY={'auto'}>
                        <DropdownSelect
                          testId={new TestIdIdentifier(item)}
                          maxHeight='150px'
                          values={headers.filter(h => !alreadySelectedHeaders.has(h))}
                          selectedValue={csvUploadConfigurationInput.mapping.get(item) || null}
                          toText={v => v}
                          onChange={v => handleMappingChange(v, item)}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={10}>
                  {optional.sort().map(item => (
                    <Grid key={item} container>
                      <Grid item width={'250px'} marginY={'auto'}>
                        <Grid container>
                          <Grid item>
                            <p className='text-sm'>{ventoryFieldToText(item)}</p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item width={'300px'} marginY={'auto'}>
                        <DropdownSelect
                          maxHeight='150px'
                          testId={new TestIdIdentifier(item)}
                          values={headers.filter(h => !alreadySelectedHeaders.has(h))}
                          selectedValue={csvUploadConfigurationInput.mapping.get(item) || null}
                          toText={v => v}
                          onChange={v => handleMappingChange(v, item)}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
