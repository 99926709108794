import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { OrderSettings } from '../types/orderSettings';

const orderSettings = gql`
  fragment OrderSettings on OrderSettings {
    id
    version
    companyId
    prefix
    suffix
    digits
    type
    sendPushNotificationToAssignees
  }
`;

const get = gql`
  query OrderSettings($companyId: String!) {
    orderSettings(companyId: $companyId) {
      data {
        ...OrderSettings
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${orderSettings}
`;

export interface GetOrderSettingsVariables {
  companyId: string;
}

export interface GetOrderSettingsResponse {
  orderSettings: PaginatedOrderSettings;
}

class PaginatedOrderSettings extends forPaginated<OrderSettings>() {}

const create = gql`
  mutation CreateOrderSettings($orderSettings: [CreateOrderSettingsInput!]!) {
    createOrderSettings(orderSettings: $orderSettings) {
      ...OrderSettings
    }
  }
  ${orderSettings}
`;

export interface CreateOrderSettingsVariables {
  orderSettings: OrderSettings[];
}

export interface CreateOrderSettingsResponse {
  createOrderSettings: OrderSettings[];
}

const update = gql`
  mutation UpdateOrderSettings($orderSettings: [UpdateOrderSettingsInput!]!) {
    updateOrderSettings(orderSettings: $orderSettings) {
      ...OrderSettings
    }
  }
  ${orderSettings}
`;

export interface UpdateOrderSettingsVariables {
  orderSettings: OrderSettings[];
}

export interface UpdateOrderSettingsResponse {
  updateOrderSettings: OrderSettings[];
}

export const OrderSettingsQueries = {
  get,
};

export const OrderSettingsMutations = {
  create,
  update,
};
