import React from 'react';
import { ScanRule } from '../types/scanRule';

export interface ScanRuleContextProps {
  scanRules: Map<string, ScanRule>;
  setScanRules: (ScanRule: Map<string, ScanRule>) => void;
  scanRulesLoading: boolean;
}

const defaultContext: ScanRuleContextProps = {
  scanRules: new Map(),
  setScanRules: () => {},
  scanRulesLoading: false,
};

export const ScanRuleContext = React.createContext<ScanRuleContextProps>(defaultContext);
