import React from 'react';
import { CompanyRole, CompanyRoleAssignment } from '../types/companyRoleAssignment';

export interface CompanyRoleAssignmentContextProps {
  companyRoles: Map<string, CompanyRoleAssignment[]>;
  setCompanyRoles: (roles: Map<string, CompanyRoleAssignment[]>) => void;
  refreshCompanyRoles: (companyId: string) => Promise<void>;
  currentCompanyRole: (companyId: string) => CompanyRole | undefined;
  hasCompanyRole: (companyId: string, role: CompanyRole) => boolean;
}

const defaultContext: CompanyRoleAssignmentContextProps = {
  companyRoles: new Map(),
  setCompanyRoles: roles => {},
  refreshCompanyRoles: async () => {},
  currentCompanyRole: () => undefined,
  hasCompanyRole: () => false,
};

export const CompanyRoleAssignmentContext = React.createContext<CompanyRoleAssignmentContextProps>(defaultContext);
