import React, { useContext } from 'react';
import { ProductMasterDataExportConfigurationContext } from '../../../../context/ProductMasterDataExportConfigurationContext';
import LoadingPackage from '../../../Common/LoadingPackage';
import { Grid } from '@mui/material';
import ProductMasterDataExportConfigurationItem from './Export/Common/ProductMasterDataExportConfigurationItem';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

export default function ReferenceDataSettingsReportPane() {
  const {
    productMasterDataExportConfigurations,
    setProductMasterDataExportConfigurations,
    productMasterDataExportConfigurationsLoading,
  } = useContext(ProductMasterDataExportConfigurationContext);

  if (productMasterDataExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper>
      <Grid container>
        {[...productMasterDataExportConfigurations.values()].map(configuration => (
          <Grid key={configuration.id} item>
            <ProductMasterDataExportConfigurationItem configuration={configuration} key={configuration.id} />
          </Grid>
        ))}
        <Grid item>
          <ProductMasterDataExportConfigurationItem />
        </Grid>
      </Grid>
    </Paper>
  );
}
