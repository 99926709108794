import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CreateTriggerInput, Trigger, TriggerEntityType } from '../../../../../types/trigger';
import Selector from '../../../../Common/Selector';
import { UserContext } from '../../../../../context/UserContext';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import Checkbox from '../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { FinishButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/FinishButton';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';

interface CreateTriggerUsersInputProps {
  trigger: CreateTriggerInput;
  setTrigger: (trigger: Trigger) => void;
  next: () => void;
  back: () => void;
  loading: boolean;
}

export function CreateTriggerUsersPane({ trigger, setTrigger, next, back, loading }: CreateTriggerUsersInputProps) {
  const { companyUsers } = useContext(UserContext);

  return (
    <ModalPane
      testId={testIds.createTriggerUsersPane}
      footerButtons={[
        BackButtonTemplate(back, { disabled: loading }),
        FinishButtonTemplate(next, { loading: loading }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Selector
                testId={testIds.user}
                placeholder={t().triggerUserText.singular.label}
                values={[...companyUsers.values()].map(user => user.userId) || []}
                checkedValues={trigger.userIds}
                toText={item => companyUsers.get(item)?.email || 'Unknown user'}
                onChange={checked => setTrigger(trigger.withUserIds(checked))}
              />
            </Grid>
            {(trigger.entityType === TriggerEntityType.productTransaction && trigger.entityParentType) ||
            trigger.entityType === TriggerEntityType.task ? (
              <Grid item xs={12} mb={1}>
                <Checkbox
                  label={`Notify entity assignees`}
                  value={trigger.notifyEntityAssignees}
                  onChange={value => setTrigger(trigger.withNotifyEntityAssignees(value))}
                  tooltip={
                    'This will make sure that any users, assigned to an order or task related to this trigger, will also receive the appropriate alerts.'
                  }
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
