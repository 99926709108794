import React, { useRef } from 'react';
import * as d3 from 'd3';

interface RadialBarProps {
  value: number;
  max: number;
  height: number;
  width: number;
  color: string;
}

export function RadialBar({ value, max, height, width, color }: RadialBarProps) {
  const ref = useRef<SVGGElement>(null);

  const size = height > width ? width : height;

  const arcWidth = 20;
  const arcOuterRadius = (size > 180 ? 180 : size) / 2 - 10;
  const arcInnerRadius = arcOuterRadius - arcWidth;

  const progressPercentage = (value / max) * 100 || 0;

  const radius = 30;
  const arc = d3.arc().cornerRadius(radius);

  const progressArc = (value: number) =>
    arc({
      innerRadius: arcInnerRadius,
      outerRadius: arcOuterRadius,
      startAngle: 0,
      endAngle: 2 * Math.PI * value,
    });

  const fontSize = 20;
  const text = progressPercentage.toFixed(2);

  return (
    <>
      <g className='hover:stroke-1 hover:stroke-white' transform={`translate(${width / 2}, ${height / 2})`}>
        <path d={progressArc(1)!} fill={'rgba(234, 236, 240, 1)'} />
      </g>
      <g className='hover:stroke-2 hover:stroke-white' transform={`translate(${width / 2}, ${height / 2})`}>
        <path d={progressArc(progressPercentage / 100)!} fill={color} />
      </g>
      <g>
        <text
          transform={`translate(${width / 2 + text.length / 2}, ${height / 2 + fontSize / 3})`}
          fontSize={fontSize}
          fill='#4B5565'
          className='font-[500]'
          textAnchor={'middle'}
        >
          {`${text}%`}
        </text>
      </g>
    </>
  );
}
