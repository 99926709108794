import React, { useContext, useState } from 'react';
import { BinContext } from '../../../../context/BinContext';
import LoadingPackage from '../../../Common/LoadingPackage';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { t } from '../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import BinStatusLabel from '../../../Common/BinStatusLabel';
import { ProductContext } from '../../../../context/ProductContext';
import { Product } from '../../../../types/product';
import { LotContext } from '../../../../context/LotContext';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { quantityWithSuffix } from '../../../../types/unitOfMeasure';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SearchBar from '../../../../VentoryUI/components/common/SearchBar/SearchBar';
import Pane from '../../../../VentoryUI/components/common/Pane/Pane';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import Table from '../../../../VentoryUI/components/common/Table/Table';

export default function BinDetailPane() {
  const id = useParams()['id'] || '';

  const navigate = useNavigate();

  if (!id) return null; // TODO: Entity not found

  const { bins, binsLoading } = useContext(BinContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { products } = useContext(ProductContext);
  const { lots } = useContext(LotContext);
  const { productMasterData } = useContext(ProductMasterDataContext);

  const [filter, setFilter] = useState<string>('');

  const bin = bins.get(id);

  const items = [...products.values()]
    .map(v => [...v.values()])
    .flat()
    .filter(item => item.binId === bin?.id);

  const hasLPN = !!items.filter(item => item.lpn).length;
  const hasSerial = !!items.filter(item => item.serialNbr).length;
  const hasLot = !!items.filter(item => item.lotId).length;

  const handleFilter = (item: Product) => {
    if (
      removeDiacritics(toFilterString(productMasterData.get(item.productMasterDataId)?.productName)).includes(filter) ||
      removeDiacritics(toFilterString(productMasterData.get(item.productMasterDataId)?.productNumber)).includes(
        filter,
      ) ||
      removeDiacritics(toFilterString(bins.get(item.binId)?.name)).includes(filter) ||
      removeDiacritics(toFilterString(lots.get(item.lotId || '')?.number)).includes(filter) ||
      removeDiacritics(toFilterString(item.serialNbr)).includes(filter) ||
      removeDiacritics(toFilterString(item.lpn)).includes(filter)
    ) {
      return true;
    }
    return false;
  };

  const headers = [
    {
      key: 'productName',
      name: t().productName.singular.label,
      text: (item: Product) => productMasterData.get(item.productMasterDataId)?.productName || 'Unknown Product Name',
    },
    {
      key: 'productNumber',
      name: t().productNumber.singular.label,
      text: (item: Product) =>
        productMasterData.get(item.productMasterDataId)?.productNumber || 'Unknown Product Number',
    },
    {
      key: 'lpn',
      name: t().lpn.singular.label,
      text: (item: Product) => item.lpn || '',
    },
    {
      key: 'serialNumber',
      name: t().serial.singular.label,
      text: (item: Product) => item.serialNbr || '',
    },
    {
      key: 'lotNumber',
      name: t().lotNumber.singular.label,
      text: (item: Product) => lots.get(item.lotId || '')?.number || '',
    },
    {
      key: 'quantity',
      sortValue: (item: Product) => item.quantity,
      name: t().quantity.singular.label,
      text: (item: Product) =>
        quantityWithSuffix(item.quantityString, productMasterData.get(item.productMasterDataId)?.unitOfMeasure),
    },
  ].filter(item => {
    if (item.key === 'serialNumber' && !hasSerial) return false;
    if (item.key === 'lpn' && !hasLPN) return false;
    if (item.key === 'lotNumber' && !hasLot) return false;

    return true;
  });

  return (
    <Pane>
      {binsLoading && !bins.get(id) ? (
        <Grid container alignItems={'center'} className='h-full'>
          <Grid item className='fill-gray-300 ' marginX={'auto'}>
            <LoadingPackage />
          </Grid>
        </Grid>
      ) : (
        <FlexPane
          header={
            <Paper>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={6}>
                  <Grid container columnSpacing={1}>
                    <Grid item flexGrow={1} marginY={'auto'}>
                      <p className='font-semibold'>{t().bin.singular.label}</p>
                      <p className='text-xl font-bold text-gray-400 mt-2'>{bin?.name}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} marginY={'auto'}>
                  <p className='font-semibold'>{t().stockLocation.singular.label}</p>
                  <p className='text-xl font-bold text-gray-400 mt-2'>
                    {stockLocations.get(bin?.stockLocationId || '')?.name || ''}
                  </p>
                </Grid>
                <Grid item xs={2} textAlign={'right'} marginY={'auto'}>
                  <p className='font-semibold mb-2'>{t().status.singular.label}</p>
                  <BinStatusLabel status={binStatuses.get(bin?.binStatusId || '')} />
                </Grid>
              </Grid>
            </Paper>
          }
          content={
            <FlexPane
              header={
                <SearchBar onChange={value => setFilter(value)} placeholder={t().filterProducts.singular.label} />
              }
              content={<Table loading={binsLoading} items={items.filter(handleFilter)} headers={headers} />}
            />
          }
          footer={
            <Grid container>
              <Grid item xs={12} alignContent={'flex-end'}>
                <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                  <Grid item>
                    <BackButton onClick={() => navigate('/stock/bins')} style='secondary' />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
    </Pane>
  );
}
