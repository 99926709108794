import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { CompanyContext } from '../../../../context/CompanyContext';
import { CsvUploadConfiguration, CsvUploadType } from '../../../../types/csvUploadConfiguration';
import {
  CsvUploadConfigurationQueries,
  GetCsvUploadConfigurationForFileResponse,
  GetCsvUploadConfigurationForFileVariables,
} from '../../../../graphql/csvUploadConfiguration';
import { useLazyQuery } from '@apollo/client';
import { Base64 } from 'js-base64';
import CreateCsvUploadConfigurationMapping from '../../../Settings/StockLocation/Modals/CreateCsvUploadConfigurationMapping';
import { CsvUploadConfigurationSelect } from '../../../Settings/StockLocation/Modals/CsvUploadConfigurationSelect';
import { Grid } from '@mui/material';
import UploadCsvInput from '../../../Common/UploadCsvInput';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import CsvUploadConfigurationInspect from '../../../Settings/StockLocation/Modals/CsvUploadConfigurationInspect';

import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { CancelButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { NextButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/NextButton';

enum ImportProductMasterDataStatus {
  fileUpload,
  selectConfig,
  createConfig,
  inspect,
}

interface ImportProductMasterDataModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function ImportProductMasterDataModal({ open, setOpen }: ImportProductMasterDataModalInputProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);

  const [file, setFile] = useState<File | null>(null);
  const [status, setStatus] = useState<ImportProductMasterDataStatus>(ImportProductMasterDataStatus.fileUpload);

  const [configurations, setConfigurations] = useState<CsvUploadConfiguration[]>([]);
  const [otherConfigurations, setOtherConfigurations] = useState<CsvUploadConfiguration[]>([]);
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [requiredHeaders, setRequiredHeaders] = useState<string[]>([]);
  const [optionalHeaders, setOptionalHeaders] = useState<string[]>([]);
  const [fileAsBase64, setFileAsBase64] = useState<string | null>(null);

  const [error, setError] = useState<string>('');
  const [inspectId, setInspectId] = useState<string>();

  const [getConfigurations, { loading: getConfigurationsLoading }] = useLazyQuery<
    GetCsvUploadConfigurationForFileResponse,
    GetCsvUploadConfigurationForFileVariables
  >(CsvUploadConfigurationQueries.getForFile, {
    onCompleted: res => {
      setConfigurations(res.csvConfigurationsForFile.configurations.map(c => new CsvUploadConfiguration(c)));
      setFileHeaders(res.csvConfigurationsForFile.fileHeaders);
      setRequiredHeaders(res.csvConfigurationsForFile.requiredHeaders);
      setOptionalHeaders(res.csvConfigurationsForFile.optionalHeaders);
      setOtherConfigurations(res.csvConfigurationsForFile.otherConfigurations);
      if (
        !res.csvConfigurationsForFile.configurations.length &&
        !res.csvConfigurationsForFile.otherConfigurations.length
      ) {
        setStatus(ImportProductMasterDataStatus.createConfig);
      } else {
        setStatus(ImportProductMasterDataStatus.selectConfig);
      }
    },
    onError: err => setError(err.message),
  });

  const handleFile = (files: FileList | null) => {
    if (!files) {
      setFile(null);
      return;
    }
    setFile(files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    let asBase64 = Base64.encode(await file.text());

    await getConfigurations({
      variables: {
        companyId: currentCompany.id,
        type: CsvUploadType.productMasterData,
        csvFileAsBase64Data: asBase64,
      },
    });
    setFileAsBase64(asBase64);
  };

  const handleClose = () => {
    setFile(null);
    setStatus(ImportProductMasterDataStatus.fileUpload);
    setConfigurations([]);
    setOtherConfigurations([]);
    setFileHeaders([]);
    setRequiredHeaders([]);
    setOptionalHeaders([]);
    setFileAsBase64(null);
    setOpen(false);
    setError('');
  };

  const handleBack = () => {
    setError('');
    switch (status) {
      case ImportProductMasterDataStatus.fileUpload:
        return;
      case ImportProductMasterDataStatus.selectConfig:
        return setStatus(ImportProductMasterDataStatus.fileUpload);
      case ImportProductMasterDataStatus.createConfig:
        return setStatus(ImportProductMasterDataStatus.fileUpload);
    }
  };

  const content = () => {
    if (status === ImportProductMasterDataStatus.createConfig && fileAsBase64) {
      return (
        <CreateCsvUploadConfigurationMapping
          onClose={handleClose}
          onBack={handleBack}
          type={CsvUploadType.productMasterData}
          required={requiredHeaders}
          optional={optionalHeaders}
          headers={fileHeaders}
          csvFileAsBase64Data={fileAsBase64}
          setError={setError}
        />
      );
    }

    if (status === ImportProductMasterDataStatus.selectConfig && fileAsBase64) {
      return (
        <CsvUploadConfigurationSelect
          deleteExisting={false}
          otherConfigurations={otherConfigurations}
          onCreate={() => setStatus(ImportProductMasterDataStatus.createConfig)}
          onClose={handleClose}
          onBack={handleBack}
          csvFileAsBase64Data={fileAsBase64}
          type={CsvUploadType.productMasterData}
          configurations={configurations}
          setError={setError}
          onInspect={id => {
            setInspectId(id);
            setStatus(ImportProductMasterDataStatus.inspect);
          }}
        />
      );
    }

    if (status === ImportProductMasterDataStatus.inspect) {
      return (
        <CsvUploadConfigurationInspect
          headers={fileHeaders}
          configuration={otherConfigurations.find(c => c.id === inspectId)}
          onBack={handleBack}
        />
      );
    }

    return (
      <ModalPane
        testId={testIds.importProductMasterDataFileUploadPane}
        footerButtons={[
          {
            align: 'left',
            onClick: () => window.open(`${process.env.REACT_APP_STORAGE_URL}/ventory/pmd_template.csv`, '_blank'),
            text: t().downloadTemplate.singular.label,
          },
          CancelButtonTemplate(handleClose, { disabled: getConfigurationsLoading }),
          NextButtonTemplate(handleFileUpload, { disabled: !file, loading: getConfigurationsLoading }),
        ]}
      >
        <Grid container height={'100%'} alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <UploadCsvInput file={file} onFile={handleFile} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      error={error}
      height='650px'
      width='70%'
      title={t().importProductMasterData.singular.label}
      testId={testIds.importProductMasterDataModal}
    >
      {content()}
    </Modal>
  );
}
