import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function MessageQuestionCircleIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.00008 6.00149C7.11754 5.66757 7.34939 5.386 7.65456 5.20665C7.95974 5.0273 8.31854 4.96174 8.66742 5.02158C9.0163 5.08142 9.33274 5.2628 9.5607 5.5336C9.78866 5.8044 9.91343 6.14714 9.9129 6.50112C9.9129 7.50037 8.41402 8 8.41402 8M8.43334 10H8.44001M8.33341 13.3333C11.463 13.3333 14.0001 10.7963 14.0001 7.66667C14.0001 4.53705 11.463 2 8.33341 2C5.2038 2 2.66674 4.53705 2.66674 7.66667C2.66674 8.3 2.77064 8.90906 2.96232 9.47774C3.03446 9.69175 3.07052 9.79875 3.07703 9.88095C3.08345 9.96213 3.0786 10.019 3.05851 10.098C3.03817 10.1779 2.99327 10.261 2.90347 10.4272L1.81303 12.4456C1.65749 12.7335 1.57972 12.8774 1.59713 12.9885C1.61229 13.0853 1.66924 13.1705 1.75286 13.2215C1.84886 13.2801 2.01161 13.2632 2.3371 13.2296L5.75112 12.8767C5.8545 12.866 5.9062 12.8606 5.95332 12.8624C5.99966 12.8642 6.03237 12.8686 6.07756 12.879C6.12351 12.8896 6.18129 12.9118 6.29685 12.9564C6.92888 13.1999 7.61556 13.3333 8.33341 13.3333Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
