import React from 'react';
import { TaskExportConfiguration } from '../types/taskExportConfiguration';

export interface TaskExportConfigurationContextProps {
  taskExportConfigurations: Map<string, TaskExportConfiguration>;
  setTaskExportConfigurations: (tasks: Map<string, TaskExportConfiguration>) => void;
  taskExportConfigurationsLoading: boolean;
}

const defaultContext: TaskExportConfigurationContextProps = {
  taskExportConfigurations: new Map(),
  setTaskExportConfigurations: () => {},
  taskExportConfigurationsLoading: false,
};

export const TaskExportConfigurationContext = React.createContext<TaskExportConfigurationContextProps>(defaultContext);
