import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import {
  CompanyInviteState,
  CompanyRole,
  CompanyRoleAssignment,
  companyRoleToString,
} from '../../../types/companyRoleAssignment';
import InviteUserModal from './Modals/InviteUserModal';
import { CompanyContext } from '../../../context/CompanyContext';
import { useNavigate } from 'react-router-dom';
import UserImportModal from './Modals/UserImportModal';
import { UserContext } from '../../../context/UserContext';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import { ImportMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/ImportMenuItem';
import { SettingsMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { FilterEntity } from '../../../VentoryUI/components/filters/filter.util';
import { AddIcon } from '../../../VentoryUI/icons/Add/AddIcon';
import { CompanyRoleAssignmentFilter } from '../../../VentoryUI/components/filters/Filter/UserFilter';
import { MenuItemProps } from '../../../VentoryUI/components/common/Menu/MenuItem';
import Table from '../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';

interface UserSettingsPaneInputProps {}

export default function UserSettingsPane({}: UserSettingsPaneInputProps) {
  const navigate = useNavigate();

  const { companyRoles, hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { currentCompany } = useContext(CompanyContext);
  const { companyUsers } = useContext(UserContext);
  const { stockLocationRoles, hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);

  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);

  const headers = [
    {
      key: 'email',
      name: t().email.singular.label,
      text: (item: CompanyRoleAssignment) => item.email,
    },
    {
      key: 'name',
      name: t().firstAndLastName.singular.label,
      text: (item: CompanyRoleAssignment) =>
        `${companyUsers.get(item.userId)?.firstName} ${companyUsers.get(item.userId)?.lastName}` || '',
    },
    {
      key: 'role',
      name: t().role.singular.label,
      text: (item: CompanyRoleAssignment) =>
        companyRoleToString(item.role) + `${item.state === CompanyInviteState.accepted ? '' : ' (Invited)'}`,
    },
  ];

  const shownCompanyRoles = useMemo(() => {
    const currentCompanyRoles = [...companyRoles.values()].flat().filter(cr => cr.companyId === currentCompany.id);

    if (hasCompanyRole(currentCompany.id, CompanyRole.administrator)) return currentCompanyRoles;

    const visibleUsers = new Set<string>();
    for (const [stockLocationId, roles] of stockLocationRoles) {
      if (hasStockLocationRole(currentCompany.id, stockLocationId, StockLocationRole.STOCK_LOCATION_MANAGER))
        roles.forEach(role => visibleUsers.add(role.userId));
    }

    return currentCompanyRoles.filter(role => role.role === CompanyRole.administrator || visibleUsers.has(role.userId));
  }, [companyRoles]);

  const [items, setItems] = useState(shownCompanyRoles);

  const menuItems = useMemo(() => {
    const shown: MenuItemProps[] = [
      {
        text: t().inviteUser.singular.label,
        icon: <AddIcon />,
        onClick: () => setOpenInviteModal(true),
      },
    ];

    if (hasCompanyRole(currentCompany.id, CompanyRole.administrator)) {
      shown.push(
        ImportMenuItemTemplate(() => setOpenImportModal(true)),
        SettingsMenuItemTemplate(() => navigate('advanced/import_configurations')),
      );
    }

    return shown;
  }, []);

  return (
    <>
      <InviteUserModal open={openInviteModal} setOpen={setOpenInviteModal} />
      <UserImportModal open={openImportModal} setOpen={setOpenImportModal} />

      <FlexPane
        header={
          <SearchBarWithFilter
            onFilter={(item, filter) => CompanyRoleAssignmentFilter.search(item, filter, companyUsers)}
            entity={FilterEntity.USER}
            menuItems={menuItems}
            items={shownCompanyRoles}
            setItems={setItems}
            placeholder={t().filterUsers.singular.label}
          />
        }
        content={
          <Table
            items={items}
            headers={headers}
            onClick={item => {
              navigate(`/settings/users/${item.userId}/update/roles`);
            }}
          />
        }
      />
    </>
  );
}
