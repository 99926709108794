import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { FileContext } from '../../../../../context/FileContext';
import { DeleteFilesResponse, DeleteFilesVarables, FileMutations } from '../../../../../graphql/file.graphql';
import { FileEntityType } from '../../../../../types/file';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import DeleteModal from '../../Modal/DeleteModal';

interface DeleteFilesModalProps {
  open: boolean;
  setOpen: (open: boolean) => any;
  ids: string[];
  entityType: FileEntityType;
}

export default function DeleteFilesModal({ ids, open, setOpen, entityType }: DeleteFilesModalProps) {
  const { files, setFiles } = useContext(FileContext);

  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteFilesResponse, DeleteFilesVarables>(FileMutations.remove, {
    onCompleted: res => {
      res.deleteFiles.forEach(file => files.delete(file.id));
      setFiles(new Map(files));
      handleClose();
    },
  });

  const handleDelete = async () => {
    try {
      const filesToDelete = [];
      for (const id of ids) {
        const file = files.get(id);
        if (file) filesToDelete.push(file.forDelete());
      }

      if (!files.size) return setError('No files found!');

      let deleted = 0;
      do {
        await remove({
          variables: {
            files: filesToDelete.slice(deleted, deleted + 3000),
            entityType: entityType,
          },
        });
        deleted += 3000;
      } while (deleted < filesToDelete.length);
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      testId={testIds.deleteFilesModal}
      onClose={handleClose}
      open={open}
      error={error}
      text={t().verifyDeleteFiles.singular.label}
      onConfirm={handleDelete}
    />
  );
}
