import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ReorderRuleContext } from '../../../context/ReorderRuleContext';
import ReorderRuleInfoPane from './panes/ReorderRuleInfoPane';
import ReorderRuleUserPane from './panes/ReorderRuleUserPane';
import ReorderRuleBinPane from './panes/ReorderRuleBinPane';
import ReorderRuleStockLocationPane from './panes/ReorderRuleStockLocationPane';
import { ReorderRule } from '../../../types/reorderRule';
import { CompanyContext } from '../../../context/CompanyContext';
import { toMap } from '../../../util/map.util';
import { StockLocationAggregateType } from '../../../types/common/stockLocationAggregateType';
import { CompanyRole } from '../../../types/companyRoleAssignment';
import { ApolloError, useMutation } from '@apollo/client';
import {
  CreateReorderRuleResponse,
  CreateReorderRuleVariables,
  ReorderRuleMutations,
  UpdateReorderRuleResponse,
  UpdateReorderRuleVariables,
  DeleteReorderRuleResponse,
  DeleteReorderRuleVariables,
} from '../../../graphql/reorderRule.graphql';
import { v4 as uuidV4 } from 'uuid';
import ReorderRuleProductPane from './panes/ReorderRuleProductPane';

import CreateButton from '../../../VentoryUI/components/common/Button/Templates/CreateButton';
import UpdateButton from '../../../VentoryUI/components/common/Button/Templates/UpdateButton';
import BackButton from '../../../VentoryUI/components/common/Button/Templates/BackButton';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';
import { Tab } from '../../../VentoryUI/components/common/TabBar/TabBar';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';

export interface ReorderRuleProduct {
  productMasterDataId: string;
  reorderQuantity: string;
  replenishQuantity: string;
}

interface ReorderRuleScreenProps {
  groupId?: string;
}

export default function ReorderRuleScreen({ groupId }: ReorderRuleScreenProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { groupedReorderRules, setGroupedReorderRules, reorderRules, setReorderRules } = useContext(ReorderRuleContext);

  const [error, setError] = useState('');

  const { ruleMap, rules, group } = useMemo(() => {
    const rules = groupedReorderRules.get(groupId || '') || [];
    const group = rules.length ? { groupId: rules[0].groupId, rules: rules } : undefined;

    const ruleMap = new Map<string, ReorderRule>();
    for (const rule of rules) {
      ruleMap.set(rule.toHash(), rule);
    }

    return { ruleMap, rules, group };
  }, []);

  const [rule, setRule] = useState(group?.rules[0] || new ReorderRule({ companyId: currentCompany.id }));
  const [appliesToAllProducts, setAppliesToAllProducts] = useState(
    rules.filter(rule => !rule.productMasterDataId).length ? true : false,
  );
  const [reorderRuleProducts, setReorderRuleProducts] = useState<Map<string, ReorderRuleProduct>>(
    toMap(
      rules
        .filter(rule => rule.productMasterDataId)
        .map(rule => {
          return {
            productMasterDataId: rule.productMasterDataId!,
            reorderQuantity: rule.reorderQuantity,
            replenishQuantity: rule.replenishQuantity,
          };
        }),
      'productMasterDataId',
    ),
  );
  const [stockLocationIds, setStockLocationIds] = useState(
    (rules?.filter(rule => rule.stockLocationId).map(rule => rule.stockLocationId) || []) as string[],
  );

  const tabs: Tab[] = [
    {
      text: t().general.singular.label,
      path: 'general',
      key: 'general',
    },
    {
      text: t().product.plural.label,
      path: 'products',
      key: 'products',
    },
    {
      text: t().locations.singular.label,
      path: 'locations',
      key: 'locations',
    },
    {
      text: t().user.plural.label,
      path: 'users',
      key: 'users',
    },
    {
      text: t().bin.plural.label,
      path: 'bins',
      key: 'bins',
    },
  ].filter(tab => {
    if (tab.key === 'products' && appliesToAllProducts) return false;
    return true;
  });

  const [create, { loading: createLoading }] = useMutation<CreateReorderRuleResponse, CreateReorderRuleVariables>(
    ReorderRuleMutations.create,
  );

  const [update, { loading: updateLoading }] = useMutation<UpdateReorderRuleResponse, UpdateReorderRuleVariables>(
    ReorderRuleMutations.update,
  );

  const [remove, { loading: removeLoading }] = useMutation<DeleteReorderRuleResponse, DeleteReorderRuleVariables>(
    ReorderRuleMutations.remove,
  );

  const handleDone = () => {
    navigate('/reference_data/settings/stock_triggers');
  };

  const handleError = (e: any) => {
    if (e instanceof ApolloError) {
      if (e.message.includes('duplicate key')) {
        setError('Your rule includes combinations of products and locations that already exist in other rules.');
      } else {
        setError(e.message);
      }
    } else {
      setError((e as any).toString());
    }
  };

  const handleCreate = async () => {
    const toCreate: ReorderRule[] = [];
    const groupId = uuidV4();

    if (appliesToAllProducts) {
      if (rule.anyOrAll) {
        toCreate.push(
          new ReorderRule({ ...rule, stockLocationId: undefined, productMasterDataId: undefined, groupId: groupId }),
        );
      } else {
        for (const stockLocationId of stockLocationIds) {
          toCreate.push(
            new ReorderRule({
              ...rule,
              stockLocationId: stockLocationId,
              productMasterDataId: undefined,
              groupId: groupId,
            }),
          );
        }
      }
    } else if (rule.anyOrAll) {
      for (const [pmdId, product] of reorderRuleProducts) {
        toCreate.push(
          new ReorderRule({
            ...rule,
            stockLocationId: undefined,
            productMasterDataId: product.productMasterDataId,
            reorderQuantity: product.reorderQuantity,
            replenishQuantity: product.replenishQuantity,
            groupId: groupId,
          }),
        );
      }
    } else {
      for (const [pmdId, product] of reorderRuleProducts) {
        for (const stockLocationId of stockLocationIds) {
          toCreate.push(
            new ReorderRule({
              ...rule,
              stockLocationId: stockLocationId,
              productMasterDataId: product.productMasterDataId,
              reorderQuantity: product.reorderQuantity,
              replenishQuantity: product.replenishQuantity,
              groupId: groupId,
            }),
          );
        }
      }
    }

    try {
      if (toCreate.length) {
        const response = await create({ variables: { reorderRules: toCreate } });
        const data = response.data?.createReorderRule;
        if (data) {
          data.forEach(rule => {
            reorderRules.set(rule.id, new ReorderRule(new ReorderRule(rule)));
            if (groupedReorderRules.has(rule.groupId)) {
              groupedReorderRules.get(rule.groupId)?.push(new ReorderRule(rule));
            } else {
              groupedReorderRules.set(rule.groupId, [new ReorderRule(rule)]);
            }
          });
          setReorderRules(new Map(reorderRules));
          setGroupedReorderRules(new Map(groupedReorderRules));
        }
      }
    } catch (e) {
      return handleError(e);
    }

    handleDone();
  };

  const handleUpdate = async () => {
    const toCreate: ReorderRule[] = [];
    const toUpdate: ReorderRule[] = [];
    const toDelete: ReorderRule[] = [];

    const hashes = new Set<string>();
    if (appliesToAllProducts) {
      if (rule.anyOrAll) {
        hashes.add(':');
      } else {
        for (const stockLocationId of stockLocationIds) {
          hashes.add('' + ':' + stockLocationId);
        }
      }
    } else if (rule.anyOrAll) {
      for (const [pmdId, product] of reorderRuleProducts) {
        hashes.add(product.productMasterDataId + ':' + '');
      }
    } else {
      for (const [pmdId, product] of reorderRuleProducts) {
        for (const stockLocationId of stockLocationIds) {
          hashes.add(product.productMasterDataId + ':' + stockLocationId);
        }
      }
    }

    for (const hash of hashes) {
      const product = reorderRuleProducts.get(hash.split(':')[0] || '');

      const existing = ruleMap.get(hash);
      if (existing) {
        toUpdate.push(
          new ReorderRule({
            ...rule,
            id: existing.id,
            version: existing.version,
            productMasterDataId: existing.productMasterDataId,
            stockLocationId: existing.stockLocationId,
            reorderQuantity: product?.reorderQuantity || rule.reorderQuantity,
            replenishQuantity: product?.replenishQuantity || rule.replenishQuantity,
          }),
        );
      } else {
        toCreate.push(
          new ReorderRule({
            ...rule,
            groupId: groupId,
            productMasterDataId: hash.split(':')[0] || undefined,
            stockLocationId: hash.split(':')[1] || undefined,
            version: undefined,
            id: undefined,
            reorderQuantity: product?.reorderQuantity || rule.reorderQuantity,
            replenishQuantity: product?.replenishQuantity || rule.replenishQuantity,
          }),
        );
      }
    }

    for (const [key, value] of ruleMap) {
      if (!hashes.has(key)) toDelete.push(value.forDelete());
    }

    try {
      if (toDelete.length) {
      }
      if (toCreate.length || toUpdate.length || toDelete.length) {
        const { data } = await update({ variables: { toCreate, toUpdate, toDelete } });
        if (!data) return;

        const { deleted, updated, created } = data.updateReorderRule;

        if (deleted.length) {
          deleted.forEach(rule => {
            reorderRules.delete(rule.id);
            const rules = groupedReorderRules.get(rule.groupId);
            if (rules) {
              groupedReorderRules.set(
                rule.groupId,
                rules.filter(gr => gr.id !== rule.id),
              );
            }
          });
        }

        if (updated.length) {
          const map = new Map<string, ReorderRule[]>();
          for (const rule of updated) {
            reorderRules.set(rule.id, new ReorderRule(rule));
            if (map.has(rule.groupId)) map.get(rule.groupId)?.push(new ReorderRule(rule));
            else map.set(rule.groupId, [new ReorderRule(rule)]);
          }

          for (const [groupId, rules] of map) {
            groupedReorderRules.set(groupId, rules);
          }
        }

        if (created.length) {
          created.forEach(rule => {
            reorderRules.set(rule.id, new ReorderRule(new ReorderRule(rule)));
            if (groupedReorderRules.has(rule.groupId)) {
              groupedReorderRules.get(rule.groupId)?.push(new ReorderRule(rule));
            } else {
              groupedReorderRules.set(rule.groupId, [new ReorderRule(rule)]);
            }
          });
          setReorderRules(new Map(reorderRules));
          setGroupedReorderRules(new Map(groupedReorderRules));
        }
      }
    } catch (e: any) {
      return handleError(e);
    }

    handleDone();
  };

  const validate = () => {
    let reasons: string[] = [];

    if (!rule.name) reasons.push('General: Must have a name!');
    if ([...groupedReorderRules.values()].some(rules => rules[0].name === rule.name && rules[0].groupId !== groupId))
      reasons.push('General: A rule with this name already exists!');

    if (appliesToAllProducts) {
      if (!rule.reorderQuantity) reasons.push('General: Must enter reorder quantity!');
      if (!rule.replenishQuantity) reasons.push('General: Must enter replenish quantity!');
      if ([...reorderRules.values()].some(rule => !rule.reorderQuantity || !rule.replenishQuantity))
        reasons.push('Products: must enter reorder- & replenish quantity for every product!');
    } else {
      if (!reorderRuleProducts.size) reasons.push('Products: Must have products!');
    }

    if (
      !stockLocationIds.length &&
      (rule.stockLocationAggregateType === StockLocationAggregateType.allOf ||
        rule.stockLocationAggregateType === StockLocationAggregateType.anyOf)
    ) {
      reasons.push('Locations: Must select at least one location when type is All Of or Any Of!');
    }

    if (rule.notificationCompanyRoles.includes(CompanyRole.employee) && !rule.notificationStockLocationRoles.length) {
      reasons.push('Users: Must select at least one stock location role if company role employee is selected!');
    }

    return reasons;
  };
  const reasons = validate();

  const tooltip = () => {
    if (!reasons.length) return;

    return (
      <ul>
        {reasons.map(reason => {
          return <li key={reason}>{reason}</li>;
        })}
      </ul>
    );
  };

  return (
    <Pane error={error} tabs={tabs}>
      <FlexPane
        content={
          <Routes>
            <Route
              path='general'
              element={
                <ReorderRuleInfoPane
                  rule={rule}
                  setRule={setRule}
                  appliesToAllProducts={appliesToAllProducts}
                  setAppliesToAllProducts={setAppliesToAllProducts}
                />
              }
            />
            <Route
              path='products'
              element={
                <ReorderRuleProductPane
                  reorderRuleProducts={reorderRuleProducts}
                  setReorderRuleProducts={setReorderRuleProducts}
                />
              }
            />
            <Route
              path='locations'
              element={
                <ReorderRuleStockLocationPane
                  rule={rule}
                  setRule={setRule}
                  stockLocationIds={stockLocationIds}
                  setStockLocationIds={setStockLocationIds}
                />
              }
            />
            <Route path='users' element={<ReorderRuleUserPane rule={rule} setRule={setRule} />} />
            <Route
              path='bins'
              element={<ReorderRuleBinPane rule={rule} setRule={setRule} stockLocationIds={stockLocationIds} />}
            />
          </Routes>
        }
        footer={
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <BackButton
                onClick={() => navigate('/reference_data/settings/stock_triggers')}
                disabled={createLoading || removeLoading || updateLoading}
              />
            </Grid>
            <Grid item>
              {groupId ? (
                <UpdateButton
                  badge={{ content: reasons.length, backgroundColor: 'orange' }}
                  onClick={handleUpdate}
                  disabled={!!reasons.length}
                  tooltip={tooltip()}
                  loading={createLoading || removeLoading || updateLoading}
                />
              ) : (
                <CreateButton
                  badge={{ content: reasons.length, backgroundColor: 'orange' }}
                  onClick={handleCreate}
                  disabled={!!reasons.length}
                  tooltip={tooltip()}
                  loading={createLoading}
                />
              )}
            </Grid>
          </Grid>
        }
      />
    </Pane>
  );
}
