import { gql } from '@apollo/client';

const refreshCompanyCache = gql`
  mutation RefreshCompanyCache {
    refreshCompanyCache
  }
`;

export const UtilQueries = {
  refreshCompanyCache,
};
