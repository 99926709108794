import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useState } from 'react';
import { Tooltip as MuiTooltip, Grid } from '@mui/material';

interface TooltipProps {
  element: string | JSX.Element;
}

export default function Tooltip({ element }: TooltipProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid className='hover:cursor-pointer' item display='flex' marginY={'auto'}>
      <MuiTooltip open={open} onClose={handleClose} onOpen={handleOpen} title={element}>
        <InfoOutlinedIcon sx={{ width: '25px' }} />
      </MuiTooltip>
    </Grid>
  );
}
