import React from 'react';
import { FileWithSas } from '../../../../types/file';
import ImageView from './Image/ImageView';
import VideoView from './Video/VideoView';

interface FileViewProps {
  file: FileWithSas;
  className?: string;
}

export default function FileView({ file, className }: FileViewProps) {
  if (!file) return null;

  if (file.primaryFileType.includes('image')) {
    return <ImageView url={file.signature} className={className} />;
  }

  if (file.primaryFileType.includes('video')) {
    return <VideoView url={file.signature} className={className} />;
  }

  return null;
}
