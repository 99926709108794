import { gql } from '@apollo/client';
import { TranslationRecord, Translations } from '../../types/translation/common';
import { Translation, TranslationLanguage } from '../../types/translation/translation';
import { TranslationKey } from '../../types/translation/TranslationKey';

const translation = gql`
  fragment Translation on Translation {
    language
    translations
  }
`;

const get = gql`
  query Translation($language: String) {
    translation(language: $language) {
      ...Translation
    }
  }
  ${translation}
`;

export interface GetTranslationVariables {
  language?: string;
}

export interface GetTranslationResponse {
  translation: Translation;
}

const getAll = gql`
  query Translations {
    translations {
      ...Translation
    }
  }
  ${translation}
`;

export interface GetTranslationsVariables {}

export interface GetTranslationsResponse {
  translations: Translation[];
}

const updateRecord = gql`
  mutation UpdateTranslationRecord($record: TranslationRecordInput!, $key: TranslationKey!, $language: String!) {
    updateTranslationRecord(record: $record, key: $key, language: $language) {
      language
      translations
    }
  }
`;

export interface UpdateTranslationRecordVariables {
  key: TranslationKey;
  record: TranslationRecord;
  language: TranslationLanguage;
}

export interface UpdateTranslationRecordResponse {
  updateTranslationRecord: {
    language: TranslationLanguage;
    translations: Translations;
  };
}

export const TranslationQueries = {
  get,
  getAll,
};

export const TranslationMutations = {
  updateRecord,
};
