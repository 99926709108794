import { EntityFilter } from '../filter.util';
import { Lot } from '../../../../types/lot';
import { toFilterString } from '../../../../util/string.util';

export namespace LotFilter {
  export function search(item: Lot, textFilter: string) {
    if (toFilterString(item.number).includes(textFilter)) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: Lot[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      return onFilter(item, textFilter);
    });

    return result;
  }
}
