import { gql } from '@apollo/client';
import { CreateFileInput, DeleteFileInput, FileEntity, FileEntityType, FileWithSas } from '../types/file';
import { forPaginated } from './common/paginated.graphql';

const file = gql`
  fragment FileEntity on FileEntity {
    id
    version
    companyId
    createdAt
    createdBy
    updatedAt
    updatedBy
    entityId
    entityType
    name
    path
    size
    type
  }
`;

const get = gql`
  query Files($companyId: String!, $page: Float) {
    files(companyId: $companyId, page: $page) {
      page
      pageSize
      batchSize
      hasNext
      data {
        ...FileEntity
      }
    }
  }
  ${file}
`;

export interface GetFilesVariables {
  companyId: string;
  page?: number;
}

export interface GetFilesResponse {
  files: PaginatedFiles;
}

class PaginatedFiles extends forPaginated<FileEntity>() {}

const getData = gql`
  query FileSas($entityType: String!, $id: String!, $companyId: String!) {
    fileSas(entityType: $entityType, id: $id, companyId: $companyId) {
      id
      version
      companyId
      createdAt
      createdBy
      updatedAt
      updatedBy
      entityId
      entityType
      name
      path
      size
      type
      signature
      expirationDate
    }
  }
`;

export interface GetFileDataVariables {
  companyId: string;
  id: string;
  entityType: FileEntityType;
}

export interface GetFileDataResponse {
  fileSas: FileWithSas;
}

const create = gql`
  mutation CreateFiles($files: [CreateFileInput!]!) {
    createFiles(files: $files) {
      ...FileEntity
    }
  }
  ${file}
`;

export interface CreateFilesResponse {
  createFiles: FileEntity[];
}

export interface CreateFilesVariables {
  files: CreateFileInput[];
}

const update = gql`
  mutation UpdateFile($file: UpdateFileInput!) {
    updateFile(file: $file) {
      ...FileEntity
    }
  }
  ${file}
`;

const remove = gql`
  mutation DeleteFiles($entityType: String!, $files: [DeleteFileInput!]!) {
    deleteFiles(entityType: $entityType, files: $files) {
      ...FileEntity
    }
  }
  ${file}
`;

export interface DeleteFilesResponse {
  deleteFiles: FileEntity[];
}

export interface DeleteFilesVarables {
  files: DeleteFileInput[];
  entityType: FileEntityType;
}

export const FileQueries = {
  get,
  getData,
};

export const FileMutations = {
  create,
  update,
  remove,
};
