import { Grid } from '@mui/material';
import { ChartIcon } from '../../icons/Chart/ChartIcon';
import { VentoryColor } from '../../util/color.util';
import React from 'react';

export interface ChartData {
  label: string;
  value: number;
}

export interface ChartContainerProps {
  text: string;
  color?: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

export function ChartContainer({
  text,
  color = VentoryColor.primaryVentoryColor,
  children,
  className,
}: ChartContainerProps) {
  return (
    <Grid item xs={12} pb={1} className={`h-full`}>
      <Grid
        container
        flexDirection={'column'}
        className={`flex w-full mb-2 h-full bg-white ventory-border select-none`}
      >
        <Grid item>
          <Grid container flexDirection={'row'}>
            <Grid item xs={12} display='flex' className='border-ventory-grey-100 border-b'>
              <ChartIcon className='px-3' color={color} />
              <p className='font-[500] text-[16px] text-ventory-grey-500 py-3'>{text}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item flexGrow={1} alignContent={'center'}>
          <Grid container flexDirection={'row'} className='h-full'>
            <Grid item xs={12} display='flex' className='h-full'>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
