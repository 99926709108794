import { Divider, Grid } from '@mui/material';
import React from 'react';
import { FooterButton } from '../Button/Button';
import ButtonRow from '../Button/ButtonRow';

interface ModalFooterProps {
  children?: React.ReactNode;
  buttons?: FooterButton[];
}

export function ModalFooter({ children, buttons = [] }: ModalFooterProps) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} className={'px-6 py-3'}>
        {children || <ButtonRow buttons={buttons} />}
      </Grid>
    </Grid>
  );
}
