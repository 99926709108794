import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forDelete } from './common/entity';
import { CustomField, CustomFieldEntityType } from './customField';

export enum CsvUploadType {
  product = 'product',
  bin = 'bin',
  productMasterData = 'productMasterData',
  user = 'user',
  stockLocation = 'stockLocation',
  lot = 'lot',
  order = 'order',
}

enum CsvUploadConfigurationValidationError {
  name = 'Please enter a valid name',
}

export interface CsvUploadConfigurationMapping {
  field: string;
  customField: string;
}

export class CsvUploadConfiguration extends CompanyEntity {
  type!: CsvUploadType;
  name!: string;
  description?: string = undefined;
  headerMapping: CsvUploadConfigurationMapping[] = [];
  headerNames: string[] = [];

  mapping: Map<string, string> = new Map();

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
    this.mapping = new Map(this.headerMapping.map(value => [String(value['field']), value['customField']]));
  }

  forCreate(): CreateCsvUploadConfigurationInput {
    this.headerMapping = [...this.mapping.entries()].map(([key, value]) => {
      return { field: key, customField: value } as CsvUploadConfigurationMapping;
    });

    return {
      companyId: this.companyId,
      type: this.type,
      name: this.name,
      description: this.description,
      headerMapping: this.headerMapping,
      headerNames: this.headerNames,
    };
  }

  forUpdate(): UpdateCsvUploadConfigurationInput {
    this.headerMapping = [...this.mapping.entries()].map(([key, value]) => {
      return { field: key, customField: value } as CsvUploadConfigurationMapping;
    });

    return {
      id: this.id,
      companyId: this.companyId,
      version: this.version,
      name: this.name,
      type: this.type,
      description: this.description,
      headerNames: this.headerNames,
      headerMapping: this.headerMapping,
    };
  }

  forDelete(): DeleteCsvUploadConfigurationInput {
    return DeleteCsvUploadConfigurationInput.from(this, DeleteCsvUploadConfigurationInput);
  }

  validate(fields: (keyof CsvUploadConfiguration)[]) {
    return this.validateEntity(fields, (field: keyof CsvUploadConfiguration) => {
      if (field === 'name' && !this.name) return CsvUploadConfigurationValidationError.name;
      return null;
    });
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withType(type: CsvUploadType) {
    this.type = type;
    return cloneDeep(this);
  }

  withHeaderNames(headerNames: string[]) {
    this.headerNames = headerNames;
    return cloneDeep(this);
  }

  withMapping(field: string, customField: string) {
    this.mapping.set(field, customField);
    return cloneDeep(this);
  }

  removeMapping(field: string) {
    this.mapping.delete(field);
    return cloneDeep(this);
  }
}

export class CreateCsvUploadConfigurationInput {
  companyId!: string;
  type!: CsvUploadType;
  name!: string;
  description?: string = undefined;
  headerMapping: CsvUploadConfigurationMapping[] = [];
  headerNames: string[] = [];
}

export class UpdateCsvUploadConfigurationInput {
  id!: string;
  companyId!: string;
  version!: number;
  name!: string;
  type!: CsvUploadType;
  headerNames: string[] = [];
  description?: string = undefined;
  headerMapping: CsvUploadConfigurationMapping[] = [];
}

export class DeleteCsvUploadConfigurationInput extends forDelete(CsvUploadConfiguration) {}

export enum CsvProductMasterDataUploadHeaders {
  productName = 'Product Name',
  productNumber = 'Product Number',
  lotManaged = 'Lot Managed',
  serialManaged = 'Serial Managed',
  lpnManaged = 'LPN Managed',
  reorderPoint = 'Reorder Point',
  countryOfOrigin = 'Product Master Data Country Of Origin',
  dimensionsHeight = 'Height',
  dimensionsWidth = 'Width',
  dimensionsDepth = 'Depth',
  dimensionsUnit = 'Dimensions Unit',
  netWeight = 'Net Weight',
  grossWeight = 'Gross Weight',
  weightUnit = 'Weight Unit',
  purchasePrice = 'Purchase Price',
  purchasePriceCurrency = 'Purchase Price Currency',
  sellingPrice = 'Selling Price',
  sellingPriceCurrency = 'Selling Price Currency',
  binConstraint = 'Bin Constraint',
  originalEquipmentManufacturer = 'OEM Number',
  manufacturer = 'Manufacturer Number',
}

export enum CsvBinUploadHeaders {
  binName = 'Bin Name',
  binStatus = 'Bin Status',
}

export enum CsvUserUploadHeaders {
  email = 'Email',
  companyRole = 'Company Role',
  firstName = 'First Name',
  lastName = 'Last Name',
}

export enum CsvLotUploadHeaders {
  lotNumber = 'Lot Number',
  lotExpiry = 'Lot Expiry',
}

export enum CsvProductUploadHeaders {
  quantity = 'Quantity',
  serialNumber = 'Serial Number',
  lpn = 'LPN',
  countryOfOrigin = 'Product Country of Origin',
  stockLocationName = 'Stock Location Name',
  stockLocationIdentifier = 'Stock Location Identifier',
}

export enum CsvStockLocationUploadHeaders {
  name = 'Stock Location Name',
  identifier = 'Stock Location Identifier',
  mobile = 'Mobile Stock Location',
  addressLineOne = 'Address Line 1',
  addressLineTwo = 'Address Line 2',
  postalCode = 'ZIP/Postal Code',
  city = 'City',
  state = 'State/Province/Region',
  country = 'Country Code',
}

export enum CsvOrderUploadHeaders {
  number = 'Number',
  type = 'Type',
  stockLocationName = 'Stock Location Name',
  stockLocationIdentifier = 'Stock Location Identifier',
  status = 'Status',
  productNumber = 'Product Number',
  quantity = 'Quantity',
  contact = 'Contact',
  contactLocation = 'Contact Location',
}

export const RequiredCsvHeaders = {
  productMasterData: [CsvProductMasterDataUploadHeaders.productNumber, CsvProductMasterDataUploadHeaders.productName],
  product: [CsvProductUploadHeaders.quantity],
  bin: [CsvBinUploadHeaders.binName],
  lot: [CsvLotUploadHeaders.lotNumber],
  user: [CsvUserUploadHeaders.email],
  stockLocation: [CsvStockLocationUploadHeaders.name],
  order: [
    CsvOrderUploadHeaders.number,
    CsvOrderUploadHeaders.type,
    CsvOrderUploadHeaders.productNumber,
    CsvOrderUploadHeaders.quantity,
  ],
};

export const OptionalCsvHeaders = {
  productMasterData: [
    CsvProductMasterDataUploadHeaders.lotManaged,
    CsvProductMasterDataUploadHeaders.serialManaged,
    CsvProductMasterDataUploadHeaders.lpnManaged,
    CsvProductMasterDataUploadHeaders.reorderPoint,
    CsvProductMasterDataUploadHeaders.dimensionsHeight,
    CsvProductMasterDataUploadHeaders.dimensionsWidth,
    CsvProductMasterDataUploadHeaders.dimensionsDepth,
    CsvProductMasterDataUploadHeaders.dimensionsUnit,
    CsvProductMasterDataUploadHeaders.netWeight,
    CsvProductMasterDataUploadHeaders.grossWeight,
    CsvProductMasterDataUploadHeaders.weightUnit,
    CsvProductMasterDataUploadHeaders.purchasePrice,
    CsvProductMasterDataUploadHeaders.purchasePriceCurrency,
    CsvProductMasterDataUploadHeaders.sellingPrice,
    CsvProductMasterDataUploadHeaders.sellingPriceCurrency,
    CsvProductMasterDataUploadHeaders.countryOfOrigin,
    CsvProductMasterDataUploadHeaders.binConstraint,
    CsvProductMasterDataUploadHeaders.manufacturer,
    CsvProductMasterDataUploadHeaders.originalEquipmentManufacturer,
  ],
  product: [
    CsvProductUploadHeaders.serialNumber,
    CsvProductUploadHeaders.lpn,
    CsvProductUploadHeaders.countryOfOrigin,
    CsvProductUploadHeaders.stockLocationName,
    CsvProductUploadHeaders.stockLocationIdentifier,
  ],
  bin: [CsvBinUploadHeaders.binStatus],
  lot: [CsvLotUploadHeaders.lotExpiry],
  user: [CsvUserUploadHeaders.companyRole, CsvUserUploadHeaders.firstName, CsvUserUploadHeaders.lastName],
  stockLocation: [
    CsvStockLocationUploadHeaders.identifier,
    CsvStockLocationUploadHeaders.mobile,
    CsvStockLocationUploadHeaders.addressLineOne,
    CsvStockLocationUploadHeaders.addressLineTwo,
    CsvStockLocationUploadHeaders.postalCode,
    CsvStockLocationUploadHeaders.city,
    CsvStockLocationUploadHeaders.state,
    CsvStockLocationUploadHeaders.country,
  ],
  order: [
    CsvOrderUploadHeaders.stockLocationName,
    CsvOrderUploadHeaders.stockLocationIdentifier,
    CsvOrderUploadHeaders.status,
    CsvOrderUploadHeaders.contact,
    CsvOrderUploadHeaders.contactLocation,
  ],
};

export function getRequiredHeadersForType(
  type: CsvUploadType,
  customFields: Map<CustomFieldEntityType, CustomField[]>,
) {
  switch (type) {
    case CsvUploadType.productMasterData:
      return RequiredCsvHeaders.productMasterData;
    case CsvUploadType.product:
      return [...RequiredCsvHeaders.productMasterData, ...RequiredCsvHeaders.product, ...RequiredCsvHeaders.bin];
    case CsvUploadType.bin:
      return RequiredCsvHeaders.bin;
    case CsvUploadType.user:
      return RequiredCsvHeaders.user;
    case CsvUploadType.stockLocation:
      return RequiredCsvHeaders.stockLocation;
    case CsvUploadType.order:
      const orderCustomFields = customFields.get(CustomFieldEntityType.order);
      const requiredOrderCustomFields = orderCustomFields?.filter(cf => cf.mandatory).map(cf => 'order-' + cf.name);
      const ptCustomFields = customFields.get(CustomFieldEntityType.productTransaction);
      const requiredPtCustomFields = ptCustomFields
        ?.filter(cf => cf.mandatory)
        .map(cf => 'productTransaction-' + cf.name);

      return [...RequiredCsvHeaders.order, ...(requiredOrderCustomFields || []), ...(requiredPtCustomFields || [])];
    default:
      return [];
  }
}

export function getOptionalHeadersForType(
  type: CsvUploadType,
  customFields: Map<CustomFieldEntityType, CustomField[]>,
) {
  switch (type) {
    case CsvUploadType.productMasterData:
      return OptionalCsvHeaders.productMasterData;
    case CsvUploadType.product:
      return [
        ...OptionalCsvHeaders.productMasterData,
        ...OptionalCsvHeaders.product,
        ...OptionalCsvHeaders.bin,
        ...OptionalCsvHeaders.lot,
        ...RequiredCsvHeaders.lot,
      ];
    case CsvUploadType.bin:
      return OptionalCsvHeaders.bin;
    case CsvUploadType.user:
      return OptionalCsvHeaders.user;
    case CsvUploadType.stockLocation:
      return OptionalCsvHeaders.stockLocation;
    case CsvUploadType.order:
      const orderCustomFields = customFields.get(CustomFieldEntityType.order);
      const optionalOrderCustomFields = orderCustomFields?.filter(cf => !cf.mandatory).map(cf => 'order-' + cf.name);
      const ptCustomFields = customFields.get(CustomFieldEntityType.productTransaction);
      const optionalPtCustomFields = ptCustomFields
        ?.filter(cf => !cf.mandatory)
        .map(cf => 'productTransaction-' + cf.name);

      return [...OptionalCsvHeaders.order, ...(optionalOrderCustomFields || []), ...(optionalPtCustomFields || [])];
    default:
      return [];
  }
}

function getCustomFieldEntityTypeByCsvHeaderPrefix(type: string) {
  if (type === 'productTransaction') {
    return 'Product Transaction';
  } else if (type === 'order') {
    return 'Order';
  }
}

export function ventoryFieldToText(field: string) {
  const split = field.split('-');
  if (split.length === 2) {
    return split[1] + ` (${getCustomFieldEntityTypeByCsvHeaderPrefix(split[0])})`;
  }

  return field;
}
