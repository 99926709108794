import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { Lot } from '../types/lot';

const lot = gql`
  fragment Lot on Lot {
    id
    version
    companyId
    number
    productMasterDataId
    expirationDate
  }
`;

const get = gql`
  query Lots($companyId: String!, $page: Float) {
    lots(companyId: $companyId, page: $page) {
      data {
        ...Lot
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${lot}
`;

export interface GetLotsVariables {
  companyId: string;
  page?: number;
}

export interface GetLotsResponse {
  lots: PaginatedLots;
}

class PaginatedLots extends forPaginated<Lot>() {}

export const LotQueries = {
  get,
};
