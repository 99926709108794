import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { LotContext } from '../../../../../context/LotContext';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function LotFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { lots } = useContext(LotContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.lot || [])]}
      text={item => lots.get(item)?.number || t().unknownLot.singular.label}
      onRemove={i => {
        filter.lot?.delete(i);
        if (!filter.lot?.size) filter.lot = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
