import { Translations, translationsFromJson, undefinedTranslationRecord } from './common';
import { TranslationKey } from './TranslationKey';

export enum TranslationLanguage {
  en = 'en',
  lt = 'lt',
}

export class Translation {
  language: TranslationLanguage;
  translations: Translations;

  constructor(language: TranslationLanguage, jsonString: any) {
    this.language = language;
    this.translations = translationsFromJson(JSON.parse(jsonString));
    const keySet = new Set(Object.keys(this.translations));
    for (const key of Object.keys(TranslationKey)) {
      if (!keySet.has(key as TranslationKey)) {
        this.translations[key as TranslationKey] = undefinedTranslationRecord;
      }
    }
  }
}

export const ENGLISH_TRANSLATION_URL = 'https://ventorystorageshared.blob.core.windows.net/translations/en.json';

export function getTranslationUrl(language: TranslationLanguage) {
  return `https://ventorystorageshared.blob.core.windows.net/translations/${language}.json`;
}
