import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function UserAddIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.3337 17.5V12.5M13.8337 15H18.8337M10.5003 12.5H7.16699C5.61385 12.5 4.83728 12.5 4.22471 12.7537C3.40795 13.092 2.75904 13.741 2.42073 14.5577C2.16699 15.1703 2.16699 15.9469 2.16699 17.5M13.417 2.7423C14.6386 3.23679 15.5003 4.43443 15.5003 5.83333C15.5003 7.23224 14.6386 8.42988 13.417 8.92437M11.7503 5.83333C11.7503 7.67428 10.2579 9.16667 8.41699 9.16667C6.57604 9.16667 5.08366 7.67428 5.08366 5.83333C5.08366 3.99238 6.57604 2.5 8.41699 2.5C10.2579 2.5 11.7503 3.99238 11.7503 5.83333Z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
