import { EntityFilter, FilterEntity } from '../filter.util';
import { CalendarIcon } from '../../../icons/Calendar/CalendarIcon';
import React from 'react';
import { LocationMarkerIcon } from '../../../icons/LocationMarker/LocationMarkerIcon';
import { GlobeIcon } from '../../../icons/Globe/GlobeIcon';
import { ListIcon } from '../../../icons/List/ListIcon';
import DateTimeFilterContent from './Content/DateTimeFilterContent';
import StockLocationFilterContent from './Content/StockLocationFilterContent';
import CountryFilterContent from './Content/CountryFilterContent';
import UserFilterContent from './Content/UserFilterContent';
import BinFilterContent from './Content/BinFilterContent';
import ProductFilterContent from './Content/ProductFilterContent';
import TaskStatusFilterContent from './Content/TaskStatusFilterContent';
import OrderStatusFilterContent from './Content/OrderStatusFilterContent';
import { ProductIcon } from '../../../icons/Product/ProductIcon';
import { BinIcon } from '../../../icons/Bin/BinIcon';
import { UserAddIcon } from '../../../icons/User/UserAddIcon';
import { MessageQuestionCircleIcon } from '../../../icons/MessageQuestionCircle/MessageQuestionCircleIcon';
import OrderTypeFilterContent from './Content/OrderTypeFilterContent';
import StockLocationFilterInnerContent from './InnerContent/StockLocationFilterInnerContent';
import CountryFilterInnerContent from './InnerContent/CountryFilterInnerContent';
import BinFilterInnerContent from './InnerContent/BinFilterInnerContent';
import UserFilterInnerContent from './InnerContent/UserFilterInnerContent';
import TaskStatusFilterInnerContent from './InnerContent/TaskStatusFilterInnerContent';
import OrderStatusFilterInnerContent from './InnerContent/OrderStatusFilterInnerContent';
import OrderTypeFilterInnerContent from './InnerContent/OrderTypeFilterInnerContent';
import ProductFilterInnerContent from './InnerContent/ProductFilterInnerContent';
import { DropdownButton } from '../../common/DropdownButton/DropdownButton';
import LotFilterContent from './Content/LotFilterContent';
import BoxIcon from '../../../icons/Box/BoxIcon';
import LotFilterInnerContent from './InnerContent/LotFilterInnerContext';
import ManageTypeFilterContent from './Content/ManageTypeFilterContent';
import ManageTypeFilterInnerContent from './InnerContent/ManageTypeFilterInnerContent';
import FileTypeFilterContent from './Content/FileTypeFilterContent';
import FileTypeFilterInnerContent from './InnerContent/FileTypeFilterInnerContent';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import FileTypeCategoryFilterContent from './Content/FileTypeCategoryFilterContent';
import FileTypeCategoryFilterInnerContent from './InnerContent/FileTypeCategoryFilterInnerContent';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { t } from '../../../../types/translation/Translator';

export namespace DynamicEntityFilterType {
  export const DATE_TIME = 'DATE_TIME';
  export const STOCK_LOCATION = 'STOCK_LOCATION';
  export const COUNTRY = 'COUNTRY';
  export const BIN = 'BIN';
  export const PRODUCT = 'PRODUCT';
  export const USER = 'USER';
  export const TASK_STATUS = 'TASK_STATUS';
  export const ORDER_STATUS = 'ORDER_STATUS';
  export const ORDER_TYPE = 'ORDER_TYPE';
  export const LOT = 'LOT';
  export const FILE_TYPE_CATEGORY = 'FILE_TYPE_CATEGORY';
  export const FILE_TYPE = 'FILE_TYPE';

  export const MANAGE_TYPE = 'MANAGE_TYPE';
  // Checkboxes
  export const SERIAL_MANAGED = 'SERIAL_MANAGED';
  export const LPN_MANAGED = 'LPN_MANAGED';
  export const LOT_MANAGED = 'LOT_MANAGED';

  export function forEntity(entity: FilterEntity): Set<string> {
    switch (entity) {
      case FilterEntity.TASK:
        return forTask();
      case FilterEntity.ORDER:
        return forOrder();
      case FilterEntity.PRODUCT:
        return forProduct();
      case FilterEntity.BIN:
        return forBin();
      case FilterEntity.PRODUCT_TRANSACTION:
        return forProductTransaction();
      case FilterEntity.ALERT:
        return forAlert();
      case FilterEntity.PRODUCT_MASTER_DATA:
        return forProductMasterData();
      case FilterEntity.FILE:
        return forFile();
    }

    return new Set();
  }

  function forProductTransaction() {
    return new Set([DATE_TIME, STOCK_LOCATION, PRODUCT, USER]);
  }

  function forBin() {
    return new Set([STOCK_LOCATION]);
  }

  function forProduct() {
    return new Set([STOCK_LOCATION]);
  }

  function forOrder() {
    return new Set([DATE_TIME, STOCK_LOCATION, ORDER_STATUS]);
  }

  function forTask() {
    return new Set([DATE_TIME, COUNTRY, STOCK_LOCATION, USER, BIN, PRODUCT, TASK_STATUS]);
  }

  function forAlert() {
    return new Set([DATE_TIME, STOCK_LOCATION, PRODUCT, LOT]);
  }

  function forProductMasterData() {
    return new Set([MANAGE_TYPE]);
  }

  function forFile() {
    return new Set([DATE_TIME, USER, FILE_TYPE_CATEGORY, FILE_TYPE]);
  }

  export function toLabel(item: string): string {
    switch (item) {
      case DATE_TIME:
        return t().dateAndTime.singular.label;
      case STOCK_LOCATION:
        return t().stockLocation.singular.label;
      case COUNTRY:
        return t().country.singular.label;
      case BIN:
        return t().bin.singular.label;
      case PRODUCT:
        return t().product.singular.label;
      case USER:
        return t().user.singular.label;
      case TASK_STATUS:
        return t().taskStatus.singular.label;
      case ORDER_STATUS:
        return t().orderStatus.singular.label;
      case ORDER_TYPE:
        return t().orderType.singular.label;
      case LOT:
        return t().lot.singular.label;
      case MANAGE_TYPE:
        return t().manageType.singular.label;
      case FILE_TYPE_CATEGORY:
        return t().fileTypeCategory.singular.label;
      case FILE_TYPE:
        return t().fileType.singular.label;
    }

    return '';
  }

  export function toString(item: string, filter: EntityFilter): string {
    switch (item) {
      case DATE_TIME:
        return filter.timeFrame
          ? `${new Date(filter.timeFrame.start).toLocaleDateString()} - ${new Date(
              filter.timeFrame.end,
            ).toLocaleDateString()}`
          : t().dateAndTime.singular.label;
      case STOCK_LOCATION:
        return t().stockLocation.singular.label;
      case COUNTRY:
        return t().country.singular.label;
      case BIN:
        return t().bin.singular.label;
      case PRODUCT:
        return t().product.singular.label;
      case USER:
        return t().user.singular.label;
      case TASK_STATUS:
        return t().taskStatus.singular.label;
      case ORDER_STATUS:
        return t().orderStatus.singular.label;
      case ORDER_TYPE:
        return t().orderType.singular.label;
      case LOT:
        return t().lot.singular.label;
      case MANAGE_TYPE:
        return t().manageType.singular.label;
      case FILE_TYPE_CATEGORY:
        return t().fileTypeCategory.singular.label;
      case FILE_TYPE:
        return t().fileType.singular.label;
    }

    return '';
  }

  export function toInnerContent(
    item: string,
    filter: EntityFilter,
    setFilter: (filter: EntityFilter) => void,
  ): string | JSX.Element {
    switch (item) {
      case DATE_TIME:
        return toString(item, filter);
      case STOCK_LOCATION:
        return <StockLocationFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case COUNTRY:
        return <CountryFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case BIN:
        return <BinFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case PRODUCT:
        return <ProductFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case USER:
        return <UserFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case TASK_STATUS:
        return <TaskStatusFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case ORDER_STATUS:
        return <OrderStatusFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case ORDER_TYPE:
        return <OrderTypeFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case LOT:
        return <LotFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case MANAGE_TYPE:
        return <ManageTypeFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case FILE_TYPE:
        return <FileTypeFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
      case FILE_TYPE_CATEGORY:
        return <FileTypeCategoryFilterInnerContent item={item} filter={filter} setFilter={setFilter} />;
    }

    return toString(item, filter);
  }

  export function toIcon(item: string, className?: string) {
    switch (item) {
      case DATE_TIME:
        return <CalendarIcon className={className} />;
      case STOCK_LOCATION:
        return <LocationMarkerIcon className={className} />;
      case COUNTRY:
        return <GlobeIcon className={className} />;
      case BIN:
        return <BinIcon className={className} />;
      case PRODUCT:
        return <ProductIcon className={className} />;
      case USER:
        return <UserAddIcon className={className} />;
      case TASK_STATUS:
        return <ListIcon className={className} />;
      case ORDER_STATUS:
        return <ListIcon className={className} />;
      case ORDER_TYPE:
        return <MessageQuestionCircleIcon className={className} />;
      case LOT:
        return <BoxIcon className={className} />;
      case MANAGE_TYPE:
        return <BoxIcon className={className} />;
      case FILE_TYPE_CATEGORY:
        return <FilePresentOutlinedIcon className={className + ' stroke-none'} />;
      case FILE_TYPE:
        return <InsertDriveFileOutlinedIcon className={className + ' stroke-none'} />;
    }

    return <></>;
  }

  export function toContent(
    item: string,
    filter: EntityFilter,
    setFilter: (filter: EntityFilter) => void,
    color: string,
    className?: string,
  ) {
    switch (item) {
      case DATE_TIME:
        return <DateTimeFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case STOCK_LOCATION:
        return <StockLocationFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case COUNTRY:
        return <CountryFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case BIN:
        return <BinFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case PRODUCT:
        return <ProductFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case USER:
        return <UserFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case TASK_STATUS:
        return <TaskStatusFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case ORDER_STATUS:
        return <OrderStatusFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case ORDER_TYPE:
        return <OrderTypeFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case LOT:
        return <LotFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case MANAGE_TYPE:
        return <ManageTypeFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case FILE_TYPE:
        return <FileTypeFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />;
      case FILE_TYPE_CATEGORY:
        return (
          <FileTypeCategoryFilterContent filter={filter} setFilter={setFilter} className={className} color={color} />
        );
    }

    return <></>;
  }

  export function toCount(item: string, filter: EntityFilter): number {
    switch (item) {
      case DATE_TIME:
        return 0;
      case STOCK_LOCATION:
        return filter.stockLocation?.size || 0;
      case COUNTRY:
        return filter.country?.size || 0;
      case BIN:
        return filter.bin?.size || 0;
      case PRODUCT:
        return filter.product?.size || 0;
      case USER:
        return filter.user?.size || 0;
      case TASK_STATUS:
        return filter.taskStatus?.size || 0;
      case ORDER_STATUS:
        return filter.orderStatus?.size || 0;
      case ORDER_TYPE:
        return filter.orderType?.size || 0;
      case MANAGE_TYPE:
        return (filter.serialManaged ? 1 : 0) + (filter.lpnManaged ? 1 : 0) + (filter.lotManaged ? 1 : 0);
      case FILE_TYPE:
        return filter.fileType?.size || 0;
      case FILE_TYPE_CATEGORY:
        return filter.fileTypeCategory?.size || 0;
      default:
        return 0;
    }
  }

  export function toComponent(
    item: string,
    filter: EntityFilter,
    setFilter: (filter: EntityFilter) => void,
    color: string,
    className?: string,
  ) {
    return (
      <>
        <p className='mb-1'>{DynamicEntityFilterType.toLabel(item)}</p>
        <DropdownButton
          width='100%'
          content={DynamicEntityFilterType.toInnerContent(item, filter, setFilter)}
          badge={DynamicEntityFilterType.toCount(item, filter)}
          startIcon={DynamicEntityFilterType.toIcon(item, className)}
          dropdownContent={DynamicEntityFilterType.toContent(item, filter, setFilter, color)}
        />
      </>
    );
  }
}
