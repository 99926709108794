import { useCallback } from 'react';
import update from 'immutability-helper';
import React from 'react';
import AppDraggableItem, { DraggableItemData } from './DraggableItem';

export interface DraggableListProps<T extends DraggableItemData> {
  items: T[];
  displayFn: (item: T) => JSX.Element;
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
}

export function DraggableList<T extends DraggableItemData>({ items, displayFn, setItems }: DraggableListProps<T>) {
  const move = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems((oldItems: T[]) =>
      update(oldItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, oldItems[dragIndex] as T],
        ],
      }),
    );
  }, []);

  return (
    <div>
      {items.map((item, i) => (
        <AppDraggableItem key={item.key} content={displayFn(item)} index={i} id={item.id} moveItem={move} />
      ))}
    </div>
  );
}
