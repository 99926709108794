import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function SettingsIcon({ className, width = '14', height = '16' }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 14 16`}
        fill='none'
        stroke='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.09621 12.914L5.48584 13.7903C5.60166 14.0511 5.79069 14.2728 6.02999 14.4283C6.26929 14.5839 6.54858 14.6666 6.83398 14.6666C7.11939 14.6666 7.39868 14.5839 7.63798 14.4283C7.87728 14.2728 8.06631 14.0511 8.18213 13.7903L8.57176 12.914C8.71046 12.6031 8.94377 12.3438 9.23843 12.1733C9.53495 12.0022 9.87798 11.9294 10.2184 11.9651L11.1718 12.0666C11.4555 12.0966 11.7419 12.0436 11.9962 11.9141C12.2505 11.7846 12.4618 11.5841 12.6044 11.337C12.7471 11.0899 12.8152 10.8068 12.8002 10.5218C12.7852 10.2369 12.6878 9.96245 12.5199 9.73177L11.9555 8.95622C11.7545 8.67801 11.6471 8.34313 11.6488 7.99992C11.6487 7.65764 11.7571 7.32415 11.9584 7.04733L12.5229 6.27177C12.6908 6.04109 12.7881 5.76663 12.8031 5.4817C12.8181 5.19677 12.7501 4.91361 12.6073 4.66659C12.4647 4.41941 12.2535 4.21891 11.9992 4.0894C11.7449 3.9599 11.4585 3.90694 11.1747 3.93696L10.2214 4.03844C9.88095 4.07419 9.53791 4.00133 9.24139 3.83029C8.94614 3.65875 8.71277 3.39816 8.57473 3.08584L8.18213 2.20955C8.06631 1.9487 7.87728 1.72706 7.63798 1.57152C7.39868 1.41597 7.11939 1.33321 6.83398 1.33325C6.54858 1.33321 6.26929 1.41597 6.02999 1.57152C5.79069 1.72706 5.60166 1.9487 5.48584 2.20955L5.09621 3.08584C4.95816 3.39816 4.72479 3.65875 4.42954 3.83029C4.13302 4.00133 3.78999 4.07419 3.44954 4.03844L2.49324 3.93696C2.20946 3.90694 1.92307 3.9599 1.66879 4.0894C1.4145 4.21891 1.20325 4.41941 1.06065 4.66659C0.917865 4.91361 0.84984 5.19677 0.864829 5.4817C0.879818 5.76663 0.977178 6.04109 1.1451 6.27177L1.70954 7.04733C1.91083 7.32415 2.01923 7.65764 2.01917 7.99992C2.01923 8.34219 1.91083 8.67569 1.70954 8.95251L1.1451 9.72807C0.977178 9.95875 0.879818 10.2332 0.864829 10.5181C0.84984 10.8031 0.917865 11.0862 1.06065 11.3333C1.20339 11.5803 1.41467 11.7807 1.66892 11.9102C1.92316 12.0397 2.20948 12.0927 2.49324 12.0629L3.44658 11.9614C3.78702 11.9256 4.13005 11.9985 4.42658 12.1695C4.72293 12.3406 4.95738 12.6012 5.09621 12.914Z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.83279 9.99992C7.93736 9.99992 8.83279 9.10449 8.83279 7.99992C8.83279 6.89535 7.93736 5.99992 6.83279 5.99992C5.72822 5.99992 4.83279 6.89535 4.83279 7.99992C4.83279 9.10449 5.72822 9.99992 6.83279 9.99992Z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
