import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { DashboardContext, getEntityDataCacheKey } from '../../../../context/DashboardContext';
import {
  DashboardQueries,
  GetDashboardFilterResponse,
  GetDashboardFilterVariables,
  toFilterParameters,
} from '../../../../graphql/dashboard.graphql';
import { useQuery } from '@apollo/client';
import { EntityFilter, FilterEntity } from '../../../../VentoryUI/components/filters/filter.util';
import { RadialChart } from '../../../../VentoryUI/components/charts/RadialChart/RadialChart';

interface RadialChartContainerProps {
  text: string;
  entity: FilterEntity;
  color: string;
  filter: EntityFilter;
  className?: string;
}

export function RadialChartContainer({ text, entity, color, filter, className }: RadialChartContainerProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { cache, setCache } = useContext(DashboardContext);

  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);

  const cacheKey = getEntityDataCacheKey(entity, filter);
  const hasCachedData = cache.has(cacheKey);

  const { loading } = useQuery<GetDashboardFilterResponse, GetDashboardFilterVariables>(DashboardQueries.get, {
    skip: hasCachedData,
    variables: {
      companyId: currentCompany.id,
      locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
      parameters: toFilterParameters(entity, filter),
    },
    onCompleted: res => {
      cache.set(cacheKey, res.dashboardQuery);
      setCache(new Map(cache));

      setCount(res.dashboardQuery.filteredChartData.find(v => v.label === 'count')?.value || 0);
      setTotal(res.dashboardQuery.filteredChartData.find(v => v.label === 'total')?.value || 0);
    },
    onError: err => console.log(err),
  });

  useEffect(() => {
    if (hasCachedData) {
      const data = cache.get(cacheKey)!;
      setCount(data.filteredChartData.find(v => v.label === 'count')?.value || 0);
      setTotal(data.filteredChartData.find(v => v.label === 'total')?.value || 0);
    }
  }, [cache, filter]);

  return <RadialChart text={text} value={count} max={total} className={className} color={color} loading={loading} />;
}
