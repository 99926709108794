import React from 'react';
import { Lot } from '../types/lot';

export interface LotContextProps {
  lots: Map<string, Lot>;
  setLots: (lots: Map<string, Lot>) => void;
}

const defaultContext: LotContextProps = {
  lots: new Map(),
  setLots: () => {},
};

export const LotContext = React.createContext<LotContextProps>(defaultContext);
