import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { GetSuperUsersResponse, GetSuperUsersVariables, SuperUserQueries } from '../../../graphql/superUser.graphql';
import { SuperUser, superUserRoleToString } from '../../../types/superUser';
import { toMap } from '../../../util/map.util';
import { toFilterString } from '../../../util/string.util';
import AddButton from '../../../VentoryUI/components/common/Button/Templates/AddButton';
import { FlexPane } from '../../../VentoryUI/components/common/FlexPane/FlexPane';
import SearchBar from '../../../VentoryUI/components/common/SearchBar/SearchBar';
import Table from '../../../VentoryUI/components/common/Table/Table';
import LoadingPackage from '../../Common/LoadingPackage';
import CreateSuperUserModal from './Modals/CreateSuperUserModal';
import UpdateSuperUserModal from './Modals/UpdateSuperUserModal';

export default function AdministratorSuperUserPane() {
  const [superUsers, setSuperUsers] = useState<Map<string, SuperUser>>(new Map());
  const [filter, setFilter] = useState<string>('');
  const [selected, setSelected] = useState<string>('');

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const { loading } = useQuery<GetSuperUsersResponse, GetSuperUsersVariables>(SuperUserQueries.getAll, {
    onCompleted: res => {
      setSuperUsers(new Map(toMap(res.superUsers.data, 'userId')));
    },
  });

  const handleFilter = (item: SuperUser) => {
    if (toFilterString(item.email).includes(filter) || toFilterString(item.role).includes(filter)) {
      return true;
    }
    return false;
  };

  const filteredSuperUsers = useMemo(() => {
    return [...superUsers.values()].filter(handleFilter);
  }, [filter, superUsers]);

  const headers = [
    {
      key: 'email',
      name: 'Email',
      text: (item: SuperUser) => {
        return item.email;
      },
    },
    {
      key: 'role',
      name: 'Role',
      text: (item: SuperUser) => {
        return superUserRoleToString(item.role);
      },
    },
  ];

  if (loading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <CreateSuperUserModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        superUsers={superUsers}
        setSuperUsers={setSuperUsers}
      />
      {selected && superUsers.get(selected) ? (
        <UpdateSuperUserModal
          open={openUpdateModal}
          setOpen={v => {
            setOpenUpdateModal(v);
            setSelected('');
          }}
          superUser={superUsers.get(selected)!}
          superUsers={superUsers}
          setSuperUsers={setSuperUsers}
        />
      ) : null}

      <FlexPane
        header={
          <Grid container alignContent={'start'} columnSpacing={1} rowSpacing={2}>
            <Grid item flexGrow={1} marginY={'auto'}>
              <SearchBar placeholder='Filter Super Users' onChange={v => setFilter(v)} />
            </Grid>
            <Grid item>
              <AddButton onClick={() => setOpenCreateModal(true)} />
            </Grid>
          </Grid>
        }
        content={
          <Table
            onClick={item => {
              setSelected(item.userId);
              setOpenUpdateModal(true);
            }}
            items={filteredSuperUsers}
            headers={headers}
          />
        }
      />
    </>
  );
}
