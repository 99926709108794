import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { TaskFragments } from '../../graphql/task.graphql';
import { Task } from '../../types/task';
import { TaskContext } from '../../context/TaskContext';

const taskSubscription = gql`
  subscription taskNotification($companyId: String!) {
    taskNotification(companyId: $companyId) {
      companyId
      entities {
        ...Task
      }
      topic
      date
    }
  }
  ${TaskFragments.task}
`;

export class TaskNotificationResponse extends forNotification<Task>('taskNotification') {}

export default function TaskNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { tasks, setTasks } = useContext(TaskContext);

  if (!currentCompany) return null;

  const { data: taskNotification } = useSubscription<TaskNotificationResponse>(taskSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleTaskNotification = (notification?: TaskNotificationResponse) => {
    if (!notification?.taskNotification) return;
    const taskNotification = notification.taskNotification;

    switch (taskNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        taskNotification.entities.forEach(task => tasks.set(task.id, new Task(task)));
        break;
      case 'DELETE':
        taskNotification.entities.forEach(task => (tasks.has(task.id) ? tasks.delete(task.id) : null));
    }
    setTasks(new Map(tasks));
  };

  useEffect(() => {
    handleTaskNotification(taskNotification);
  }, [taskNotification]);

  return <></>;
}
