import React, { useContext, useEffect, useState } from 'react';
import { EntityFilter } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { removeDiacritics } from '../../../../../util/string.util';
import { TaskStatus, taskStatusToString } from '../../../../../types/task';
import ScrollableFilterContent from './ScrollableFilterContent';

interface TaskStatusFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

const taskStatusFilter = (status: string, text: string, filter: EntityFilter) => {
  const filterText = removeDiacritics(text.toLowerCase());
  if (removeDiacritics(status.toLowerCase()).includes(filterText)) return true;
  if (removeDiacritics(status.toLowerCase()).includes(filterText)) return true;
  return false;
};

export default function TaskStatusFilterContent({ className, filter, color, setFilter }: TaskStatusFilterContentProps) {
  const { stockLocations } = useContext(StockLocationContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterTaskStatus = () => {
    return new Set(
      [...Object.keys(TaskStatus), 'Overdue']
        .filter(item => taskStatusFilter(item, textFilter, filter))
        .map(item => item),
    );
  };

  const [values, setValues] = useState<Set<string>>(filterTaskStatus());

  const handleChange = (selected: Set<string>) => {
    filter.taskStatus = selected;
    if (!filter.taskStatus?.size) filter.product = undefined;
    setFilter({ ...filter });
  };

  const handleRemove = () => {
    filter.taskStatus = undefined;
    setFilter({ ...filter });
  };

  useEffect(() => {
    setValues(filterTaskStatus());
  }, [textFilter]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().taskStatus.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filter.taskStatus}
            values={values}
            onChange={handleChange}
            toText={id => taskStatusToString(id, false)}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
