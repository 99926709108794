import { Observable } from '@apollo/client';

export const promiseToObservable = (promise: Promise<unknown>) =>
  new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err),
    );
  });
