import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { CreateScanRuleInput, DeleteScanRuleInput, ScanRule, UpdateScanRuleInput } from '../types/scanRule';

const scanRule = gql`
  fragment ScanRule on ScanRule {
    id
    version
    companyId
    type
    configurationValues {
      field
      key
      stringValue
      stringArrayValue
    }
  }
`;

const get = gql`
  query scanRules($companyId: String!, $page: Float) {
    scanRules(companyId: $companyId, page: $page) {
      ...ScanRule
    }
  }
  ${scanRule}
`;

export interface GetScanRuleVariables {
  companyId: string;
  page?: number;
}

export interface GetScanRuleResponse {
  scanRules: ScanRule[];
}

class PaginatedScanRule extends forPaginated<ScanRule>() {}

const create = gql`
  mutation CreateScanRule($scanRule: [CreateScanRuleInput!]!) {
    createScanRule(scanRule: $scanRule) {
      ...ScanRule
    }
  }
  ${scanRule}
`;

export interface CreateScanRuleVariables {
  scanRule: CreateScanRuleInput[];
}

export interface CreateScanRuleResponse {
  createScanRule: ScanRule[];
}

const update = gql`
  mutation UpdateScanRule($scanRule: [UpdateScanRuleInput!]!) {
    updateScanRule(scanRule: $scanRule) {
      ...ScanRule
    }
  }
  ${scanRule}
`;

export interface UpdateScanRuleVariables {
  scanRule: UpdateScanRuleInput[];
}

export interface UpdateScanRuleResponse {
  updateScanRule: ScanRule[];
}

const remove = gql`
  mutation DeleteScanRule($scanRule: [DeleteScanRuleInput!]!) {
    deleteScanRule(scanRule: $scanRule) {
      ...ScanRule
    }
  }
  ${scanRule}
`;

export interface DeleteScanRuleVariables {
  scanRule: DeleteScanRuleInput[];
}

export interface DeleteScanRuleResponse {
  deleteScanRule: ScanRule[];
}

export const ScanRuleQueries = {
  get,
};

export const ScanRuleMutations = {
  create,
  update,
  remove,
};
