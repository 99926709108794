import { gql } from '@apollo/client';
import {
  CreateStockLocationRoleAssignmentInput,
  DeleteStockLocationRoleAssignmentInput,
  StockLocationRoleAssignment,
  UpdateStockLocationRoleAssignmentInput,
} from '../types/stockLocationRoleAssignment';
import { forPaginated } from './common/paginated.graphql';

const stockLocationRoleAssignment = gql`
  fragment StockLocationRoleAssignment on StockLocationRoleAssignment {
    id
    version
    companyId
    email
    stockLocationId
    userId
    role
  }
`;

const get = gql`
  query StockLocationRolesForCompany($companyId: String!) {
    stockLocationRolesForCompany(companyId: $companyId) {
      data {
        ...StockLocationRoleAssignment
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${stockLocationRoleAssignment}
`;

export interface GetStockLocationRoleAssignmentsVariables {
  companyId: string;
}

export interface GetStockLocationRoleAssignmentsResponse {
  stockLocationRolesForCompany: PaginatedStockLocationRoleAssignments;
}

class PaginatedStockLocationRoleAssignments extends forPaginated<StockLocationRoleAssignment>() {}

const create = gql`
  mutation CreateStockLocationRoleAssignment(
    $stockLocationRoleAssignments: [CreateStockLocationRoleAssignmentInput!]!
  ) {
    createStockLocationRoleAssignment(stockLocationRoleAssignments: $stockLocationRoleAssignments) {
      ...StockLocationRoleAssignment
    }
  }
  ${stockLocationRoleAssignment}
`;

export interface CreateStockLocationRoleAssignmentVariables {
  stockLocationRoleAssignments: CreateStockLocationRoleAssignmentInput[];
}

export interface CreateStockLocationRoleAssignmentResponse {
  createStockLocationRoleAssignment: StockLocationRoleAssignment[];
}

const update = gql`
  mutation UpdateStockLocationRoleAssignment(
    $stockLocationRoleAssignments: [UpdateStockLocationRoleAssignmentInput!]!
  ) {
    updateStockLocationRoleAssignment(stockLocationRoleAssignments: $stockLocationRoleAssignments) {
      ...StockLocationRoleAssignment
    }
  }
  ${stockLocationRoleAssignment}
`;

export interface UpdateStockLocationRoleAssignmentVariables {
  stockLocationRoleAssignments: UpdateStockLocationRoleAssignmentInput[];
}

export interface UpdateStockLocationRoleAssignmentResponse {
  updateStockLocationRoleAssignment: StockLocationRoleAssignment[];
}

const remove = gql`
  mutation DeleteStockLocationRoleAssignment(
    $stockLocationRoleAssignments: [DeleteStockLocationRoleAssignmentInput!]!
  ) {
    deleteStockLocationRoleAssignment(stockLocationRoleAssignments: $stockLocationRoleAssignments) {
      ...StockLocationRoleAssignment
    }
  }
  ${stockLocationRoleAssignment}
`;

export interface DeleteStockLocationRoleAssignmentVariables {
  stockLocationRoleAssignments: DeleteStockLocationRoleAssignmentInput[];
}

export interface DeleteStockLocationRoleAssignmentResponse {
  deleteStockLocationRoleAssignment: StockLocationRoleAssignment[];
}

export const StockLocationRoleAssignmentQueries = {
  get,
};

export const StockLocationRoleAssignmentMutation = {
  create,
  update,
  remove,
};
