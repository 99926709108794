import React from 'react';
import { OrderType, orderTypeToLocalizedString } from '../../../../../types/order';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function OrderTypeFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.orderType || [])]}
      text={item => orderTypeToLocalizedString(item as OrderType)}
      onRemove={i => {
        filter.orderType?.delete(i);
        if (!filter.orderType?.size) filter.orderType = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
