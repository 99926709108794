import { useMutation } from '@apollo/client';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ForwardIcon from '@mui/icons-material/Forward';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Grid } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { t } from '../../../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import { CustomFieldContext } from '../../../../../../context/CustomFieldContext';
import { ProductTransactionTableSettingsContext } from '../../../../../../context/ProductTransactionTableSettingsContext';
import {
  CreateProductTransactionTableSettingsResponse,
  CreateProductTransactionTableSettingsVariables,
  ProductTransactionTableSettingsMutations,
  UpdateProductTransactionTableSettingsResponse,
  UpdateProductTransactionTableSettingsVariables,
} from '../../../../../../graphql/productTransactionTableSettings.graphql';

import { CustomFieldEntityType } from '../../../../../../types/customField';
import {
  ProductTransactionColumn,
  productTransactionColumnToString,
  ProductTransactionTableColumn,
  ProductTransactionTableSettings,
  ProductTransactionTableType,
  stringToProductTransactionColumn,
} from '../../../../../../types/productTransactionTableSettings';
import BackButton from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import { FlexPane } from '../../../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../../../VentoryUI/components/common/Paper/Paper';
import { DraggableItemData } from '../../../../../Common/DraggableItem';
import { DraggableList } from '../../../../../Common/DraggableList';
import DropdownSelect from '../../../../../Common/DropdownSelect';
import LoadingPackage from '../../../../../Common/LoadingPackage';
import TextInput from '../../../../../Common/TextInput';

interface DraggableProductTransactionTableField extends DraggableItemData, ProductTransactionTableColumn {}

interface ProductTransactionTableSettingsViewProps {
  setError: (error: string) => void;
  offset?: number;
}

export function ProductTransactionTableSettingsView({
  setError,
  offset = 64,
}: ProductTransactionTableSettingsViewProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { customFields } = useContext(CustomFieldContext);
  const {
    productTransactionTableSettings,
    setProductTransactionTableSettings,
    productTransactionTableSettingsLoading,
  } = useContext(ProductTransactionTableSettingsContext);

  const settings = useMemo(() => {
    const existingSettings = [...productTransactionTableSettings.values()].find(
      setting => setting.type === ProductTransactionTableType.orderTransaction,
    );
    return new ProductTransactionTableSettings(
      existingSettings || { companyId: currentCompany.id },
      existingSettings?.type || ProductTransactionTableType.orderTransaction,
    );
  }, [productTransactionTableSettings]);

  const [input, setInput] = useState<ProductTransactionTableSettings>(settings);
  const [items, setItems] = useState<DraggableProductTransactionTableField[]>([]);

  const dropdownItems = [
    ...Object.keys(ProductTransactionColumn)
      .filter(
        i =>
          items.findIndex(item => item.column === i) === -1 &&
          ProductTransactionTableSettings.possibleFieldsForType(ProductTransactionTableType.orderTransaction).has(i),
      )
      .map(k => productTransactionColumnToString(k as ProductTransactionColumn)),
    ...[...customFields.values()]
      .filter(
        i =>
          i.entityType === CustomFieldEntityType.productTransaction &&
          items.findIndex(item => item.column === i.name) === -1,
      )
      .map(i => i.name),
  ];

  const handleDelete = (id: number) => {
    const index = items.findIndex(item => item.id === id);
    if (index === -1) return;
    items.splice(index, 1);
    setItems([...items]);
  };

  const handleAdd = (item: DraggableProductTransactionTableField) => {
    setItems([...items, item]);
  };

  const [create, { loading: createLoading }] = useMutation<
    CreateProductTransactionTableSettingsResponse,
    CreateProductTransactionTableSettingsVariables
  >(ProductTransactionTableSettingsMutations.create, {
    onCompleted: res => {
      const setting = res.createProductTransactionTableSettings[0];
      productTransactionTableSettings.set(setting.id, new ProductTransactionTableSettings(setting));
      setProductTransactionTableSettings(new Map(productTransactionTableSettings));
      navigate('/operations/orders');
    },
    onError: err => setError(err.message),
  });

  const [update, { loading: updateLoading }] = useMutation<
    UpdateProductTransactionTableSettingsResponse,
    UpdateProductTransactionTableSettingsVariables
  >(ProductTransactionTableSettingsMutations.update, {
    onCompleted: res => {
      const setting = res.updateProductTransactionTableSettings[0];
      productTransactionTableSettings.set(setting.id, new ProductTransactionTableSettings(setting));
      setProductTransactionTableSettings(new Map(productTransactionTableSettings));
      navigate('/operations/orders');
    },
    onError: err => setError(err.message),
  });

  useEffect(() => {
    if (
      [...productTransactionTableSettings.values()].filter(
        setting => setting.type === ProductTransactionTableType.orderTransaction,
      ).length
    ) {
      const settings = [...productTransactionTableSettings.values()][0];
      setInput(new ProductTransactionTableSettings(settings));
      setItems(
        settings.columns.map((col, id) => {
          return { ...col, key: `${col.index}${col.label}`, id };
        }),
      );
    } else {
      setItems(
        input.columns.map((col, id) => {
          return { ...col, key: `${col.index}${col.label}`, id };
        }),
      );
    }
  }, [ProductTransactionTableSettings]);

  const handleSave = async () => {
    try {
      input.columns = items.map((i, idx) => new ProductTransactionTableColumn(idx, i.column, i.label));
      if (!input.id) {
        return await create({
          variables: {
            productTransactionTableSettings: [input],
          },
        });
      }
      return await update({
        variables: {
          productTransactionTableSettings: [input.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  if (productTransactionTableSettingsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'}>
        <Grid item className='fill-gray-300 ' marginX={'auto'}>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  const ProductTransactionReportFieldItem = (item: DraggableProductTransactionTableField) => {
    return (
      <Grid key={`parent-${item.id}`} container display='flex' marginY='auto' columnSpacing={2} alignItems={'center'}>
        <Grid item>
          <ImportExportIcon />
        </Grid>
        <Grid item xs={3}>
          <p style={{ marginBottom: '0px' }}>{productTransactionColumnToString(item.column)}</p>
        </Grid>
        <Grid item>
          <ForwardIcon />
        </Grid>
        <Grid item flexGrow={1}>
          <TextInput
            disabled={updateLoading || createLoading}
            dynamicUpdate
            draggable={true}
            onDragStart={event => event.preventDefault()}
            value={item.label}
            onChange={v => {
              item.label = v;
              setItems([...items]);
            }}
            placeholder={productTransactionColumnToString(item.column)}
          />
        </Grid>
        <Grid item style={{ cursor: 'pointer' }}>
          <DeleteOutlineIcon color='error' onClick={() => handleDelete(item.id)} />
        </Grid>
      </Grid>
    );
  };

  return (
    <FlexPane
      content={
        <Paper>
          <FlexPane
            header={
              <DropdownSelect
                selectOnly
                label={'Add Product Transaction Column'}
                values={dropdownItems}
                disabled={items.length > 12 || !dropdownItems.length || createLoading || updateLoading}
                selectedValue={null}
                toText={item => item || 'Add'}
                onChange={item => {
                  if (!item) return;
                  handleAdd({
                    label: item,
                    column: stringToProductTransactionColumn(item) as ProductTransactionColumn,
                    id: items.length,
                    index: items.length,
                    key: `${stringToProductTransactionColumn(item)}${items.length}`,
                  });
                }}
                placeholder={t().addProductTransactionColumn.singular.label}
              />
            }
            content={
              <div className='overflow-auto max-h-full'>
                <DndProvider backend={HTML5Backend}>
                  <DraggableList displayFn={ProductTransactionReportFieldItem} items={items} setItems={setItems} />
                </DndProvider>
              </div>
            }
          />
        </Paper>
      }
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <BackButton disabled={createLoading || updateLoading} onClick={() => navigate('/operations/orders')} />
          </Grid>
          <Grid item>
            <SaveButton onClick={handleSave} loading={createLoading || updateLoading} />
          </Grid>
        </Grid>
      }
    />
  );
}
