import React from 'react';
import { ProductTransaction } from '../types/productTransaction';

export interface ProductTransactionContextProps {
  productTransactions: Map<string, ProductTransaction>;
  setProductTransactions: (productTransactions: Map<string, ProductTransaction>) => void;
  productTransactionsLoading: boolean;
  loadAllProductTransactions: () => void;
}

const defaultContext: ProductTransactionContextProps = {
  productTransactions: new Map(),
  setProductTransactions: () => {},
  productTransactionsLoading: false,
  loadAllProductTransactions: async () => {},
};

export const ProductTransactionContext = React.createContext<ProductTransactionContextProps>(defaultContext);
