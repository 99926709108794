import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import { CustomField } from '../../../../types/customField';
import CustomFieldItem from '../Common/CustomFieldItem';
import { useMutation } from '@apollo/client';
import {
  CustomFieldMutations,
  DeleteCustomFieldResponse,
  DeleteCustomFieldVariables,
  UpdateCustomFieldResponse,
  UpdateCustomFieldVariables,
} from '../../../../graphql/customField.graphql';
import { CustomFieldContext } from '../../../../context/CustomFieldContext';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import DeleteButton from '../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import CancelButton from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import SaveButton from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';

interface UpdateCustomFieldModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  customField: CustomField;
}

export default function UpdateCustomFieldModal({ open, setOpen, customField }: UpdateCustomFieldModalProps) {
  const { customFields, setCustomFields } = useContext(CustomFieldContext);

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<UpdateCustomFieldResponse, UpdateCustomFieldVariables>(
    CustomFieldMutations.update,
    {
      onCompleted: res => {
        const field = res.updateCustomField[0];
        customFields.set(field.id, new CustomField(field));
        setCustomFields(new Map(customFields));
        handleClose();
      },
      onError: res => setError(res.message),
    },
  );

  const [remove, { loading: removeLoading }] = useMutation<DeleteCustomFieldResponse, DeleteCustomFieldVariables>(
    CustomFieldMutations.remove,
    {
      onCompleted: res => {
        const field = res.deleteCustomField[0];
        customFields.delete(field.id);
        setCustomFields(new Map(customFields));
        handleClose();
      },
    },
  );

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleUpdate = async (field: CustomField) => {
    try {
      field.validate();

      await create({
        variables: {
          customFields: [field],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleDelete = async (field: CustomField) => {
    try {
      await remove({
        variables: {
          customFields: [field.forDelete()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const footer = (field: CustomField) => (
    <Grid container columnSpacing={1} justifyContent={'space-between'}>
      <Grid item>
        <DeleteButton disabled={loading} loading={removeLoading} onClick={() => handleDelete(field)} />
      </Grid>
      <Grid item>
        <Grid container columnSpacing={1}>
          <Grid item>
            <CancelButton disabled={loading || removeLoading} onClick={handleClose} />
          </Grid>
          <Grid item>
            <SaveButton loading={loading} disabled={removeLoading} onClick={() => handleUpdate(field)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      height='650px'
      width='60%'
      onClose={handleClose}
      title={t().updateCustomField.singular.label}
      error={error}
    >
      <CustomFieldItem setError={setError} customField={customField} footer={footer} />
    </Modal>
  );
}
