import { ClickAwayListener, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { t } from '../../types/translation/Translator';
import { classes, stringToTestId, TestIdIdentifier } from '../../util/identifiers/identifiers.util';
import { removeDiacritics, toFilterString } from '../../util/string.util';

interface DropdownSelectInputProps<T> {
  values: T[];
  selectedValue: T | null;
  toText: (item: T) => string;
  toElement?: (item: T) => JSX.Element;
  onChange: (item: T | null) => void;
  maxHeight?: string;
  label?: string;
  mandatory?: boolean;
  placeholder?: string;
  disabled?: boolean;
  testId?: TestIdIdentifier;
  selectOnly?: boolean;
  dynamicUpdate?: boolean;
}

export default function DropdownSelect<T>({
  values,
  selectedValue,
  onChange,
  toText,
  maxHeight = '400px',
  label,
  mandatory = false,
  placeholder,
  disabled = false,
  testId,
  selectOnly = false,
  dynamicUpdate = false,
  toElement,
}: DropdownSelectInputProps<T>) {
  const [value, setValue] = useState<T | null>(selectedValue);
  const [hidden, setHidden] = useState<boolean>(true);
  const [items, setItems] = useState<T[]>(values);
  const [filter, setFilter] = useState<string>('');

  const [scrollTop, setScrollTop] = useState<number>(0);
  const [keyOverIndex, setKeyOverIndex] = useState<number>(-1);

  useEffect(() => {
    setItems(values);
    setValue(selectedValue);
    if (dynamicUpdate) setValue(selectedValue);
  }, [values, selectedValue]);

  useEffect(() => {
    if (!hidden) {
      setScrollTop(0);
      setKeyOverIndex(-1);
    }
  }, [hidden]);

  const filteredItems = items
    .filter(i => removeDiacritics(toFilterString(toText(i))).includes(removeDiacritics(toFilterString(filter))))
    .splice(0, 15 + scrollTop / 20);

  const disabledClass = () => {
    if (value || disabled) {
      return 'bg-gray-100 text-gray-400';
    }
    return '';
  };

  const handleScroll = (event?: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(event?.currentTarget.scrollTop || 0);
  };

  const handleChange = (item: T | null) => {
    onChange(item);
    if (!selectOnly) setValue(item);
    else setHidden(true);
    setFilter('');
  };

  const handleFilterChange = (filter: string) => {
    if (hidden) setHidden(false);
    setFilter(filter);
    setKeyOverIndex(-1);
  };

  const handleKeyOverChange = (index: number) => {
    setKeyOverIndex(index);
    const element = document.querySelector(
      `#${stringToTestId(testId?.name || '')} .${classes.dropdownContentItem.name}:nth-child(${index + 1})`,
    );
    element?.scrollIntoView({ block: 'nearest' });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (disabled) return;
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (keyOverIndex >= filteredItems.length - 1) return handleKeyOverChange(0);
        return handleKeyOverChange(keyOverIndex + 1);
      case 'ArrowUp':
        event.preventDefault();
        if (keyOverIndex <= 0) return handleKeyOverChange(filteredItems.length - 1);
        return handleKeyOverChange(keyOverIndex - 1);
      case 'Escape':
        setHidden(true);
        setKeyOverIndex(-1);
        break;
      case 'Enter':
        if (value) {
          onChange(null);
          setKeyOverIndex(-1);
          setHidden(true);
          return;
        }
        if (keyOverIndex < 0 || keyOverIndex >= filteredItems.length) return;
        return handleChange(filteredItems[keyOverIndex]);
    }
  };

  const dropdownContent = () => {
    if (!filteredItems.length) {
      return (
        <Grid item xs={12} textAlign={'center'}>
          <p className='font-semibold text-gray-300'>{t().noItemsFound.singular.label}</p>
        </Grid>
      );
    }

    return (
      // The reason the default event of onMouseDown is prevented here is because this will prevent the onBlur (onFocusLost) which we want so the onClick goes through!
      <Grid container className={classes.dropdownContent.name} onMouseDown={event => event.preventDefault()}>
        <Grid container maxHeight={maxHeight} onScroll={handleScroll} className='overflow-auto'>
          {filteredItems.map((item, index) => (
            <Grid
              item
              xs={12}
              key={`${item}${index}`}
              onClick={event => handleChange(item)}
              className={`${classes.dropdownContentItem.name} px-3 py-1 hover:backdrop-brightness-95 ${
                index === keyOverIndex ? 'backdrop-brightness-95' : ''
              }`}
            >
              <Grid container marginY={'auto'}>
                {toElement ? (
                  toElement(item)
                ) : (
                  <Grid item marginY={'auto'}>
                    <p>{toText(item)}</p>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setHidden(true)}>
      <Grid
        container
        width={'100%'}
        minWidth={'240px'}
        className={classes.dropdownSelect.name}
        data-testid={testId?.name}
        id={stringToTestId(testId?.name || '')}
        onKeyDown={handleKeyDown}
        onFocus={() => (disabled ? null : setHidden(false))}
        onBlur={() => setHidden(true)}
      >
        <Grid item display='flex'>
          {label ? <p className='text-sm font-medium text-slate-800'>{label}</p> : null}
          {mandatory ? <p className='ml-1 text-sm font-medium text-red-500'>*</p> : null}
        </Grid>
        <Grid
          container
          className={`mt-1 pl-3 pr-1 py-2 h-10  ${
            disabled ? 'bg-gray-100' : value ? 'bg-gray-100' : 'bg-white'
          } rounded disabled:opacity-50 border shadow-sm border-slate-300 placeholder-slate-400 block  w-full sm:text-sm focus:ring-1 ${disabledClass()}`}
        >
          <Grid item flexGrow={1} my={'auto'}>
            <input
              value={value ? (selectOnly ? '' : toText(value)) : filter}
              disabled={!!value || disabled}
              type='text'
              placeholder={placeholder}
              onClick={() => (disabled ? null : setHidden(false))}
              onChange={e => handleFilterChange(e.currentTarget.value)}
              className={`focus:outline-none  w-full ${disabled ? 'bg-gray-100' : value ? 'bg-gray-100' : 'bg-white'}`}
              autoComplete='new-password'
            />
          </Grid>
          <Grid item textAlign={'end'}>
            {disabled ? null : value ? (
              <CloseIcon
                className={`cursor-pointer ${classes.close.name}`}
                onClick={() => (disabled ? null : onChange(null))}
                style={{
                  width: '18px',
                  height: '18px',
                  color: 'black',
                }}
                tabIndex={0}
              />
            ) : (
              <ArrowDropDownIcon
                style={{
                  width: '18px',
                  height: '18px',
                }}
              />
            )}
          </Grid>
        </Grid>
        {!hidden && !value ? (
          <Grid item xs={12} className='relative inline-block w-full'>
            <Grid
              container
              p={2}
              pr={0.5}
              pl={0}
              className='mt-1 border rounded boder-t-gray-200 shadow-md cursor-pointer select-none absolute bg-white w-full z-10'
              alignContent={'flex-start'}
            >
              {dropdownContent()}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </ClickAwayListener>
  );
}
