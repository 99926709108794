import { gql } from '@apollo/client';
import { VentoryApiKey } from '../types/api';

const alert = gql`
  fragment VentoryApiKey on VentoryApiKey {
    id
    userId
    key
    companyId
    createdAt
    email
  }
`;

const get = gql`
  query VentoryApiKey($companyId: String!) {
    ventoryApiKey(companyId: $companyId) {
      ...VentoryApiKey
    }
  }
  ${alert}
`;

export interface GetVentoryApiKeyVariables {
  companyId: string;
}

export interface GetVentoryApiKeyResponse {
  ventoryApiKey: VentoryApiKey;
}

const generate = gql`
  mutation GenerateApiKey($companyId: String!) {
    generateApiKey(companyId: $companyId) {
      ...VentoryApiKey
    }
  }
  ${alert}
`;

export interface GenerateVentoryApiKeyVariables {
  companyId: string;
}

export interface GenerateVentoryApiKeyResponse {
  generateApiKey: VentoryApiKey;
}

export const VentoryApiQueries = {
  get,
};

export const VentoryApiMutations = {
  generate,
};
