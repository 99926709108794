import { cloneDeep } from '@apollo/client/utilities';
import { forCreate, forDelete, forUpdate, StockLocationEntity } from './common/entity';

enum ProductValidationError {}

export class Product extends StockLocationEntity {
  productMasterDataId!: string;
  stockLocationId!: string;
  binId!: string;
  lotId?: string;
  containerId?: string;
  serialNbr?: string;
  lpn?: string;
  countryOfOrigin?: string;
  quantity!: string;
  quantityString!: string;
  gs1ApplicationIdentifiers: Map<GS1ApplicationIdentifier, string> = new Map();

  constructor(obj: any) {
    if (!obj.companyId || !obj.stockLocationId) return;
    super(obj.companyId, obj.stockLocationId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateProductInput {
    this.validate();
    return UpdateProductInput.from(this, UpdateProductInput);
  }

  forDelete(): DeleteProductInput {
    this.validate();
    return DeleteProductInput.from(this, DeleteProductInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof Product)[], (field: keyof Product) => {
      return null;
    });
  }
}

export class CreateProductInput extends forCreate(Product) {}

export class UpdateProductInput extends forUpdate(Product) {}

export class DeleteProductInput extends forDelete(Product) {}

export enum GS1ApplicationIdentifier {
  serialShippingContainerCode = '00',
  globalTradeItemNumber = '01',
  globalTradeItemNumberOfTradeItemsContained = '02',
  lotNumber = '10',
  productionDate = '11',
  dueDate = '12',
  packagingDate = '13',
  bestBeforeDate = '15',
  expirationDate = '17',
  internalProductVariant = '20',
  serialNumber = '21',
  consumerProductVariant = '22',
  additionalProductIdentificationAssignedByTheManufacturer = '240',
  customerPartNumber = '241',
  madeToOrderVariationNumber = '242',
  secondarySerialNumber = '250',
  referenceToSourceEntity = '251',
  globalDocumentTypeIdentifier = '253',
  globalLocationNumberExtensionComponent = '254',
  globalCouponNumber = '255',
  variableCountOfItems = '30',
  countOfTradeItemsContained = '37',
  customerPurchaseOrderNumber = '400',
  globalIdentifierNumberForConsignment = '401',
  globalShipmentIdentificationNumber = '402',
  routingCode = '403',
  shipToDeliverToGlobalLocationNumber = '410',
  billToInvoiceToGlobalLocationNumber = '411',
  purchasedFromGlobalLocationNumber = '412',
  shipForDeliverForForwardToGlobalLocationNumber = '413',
  globalLocationNumber = '414',
  globalLocationNumberInvoicingParty = '415',
  shipToDeliverToPostalCode = '420',
  shipToDeliverISOcountryCode = '421',
  countryOfOriginOfTradeItem = '422',
  countryOfInitialProcessing = '423',
  countryOfProcessing = '424',
  countryOfDisassembly = '425',
  countryCoveringFullProcessChain = '426',
  natoStockNumber = '7001',
  uneceMeatCarcassesAndCutsClassification = '7002',
  expirationDateAndTime = '7003',
  rollProducts = '8001',
  cellularMobileTelephoneIdentifier = '8002',
  globalReturnableAssetIdentifier = '8003',
  globalIndividualAssetIdentifier = '8004',
  pricePerUnitOfMeasure = '8005',
  identificationOfIndividualTradeItem = '8006',
  internationalBankAccountNumber = '8007',
  dateAndTimeOfProduction = '8008',
  componentPartIdentifier = '8010',
  componentPartIdentifierSerialNumber = '8011',
  softwareVersion = '8012',
  globalModelNumber = '8013',
  globalServiceRelationNumberProvider = '8017',
  globalServiceRelationNumberRecipient = '8018',
  paymentSlipReferenceNumber = '8020',
  couponCodeIdentificationNorthAmerica = '8110',
  informationMutuallyAgreedBetweenTradingPartners = '90',
  companyInternalInformationExtra = '91',
  companyInternalInformationExtra2 = '92',
  companyInternalInformationExtra3 = '93',
  companyInternalInformationExtra4 = '94',
  companyInternalInformationExtra5 = '95',
  companyInternalInformationExtra6 = '96',
  companyInternalInformationExtra7 = '97',
  companyInternalInformationExtra8 = '98',
  companyInternalInformationExtra9 = '99',
  unknown = '-1',
}
