import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import {
  CreateStockLocationResponse,
  CreateStockLocationVariables,
  CreateStockLocationWithBinCsvResponse,
  CreateStockLocationWithBinCsvVariables,
  StockLocationMutations,
} from '../../../graphql/stockLocation.graphql';
import { StockLocation } from '../../../types/stockLocation';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../context/CompanyContext';
import { StockLocationContext } from '../../../context/StockLocationContext';
import StockLocationInfoPane from './Panes/StockLocationInfoPane';
import { testIds } from '../../../util/identifiers/identifiers.util';
import BinImportModal from './Modals/BinImportModal';
import ValidationModal from '../../Common/ValidationModal';
import { Button } from '../../../VentoryUI/components/common/Button/Button';
import BackButton from '../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';

export default function CreateStockLocationPane({}) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { stockLocations, setStockLocations, setFilteredStockLocations } = useContext(StockLocationContext);

  const [error, setError] = useState<string>('');
  const [binImportModalOpen, setBinImportModalOpen] = useState<boolean>(false);
  const [pendingStockLocation, setPendingStockLocation] = useState<StockLocation>();

  const onCreated = (stockLocation: StockLocation) => {
    stockLocations.set(stockLocation.id, new StockLocation(stockLocation));
    setFilteredStockLocations(new Map(stockLocations));
    setStockLocations(new Map(stockLocations));
    navigate('/settings/stock_location');
  };

  const [create, { loading }] = useMutation<CreateStockLocationResponse, CreateStockLocationVariables>(
    StockLocationMutations.create,
    {
      onCompleted: res => {
        onCreated(res.createStockLocation[0]);
      },
      onError: err => setError(err.message),
    },
  );

  const handleClickSave = (stockLocation: StockLocation) => {
    if (!stockLocation.name) return setError(t().enterStockLocationName.singular.upper);
    setPendingStockLocation(stockLocation);
    setBinImportModalOpen(true);
  };

  const handleCreate = async (stockLocation: StockLocation) => {
    await create({
      variables: {
        stockLocations: [stockLocation],
      },
    });
  };

  const footer = (stockLocation: StockLocation) => (
    <Grid container>
      <Grid container columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item alignSelf={'flex-start'}>
          <Button disabled={loading} onClick={() => {}} text={t().importBins.singular.label} testId={testIds.import} />
        </Grid>
        <Grid item>
          <BackButton disabled={loading} onClick={() => navigate('/settings/stock_location')} />
        </Grid>
        <Grid item>
          <SaveButton loading={loading} onClick={() => handleClickSave(stockLocation)} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Pane testId={testIds.createStockLocationPane} error={error}>
        <StockLocationInfoPane
          title={t().createStockLocation.singular.label}
          stockLocation={new StockLocation({ companyId: currentCompany.id })}
          footer={footer}
        />
      </Pane>
      <CreateStockLocationBinImportModal
        open={binImportModalOpen}
        setOpen={setBinImportModalOpen}
        stockLocation={pendingStockLocation}
        handleCreateWithoutUpload={handleCreate}
        onCreated={onCreated}
      />
    </>
  );
}

interface CreateStockLocationBinImportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  stockLocation: StockLocation | undefined;
  handleCreateWithoutUpload: (stockLocation: StockLocation) => Promise<void>;
  onCreated: (stockLocation: StockLocation) => void;
}

function CreateStockLocationBinImportModal({
  open,
  setOpen,
  stockLocation,
  handleCreateWithoutUpload,
  onCreated,
}: CreateStockLocationBinImportModalProps) {
  if (!open || !stockLocation) return <></>;

  const [createStockLocationWithBinCsv, { loading }] = useMutation<
    CreateStockLocationWithBinCsvResponse,
    CreateStockLocationWithBinCsvVariables
  >(StockLocationMutations.createWithBinCsv);

  const [confirmed, setConfirmed] = useState<boolean | null>(null);
  const [error, setError] = useState<string | undefined>();

  if (confirmed === null) {
    return (
      <ValidationModal
        title={'Import bins?'}
        content={'Would you like to import bins for this stock location?'}
        subContent={'Pressing "No" will create the location without bins'}
        onYes={() => setConfirmed(true)}
        onNo={async () => {
          setOpen(false);
          await handleCreateWithoutUpload(stockLocation);
        }}
        open={open}
        setOpen={setOpen}
      />
    );
  }

  if (confirmed === true) {
    return (
      <BinImportModal
        open={open}
        setOpen={setOpen}
        errorOverride={error}
        handleUploadOverride={async (csvFileAsBase64Data: string, configurationId: string) => {
          await createStockLocationWithBinCsv({
            variables: {
              stockLocation: stockLocation,
              csvFileAsBase64Data,
              configurationId,
            },
            onError: err => setError(err.message),
            onCompleted: res => {
              setOpen(false);
              onCreated(res.createStockLocationWithBinCsv);
            },
          });
        }}
      />
    );
  }

  return <></>;
}
