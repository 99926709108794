import React from 'react';
import { ReorderUserRule } from '../types/reorderUserRule';

export interface ReorderUserRuleContextProps {
  reorderUserRules: Map<string, ReorderUserRule>;
  setReorderUserRules: (reorderUserRules: Map<string, ReorderUserRule>) => void;
  groupedReorderUserRules: Map<string, ReorderUserRule[]>;
  setGroupedReorderUserRules: (groupedReorderUserRules: Map<string, ReorderUserRule[]>) => void;
  reorderUserRulesLoading: boolean;
}

const defaultContext: ReorderUserRuleContextProps = {
  reorderUserRules: new Map(),
  setReorderUserRules: () => {},
  reorderUserRulesLoading: false,
  groupedReorderUserRules: new Map<string, ReorderUserRule[]>(),
  setGroupedReorderUserRules: () => {},
};

export const ReorderUserRuleContext = React.createContext<ReorderUserRuleContextProps>(defaultContext);
