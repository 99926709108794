import React from 'react';
import { Contact } from '../types/contact';

export interface ContactContextProps {
  contacts: Map<string, Contact>;
  setContacts: (contacts: Map<string, Contact>) => void;
  contactsLoading: boolean;
}

const defaultContext: ContactContextProps = {
  contacts: new Map(),
  setContacts: () => {},
  contactsLoading: false,
};

export const ContactContext = React.createContext<ContactContextProps>(defaultContext);
