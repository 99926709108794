import React from 'react';
import {
  CompanyRelationService,
  IntegrationSettings,
  companyRelationServiceToString,
} from '../../../../../../types/integrationSettings';
import { t } from '../../../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import DropdownSelect from '../../../../../Common/DropdownSelect';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/ModalPane';
import { NextButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface CreateIntegrationSettingsPaneProps {
  settings: IntegrationSettings;
  setSettings: (settings: IntegrationSettings) => void;
  handleNext: () => void;
}

export default function CreateIntegrationSettingsTypePane({
  settings,
  setSettings,
  handleNext,
}: CreateIntegrationSettingsPaneProps) {
  return (
    <ModalPane footerButtons={[NextButtonTemplate(handleNext)]}>
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={6}>
          <DropdownSelect
            mandatory
            maxHeight='200px'
            label={t().service.singular.label}
            values={Object.values(CompanyRelationService)}
            selectedValue={settings.type || null}
            toText={item => companyRelationServiceToString(item)}
            onChange={v => setSettings(settings.withType(v))}
            placeholder={t().service.singular.label}
          />
        </Grid>
      </Grid>
    </ModalPane>
  );
}
