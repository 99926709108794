import React from 'react';
import { OrderTableSettings } from '../types/orderTableSettings';

export interface OrderTableSettingsContextProps {
  orderTableSettings: Map<string, OrderTableSettings>;
  setOrderTableSettings: (fields: Map<string, OrderTableSettings>) => void;
  orderTableSettingsLoading: boolean;
}

const defaultContext: OrderTableSettingsContextProps = {
  orderTableSettings: new Map(),
  setOrderTableSettings: () => {},
  orderTableSettingsLoading: false,
};

export const OrderTableSettingsContext = React.createContext<OrderTableSettingsContextProps>(defaultContext);
