import React from 'react';
import Icon, { IconInputProps } from '../Icon';

interface SortIconProps extends IconInputProps {
  sortDescending: boolean;
}

export default function SortIcon({ className, sortDescending }: SortIconProps) {
  return (
    <Icon className={className}>
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        stroke='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        {sortDescending ? (
          <path
            d='M10.333 11.7439L10.4216 1.07756M10.4216 1.07756L7.7329 3.72198M10.4216 1.07756L13.066 3.76629M3.75519 1.02218L3.66657 11.6885M3.66657 11.6885L1.02215 8.99975M3.66657 11.6885L6.3553 9.04405'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        ) : (
          <path
            d='M10.3333 1V11.6667M10.3333 11.6667L7.66667 9M10.3333 11.6667L13 9M3.66667 11.6667V1M3.66667 1L1 3.66667M3.66667 1L6.33333 3.66667'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        )}
      </svg>
    </Icon>
  );
}
