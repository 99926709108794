import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import {
  ProductMasterDataExportConfiguration,
  CreateProductMasterDataExportConfigurationInput,
  UpdateProductMasterDataExportConfigurationInput,
  DeleteProductMasterDataExportConfigurationInput,
} from '../types/productMasterDataExportConfiguration';

const productMasterDataExportConfiguration = gql`
  fragment ProductMasterDataExportConfiguration on ProductMasterDataExportConfiguration {
    id
    version
    companyId
    name
    filename
    prefix
    delimiter
    quote
    default
    fieldMapping {
      index
      customField
      field
    }
  }
`;

const get = gql`
  query ProductMasterDataExportConfigurations($companyId: String!, $page: Float) {
    productMasterDataExportConfigurations(companyId: $companyId, page: $page) {
      data {
        ...ProductMasterDataExportConfiguration
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${productMasterDataExportConfiguration}
`;

export interface GetProductMasterDataExportConfigurationsVariables {
  companyId: string;
  page?: number;
}

export interface GetProductMasterDataExportConfigurationsResponse {
  productMasterDataExportConfigurations: PaginatedProductMasterDataExportConfigurations;
}

class PaginatedProductMasterDataExportConfigurations extends forPaginated<ProductMasterDataExportConfiguration>() {}

const create = gql`
  mutation CreateProductMasterDataExportConfiguration(
    $productMasterDataExportConfigurations: [CreateProductMasterDataExportConfigurationInput!]!
  ) {
    createProductMasterDataExportConfiguration(
      productMasterDataExportConfigurations: $productMasterDataExportConfigurations
    ) {
      ...ProductMasterDataExportConfiguration
    }
  }
  ${productMasterDataExportConfiguration}
`;

export interface CreateProductMasterDataExportConfigurationVariables {
  productMasterDataExportConfigurations: CreateProductMasterDataExportConfigurationInput[];
}

export interface CreateProductMasterDataExportConfigurationResponse {
  createProductMasterDataExportConfiguration: ProductMasterDataExportConfiguration[];
}

const update = gql`
  mutation UpdateProductMasterDataExportConfiguration(
    $productMasterDataExportConfigurations: [UpdateProductMasterDataExportConfigurationInput!]!
  ) {
    updateProductMasterDataExportConfiguration(
      productMasterDataExportConfigurations: $productMasterDataExportConfigurations
    ) {
      ...ProductMasterDataExportConfiguration
    }
  }
  ${productMasterDataExportConfiguration}
`;

export interface UpdateProductMasterDataExportConfigurationVariables {
  productMasterDataExportConfigurations: UpdateProductMasterDataExportConfigurationInput[];
}

export interface UpdateProductMasterDataExportConfigurationResponse {
  updateProductMasterDataExportConfiguration: ProductMasterDataExportConfiguration[];
}

const remove = gql`
  mutation DeleteProductMasterDataExportConfiguration(
    $productMasterDataExportConfigurations: [DeleteProductMasterDataExportConfigurationInput!]!
  ) {
    deleteProductMasterDataExportConfiguration(
      productMasterDataExportConfigurations: $productMasterDataExportConfigurations
    ) {
      ...ProductMasterDataExportConfiguration
    }
  }
  ${productMasterDataExportConfiguration}
`;

export interface DeleteProductMasterDataExportConfigurationVariables {
  productMasterDataExportConfigurations: DeleteProductMasterDataExportConfigurationInput[];
}

export interface DeleteProductMasterDataExportConfigurationResponse {
  deleteProductMasterDataExportConfiguration: ProductMasterDataExportConfiguration[];
}

export const ProductMasterDataExportConfigurationQueries = {
  get,
};

export const ProductMasterDataExportConfigurationMutations = {
  create,
  update,
  remove,
};
