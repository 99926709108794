import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as VentoryWhiteLogo } from '../../../assets/img/Ventory-WhiteNoBack.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  ActivateMicrosoftSubscriptionResponse,
  ActivateMicrosoftSubscriptionVariables,
  UserQueries,
} from '../../../graphql/user.graphql';
import LoadingPackage from '../../Common/LoadingPackage';
import ErrorBox from '../../Common/ErrorBox';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

interface ActivateMicrosoftSubscriptionScreenProps {}

export default function SignUpScreen({}: ActivateMicrosoftSubscriptionScreenProps) {
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();

  const token = params.get('token');
  if (!token) {
    navigate('/login');
    return <></>;
  }

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { loading } = useQuery<ActivateMicrosoftSubscriptionResponse, ActivateMicrosoftSubscriptionVariables>(
    UserQueries.activateMicrosoftSubscription,
    {
      variables: {
        token,
      },
      onCompleted: res => {
        setEmail(res.activateMicrosoftSubscription);
        setTimeout(() => navigate('/login'), 5000);
      },
      onError: err => setError(err.message),
    },
  );

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
      className='bg-ventory-blue-900'
    >
      <Grid container width={'calc(100vw/8)'} mb={2}>
        <Grid item xs={12}>
          <VentoryWhiteLogo />
        </Grid>
      </Grid>
      <Grid
        width={'350px'}
        container
        paddingX={3}
        paddingY={2}
        justifyContent={'center'}
        rowSpacing={1}
        className='bg-white'
      >
        <Grid item xs={12} textAlign={'center'} justifyItems={'center'}>
          <Grid container justifyContent={'center'}>
            {error ? (
              <Grid item xs={12}>
                <ErrorBox error={error} />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {loading ? (
                <p className='text-sm font-semibold'>{`Activating Microsoft subscription...`}</p>
              ) : (
                <p className='text-sm font-semibold'>{`Microsoft subscription activated successfully. An email has been sent to ${email}`}</p>
              )}
            </Grid>
            <Grid item>
              {loading ? (
                <Grid container mt={4} justifyContent={'center'}>
                  <Grid item>
                    <LoadingPackage />
                  </Grid>
                </Grid>
              ) : (
                <Grid container mt={1}>
                  <Grid item>
                    <MarkEmailReadIcon
                      style={{
                        width: '60px',
                        height: '60px',
                      }}
                      className='text-ventory-light-green'
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
