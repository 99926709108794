import { Grid } from '@mui/material';
import React from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CreateTriggerInput, Trigger, TriggerType, triggerTypeToString } from '../../../../../types/trigger';
import Selector from '../../../../Common/Selector';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface CreateTriggerTypePaneInputProps {
  trigger: CreateTriggerInput;
  setTrigger: (trigger: Trigger) => void;
  next: () => void;
  back: () => void;
}

export function CreateTriggerTypePane({ trigger, setTrigger, next, back }: CreateTriggerTypePaneInputProps) {
  return (
    <ModalPane testId={testIds.createTriggerTypePane} footerButtons={[NextButtonTemplate(next)]}>
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Selector
              testId={testIds.triggerType}
              selectAll={false}
              placeholder={t().triggerTypeText.singular.label}
              values={[...Object.keys(TriggerType).values()] || []}
              checkedValues={trigger.types}
              disabled={item => item === TriggerType.alert}
              toText={item => triggerTypeToString(item as TriggerType)}
              onChange={checked => setTrigger(trigger.withTypes(checked as TriggerType[]))}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
