import React from 'react';
import { ProductExportConfiguration } from '../types/productExportConfiguration';

export interface ProductExportConfigurationContextProps {
  productExportConfigurations: Map<string, ProductExportConfiguration>;
  setProductExportConfigurations: (productExportConfigurations: Map<string, ProductExportConfiguration>) => void;
  productExportConfigurationsLoading: boolean;
}

const defaultContext: ProductExportConfigurationContextProps = {
  productExportConfigurations: new Map<string, ProductExportConfiguration>(),
  setProductExportConfigurations: () => {},
  productExportConfigurationsLoading: false,
};

export const ProductExportConfigurationContext =
  React.createContext<ProductExportConfigurationContextProps>(defaultContext);
