import React from 'react';
import LoadingPackage from './LoadingPackage';

export default function LoadingScreen() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100vh',
      }}
    >
      <LoadingPackage />
    </div>
  );
}
