import { CompanyRoleAssignment } from '../../../../types/companyRoleAssignment';
import { User } from '../../../../types/user';
import { toFilterString } from '../../../../util/string.util';
import { EntityFilter } from '../filter.util';

export namespace CompanyRoleAssignmentFilter {
  export function search(item: CompanyRoleAssignment, textFilter: string, users: Map<string, User>) {
    const user = users.get(item.userId);

    if (
      toFilterString(item.email).includes(textFilter) ||
      toFilterString(`${user?.firstName} ${user?.lastName}`).includes(textFilter)
    ) {
      return true;
    }
    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: CompanyRoleAssignment[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      return onFilter(item, textFilter);
    });

    return result;
  }
}
