import { Grid } from '@mui/material';
import TextInput from '../../../../../../Common/TextInput';
import React from 'react';
import { IntegrationSettings } from '../../../../../../../types/integrationSettings';
import { t } from '../../../../../../../types/translation/Translator';
import Paper from '../../../../../../../VentoryUI/components/common/Paper/Paper';

interface IntegrationSAPSettingsInfoProps {
  settingsInput: IntegrationSettings;
  setSettingsInput: (settings: IntegrationSettings) => void;
  disabled: boolean;
}

export default function IntegrationSAPSettingsInfo({
  settingsInput,
  setSettingsInput,
  disabled,
}: IntegrationSAPSettingsInfoProps) {
  return (
    <Paper>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={6}>
          <TextInput
            disabled={disabled}
            mandatory
            value={settingsInput.name}
            onChange={v => setSettingsInput(settingsInput.withName(v))}
            placeholder={t().name.singular.label}
            label={t().name.singular.label}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            mandatory
            disabled={disabled}
            value={settingsInput.externalId}
            onChange={v => setSettingsInput(settingsInput.withExternalId(v))}
            placeholder={t().externalId.singular.label}
            label={t().externalId.singular.label}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            mandatory
            disabled={disabled}
            value={settingsInput.url}
            onChange={v => setSettingsInput(settingsInput.withUrl(v))}
            placeholder={t().urlInput.singular.label}
            label={t().urlInput.singular.label}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            mandatory
            disabled={disabled}
            type={disabled ? 'password' : 'text'}
            value={settingsInput.apiKey}
            onChange={v => setSettingsInput(settingsInput.withApiKey(v))}
            placeholder={t().apiKey.singular.label}
            label={t().apiKey.singular.label}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
