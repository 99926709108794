import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div
      style={{ padding: '1rem' }}
      className='elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-e0cff36'
      data-id='e0cff36'
      data-element_type='column'
    >
      <div className='elementor-column-wrap elementor-element-populated'>
        <div className='elementor-widget-wrap'>
          <div
            className='elementor-element elementor-element-fd85e8a elementor-widget elementor-widget-text-editor'
            data-id='fd85e8a'
            data-element_type='widget'
            data-widget_type='text-editor.default'
          >
            <div className='elementor-widget-container'>
              <div className='elementor-text-editor elementor-clearfix'>
                <div>
                  As the provider of the website <a href='https://app.ventory.io/'>https://app.ventory.io/</a>,{' '}
                  <a href='https://ventory.io'>https://ventory.io</a> (hereinafter “<strong>Website</strong>”) and a
                  mobile application on the Apple and Google Play store, we at Ventory (“<strong>Ventory</strong>
                  ”) are Controller as defined in applicable data protection law, more specifically the General Data
                  Protection Regulation (EU) 2016/679 (“<strong>GDPR</strong>”), and are therefore responsible for the
                  personal data (“<strong>Personal Data</strong>”) of the Website user. As a user of the Website you
                  consent to Ventory use of your Personal Data under this privacy policy and you acknowledge and accept
                  that Ventory processes your Personal Data. Your privacy is important to Ventory and Ventory is
                  committed to protecting your privacy. This privacy policy explains how Ventory collects and processes
                  Personal Data, what Personal Data Ventory processes and for what purposes such data is processed.
                </div>
                <p>&nbsp;</p>
                <p>
                  <strong>Information about the Controller</strong>
                </p>
                <p>
                  <a href='https://app.ventory.io/'>https://app.ventory.io/</a>
                </p>
                <p>
                  <a href='https://ventory.io'>https://ventory.io</a>
                </p>
                <p>Ventory Mobile App on Apple and Google Play</p>
                <p>Ventory is a trade name of M28 Ventures BV</p>
                <ol>
                  <li>
                    <strong> What Personal Data does Ventory collect?</strong>
                  </li>
                </ol>
                <p>
                  When you access the Website and its forms, Ventory collects information that includes your IP address,
                  login data, the type of browser and version that you are using, the browser language, the operating
                  system and platform, the date and time of access, commercial contact details as provided by you, the
                  time zone setting, device type, geography and any other information transferred by you.
                </p>
                <p>
                  The Personal Data that Ventory collects may include names, surnames, e-mail addresses, postal
                  addresses, phone numbers, how you used our Website, length of visits, page interaction information and
                  other Personal Data provided by you when you sign up for the services and when you use the Website.
                  Ventory will only process your Personal Data to the extent necessary to fulfil the services and for
                  purposes which are compatible with providing the services, such as directing advertisement regarding
                  the services to you.
                </p>
                <p>
                  When processing Personal Data, Ventory complies with all applicable data protection laws, in
                  particular (but not limited to) GDPR.
                </p>
                <ol start={2}>
                  <li>
                    <strong> How do we collect Personal Data and Customer Data?</strong>
                  </li>
                </ol>
                <p>
                  You trust Ventory with your Personal Data and any data that you insert in connection with the services
                  that could be both Personal Data for your own use and other data (“<strong>Customer Data</strong>”)
                  when you register to use the services.
                </p>
                <p>
                  Ventory also collects Personal Data using cookie files to improve and personalize your use of the
                  services. When you use Ventory services, Ventory saves cookie files on your computer. Cookies are
                  small text files that, unless you have adjusted your browser setting to refuse cookies, our system
                  will send your device when you visit the Website. Cookies collect standard internet log information
                  and visitor behaviour information. The cookie files help with the functionality of the Website and
                  allow the Website to identify your browser and to recognize what preferences you have and what
                  settings you have made.
                </p>
                <p>
                  You can access more information about cookies at{' '}
                  <a href='https://www.allaboutcookies.org/'>www.allaboutcookies.org</a>.
                </p>
                <p>Ventory uses the following Cookies:</p>
                <ul>
                  <li>
                    <strong>Functional and Required Cookies.</strong> We use some necessary cookies because they allow
                    visitors to navigate and key features on the site.
                  </li>
                  <li>
                    <strong>Analytics and Performance Cookies. </strong>We use Analytics and Performance cookies to
                    collect information about how visitors interact with the site.
                  </li>
                  <li>
                    <strong>Session Cookies.</strong>&nbsp;We use Session Cookies to operate our Services.
                  </li>
                  <li>
                    <strong>Preference Cookies.</strong>&nbsp;We use Preference Cookies to remember your preferences and
                    various settings.
                  </li>
                  <li>
                    <strong>Security Cookies.</strong>&nbsp;We use Security Cookies for security purposes.
                  </li>
                </ul>
                <ol start={3}>
                  <li>
                    <strong> How does Ventory process your Personal Data and Customer Data?</strong>
                  </li>
                </ol>
                <p>
                  Ventory only processes Personal Data to the extent necessary to provide the services and for purposes
                  which are compatible with providing the services, such as directing advertisement regarding the
                  services to you. Customer Data is only processed on your behalf to provide the services to you.
                </p>
                <p>
                  Ventory stores Personal Data and Customer Data in a database. Personal Data is used to contact you and
                  to manage your account and any payments made by you. Customer Data is only processed on your behalf
                  and in accordance with your instructions.
                </p>
                <p>
                  When Ventory processes Personal Data and Customer Data, Ventory complies with all applicable data
                  protection laws as well as any laws and regulations applicable to the services provided to you.
                </p>
                <ol start={4}>
                  <li>
                    <strong> Who has access to your Personal Data and Customer Data?</strong>
                  </li>
                </ol>
                <p>
                  Ventory processes your Personal Data and Customer Data confidentially. Ventory does not sell your data
                  to third parties. In this regard “third parties” shall not entail Ventory subcontractors who assist
                  Ventory in performing the services to you. Ventory has subcontractors such as cloud service providers,
                  and consultants including for IT-support and accounting. Ventory ensures that its subcontractors are
                  liable to keep your Personal Data and Customer Data confidential.
                </p>
                <p>
                  Ventory may further reveal your Personal Data and Customer Data to third parties, including the
                  competent supervisory authority, if it is required by applicable data protection laws or other
                  applicable laws and regulations.
                </p>
                <p>
                  Ventory ensures that access to your Personal Data and Customer Data is limited to the personnel who
                  require such access to perform the services and other processing activities which are compatible with
                  providing the services.
                </p>
                <p>
                  Should Personal Data be submitted or stored by any third party in so called Third Countries, countries
                  outside the EU, the Ventory cannot guarantee that the same level of protection can be offered as that
                  provided for by the GDPR.
                </p>
                <ol start={5}>
                  <li>
                    <strong> How long does Ventory store Personal Data and Customer Data?&nbsp;</strong>
                  </li>
                </ol>
                <p>
                  Ventory will only store Personal Data and Customer Data for a limited period. Ventory ensures that
                  Personal Data and Customer Data will be deleted when it is no longer necessary to retain it to provide
                  the services, or for purposes which are compatible with providing the services.
                </p>
                <p>
                  Personal Data and Customer Data provided by you to Ventory is retained for the duration of your
                  subscription of the services and for a longer period if it is required by applicable laws, for example
                  for any legal, accounting, or reporting requirements or purposes.
                </p>
                <ol start={6}>
                  <li>
                    <strong> Direct marketing and the right to opt-out&nbsp;</strong>
                  </li>
                </ol>
                <p>
                  Ventory may process your Personal Data to provide information regarding Ventory services to you as a
                  Website user, including regular electronic newsletters that you can subscribe to. You have the right
                  to object to Ventory use of your Personal Data for direct marketing under applicable data protection
                  laws. If you wish to object to direct marketing, please contact Ventory by sending an e-mail to{' '}
                  <a href='mailto:sales@ventory.io'>sales@ventory.io</a> or use the unsubscribe link as provided.
                </p>
                <ol start={7}>
                  <li>
                    <strong> Subject Access Requests&nbsp;</strong>
                  </li>
                </ol>
                <p>
                  You are entitled to additional information regarding Ventory processing of your Personal Data. In case
                  you want to know what Personal Data Ventory stores about you and how your Personal Data is processed,
                  please contact Ventory by sending an e-mail to <a href='mailto:legal@ventory.io'>legal@ventory.io</a>{' '}
                  (“
                  <strong>Subject Access Request</strong>”). If you make a Subject Access Request by electronic means,
                  Ventory will provide information regarding the processing of your Personal Data in a commonly used
                  electronic form. The information provided by Ventory shall include the following:
                </p>
                <p>(1) the purposes of the processing;</p>
                <p>(2) the categories of Personal Data concerned;</p>
                <p>
                  (3) the recipients or categories of recipients to whom your Personal Data has been or will be
                  disclosed, in particular recipients in third countries or international organizations;
                </p>
                <p>(4) where possible, the envisaged period for which your Personal Data will be stored;</p>
                <p>
                  (5) whether you are entitled to request rectification or erasure of Personal Data or restriction of
                  processing of Personal Data or to object to the processing or to lodge a complaint with a supervisory
                  authority;
                </p>
                <p>
                  (6) where Personal Data is not collected from you, available information as to the source of the
                  Personal Data;
                </p>
                <p>(7) the existence of automated decision-making, including profiling;</p>
                <p>
                  (8) where Personal Data is transferred to a third country or to an international organization,
                  information regarding the appropriate safeguards relating to the transfer;
                </p>
                <p>(9) a copy of the Personal Data undergoing processing.</p>
                <ol start={8}>
                  <li>
                    <strong>Rights relating to the processing of Personal Data</strong>
                  </li>
                </ol>
                <p>
                  You are entitled to request rectification or erasure of your Personal Data and to object to the
                  processing of your Personal Data. You may also request restriction of processing of your Personal
                  Data. Ventory is required to update or rectify your Personal Data if the Personal Data Ventory holds
                  on you is inaccurate. Ventory may also be required to delete your Personal Data, in example if you
                  withdraw your consent to the processing or if the Personal Data Ventory stores about you is incorrect
                  or irrelevant.
                </p>
                <p>
                  Ventory undertakes to respond to requests regarding rectification, erasure or restriction of Personal
                  Data, as well as to objections to the processing of Personal Data, in a timely manner. When Ventory
                  receives a request, which is justified according to applicable data protection laws, Ventory shall
                  comply with the request and delete or rectify the Personal Data or restrict or cease the processing of
                  such data.
                </p>
                <p>
                  You have the right to receive the Personal Data, which has been provided to a controller, in a
                  structured, commonly used and machine-readable format and have the right to transmit such data to
                  another controller without hindrance from the controller to which the Personal Data have been
                  provided. You also have the right to lodge a complaint with a supervisory authority.
                </p>
                <p>
                  Requests under this paragraph shall be sent to Ventory by e-mail to{' '}
                  <a href='mailto:legal@ventory.io'>legal@ventory.io</a>.
                </p>
                <ol start={9}>
                  <li>
                    <strong> Data security&nbsp;</strong>
                  </li>
                </ol>
                <p>
                  Your privacy is important to Ventory and Ventory is committed to protecting your Personal Data and
                  your Customer Data. Ventory ensures that Ventory will take all reasonable measures to protect your
                  Personal Data and Customer Data and in particular to prevent unauthorized access to such data.
                </p>
                <p>
                  Ventory wants to make you aware of the risks which are inherent in data transmissions over the
                  Internet since such transmissions are never completely secure. When you provide your Personal Data and
                  Customer Data to Ventory over the Internet you are responsible for the risks of unauthorized access
                  and loss of data which the transmission entails.
                </p>
                <p>
                  Ventory has implemented and maintains a number of technical and organizational security measures to
                  keep your Personal Data and Customer Data safe when stored by Ventory. Such security measures include
                  logical separation, encryption, firewalls and antivirus in accordance with applicable industry
                  standards.
                </p>
                <p>
                  In the event that the security of the Website and the Personal Data be compromised, the Ventory
                  reserves the right to take any appropriate action as set out in the relevant data protection laws,
                  including notifying you and the appropriate regulator of such a breach where required by law.
                </p>
                <p>
                  The Website may carry links to other websites that are not affiliated to nor controlled by the
                  Ventory. Should you access these sites from the Website, Ventory accepts no liability or
                  responsibility over the content, privacy policies, data handling or practices of these sites.
                </p>
                <ol start={10}>
                  <li>
                    <strong> User responsibility</strong>
                  </li>
                </ol>
                <p>
                  As a Website user it is important that you are aware of your responsibility regarding the use of the
                  services. You are responsible for protecting and updating your account information to prevent
                  unauthorized access to your account.
                </p>
                <p>
                  You are also responsible to abide by all applicable laws and regulations. Where you process your own
                  Customer Data which is Personal Data you act as the Data Controller, hence you are responsible for
                  compliance with applicable data protection laws. This entails ensuring the rights of the data subjects
                  and in particular to erase your Customer Data when you no longer need it for the purposes specified by
                  you. If you use free text fields, you are responsible for ensuring that free text fields do not
                  generate processing of excessive or irrelevant Personal Data. You are further responsible for your
                  sharing of your Customer Data, for example when providing access to third parties.
                </p>
                <p>
                  The Ventory may use third-party services in order to provide services on the Website. If you decide to
                  enable, access or use such third-party services, be advised that your access and use of such other
                  services are governed solely by the terms and conditions and data privacy policy of such other
                  providers, and the Ventory is not liable for, and makes no representations as to such other services
                  and products, including, without limitation, their content or the manner in which they handle data
                  (including your data) or any interaction between you and the such provider.
                </p>
                <p>
                  For your payments on the Website, Ventory assists you by way of utilizing the services of Stripe
                  Payments Europe, Ltd. (“Stripe”). The handling of your Personal Data throughout your payment process
                  is therefore subject to (i) the Global Privacy Policy of Stripe; and (ii) this policy. In the event of
                  conflict between these policies in strict relation to the processing of your Personal Data by Stripe
                  in connection with your payments on the Website, the terms of the Global Privacy Policy of Stripe
                  prevail.
                </p>
                <ol start={11}>
                  <li>
                    <strong> Changes to our Privacy Policy</strong>
                  </li>
                </ol>
                <p>
                  Ventory keeps its Privacy Policy under regular review and places any updates on this page. This
                  Privacy Policy was last updated: 19 October 2020.
                </p>
                <ol start={12}>
                  <li>
                    <strong> Contact</strong>
                  </li>
                </ol>
                <p>
                  If you have any questions about Ventory’s Privacy Policy, the data we hold on you, or would like to
                  exercise one of your data protection rights, please do not hesitate to contact Ventory.
                </p>
                <p>
                  <strong>M28 Ventures BV (dba Ventory)</strong>
                </p>
                <p>
                  E-mail: <a href='mailto:legal@ventory.io'>legal@ventory.io</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
