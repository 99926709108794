import { Divider, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import TextInput from '../../../Common/TextInput';
import { t } from '../../../../types/translation/Translator';
import { TaskSettings } from '../../../../types/taskSettings';
import { CompanyContext } from '../../../../context/CompanyContext';
import {
  CreateTaskSettingsResponse,
  CreateTaskSettingsVariables,
  TaskSettingsMutations,
  UpdateTaskSettingsResponse,
  UpdateTaskSettingsVariables,
} from '../../../../graphql/taskSettings.graphql';
import { useMutation } from '@apollo/client';
import LoadingPackage from '../../../Common/LoadingPackage';
import { useNavigate } from 'react-router-dom';
import { TaskSettingsContext } from '../../../../context/TaskSettingsContext';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import Selector from '../../../Common/Selector';
import { StockLocationRole, stockLocationRoleToString } from '../../../../types/stockLocationRoleAssignment';
import { CompanyRole, companyRoleToString } from '../../../../types/companyRoleAssignment';
import Dropdown from '../../../Common/Dropdown';
import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface TaskSettingsPaneProps {
  setError: (error: string) => void;
}

export default function TaskSettingsPane({ setError }: TaskSettingsPaneProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { taskSettings, setTaskSettings, taskSettingsLoading } = useContext(TaskSettingsContext);
  const taskSetting = [...taskSettings.values()][0];

  const [taskSettingsInput, setTaskSettingsInput] = useState<TaskSettings>(
    new TaskSettings(taskSetting || { companyId: currentCompany.id }),
  );

  useEffect(() => {
    if (!taskSettingsLoading)
      setTaskSettingsInput(
        new TaskSettings(new TaskSettings([...taskSettings.values()][0] || { companyId: currentCompany.id })),
      );
  }, [taskSettingsLoading]);

  const [create, { loading: createLoading }] = useMutation<CreateTaskSettingsResponse, CreateTaskSettingsVariables>(
    TaskSettingsMutations.create,
    {
      onCompleted: res => {
        taskSettings.set(res.createTaskSettings.id, new TaskSettings(res.createTaskSettings));
        setTaskSettings(new Map(taskSettings));
        navigate('/tasks');
      },
      onError: err => setError(err.message),
    },
  );

  const [update, { loading: updateLoading }] = useMutation<UpdateTaskSettingsResponse, UpdateTaskSettingsVariables>(
    TaskSettingsMutations.update,
    {
      onCompleted: res => {
        taskSettings.set(res.updateTaskSettings.id, new TaskSettings(res.updateTaskSettings));
        setTaskSettings(new Map(taskSettings));
        navigate('/tasks');
      },
      onError: err => setError(err.message),
    },
  );

  const handleSave = async () => {
    try {
      if (!taskSettingsInput.id) {
        return await create({
          variables: {
            taskSettings: taskSettingsInput,
          },
        });
      }
      return await update({
        variables: {
          taskSettings: taskSettingsInput.forUpdate(),
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  if (taskSettingsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'}>
        <Grid item className='fill-gray-300 ' marginX={'auto'}>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <FlexPane
      wrapContent={false}
      content={
        <Paper overflow='auto'>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <TextInput
                type='number'
                mandatory
                dynamicUpdate
                value={taskSettingsInput.discrepancyThreshold?.toString() || ''}
                label={t().discrepancyThreshold.singular.label}
                placeholder={t().discrepancyThreshold.singular.label}
                onChange={v => setTaskSettingsInput(taskSettingsInput.withDiscrepancyThreshold(v))}
              />
            </Grid>
            {currentCompany.settings.featureToggles.tasks.allowStockUpdate ? (
              <Grid item xs={6}>
                <Dropdown<StockLocationRole>
                  label={t().updateStockRole.singular.label}
                  values={[
                    StockLocationRole.STOCK_LOCATION_MANAGER,
                    StockLocationRole.STOCK_LOCATION_SUPERVISOR,
                    StockLocationRole.STOCK_LOCATION_USER,
                  ]}
                  selectedValue={taskSettingsInput.stockLocationRoleForUpdateStock || null}
                  placeholder={t().stockLocationRole.singular.label}
                  onChange={v =>
                    setTaskSettingsInput(taskSettingsInput.withStockLocationRoleForUpdateStock(v || undefined))
                  }
                  toText={v => stockLocationRoleToString(v)}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} mt={1}>
              <Checkbox
                label={t().sendPushNotificationToAssignees.singular.label}
                onChange={value => setTaskSettingsInput(taskSettingsInput.withSendPushNotificationToAssignees(value))}
                value={taskSettingsInput.sendPushNotificationToAssignees}
              />
            </Grid>

            <Grid item xs={12} mt={1}>
              <Checkbox
                label={t().dueDateMandatory.singular.label}
                onChange={value => setTaskSettingsInput(taskSettingsInput.withDueDateMandatory(value))}
                value={taskSettingsInput.dueDateMandatory}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <p>{t().rolesForInitialCount.singular.label}</p>
            </Grid>
            <Grid item xs={6} mt={1} pr={1}>
              <p className='font-semibold text-sm'>{t().stockLocationLevel.singular.label}</p>
              <Selector
                showSearch={false}
                checkedValues={taskSettingsInput.stockLocationRolesForInitialCount}
                values={[
                  StockLocationRole.STOCK_LOCATION_MANAGER,
                  StockLocationRole.STOCK_LOCATION_SUPERVISOR,
                  StockLocationRole.STOCK_LOCATION_USER,
                ]}
                toText={el => stockLocationRoleToString(el)}
                onChange={checked =>
                  setTaskSettingsInput(taskSettingsInput.withStockLocationRolesForInitialCount(checked))
                }
              />
            </Grid>
            <Grid item xs={6} mt={1}>
              <p className='font-semibold text-sm'>{t().companyLevel.singular.label}</p>
              <Selector
                showSearch={false}
                checkedValues={taskSettingsInput.companyRolesForInitialCount}
                values={[CompanyRole.administrator]}
                disabledValues={[CompanyRole.employee]}
                toText={el => companyRoleToString(el)}
                onChange={checked => setTaskSettingsInput(taskSettingsInput.withCompanyRolesForInitialCount(checked))}
              />
            </Grid>

            {taskSettingsInput.discrepancyThreshold > 0 ? (
              <>
                <Grid item xs={12} mt={1}>
                  <p>{t().rolesForRecount.singular.label}</p>
                </Grid>
                <Grid item xs={6} mt={1} pr={1}>
                  <Selector
                    showSearch={false}
                    checkedValues={taskSettingsInput.stockLocationRolesForRecount}
                    values={[
                      StockLocationRole.STOCK_LOCATION_MANAGER,
                      StockLocationRole.STOCK_LOCATION_SUPERVISOR,
                      StockLocationRole.STOCK_LOCATION_USER,
                    ]}
                    disabled={el => !taskSettingsInput.companyRolesForRecount.includes(CompanyRole.employee)}
                    toText={el => stockLocationRoleToString(el)}
                    onChange={value => setTaskSettingsInput(taskSettingsInput.withStockLocationRolesForRecount(value))}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <Selector
                    showSearch={false}
                    checkedValues={taskSettingsInput.companyRolesForRecount}
                    values={[...Object.values(CompanyRole)]}
                    toText={el => companyRoleToString(el)}
                    onChange={checked => {
                      if (!checked.includes(CompanyRole.employee))
                        setTaskSettingsInput(taskSettingsInput.withStockLocationRolesForRecount([]));
                      setTaskSettingsInput(taskSettingsInput.withCompanyRolesForRecount(checked));
                    }}
                  />
                </Grid>

                <Grid item xs={12} mt={1}>
                  <Checkbox
                    label={
                      'The user doing a recount should always be different from the user that did the initial count.'
                    }
                    onChange={value => setTaskSettingsInput(taskSettingsInput.withRequireUniqueRecountUser(value))}
                    value={taskSettingsInput.requireUniqueRecountUser}
                  />
                </Grid>

                <Grid item xs={12} mt={1} display={'flex'} alignItems={'start'}>
                  <Checkbox
                    label={'When a recount is triggered also assign the users that were assigned to the initial task.'}
                    onChange={value =>
                      setTaskSettingsInput(taskSettingsInput.withAssignInitialAssigneesToRecount(value))
                    }
                    value={taskSettingsInput.assignInitialAssigneesToRecount}
                    tooltip={
                      <Grid container>
                        <Grid item xs={12}>
                          <p>
                            {'Activating this setting will cause the system to also assign the recount task to users that were assigned to the inial count and ' +
                              'have a valid company- and/or stock location role.'}
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <p>{'This will also make assigning a fixed user for the recount optional.'}</p>
                        </Grid>

                        <Grid item xs={12}>
                          <p>{'If the setting above this one is activated it will have following effects:'}</p>
                          <ul className={'list-disc'}>
                            <li>
                              {'The users selected for the recount will be chosen from the users that did not count any item or bin with the discrepancies ' +
                                'for which the recount task was made. If no eligible users were found the system will reassign the recount task to all users ' +
                                'who were assigned to the intial task.'}
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} my={1}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <p className='underline'>{t().taskNumbers.singular.label}</p>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    dynamicUpdate
                    value={taskSettingsInput.prefix}
                    label={t().prefix.singular.label}
                    placeholder={t().prefix.singular.label}
                    onChange={v => setTaskSettingsInput(taskSettingsInput.withPrefix(v))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    dynamicUpdate
                    value={taskSettingsInput.suffix}
                    label={t().suffix.singular.label}
                    placeholder={t().suffix.singular.label}
                    onChange={v => setTaskSettingsInput(taskSettingsInput.withSuffix(v))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    type='number'
                    mandatory
                    dynamicUpdate
                    value={taskSettingsInput.digits?.toString() || ''}
                    label={t().nrOfDigits.singular.label}
                    placeholder={t().nrOfDigits.singular.label}
                    min={3}
                    onChange={v => setTaskSettingsInput(taskSettingsInput.withDigits(v))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <Button
              disabled={createLoading || updateLoading}
              onClick={() => navigate('/tasks')}
              text={t().back.singular.label}
            />
          </Grid>
          <Grid item>
            <Button
              style='secondary'
              onClick={handleSave}
              loading={createLoading || updateLoading}
              text={t().save.singular.label}
            />
          </Grid>
        </Grid>
      }
    />
  );
}
