import React from 'react';
import { Company } from '../types/company';

export interface CompanyContextProps {
  currentCompany: Company;
  setCurrentCompany: (company: Company) => void;
  companies: Map<string, Company>;
  setCompanies: (companies: Map<string, Company>) => void;
  refreshCompany: (companyId: string) => Promise<void>;
}

const defaultContext: CompanyContextProps = {
  currentCompany: new Company({ companyId: '' }),
  setCurrentCompany: company => {},
  companies: new Map(),
  setCompanies: () => {},
  refreshCompany: async () => {},
};

export const CompanyContext = React.createContext<CompanyContextProps>(defaultContext);
