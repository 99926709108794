import React from 'react';

interface ModalBackdropProps {
  children: React.ReactNode;
}

export default function ModalBackdrop({ children }: ModalBackdropProps) {
  return (
    <div
      className={`z-40 fixed top-0 left-0 w-[100vw] h-[100vh] content-center backdrop-blur-md`}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
    >
      {children}
    </div>
  );
}
