import React from 'react';
import { ProductMasterDataExportConfiguration } from '../types/productMasterDataExportConfiguration';

export interface ProductMasterDataExportConfigurationContextProps {
  productMasterDataExportConfigurations: Map<string, ProductMasterDataExportConfiguration>;
  setProductMasterDataExportConfigurations: (
    productMasterDataExportConfigurations: Map<string, ProductMasterDataExportConfiguration>,
  ) => void;
  productMasterDataExportConfigurationsLoading: boolean;
}

const defaultContext: ProductMasterDataExportConfigurationContextProps = {
  productMasterDataExportConfigurations: new Map<string, ProductMasterDataExportConfiguration>(),
  setProductMasterDataExportConfigurations: () => {},
  productMasterDataExportConfigurationsLoading: false,
};

export const ProductMasterDataExportConfigurationContext =
  React.createContext<ProductMasterDataExportConfigurationContextProps>(defaultContext);
