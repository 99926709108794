import { useContext, useMemo, useState } from 'react';
import { ReorderUserRuleContext } from '../../../../../context/ReorderUserRuleContext';
import React from 'react';
import { ReorderUserRule, ReorderUserRuleEntityType } from '../../../../../types/reorderUserRule';
import { ProductMasterData } from '../../../../../types/productMasterData';
import { StockLocation } from '../../../../../types/stockLocation';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { t } from '../../../../../types/translation/Translator';
import DeleteReorderUserRuleModal from './Modals/DeleteReorderUserRuleModal';
import { CompanyRole, companyRoleToString } from '../../../../../types/companyRoleAssignment';
import { StockLocationRole, stockLocationRoleToString } from '../../../../../types/stockLocationRoleAssignment';
import UpsertReorderUserRuleModal from './Modals/UpsertReorderUserRuleModal';
import { StockLocationRoleAssignmentContext } from '../../../../../context/StockLocationRoleAssignmentContext';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { CompanyRoleAssignmentContext } from '../../../../../context/CompanyRoleAssignmentContext';

import SearchBarWithFilter from '../../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { ReorderUserRuleFilter } from '../../../../../VentoryUI/components/filters/Filter/ReorderUserRuleFilter';
import { FilterEntity } from '../../../../../VentoryUI/components/filters/filter.util';
import { DeleteButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { AddButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/AddButton';
import { FlexPane } from '../../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table, { TableHeader } from '../../../../../VentoryUI/components/common/Table/Table';

interface OrderReorderUsersPaneProps {
  setError: (error: string) => void;
}

const getHeaders = (
  productMasterData: Map<string, ProductMasterData>,
  stockLocations: Map<string, StockLocation>,
): TableHeader<ReorderUserRuleGroup>[] => [
  {
    key: 'name',
    name: 'Name',
    text: item => item.rules[0].name,
  },
  {
    key: 'products',
    name: 'Products',
    text: (item: ReorderUserRuleGroup) => {
      if (!item.rules[0].productMasterDataId) return 'Any product';
      if (item.rules.length === 1) {
        return productMasterData.get(item.rules[0].productMasterDataId!)?.productName || 'Unknown Product';
      }
      return new Set(item.rules.map(rule => rule.productMasterDataId)).size.toString();
    },
  },
  {
    key: 'stockLocation',
    name: 'Stock Location',
    text: (item: ReorderUserRuleGroup) => {
      if (!item.rules[0].stockLocationId) return 'Any location';

      if (item.rules.length === 1) {
        return `In ${stockLocations.get(item.rules[0].stockLocationId)?.name || 'Unknown Location'}`;
      }

      return new Set(item.rules.map(rule => rule.stockLocationId)).size.toString();
    },
  },

  {
    key: 'replenishOrderStockLocationRoles',
    name: 'Roles for replenishment',
    text: (item: ReorderUserRuleGroup) => {
      if (
        item.rules[0].replenishOrderCompanyRoles.length === 1 &&
        item.rules[0].replenishOrderCompanyRoles[0] === CompanyRole.administrator
      ) {
        return companyRoleToString(item.rules[0].replenishOrderCompanyRoles[0]);
      } else if (item.rules[0].replenishOrderStockLocationRoles.length === 1) {
        return stockLocationRoleToString(item.rules[0].replenishOrderStockLocationRoles[0]);
      } else {
        return item.rules[0].replenishOrderStockLocationRoles.length.toString();
      }
    },
  },
  {
    key: 'replenishOrderUsers',
    name: 'Users for replenishment',
    text: (item: ReorderUserRuleGroup) => {
      return item.rules[0].replenishOrderUserIds.length.toString();
    },
  },
];

export interface ReorderUserRuleGroup {
  groupId: string;
  rules: ReorderUserRule[];
}

export default function OrderReorderUsersPane({ setError }: OrderReorderUsersPaneProps) {
  const { groupedReorderUserRules, reorderUserRulesLoading } = useContext(ReorderUserRuleContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { currentCompany } = useContext(CompanyContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);

  const [upsertModalProps, setUpsertModalProps] = useState<{ groupId?: string; open: boolean }>({
    groupId: undefined,
    open: false,
  });
  function setUpsertModalOpen(open: boolean) {
    setUpsertModalProps({ ...upsertModalProps, open: open });
  }
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const filteredRules = useMemo(() => {
    const groups: ReorderUserRuleGroup[] = [];

    for (const [groupId, rules] of groupedReorderUserRules) {
      if (
        rules[0].entityType === ReorderUserRuleEntityType.productMasterData &&
        (hasCompanyRole(currentCompany.id, CompanyRole.administrator) ||
          !rules.some(
            rule =>
              rule.stockLocationId &&
              !hasStockLocationRole(rule.companyId, rule.stockLocationId, StockLocationRole.STOCK_LOCATION_MANAGER),
          ))
      ) {
        groups.push({ groupId, rules });
      }
    }

    return groups;
  }, [groupedReorderUserRules, reorderUserRulesLoading]);

  const [items, setItems] = useState(filteredRules);
  const [selected, setSelected] = useState<Set<string>>(new Set());

  const buttons = useMemo(() => {
    const shown = [];

    if (selected.size) {
      shown.push(DeleteButtonTemplate(() => setDeleteModalOpen(true)));
    }

    shown.push(AddButtonTemplate(() => setUpsertModalProps({ groupId: undefined, open: true })));

    return shown;
  }, [selected]);

  return (
    <>
      <UpsertReorderUserRuleModal
        open={upsertModalProps.open}
        setOpen={setUpsertModalOpen}
        groupId={upsertModalProps.groupId}
      />
      <DeleteReorderUserRuleModal open={deleteModalOpen} setOpen={setDeleteModalOpen} groupIds={[...selected]} />

      <>
        <FlexPane
          header={
            <SearchBarWithFilter
              items={filteredRules}
              entity={FilterEntity.REORDER_USER_RULE}
              setItems={setItems}
              onFilter={ReorderUserRuleFilter.search}
              placeholder={t().filterReorderRules.singular.label}
              buttons={buttons}
            />
          }
          content={
            <Table<ReorderUserRuleGroup>
              loading={reorderUserRulesLoading}
              items={items}
              onSelected={checked => setSelected(checked)}
              headers={getHeaders(productMasterData, stockLocations)}
              onClick={clicked => setUpsertModalProps({ groupId: clicked.groupId, open: true })}
              uniqueIdentifier='groupId'
            />
          }
        />
      </>
    </>
  );
}
