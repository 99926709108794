import { ShippingLocation } from '../../../../types/contact';
import { toFilterString } from '../../../../util/string.util';
import { EntityFilter } from '../filter.util';
import { AddressFilter } from './AddressFilter';

export namespace ShippingLocationFilter {
  export function search(item: ShippingLocation, textFilter: string) {
    if (
      toFilterString(item.name).includes(textFilter) ||
      (item.address && AddressFilter.search(item.address, textFilter)) ||
      toFilterString(item.email).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: ShippingLocation[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      return onFilter(item, textFilter);
    });

    return result;
  }
}
