import { cloneDeep } from '@apollo/client/utilities';
import { Translations } from './common';
import { CustomTranslation } from './customTranslation';
import { en } from './languages/en';
import { Translation, TranslationLanguage } from './translation';
import { TranslationKey } from './TranslationKey';

class Translator {
  readonly translations!: Translations;

  private constructor(translations: Translations) {
    this.translations = translations;
  }

  static fromTranslation(translation: Translation) {
    return new Translator(cloneDeep(translation.translations));
  }

  merge(translations: Translations) {
    for (const key of Object.keys(translations)) {
      this.translations[key as TranslationKey] = translations[key as TranslationKey];
    }
  }

  mergeLanguage(translation: Translation) {
    this.merge(translation.translations);
  }

  mergeCustom(customTranslation: CustomTranslation) {
    this.merge(customTranslation.translations);
  }
}

const englishTranslation = new Translation(TranslationLanguage.en, JSON.stringify(en));
export const english = Translator.fromTranslation(englishTranslation);

class TranslationManager {
  private translator: Translator = cloneDeep(english);

  private language: TranslationLanguage = TranslationLanguage.en;

  private get current() {
    return this.translations.get(this.language);
  }

  translations: Map<TranslationLanguage, Translation> = new Map([
    [TranslationLanguage.en, cloneDeep(englishTranslation)],
  ]);
  customTranslations: Map<string, CustomTranslation> = new Map();

  setTranslation(translation: Translation, options?: { update: boolean }) {
    if (translation.language === TranslationLanguage.en) english.mergeLanguage(translation);
    this.language = translation.language;
    this.translations.set(translation.language, translation);
    if (options?.update) this.translator.mergeLanguage(translation);
  }

  setCustomTranslation(customTranslation: CustomTranslation, options?: { merge: boolean }) {
    this.customTranslations.set(customTranslation.id, customTranslation);
    if (options?.merge) {
      if (this.current) {
        this.translator.mergeLanguage(this.current);
      }
      this.translator.mergeCustom(customTranslation);
    }
  }

  get(language?: TranslationLanguage | undefined) {
    if (language) {
      const translations = this.translations.get(language)?.translations;
      if (translations) return translations;
    }

    return this.translator.translations;
  }
}

export const translationsManager = new TranslationManager();

export function t(language?: TranslationLanguage | undefined): Translations {
  return translationsManager.get(language);
}
