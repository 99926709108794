import React from 'react';
import { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function StockLocationFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { stockLocations } = useContext(StockLocationContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.stockLocation || [])]}
      text={item => stockLocations.get(item)?.name || t().unknownStockLocation.singular.label}
      onRemove={i => {
        filter.stockLocation?.delete(i);
        if (!filter.stockLocation?.size) filter.stockLocation = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
