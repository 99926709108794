import React, { useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { ProductTransaction } from '../../../../types/productTransaction';
import ProcessOrderTransactionInfoPane from './Panes/ProcessOrderTransactionInfoPane';
import { testIds } from '../../../../util/identifiers/identifiers.util';

import Modal from '../../../../VentoryUI/components/common/Modal/Modal';

interface ProcessOrderTransactionModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  transaction: ProductTransaction;
  disabled: boolean;
}

enum ProcessOrderTransactionState {
  info = 'info',
}

export default function ProcessOrderTransactionModal({
  open,
  setOpen,
  transaction,
  disabled = false,
}: ProcessOrderTransactionModalInputProps) {
  const [transactionInput, setTransactionInput] = useState<ProductTransaction>(new ProductTransaction(transaction));
  const [currentPane, setCurrentPane] = useState<ProcessOrderTransactionState>(ProcessOrderTransactionState.info);
  const [error, setError] = useState<string>('');

  const handleClose = () => {
    setCurrentPane(ProcessOrderTransactionState.info);
    setOpen(false);
  };

  const content = () => {
    switch (currentPane) {
      case ProcessOrderTransactionState.info:
        return (
          <ProcessOrderTransactionInfoPane
            disabled={disabled}
            transaction={transactionInput}
            setTransaction={setTransactionInput}
            setError={setError}
            onClose={handleClose}
          />
        );
    }
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      height='550px'
      title={t().processTransaction.singular.label}
      testId={testIds.processOrderTransactionModal}
    >
      {content()}
    </Modal>
  );
}
