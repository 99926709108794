import { useMutation } from '@apollo/client';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ProductTransactionExportConfigurationContext } from '../../../../context/ProductTransactionExportConfigurationContext';
import {
  CsvExportFile,
  ReportMutations,
  ReportProductTransactionResponse,
  ReportProductTransactionVariables,
} from '../../../../graphql/report.graphql';
import ProductTransactionExportConfigurationItem from '../Settings/Panes/Export/ProductTransactionExportConfigurationItem';
import { Grid } from '@mui/material';
import { zipAndDownloadExportFiles } from '../../../../util/export.util';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { CancelButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';

interface ProductTransactionExportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  transactionIds: Set<string>;
}

export function ProductTransactionExportModal({ open, setOpen, transactionIds }: ProductTransactionExportModalProps) {
  const { productTransactionExportConfigurations, productTransactionExportConfigurationsLoading } = useContext(
    ProductTransactionExportConfigurationContext,
  );
  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');
  const [selected, setSelected] = useState<Set<string>>(
    new Set(
      [...productTransactionExportConfigurations.values()].filter(config => config.default).map(config => config.id),
    ),
  );

  useEffect(() => {
    if (!productTransactionExportConfigurationsLoading)
      setSelected(
        new Set(
          [...productTransactionExportConfigurations.values()]
            .filter(config => config.default)
            .map(config => config.id),
        ),
      );
  }, [productTransactionExportConfigurationsLoading]);

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const [download, { loading: downloadLoading }] = useMutation<
    ReportProductTransactionResponse,
    ReportProductTransactionVariables
  >(ReportMutations.productTransaction);

  const handleDownload = async () => {
    const files: CsvExportFile[] = [];
    let hasNext = true;
    let page = 0;

    while (hasNext) {
      const response = await download({
        variables: {
          page: page,
          transactionIds: [...transactionIds.keys()],
          timezone: moment.tz.guess(),
          locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
          companyId: currentCompany.id,
          configurationIds: [...selected.values()],
        },
      });
      files.push(...(response.data?.generateProductTransactionExport.data || []));

      hasNext = response.data?.generateProductTransactionExport.hasNext || false;
      page++;
    }

    if (!files.length) return console.error('No files to download');
    zipAndDownloadExportFiles(
      files,
      productTransactionExportConfigurations,
      `ventory_product_transactions_${new Date().toISOString()}.zip`,
    );

    setSelected(new Set());
    handleClose();
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().exportProductTransactions.singular.upper}>
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: downloadLoading }),
          {
            onClick: handleDownload,
            text: t().export.singular.label,
            loading: downloadLoading,
            disabled: !selected.size,
            style: 'secondary',
          },
        ]}
      >
        <Grid container alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <p className='text-sm font-semibold'>
                  {'Please select the configuration(s) you would like to use to export the product transactions'}
                </p>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Grid container maxHeight={'500px'} overflow={'auto'}>
                  {[...productTransactionExportConfigurations.values()].map(config => (
                    <Grid key={config.id} item>
                      <ProductTransactionExportConfigurationItem
                        onClick={() => {
                          selected.has(config.id) ? selected.delete(config.id) : selected.add(config.id);
                          setSelected(new Set(selected));
                        }}
                        configuration={config}
                        key={config.id}
                        ids={selected}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
