import React from 'react';
import { Bin } from '../types/bin';

export interface BinContextProps {
  bins: Map<string, Bin>;
  setBins: (bins: Map<string, Bin>) => void;
  binsLoading: boolean;
}

const defaultContext: BinContextProps = {
  bins: new Map(),
  setBins: () => {},
  binsLoading: false,
};

export const BinContext = React.createContext<BinContextProps>(defaultContext);
