import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateReorderUserRuleInput,
  DeleteReorderUserRuleInput,
  ReorderUserRule,
  UpdateReorderUserRuleInput,
} from '../types/reorderUserRule';

const reorderUserRule = gql`
  fragment ReorderUserRule on ReorderUserRule {
    id
    version
    groupId
    companyId
    stockLocationId
    productMasterDataId
    replenishOrderCompanyRoles
    replenishOrderStockLocationRoles
    replenishOrderUserIds
    name
    entityType
  }
`;

const get = gql`
  query reorderUserRules($companyId: String!, $page: Float) {
    reorderUserRules(companyId: $companyId, page: $page) {
      data {
        ...ReorderUserRule
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${reorderUserRule}
`;

export interface GetReorderUserRulesVariables {
  companyId: string;
  page?: number;
}

export interface GetReorderUserRulesResponse {
  reorderUserRules: PaginatedReorderUserRules;
}

class PaginatedReorderUserRules extends forPaginated<ReorderUserRule>() {}

const update = gql`
  mutation UpdateReorderUserRule(
    $toCreate: [CreateReorderUserRuleInput!]!
    $toUpdate: [UpdateReorderUserRuleInput!]!
    $toDelete: [DeleteReorderUserRuleInput!]!
  ) {
    updateReorderUserRule(toCreate: $toCreate, toUpdate: $toUpdate, toDelete: $toDelete) {
      created {
        ...ReorderUserRule
      }
      updated {
        ...ReorderUserRule
      }
      deleted {
        ...ReorderUserRule
      }
    }
  }
  ${reorderUserRule}
`;

export interface UpdateReorderUserRuleVariables {
  toCreate: CreateReorderUserRuleInput[];
  toUpdate: UpdateReorderUserRuleInput[];
  toDelete: DeleteReorderUserRuleInput[];
}

export interface UpdateReorderUserRuleResponse {
  updateReorderUserRule: {
    created: ReorderUserRule[];
    updated: ReorderUserRule[];
    deleted: ReorderUserRule[];
  };
}

const remove = gql`
  mutation DeleteReorderUserRule($reorderUserRules: [DeleteReorderUserRuleInput!]!) {
    deleteReorderUserRule(reorderUserRules: $reorderUserRules) {
      ...ReorderUserRule
    }
  }
  ${reorderUserRule}
`;

export interface DeleteReorderUserRuleVariables {
  reorderUserRules: DeleteReorderUserRuleInput[];
}

export interface DeleteReorderUserRuleResponse {
  deleteReorderUserRule: ReorderUserRule[];
}

const create = gql`
  mutation CreateReorderUserRule($reorderUserRules: [CreateReorderUserRuleInput!]!) {
    createReorderUserRule(reorderUserRules: $reorderUserRules) {
      ...ReorderUserRule
    }
  }
  ${reorderUserRule}
`;

export interface CreateReorderUserRuleVariables {
  reorderUserRules: CreateReorderUserRuleInput[];
}

export interface CreateReorderUserRuleResponse {
  createReorderUserRule: ReorderUserRule[];
}

export const ReorderUserRuleMutations = {
  update,
  remove,
  create,
};

export const ReorderUserRuleQueries = {
  get,
};

export const ReorderUserRuleFragments = {
  reorderUserRule,
};
