import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function ArrowLeftIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.6666 6.00004H1.33325M1.33325 6.00004L5.99992 10.6667M1.33325 6.00004L5.99992 1.33337'
          stroke='#667085'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
