import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

enum BinStatusValidationError {
  status = 'Please enter a status',
}

export class BinStatus extends CompanyEntity {
  status!: string;
  color: string = '#A3A3A3';
  inboundAllowed?: boolean = false;
  outboundAllowed?: boolean = false;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateBinStatusInput {
    return UpdateBinStatusInput.from(this, UpdateBinStatusInput);
  }

  override forDelete(): DeleteBinStatusInput {
    return DeleteBinStatusInput.from(this, DeleteBinStatusInput);
  }

  override validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof BinStatus)[], (field: keyof BinStatus) => {
      if (field === 'status' && !this.status) throw BinStatusValidationError.status;
      return null;
    });
  }

  withStatus(status: string) {
    this.status = status;
    return this;
  }

  withColor(color: string) {
    this.color = color;
    return cloneDeep(this);
  }

  withInboundAllowed(inboundAllowed: boolean) {
    this.inboundAllowed = inboundAllowed;
    return this;
  }

  withOutboundAllowed(outboundAllowed: boolean) {
    this.outboundAllowed = outboundAllowed;
    return this;
  }
}

export class CreateBinStatusInput extends forCreate(BinStatus) {}

export class UpdateBinStatusInput extends forUpdate(BinStatus) {}

export class DeleteBinStatusInput extends forDelete(BinStatus) {}
