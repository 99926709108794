import React, { useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import CloseIcon from '../../../../icons/Close/CloseIcon';
import FileViewLoader from '../FileViewLoader';
import ImagePickerModal from './ImagePickerModal';

interface ImagePickerProps {
  ids: string[];
  onSelected: (selected: string) => void;
  onRemove?: () => void;
  fileId?: string;
  height?: string;
  width?: string;
}

export default function ImagePicker({
  ids,
  fileId,
  height = '180px',
  width = '300px',
  onSelected,
  onRemove,
}: ImagePickerProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ImagePickerModal ids={ids} open={open} setOpen={setOpen} onSelected={onSelected} />

      <div className='border-2 border-ventory-grey-300 rounded select-none border-dashed relative p-6'>
        {fileId ? (
          <div style={{ width, height }} className='flex items-center justify-center'>
            <>
              <CloseIcon className='text-red-400 absolute top-1 right-1 cursor-pointer' onClick={onRemove} />
              <FileViewLoader fileId={fileId} />
            </>
          </div>
        ) : (
          <div
            onClick={() => setOpen(true)}
            style={{ width, height }}
            className='flex flex-col text-center items-center justify-center cursor-pointer text-[14px] text-ventory-grey-900'
          >
            <p>{t().clickToSelectAndDisplayProductImage.singular.upper}</p>
            <p className='text-ventory-grey-500 text-normal'>
              {t().uploadProductImagesInTheFilesAndAssetsSection.singular.upper}
            </p>
          </div>
        )}
      </div>
    </>
  );
}
