import { gql } from '@apollo/client';
import { IntegrationSettings } from '../types/integrationSettings';

const integrationSettings = gql`
  fragment IntegrationSettingsWithRelation on IntegrationSettingsWithRelation {
    id
    version
    companyId
    name
    type
    externalId
    apiKey
    url
    tenantId
    environment
    clientId
    clientSecret
  }
`;

const get = gql`
  query IntegrationSettings($companyId: String!) {
    integrationSettings(companyId: $companyId) {
      ...IntegrationSettingsWithRelation
    }
  }
  ${integrationSettings}
`;

export interface GetIntegrationSettingsVariables {
  companyId: string;
}

export interface GetIntegrationSettingsResponse {
  integrationSettings: IntegrationSettings[];
}

const createSAP = gql`
  mutation CreateSAPIntegration(
    $companyId: String!
    $name: String!
    $externalId: String!
    $apiKey: String!
    $url: String!
  ) {
    createSAPIntegration(companyId: $companyId, name: $name, externalId: $externalId, apiKey: $apiKey, url: $url) {
      ...IntegrationSettingsWithRelation
    }
  }
  ${integrationSettings}
`;

export interface CreateSAPIntegrationVariables {
  companyId: string;
  name: string;
  externalId: string;
  apiKey: string;
  url: string;
}

export interface CreateSAPIntegrationResponse {
  createSAPIntegration: IntegrationSettings;
}

const createBusinessCentral = gql`
  mutation CreateBusinessCentralIntegration(
    $companyId: String!
    $name: String!
    $tenantId: String!
    $environment: String!
    $clientId: String!
    $clientSecret: String!
    $externalId: String!
  ) {
    createBusinessCentralIntegration(
      companyId: $companyId
      name: $name
      tenantId: $tenantId
      environment: $environment
      clientId: $clientId
      clientSecret: $clientSecret
      externalId: $externalId
    ) {
      ...IntegrationSettingsWithRelation
    }
  }
  ${integrationSettings}
`;

export interface CreateBusinessCentralIntegrationVariables {
  companyId: string;
  name: string;
  tenantId: string;
  environment: string;
  clientId: string;
  clientSecret: string;
  externalId: string;
}

export interface CreateBusinessCentralIntegrationResponse {
  createBusinessCentralIntegration: IntegrationSettings;
}

const getBusinessCentralCompanies = gql`
  query BusinessCentralCompanies(
    $companyId: String!
    $tenantId: String!
    $environment: String!
    $clientId: String!
    $clientSecret: String!
  ) {
    businessCentralCompanies(
      companyId: $companyId
      tenantId: $tenantId
      environment: $environment
      clientId: $clientId
      clientSecret: $clientSecret
    ) {
      name
      displayName
      businessProfileId
      externalId
    }
  }
`;

export interface GetBusinessCentralCompaniesVariables {
  companyId: string;
  tenantId: string;
  environment: string;
  clientId: string;
  clientSecret: string;
}

export interface GetBusinessCentralCompaniesResponse {
  businessCentralCompanies: {
    name: string;
    displayName: string;
    businessProfileId: string;
    externalId: string;
  }[];
}

export const IntegrationSettingsQueries = {
  get,
  getBusinessCentralCompanies,
};

export const IntegrationSettingsMutations = {
  createSAP,
  createBusinessCentral,
};
