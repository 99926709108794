import { ApolloError } from '@apollo/client';
import { ProductTransactionReasonForFailure } from './errors/transactionError.util';

export enum ErrorType {
  userAlreadyExists = 'USER_ALREADY_EXISTS',
  unknown = 'UNKNOWN',
  authorizationCodeRequired = 'VENTORY_AUTHORIZATION_CODE_REQUIRED',
}

export function getErrorCode(error: ApolloError) {
  if (!error.graphQLErrors.length) return;

  const e = error.graphQLErrors[0];
  if (!e.extensions) return;
  return e.extensions.code;
}

export function errorMsg(error: ApolloError | undefined): string | null {
  if (!error) return null;
  if (!error.graphQLErrors.length) return error.message;
  const actualError = error.graphQLErrors[0];
  if (!actualError.extensions) return error.message;
  const code = actualError.extensions.code;
  switch (code) {
    case ErrorType.userAlreadyExists:
      return 'A user with this email already exists'; // TODO i8n
    default:
      return 'An unsupported errror occurred';
  }
}

export function errorToText(error: ApolloError) {
  const message = error.message;
  if (error.message === 'Failed to fetch') return 'Unable to connect to server';
  if (error.message === 'invalid_credentials') return 'Invalid credentials';

  return message;
}

export function errorCodeToText(code: string) {
  switch (code) {
    case ProductTransactionReasonForFailure.product_master_data_not_serial_managed:
      return 'Product Reference Data is not serial managed'; // PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_SERIAL_MANAGED
    case ProductTransactionReasonForFailure.product_master_data_not_lpn_managed:
      return 'Product Reference Data is not LPN managed'; //'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_LPN_MANAGED'
    case ProductTransactionReasonForFailure.product_master_data_not_lot_managed:
      return 'Product Reference Data is not lot Managed'; //'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_LOT_MANAGED'
    case ProductTransactionReasonForFailure.bin_no_outbound_allowed:
      return 'Selected bin does not allow outbound actions'; //'PRODUCT_TRANSACTION_NO_OUTBOUND_ALLOWED'
    case ProductTransactionReasonForFailure.bin_no_inbound_allowed:
      return 'Selected bin does not allow inbound actions'; //'PRODUCT_TRANSACTION_NO_INBOUND_ALLOWED'
    case ProductTransactionReasonForFailure.quantity_negative:
      return 'Quantity can not be negative'; //'PRODUCT_TRANSACTION_QUANTITY_NEGATIVE'
    case ProductTransactionReasonForFailure.quantity_zero:
      return 'Quantity can not be zero'; //'PRODUCT_TRANSACTION_QUANTITY_ZERO'
    case ProductTransactionReasonForFailure.quantity_serial:
      return 'Quantity for a serialized product should be 1'; //'PRODUCT_TRANSACTION_QUANTITY_SERIAL'
    case ProductTransactionReasonForFailure.quantity_lpn:
      return 'Quantity for a LPN product should be 1'; //'PRODUCT_TRANSACTION_QUANTITY_LPN'
    case ProductTransactionReasonForFailure.interal_error:
      return 'Internal Error'; //'PRODUCT_TRANSACTION_INTERNAL_ERROR'
    case ProductTransactionReasonForFailure.manual_rollback:
      return 'Manual Rollback'; //'PRODUCT_TRANSACTION_MANUAL_ROLLBACK'
    case ProductTransactionReasonForFailure.multiple_products_found:
      return 'Multiple products found'; //'PRODUCT_TRANSACTION_MULTIPLE_PRODUCTS_FOUND'
    case ProductTransactionReasonForFailure.processed_quantity_to_big:
      return 'The processed quantity is too big'; //'PRODUCT_TRANSACTION_PROCESSED_QUANTITY_TO_BIG'
    case ProductTransactionReasonForFailure.product_master_data_id_missing:
      return 'Product Reference Id is missing'; //'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_ID_MISSING'
    case ProductTransactionReasonForFailure.product_master_data_not_found:
      return 'Product Reference Item was not found'; //'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_FOUND'
    case ProductTransactionReasonForFailure.product_not_found:
      return 'Product was not found'; //'PRODUCT_TRANSACTION_PRODUCT_NOT_FOUND'
    case ProductTransactionReasonForFailure.product_master_data_not_defined:
      return 'Product Reference Data was not defined'; //'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_DEFINED'
    case ProductTransactionReasonForFailure.to_bin_not_defined:
      return 'Destination bin was not defined'; //'PRODUCT_TRANSACTION_TO_BIN_NOT_DEFINED'
    case ProductTransactionReasonForFailure.from_bin_not_defined:
      return 'Source bin was not defined'; //'PRODUCT_TRANSACTION_FROM_BIN_NOT_DEFINED'
    case ProductTransactionReasonForFailure.to_stock_location_not_defined:
      return 'Destination stock location was not defined'; //'PRODUCT_TRANSACTION_TO_STOCK_LOCATION_NOT_DEFINED'
    case ProductTransactionReasonForFailure.from_stock_location_not_defined:
      return 'Source stock location was not defined'; //'PRODUCT_TRANSACTION_FROM_STOCK_LOCATION_NOT_DEFINED'
    case ProductTransactionReasonForFailure.serial_already_exists:
      return 'Product with this serial already exsists'; //'PRODUCT_TRANSACTION_PRODUCT_WITH_SERIAL_ALREADY_EXISTS'
    case ProductTransactionReasonForFailure.lpn_already_exists:
      return 'Product with this LPN already exists'; //'PRODUCT_TRANSACTION_PRODUCT_WITH_LPN_ALREADY_EXISTS'
    case ProductTransactionReasonForFailure.to_bin_not_found:
      return 'Destination bin not found'; //'PRODUCT_TRANSACTION_TO_BIN_NOT_FOUND'
    case ProductTransactionReasonForFailure.from_bin_not_found:
      return 'Source bin not found'; //'PRODUCT_TRANSACTION_FROM_BIN_NOT_FOUND'
    case ProductTransactionReasonForFailure.to_container_not_found:
      return 'Destination container not found'; //'PRODUCT_TRANSACTION_TO_CONTAINER_NOT_FOUND'
    case ProductTransactionReasonForFailure.from_container_not_found:
      return 'Source container not found'; //'PRODUCT_TRANSACTION_FROM_CONTAINER_NOT_FOUND'
    case ProductTransactionReasonForFailure.product_serial_not_found:
      return 'Product with serial was not found'; //'PRODUCT_TRANSACTION_PRODUCT_SERIAL_NOT_FOUND'
    case ProductTransactionReasonForFailure.product_lpn_not_found:
      return 'Product with LPN was not found'; //'PRODUCT_TRANSACTION_PRODUCT_LPN_NOT_FOUND'
    case ProductTransactionReasonForFailure.serial_missing:
      return 'Serial is missing'; //'PRODUCT_TRANSACTION_PRODUCT_SERIAL_MISSING'
    case ProductTransactionReasonForFailure.lpn_missing:
      return 'LPN is missing'; //'PRODUCT_TRANSACTION_PRODUCT_LPN_MISSING'
    case ProductTransactionReasonForFailure.lot_missing:
      return 'Lot is missing'; //'PRODUCT_TRANSACTION_PRODUCT_LOT_MISSING'
  }
  return code;
}
