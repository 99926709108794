import { ClickAwayListener, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { classes, TestIdIdentifier } from '../../util/identifiers/identifiers.util';

interface DropdownInputProps<T> {
  values: T[];
  selectedValue: T;
  toText: (item: T) => string;
  onChange: (item: T) => void;
  maxHeight?: string;
  label?: string;
  mandatory?: boolean;
  disabled?: boolean;
  placeholder?: string;
  testId?: TestIdIdentifier;
  dynamicUpdate?: boolean;
}

export default function Dropdown<T>({
  values,
  selectedValue,
  onChange,
  toText,
  maxHeight = '400px',
  label,
  mandatory = false,
  disabled = false,
  placeholder = '',
  testId,
  dynamicUpdate = false,
}: DropdownInputProps<T>) {
  const [value, setValue] = useState<T>(selectedValue);
  const [hidden, setHidden] = useState<boolean>(true);
  const [items, setItems] = useState<T[]>(values);
  const [keyOverIndex, setKeyOverIndex] = useState<number>(-1);

  useEffect(() => {
    setItems(values);
    setValue(selectedValue);
  }, [values, selectedValue]);

  const disabledClass = () => {
    if (disabled || items.length === 1) {
      return 'bg-gray-100 text-gray-400';
    }
    return 'focus:ring-1';
  };

  const handleChange = (item: T) => {
    setHidden(true);
    onChange(item);
    setValue(item);
    setKeyOverIndex(-1);
  };

  const handleKeyOverChange = (index: number) => {
    setKeyOverIndex(index);
    const element = document.querySelector(
      `#${testId?.name} .${classes.dropdownContentItem.name}:nth-child(${index + 1})`,
    );
    element?.scrollIntoView({ block: 'nearest' });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (disabled || items.length === 1) return;
    switch (event.key) {
      case 'Enter':
        if (hidden) return setHidden(false);
        if (keyOverIndex < 0 || keyOverIndex >= items.length) return;
        handleChange(items[keyOverIndex]);
        break;
      case 'Escape':
        if (!hidden) return setHidden(true);
      case 'ArrowDown':
        event.preventDefault();
        if (keyOverIndex >= items.length - 1) return handleKeyOverChange(0);
        return handleKeyOverChange(keyOverIndex + 1);
      case 'ArrowUp':
        event.preventDefault();
        if (keyOverIndex <= 0) return handleKeyOverChange(items.length - 1);
        return handleKeyOverChange(keyOverIndex - 1);
    }
  };

  const dropdownContent = () => {
    return (
      // The reason the default event of onMouseDown is prevented here is because this will prevent the onBlur (onFocusLost) which we want so the onClick goes through!
      <Grid container className={classes.dropdownContent.name} onMouseDown={event => event.preventDefault()}>
        <Grid container maxHeight={maxHeight} className='overflow-auto'>
          {items.map((item, index) => (
            <Grid
              item
              xs={12}
              key={`${item}${index}`}
              onClick={() => handleChange(item)}
              className={`${classes.dropdownContentItem.name} px-3 py-1 hover:backdrop-brightness-95 ${
                index === keyOverIndex ? 'backdrop-brightness-95' : ''
              }`}
            >
              <Grid container marginY={'auto'}>
                <Grid item marginY={'auto'}>
                  <p>{toText(item)}</p>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  if (items.length === 1 && !selectedValue) {
    onChange(items[0]);
  }

  return (
    <ClickAwayListener onClickAway={() => setHidden(true)}>
      <Grid
        container
        width={'100%'}
        className={`cursor-pointer ${classes.dropdown.name}`}
        id={testId?.name}
        data-testid={testId?.name}
      >
        <Grid item display='flex'>
          {label ? <p className='text-sm font-medium text-slate-800'>{label}</p> : null}
          {mandatory ? <p className='ml-1 text-sm font-medium text-red-500'>*</p> : null}
        </Grid>
        <Grid
          container
          onClick={() => (!disabled && items.length > 1 ? setHidden(!hidden) : null)}
          className={`mt-1 pl-3 pr-1 py-2  h-10 rounded disabled:opacity-50 border shadow-sm border-slate-300 placeholder-slate-400  block w-full sm:text-sm ${disabledClass()}`}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onBlur={event => setHidden(true)}
        >
          <Grid item flexGrow={1} my={'auto'}>
            <p className={`${classes.dropdownSelectedItem.name} select-none ${toText(value) ? '' : 'text-gray-400'}`}>
              {toText(value) ? toText(value) : placeholder}
            </p>
          </Grid>
          <Grid item textAlign={'end'}>
            <ArrowDropDownIcon
              style={{
                width: '18px',
                height: '18px',
              }}
            />
          </Grid>
        </Grid>
        {!hidden ? (
          <Grid item xs={12} className='relative inline-block w-full'>
            <Grid
              container
              p={2}
              pr={0.5}
              pl={0}
              className='mt-1 border rounded boder-t-gray-200 shadow-md cursor-pointer select-none absolute bg-white w-full z-10'
              alignContent={'flex-start'}
            >
              {dropdownContent()}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </ClickAwayListener>
  );
}
