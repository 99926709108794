import React from 'react';

interface ErrorBoxProps {
  error?: string;
}

export default function ErrorBox({ error }: ErrorBoxProps) {
  if (!error) return null;

  return (
    <div className='text-center mb-2'>
      <p className='border-red-400 border-2 rounded-sm bg-red-100 py-1 text-red-500 text-sm'>{error}</p>
    </div>
  );
}
