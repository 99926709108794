import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export enum ProductTransactionExportField {
  company = 'company',
  sourceStockLocationName = 'sourceStockLocationName',
  destinationStockLocationName = 'destinationStockLocationName',
  sourceBinName = 'sourceBinName',
  destinationBinName = 'destinationBinName',
  sourceContainerIdentifier = 'sourceContainerIdentifier',
  destinationContainerIdentifier = 'destinationContainerIdentifier',
  productName = 'productName',
  productNumber = 'productNumber',
  serialNumber = 'serialNumber',
  lpn = 'lpn',
  lotNumber = 'lotNumber',
  lotExpiryDate = 'lotExpiryDate',
  type = 'type',
  parentType = 'parentType',
  parentId = 'parentId',
  status = 'status',
  reasonForFailure = 'reasonForFailure',
  quantity = 'quantity',
  processedQuantity = 'processedQuantity',
  orderNumber = 'orderNumber',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  processedAt = 'processedAt',
  createdBy = 'createdBy',
  updatedBy = 'updatedBy',
  processedBy = 'processedBy',
  serialShippingContainerCode = 'serialShippingContainerCode',
  globalTradeItemNumber = 'globalTradeItemNumber',
  globalTradeItemNumberOfTradeItemsContained = 'globalTradeItemNumberOfTradeItemsContained',
  productionDate = 'productionDate',
  dueDate = 'dueDate',
  packagingDate = 'packagingDate',
  bestBeforeDate = 'bestBeforeDate',
  expirationDate = 'expirationDate',
  internalProductVariant = 'internalProductVariant',
  consumerProductVariant = 'consumerProductVariant',
  customerPartNumber = 'customerPartNumber',
  secondarySerialNumber = 'secondarySerialNumber',
  variableCountOfItems = 'variableCountOfItems',
  countOfTradeItemsContained = 'countOfTradeItemsContained',
  customerPurchaseOrderNumber = 'customerPurchaseOrderNumber',
  routingCode = 'routingCode',
  countryOfOrigin = 'countryOfOrigin',
  countryOfOriginOfTradeItem = 'countryOfOriginOfTradeItem',
  countryOfInitialProcessing = 'countryOfInitialProcessing',
  countryOfProcessing = 'countryOfProcessing',
  countryOfDisassembly = 'countryOfDisassembly',
  countryCoveringFullProcessChain = 'countryCoveringFullProcessChain',
  natoStockNumber = 'natoStockNumber',
  expirationDateAndTime = 'expirationDateAndTime',
  pricePerUnitOfMeasure = 'pricePerUnitOfMeasure',
  dateAndTimeOfProduction = 'dateAndTimeOfProduction',
}

export class ProductTransactionExportConfigurationCustomField {
  index: number;

  customField: string;

  constructor(customField: string, index: number) {
    this.index = index;
    this.customField = customField;
  }
}

export class ProductTransactionExportConfigurationMapping extends ProductTransactionExportConfigurationCustomField {
  field: ProductTransactionExportField;

  constructor(customField: string, index: number, field: ProductTransactionExportField) {
    super(customField, index);
    this.field = field;
  }
}

export class ProductTransactionExportConfiguration extends CompanyEntity {
  name!: string;
  filename: string = 'ventory_transactions_${TIMESTAMP}.csv';
  prefix?: string;
  delimiter: string = ',';
  quote?: string;
  fieldMapping: ProductTransactionExportConfigurationMapping[] = [...DEFAULT_MAPPING().values()];
  default: boolean = false;

  forUpdate(): UpdateProductTransactionExportConfigurationInput {
    return UpdateProductTransactionExportConfigurationInput.from(
      this,
      UpdateProductTransactionExportConfigurationInput,
    );
  }

  forDelete(): DeleteProductTransactionExportConfigurationInput {
    return DeleteProductTransactionExportConfigurationInput.from(
      this,
      DeleteProductTransactionExportConfigurationInput,
    );
  }

  validate(fields: any[]) {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof ProductTransactionExportConfiguration)[],
      (field: keyof ProductTransactionExportConfiguration) => {
        return null;
      },
    );
  }

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    this.default = false;
    Object.assign(this, cloneDeep(obj));
    this.default = obj.default || false;
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withFilename(filename: string) {
    this.filename = filename;
    return cloneDeep(this);
  }

  withPrefix(prefix: string) {
    this.prefix = prefix;
    return cloneDeep(this);
  }

  withDelimiter(delimiter: string) {
    this.delimiter = delimiter;
    return cloneDeep(this);
  }

  withDefault(value: boolean) {
    this.default = value;
    return cloneDeep(this);
  }
}
export class CreateProductTransactionExportConfigurationInput extends forCreate(
  ProductTransactionExportConfiguration,
) {}

export class UpdateProductTransactionExportConfigurationInput extends forUpdate(
  ProductTransactionExportConfiguration,
) {}

export class DeleteProductTransactionExportConfigurationInput extends forDelete(
  ProductTransactionExportConfiguration,
) {}

const DEFAULT_MAPPING = () => {
  const map = new Map<ProductTransactionExportField, ProductTransactionExportConfigurationMapping>();
  map.set(ProductTransactionExportField.type, {
    field: ProductTransactionExportField.type,
    customField: 'Type',
    index: 1,
  });
  map.set(ProductTransactionExportField.productName, {
    field: ProductTransactionExportField.productName,
    customField: 'Product Name',
    index: 2,
  });
  map.set(ProductTransactionExportField.productNumber, {
    field: ProductTransactionExportField.productNumber,
    customField: 'Product Number',
    index: 3,
  });
  map.set(ProductTransactionExportField.sourceStockLocationName, {
    field: ProductTransactionExportField.sourceStockLocationName,
    customField: 'Source Stock Location',
    index: 4,
  });
  map.set(ProductTransactionExportField.destinationStockLocationName, {
    field: ProductTransactionExportField.destinationStockLocationName,
    customField: 'Destination Stock Location',
    index: 5,
  });
  map.set(ProductTransactionExportField.sourceBinName, {
    field: ProductTransactionExportField.sourceBinName,
    customField: 'Source Bin',
    index: 6,
  });
  map.set(ProductTransactionExportField.destinationBinName, {
    field: ProductTransactionExportField.destinationBinName,
    customField: 'Destination Bin',
    index: 7,
  });
  map.set(ProductTransactionExportField.quantity, {
    field: ProductTransactionExportField.quantity,
    customField: 'Quantity',
    index: 8,
  });
  map.set(ProductTransactionExportField.serialNumber, {
    field: ProductTransactionExportField.serialNumber,
    customField: 'Serial Number',
    index: 9,
  });
  map.set(ProductTransactionExportField.status, {
    field: ProductTransactionExportField.status,
    customField: 'Status',
    index: 10,
  });
  map.set(ProductTransactionExportField.processedAt, {
    field: ProductTransactionExportField.processedAt,
    customField: 'Processed At',
    index: 11,
  });
  map.set(ProductTransactionExportField.processedBy, {
    field: ProductTransactionExportField.processedBy,
    customField: 'Processed By',
    index: 12,
  });
  return map;
};

export function productTransactionExportFieldToString(item: ProductTransactionExportField): string {
  switch (item) {
    case ProductTransactionExportField.productName:
      return 'Product Name';
    case ProductTransactionExportField.productNumber:
      return 'Product Number';
    case ProductTransactionExportField.sourceStockLocationName:
      return 'Source Stock Location';
    case ProductTransactionExportField.destinationStockLocationName:
      return 'Destination Stock Location';
    case ProductTransactionExportField.sourceBinName:
      return 'Source Bin Name';
    case ProductTransactionExportField.destinationBinName:
      return 'Destination Bin Name';
    case ProductTransactionExportField.company:
      return 'Company';
    case ProductTransactionExportField.countryOfOrigin:
      return 'Country of Origin';
    case ProductTransactionExportField.destinationContainerIdentifier:
      return 'Destination Container Identifier';
    case ProductTransactionExportField.sourceContainerIdentifier:
      return 'Source Container Identifier';
    case ProductTransactionExportField.lotExpiryDate:
      return 'Lot Expiry Date';
    case ProductTransactionExportField.orderNumber:
      return 'Order Number';
    case ProductTransactionExportField.parentId:
      return 'Parent Id';
    case ProductTransactionExportField.parentType:
      return 'Parent Type';
    case ProductTransactionExportField.processedAt:
      return 'Processed At';
    case ProductTransactionExportField.processedBy:
      return 'Processed By';
    case ProductTransactionExportField.processedQuantity:
      return 'Processed Quantity';
    case ProductTransactionExportField.reasonForFailure:
      return 'Reason for Failure';
    case ProductTransactionExportField.status:
      return 'Status';
    case ProductTransactionExportField.type:
      return 'Type';
    case ProductTransactionExportField.quantity:
      return 'Quantity';
    case ProductTransactionExportField.lpn:
      return 'LPN';
    case ProductTransactionExportField.createdAt:
      return 'Created At';
    case ProductTransactionExportField.updatedAt:
      return 'Updated At';
    case ProductTransactionExportField.createdBy:
      return 'Created By';
    case ProductTransactionExportField.updatedBy:
      return 'Updated By';
    case ProductTransactionExportField.serialShippingContainerCode:
      return 'Serial Shipping Container Code';
    case ProductTransactionExportField.globalTradeItemNumber:
      return 'Global Trade Item Number';
    case ProductTransactionExportField.globalTradeItemNumberOfTradeItemsContained:
      return 'Global Trade Item Number Of Trade Items Contained';
    case ProductTransactionExportField.lotNumber:
      return 'Lot Number';
    case ProductTransactionExportField.productionDate:
      return 'Production Date';
    case ProductTransactionExportField.dueDate:
      return 'Due Date';
    case ProductTransactionExportField.packagingDate:
      return 'Packaging Date';
    case ProductTransactionExportField.bestBeforeDate:
      return 'Best Before Date';
    case ProductTransactionExportField.expirationDate:
      return 'Expiration Date';
    case ProductTransactionExportField.internalProductVariant:
      return 'Internal Product Variant';
    case ProductTransactionExportField.serialNumber:
      return 'Serial Number';
    case ProductTransactionExportField.consumerProductVariant:
      return 'Consumer Product Variant';
    case ProductTransactionExportField.customerPartNumber:
      return 'Customer Part Number';
    case ProductTransactionExportField.secondarySerialNumber:
      return 'Secondary Serial Number';
    case ProductTransactionExportField.variableCountOfItems:
      return 'Variable Count Of Items';
    case ProductTransactionExportField.countOfTradeItemsContained:
      return 'Count Of Trade Items Contained';
    case ProductTransactionExportField.customerPurchaseOrderNumber:
      return 'Customer Purchase Order Number';
    case ProductTransactionExportField.routingCode:
      return 'Routing Code';
    case ProductTransactionExportField.countryOfOriginOfTradeItem:
      return 'Country Of Origin Of Trade Item';
    case ProductTransactionExportField.countryOfInitialProcessing:
      return 'Country Of Initial Processing';
    case ProductTransactionExportField.countryOfProcessing:
      return 'Country Of Processing';
    case ProductTransactionExportField.countryOfDisassembly:
      return 'Country Of Disassembly';
    case ProductTransactionExportField.countryCoveringFullProcessChain:
      return 'Country Covering Full Process Chain';
    case ProductTransactionExportField.natoStockNumber:
      return 'Nato Stock Number';
    case ProductTransactionExportField.expirationDateAndTime:
      return 'Expiration Date And Time';
    case ProductTransactionExportField.pricePerUnitOfMeasure:
      return 'Price Per Unit Of Measure';
    case ProductTransactionExportField.dateAndTimeOfProduction:
      return 'Date And Time Of Production';
  }
}

export function stringToProductTransactionExportField(item: string): string {
  switch (item) {
    case 'Product Name':
      return ProductTransactionExportField.productName;
    case 'Product Number':
      return ProductTransactionExportField.productNumber;
    case 'Source Stock Location':
      return ProductTransactionExportField.sourceStockLocationName;
    case 'Destination Stock Location':
      return ProductTransactionExportField.destinationStockLocationName;
    case 'Source Bin Name':
      return ProductTransactionExportField.sourceBinName;
    case 'Destination Bin Name':
      return ProductTransactionExportField.destinationBinName;
    case 'Company':
      return ProductTransactionExportField.company;
    case 'Country of Origin':
      return ProductTransactionExportField.countryOfOrigin;
    case 'Desintation Container Identifier':
      return ProductTransactionExportField.destinationContainerIdentifier;
    case 'Source Container Identifier':
      return ProductTransactionExportField.sourceContainerIdentifier;
    case 'Lot Expiry Date':
      return ProductTransactionExportField.lotExpiryDate;
    case 'Order Number':
      return ProductTransactionExportField.orderNumber;
    case 'Parent Id':
      return ProductTransactionExportField.parentId;
    case 'Parent Type':
      return ProductTransactionExportField.parentType;
    case 'Processed At':
      return ProductTransactionExportField.processedAt;
    case 'Processed By':
      return ProductTransactionExportField.processedBy;
    case 'Processed Quantity':
      return ProductTransactionExportField.processedQuantity;
    case 'Reason for Failure':
      return ProductTransactionExportField.reasonForFailure;
    case 'Status':
      return ProductTransactionExportField.status;
    case 'Type':
      return ProductTransactionExportField.type;
    case 'Quantity':
      return ProductTransactionExportField.quantity;
    case 'LPN':
      return ProductTransactionExportField.lpn;
    case 'Created At':
      return ProductTransactionExportField.createdAt;
    case 'Updated At':
      return ProductTransactionExportField.updatedAt;
    case 'Created By':
      return ProductTransactionExportField.createdBy;
    case 'Updated By':
      return ProductTransactionExportField.updatedBy;
    case 'Serial Shipping Container Code':
      return ProductTransactionExportField.serialShippingContainerCode;
    case 'Global Trade Item Number':
      return ProductTransactionExportField.globalTradeItemNumber;
    case 'Global Trade Item Number Of Trade Items Contained':
      return ProductTransactionExportField.globalTradeItemNumberOfTradeItemsContained;
    case 'Lot Number':
      return ProductTransactionExportField.lotNumber;
    case 'Production Date':
      return ProductTransactionExportField.productionDate;
    case 'Due Date':
      return ProductTransactionExportField.dueDate;
    case 'Packaging Date':
      return ProductTransactionExportField.packagingDate;
    case 'Best Before Date':
      return ProductTransactionExportField.bestBeforeDate;
    case 'Expiration Date':
      return ProductTransactionExportField.expirationDate;
    case 'Internal Product Variant':
      return ProductTransactionExportField.internalProductVariant;
    case 'Serial Number':
      return ProductTransactionExportField.serialNumber;
    case 'Consumer Product Variant':
      return ProductTransactionExportField.consumerProductVariant;
    case 'Customer Part Number':
      return ProductTransactionExportField.customerPartNumber;
    case 'Secondary Serial Number':
      return ProductTransactionExportField.secondarySerialNumber;
    case 'Variable Count Of Items':
      return ProductTransactionExportField.variableCountOfItems;
    case 'Count Of Trade Items Contained':
      return ProductTransactionExportField.countOfTradeItemsContained;
    case 'Customer Purchase Order Number':
      return ProductTransactionExportField.customerPurchaseOrderNumber;
    case 'Routing Code':
      return ProductTransactionExportField.routingCode;
    case 'Country Of Origin Of Trade Item':
      return ProductTransactionExportField.countryOfOriginOfTradeItem;
    case 'Country Of Initial Processing':
      return ProductTransactionExportField.countryOfInitialProcessing;
    case 'Country Of Processing':
      return ProductTransactionExportField.countryOfProcessing;
    case 'Country Of Disassembly':
      return ProductTransactionExportField.countryOfDisassembly;
    case 'Country Covering Full Process Chain':
      return ProductTransactionExportField.countryCoveringFullProcessChain;
    case 'Nato Stock Number':
      return ProductTransactionExportField.natoStockNumber;
    case 'Expiration Date And Time':
      return ProductTransactionExportField.expirationDateAndTime;
    case 'Price Per Unit Of Measure':
      return ProductTransactionExportField.pricePerUnitOfMeasure;
    case 'Date And Time Of Production':
      return ProductTransactionExportField.dateAndTimeOfProduction;
    default:
      return 'Unknown Product Transaction Export Field';
  }
}
