import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { Alert } from '../types/alert';
import { ProductTransactionFragments } from './productTransaction.graphql';

const alert = gql`
  fragment Alert on AlertWithEntity {
    id
    version
    companyId
    createdAt
    entityType
    entityValue
    triggeredValue
    triggerId
    stockLocationAggregateType
    stockLocationIds
    triggeredStockLocation
    userId
    customFieldIds
    alertString
    productTransaction {
      ...ProductTransaction
    }
    processed
  }
  ${ProductTransactionFragments.productTransaction}
`;

const get = gql`
  query Alerts($companyId: String!, $page: Float) {
    alerts(companyId: $companyId, page: $page) {
      data {
        ...Alert
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${alert}
`;

export interface GetAlertVariables {
  companyId: string;
  page?: number;
}

export interface GetAlertResponse {
  alerts: PaginatedAlerts;
}

class PaginatedAlerts extends forPaginated<Alert>() {}

const process = gql`
  mutation ProcessAlert($companyId: String!, $alertId: String!) {
    processAlert(companyId: $companyId, alertId: $alertId) {
      ...Alert
    }
  }
  ${alert}
`;

export interface ProcessAlertVariables {
  companyId: string;
  alertId: string;
}

export interface ProcessAlertResponse {
  processAlert: Alert;
}

export const AlertQueries = {
  get,
};

export const AlertMutations = {
  process,
};

export const AlertFragment = {
  alert,
};
