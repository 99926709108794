import React from 'react';

export default function Agreements() {
  return (
    <div className='elementor-widget-wrap' style={{ padding: '1rem' }}>
      <div
        className='elementor-element elementor-element-fd85e8a elementor-widget elementor-widget-text-editor'
        data-id='fd85e8a'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              Please read this License Agreement carefully before using, accessing, downloading, installing or otherwise
              operating any of the Products.
            </p>
            <p>
              By using, accessing, downloading or otherwise operating any of the products, Licensee constitutes an
              unconditional agreement to be bound by this License Agreement.
              <br />
              <br />
              IF YOU DO NOT ACCEPT THE TERMS OF THIS LICENSE AGREEMENT, YOU ARE PROHIBITED FROM USING ANY OF THE
              PRODUCTS.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-63aebf0 elementor-widget elementor-widget-heading'
        data-id='63aebf0'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>1. Definitions</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-4567faa elementor-widget elementor-widget-text-editor'
        data-id='4567faa'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              <strong>Agreement</strong>&nbsp;means this License Agreement and all schedules, appendixes and related
              documentations provided to Licensee.
            </p>
            <p>
              <strong>Billing Information</strong>&nbsp;means the information required for billing the usage of the
              Products. This includes, but is not limited to the Licensee’s name, address, e-mail address, telephone
              number and in case Licensee transacts business with Licensor, financial information such as a valid credit
              or debit card or other payment details.
            </p>
            <p>
              <strong>Confidential Information</strong>&nbsp;means any information of a non-public, confidential or
              proprietary nature, whether of commercial, financial or technical nature, customer-, supplier-, product-
              or production-related or otherwise, including samples, information relating to, specifications, the
              Product, patent applications, process designs, process models, materials and ideas, disclosed by the
              Licensor to the Licensee.
            </p>
            <p>
              <strong>Control</strong>&nbsp;means the power, factual or by law, to exercise a decisive influence on the
              designation of the majority of the directors of a company, or on its management. Notably, there is legal
              control when it results from the possession of the majority of the voting rights linked to the shares of
              the controlled company.
            </p>
            <p>
              <strong>Effective Date</strong>&nbsp;means the commencement date of the Agreement and is set to the date
              of the registration for the Use of any of the Products.
            </p>
            <p>
              <strong>End Date</strong>&nbsp;means the expiration date of each Term starting from the Effective Date.
            </p>
            <p>
              <strong>Intellectual Property Rights</strong>&nbsp;means any intellectual property and proprietary rights,
              including, but not limited to, copyrights, moral rights, works of authorship, trade and service marks,
              trade names, rights in logos and get-up, inventions and discoveries, Confidential Information, trade
              secrets and Know-How, registered designs, design rights, patents, utility models, all rights of whatsoever
              nature in computer software and data, source code, database rights all intangible rights and privileges of
              nature similar or allied to any of the foregoing, in every case in any part of the world and whether or
              not registered; and including all granted registrations and all applications for registration, all
              renewals, reversions or extensions, the right to sue for damages for past infringement and all forms of
              protection of a similar nature which may subsist anywhere in the world.
            </p>
            <p>
              <strong>Know-How</strong>&nbsp;means any information relating to commercial, scientific and technical
              matters, inventions and trade secrets, including but not limited to any patentable technical or other
              information which is not in the public domain including information comprising or relating to concepts,
              discoveries, data, designs, formulae, ideas, reports and data analyses.
            </p>
            <p>
              <strong>License</strong>&nbsp;has the same meaning as Agreement.
            </p>
            <p>
              <strong>License Agreement</strong>&nbsp;has the same meaning as Agreement.
            </p>
            <p>
              <strong>License Fee</strong>&nbsp;means the amount payable to Licensor required for Use of the Products.
            </p>
            <p>
              <strong>Licensee</strong>&nbsp;means you and any entity on whose behalf you Use the Products.
            </p>
            <p>
              <strong>Licensor</strong>&nbsp;means M28 Ventures BV (dba Ventory), Annecylaan 22, 1950 Kraainem, Belgium,
              VAT Nr. 683.940.268 and its successors and assignees.
            </p>
            <p>
              <strong>Third Party Software</strong> means open source software and other third-party software used with
              the Product but not branded or developed by Licensor.
            </p>
            <p>
              <strong>Parties</strong>&nbsp;means both Licensee and Licensor.
            </p>
            <p>
              <strong>Party</strong>&nbsp;means Licensee or Licensor individually.
            </p>
            <p>
              <strong>Payment Provider&nbsp;</strong>means a third-party payment platform made available to Licensee.
              Currently, Licensor is using Stripe Payments Pty Ltd ACN 160 180 343 as a third-party service provider for
              Payment Services via&nbsp;
              <a href='https://stripe.com/docs/connect/updating-accounts#tos-acceptance'>
                https://stripe.com/docs/connect/updating-accounts#tos-acceptance
              </a>
              .
            </p>
            <p>
              <strong>Stripe Account</strong>&nbsp;means an online payment profile created and registered with the
              Payment Provider for and on behalf of Licensee in order for Licensee to provide the Payment Services.
            </p>
            <p>
              <strong>Payment Services&nbsp;</strong>means the internet-based payment processing services provided by
              the Payment Provider, acting as a payment facilitator and/or escrow agent for the collection and payment
              of the License Fee under this Agreement.
            </p>
            <p>
              <strong>Products</strong>&nbsp;means the Ventory software application as published for use and/or download
              under&nbsp;
              <a href='https://app.ventory.io/'>https://app.ventory.io/</a>, https://ventory.io or any other application
              platform, such as Apple Store or Google Play and all related services offered by Licensor.
            </p>
            <p>
              Purpose&nbsp;means using or installing the Products in order use a warehouse and stock management system.
            </p>
            <p>
              <strong>Taxes</strong>&nbsp;means any value added tax (VAT), sales tax, income tax, consumption tax or any
              other similar tax, duty, fee, levy or other governmental charge, customs duties and other levies.
            </p>
            <p>
              <strong>Term</strong>&nbsp;means the Trial Period and any extended period of subscription in accordance
              with this Agreement.
            </p>
            <p>
              <strong>Trial Period</strong> means the first one (1) month after the Effective Date.
            </p>
            <p>
              <strong>Ventory </strong>means a field inventory management software comprised of a) web app; b) mobile
              app on Google Play and Apple Store; c) API integration, including any related software, source and object
              code, deliverables, technology and related resources and relevant documentation provided and/or created,
              made available or developed by Licensor to Licensee in connection with this Agreement.
            </p>
            <p>
              <strong>Use</strong>&nbsp;means using, accessing, downloading, installing or otherwise operating the
              Product subject to clause titled “LICENSE” and in connection with the Purpose of this Agreement and its
              terms and conditions.
            </p>
            <p>
              <strong>Updates</strong>&nbsp;means all updates, modifications and releases of new versions of Product
              containing improvements, corrections, minor modifications, bug fixes, patches, or the like that have been
              publicly announced by the Licensor on its website.
            </p>
            <p>
              <strong>Upgrades</strong>&nbsp;shall mean all modifications, new features, enhancements, releases of new
              versions of Product and similar developments of it which have not been announced already as Updates by
              Licensor on its website. Such Upgrades may be subject to additional charge and require a separate
              agreement by the Parties and/or are specifically designed for or requested by Licensee.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-57e088f elementor-widget elementor-widget-heading'
        data-id='57e088f'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>2. Grant of license</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-1b038a8 elementor-widget elementor-widget-text-editor'
        data-id='1b038a8'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              <strong>2.1 License</strong>
            </p>
            <p>
              THE Licensor grants to the Licensee, and the Licensee accepts from the Licensor for the Term and subject
              to the conditions of this Agreement, a non-exclusive, non-transferable, worldwide and non-assignable right
              and License to Use the Products in connection with the Purpose.
            </p>
            <p>
              Except as specifically set forth herein or as agreed by the Licensor in writing, Licensee shall not
              disclose, rent out, hire, distribute, relicense, sell, lease, transfer, encumber, assign or make available
              to third parties or for public use the Products. Any attempt to take any such actions is void and will
              automatically terminate Licensee’s rights under this Agreement. For the avoidance of doubt, the Licenses
              granted under clause titled “LICENSE” shall not include any modifications or Upgrades of any kind to
              Products made by the Licensor after the Effective Date except for the Updates that have been made publicly
              available to the Licensee.
            </p>
            <p>
              <strong>2.2 Reverse Engineering and Modifications</strong>
            </p>
            <p>
              Unless agreed by Parties in writing or if the enforcement of this provision is prohibited by applicable
              law, Licensee shall not under any circumstances attempt, or knowingly cause or permit others to attempt to
              modify, adapt, port, merge, decompile, disassemble, reverse engineer, decipher, decrypt or otherwise
              discover the source code or any other parts of the mechanisms and algorithms used by Product nor remove
              restrictions or create derivative works of Product or of any part of Product. The Licensee may not alter,
              modify, adapt, port or merge Product or any part thereof.
            </p>
            <p>
              <strong>2.3 Ownership</strong>
            </p>
            <p>
              The Licensee recognizes that all rights, title and interests in and to any and all worldwide Intellectual
              Property Rights related to the Product shall remain the property of Licensor or its suppliers. Unless
              otherwise agreed upon between the Parties, any Intellectual Property Rights in any Updates, Upgrades,
              enhancements, customization, modifications, inventions, developments, improvements thereof of any kind to,
              in, or that otherwise relate to the Product, including any derivative work or results of services during,
              before or after the Term of this Agreement, either specific to Licensee, its customer or in general in
              connection with this Agreement or arising out of the business relationship between the Parties shall
              solely and exclusively belong to or be transferred to Licensor through assignment, entitlement or
              otherwise, including the entire right, title and interest. For this purpose, Licensor shall also have the
              right to file and prosecute at its own expenses any patent application on the same above, in any country,
              region or jurisdiction in the world in its own name or on behalf of Licensee, as the case may be. Licensee
              shall not have the right to claim and will not undertake or try to obtain, register or apply for any
              Intellectual Property Rights or other rights in or to the Product anywhere in the world. Licensee shall
              not do anything that might misrepresent, change or otherwise compromises the ownership or proprietary
              rights of Licensor or its suppliers under this Agreement. Licensee shall not take any actions that would
              amount to an exhaustion of Licensor’s or its suppliers Intellectual Property Rights. The Product may
              contain the Product logo and copyright notice. It is prohibited to remove or modify the copyright notice
              and Product logo.&nbsp;
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-22c0b3c elementor-widget elementor-widget-heading'
        data-id='22c0b3c'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>3. Payment</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-ffb776c elementor-widget elementor-widget-text-editor'
        data-id='ffb776c'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              <strong>3.1 License Fee</strong>
            </p>
            <p>
              During the Trial Period the use of the Software is free of charge. In case of an extension of the Term
              after the Trial Period the Licensee must pay to the Licensor the then currently applicable license fee
              (License Fee) as provided by Licensor at&nbsp;www.ventory.io/pricing.
            </p>
            <p>
              The Licensee is prohibited from using the Products if the License Fees due have not been paid and Licensor
              reserves the right to immediately suspend, terminate, block and/or blacklist the Use of the Product under
              this Agreement.
            </p>
            <p>
              <strong>3.2 Changes</strong>
            </p>
            <p>
              During the Term the License Fee may be subject to change by Licensor provided Licensor has given to
              Licensee a prior written notice of at least one (1) month. In case of an increase of the License Fee by
              more than ten (10) percent, Licensee may terminate this Agreement with the effective date of such change.
            </p>
            <p>
              All License Fees paid for the usage of the Product are non-refundable except as otherwise provided in this
              Agreement.
            </p>
            <p>
              <strong>3.3 Taxes</strong>
            </p>
            <p>
              Licensee shall be responsible to pay all Taxes or levies due in connection with the License Fee and
              Licensee’s Use of the Products.
            </p>
            <p>
              <strong>3.4 Payment Term</strong>
            </p>
            <p>
              In case the Use of the Product is subject to payment by Licensee in accordance with this Agreement, the
              Licensee Fee shall become immediately due and must be issued prior the User of the Product for the
              following Term subscription in advance. Payment must be executed in accordance with the clause titled
              “PAYMENT PROVIDER” and “PAYMENT DETAILS”.
            </p>
            <p>
              <strong>3.5 Invoices</strong>
            </p>
            <p>
              In order to Use the Product, Licensee must provide Licensor with accurate and complete Billing
              Information. Invoices related to the License will be sent in PDF format to the email address as provided
              by Licensee during its registration process for using the Product. Licensor will issue each Invoice on a
              monthly or annual basis, as subscribed by the Licensee, either, after each successful payment transaction
              or when the payment for the applicable payment transaction has become due.
            </p>
            <p>
              For the purpose of billing and invoicing, Licensee shall provide to Licensor its payment details in
              accordance with clause titled “PAYMENT DETAILS” under this Agreement, either during the first registration
              or at the latest upon notification by Licensor when the usage of the Product becomes chargeable under this
              Agreement.
            </p>
            <p>
              <strong>3.6 Non-Payment</strong>
            </p>
            <p>The Licensee is prohibited from using the Products if the License Fee due has not been paid.</p>
            <p>In case of (partial) non-payment of an invoice at its expiration date:</p>
            <ol>
              <li>any other non-expired debt of the Licensee will become due automatically without prior notice;</li>
              <li>the Licensor is entitled to immediately suspend the License and Use without prior notice;</li>
              <li>
                the amount due will automatically and without prior notice be increased with ten (10) % interest per
                year; and
              </li>
              <li>
                a lump-sum compensation in the amount of ten (10) % of the unpaid or overdue paid invoice amounts will
                be due, as well as other applicable legal and extra-legal recovery expenses, protest and legal costs.
              </li>
            </ol>
            <p>
              <strong>
                <br />
                3.7 Payment Provider
              </strong>
            </p>
            <p>
              All payments due under this Agreement will be executed by using a Payment Provider. Licensor assists in
              processing payment of the License Fees by using a Payment Provider for payments under this Agreement. For
              such purpose, the Product may contain links to third-party websites, terms and conditions, resources which
              may be subject to different terms and conditions and privacy practices or policies. Such links are not an
              endorsement by Licensor of such Payment Services.
            </p>
            <p>
              By registering for the Use of the Product Licensee agrees (a) that Licensor and Payment Provider are
              independent entities and do not have any partnership, agency, or employment relationship; and (b) that the
              Payment Services are subject to all applicable terms and conditions of the Payment Provider as published
              on its website, including but not limited to, the service agreement under&nbsp;
              <a href='https://stripe.com/be/ssa'>https://stripe.com/be/ssa</a>&nbsp;the end user terms under&nbsp;
              <a href='https://stripe.com/be/connect-account/legal'>https://stripe.com/be/connect-account/legal</a>
              &nbsp;and&nbsp;<a href='https://stripe.com/be/connect/legal'>https://stripe.com/be/connect/legal</a>, the
              Stripe payment terms&nbsp;
              <a href='https://stripe.com/payment-terms/legal'>https://stripe.com/payment-terms/legal</a>&nbsp;and&nbsp;
              <a href='https://stripe.com/sepa-direct-debit/legal'>https://stripe.com/sepa-direct-debit/legal</a>
              &nbsp;and the Stripe’s privacy policy which are located at&nbsp;
              <a href='https://stripe.com/be/privacy'>https://stripe.com/be/privacy</a>, whereby such terms and
              conditions apply between Licensee and the Payment Provider directly.
            </p>
            <p>
              Additionally, Licensee is also solely responsible to the Payment Provider for all transactions and
              charges, disputes, refunds, claims, fines, or use of the Payment Services and any penalties or fines
              imposed by any bank, money services business, payment network, or other financial intermediary resulting
              from Licensee’s use of Payment Services in a manner not permitted by this Agreement and the terms and
              conditions of the Payment provider.
            </p>
            <p>
              By registering for the Use of the Product, Licensee consents and authorizes Licensor and the Payment
              Provider to: (a) share and disclose with one another any information, payment details and payments
              instructions that Licensee provides when Using the Product for the purpose of the Payment Services; (b)
              connect Licensee’s account through the Product with the Payment Provider’s Stripe Account or any other
              account&nbsp;created by or for Licensee and, to the extent required to complete Licensee’s transactions,
              with any third party service provider for the purpose of the Payment Services.
            </p>
            <p>
              Additionally for the purpose of the Payment Services, by registering for the Use of the Product, Licensee
              authorizes, and agrees to allow Licensor and the Payment Provider to collect, use, retain, and disclose
              any information that Licensee provides to Licensor or the Service Provider, including information that
              Licensor or the Payment Provider may collect directly using cookies or other similar means.
            </p>
            <p>
              Licensee acknowledges that it is solely responsible for any information or data provided by Licensee
              directly to Licensor or the Payment Provider or as part of the standard registration process with the
              Product or otherwise for the purpose of the Payment Services.&nbsp;
            </p>
            <p>
              Licensor is not responsible or liable for the availability or accuracy of such Payment Services, or the
              content, products, or services available from such Payment Provider, or any fraud or other crime
              facilitated thereby.
            </p>
            <p>
              In no event shall Licensor be liable to Licensee for any actions, claims, demands, losses, liabilities,
              damages, recoveries, settlements and/or costs (including attorney, accountant, and expert witness fees and
              costs), known or unknown, contingent or otherwise of any kind whatsoever,&nbsp;arising out or relating to
              their respective use of, or in ability to use, the Payment Services, regardless of the legal theory under
              which such losses are sought.
            </p>
            <p>
              Licensor takes no responsibility and assumes no liability for any action, inaction, omission or negligence
              of the Payment Provider in connection with the Payment Services.
            </p>
            <p>
              Due to the nature of the internet, user verification on the internet is difficult. Licensor does not
              assume any responsibility for the confirmation of Licensee’s identity, qualification and/or experience.
              Notwithstanding the above, for transparency and fraud prevention purposes to the extent as permitted by
              applicable laws, Licensor may request Licensee to provide proof of its identity or obtain information
              about Licensee from third party databases in Licensee’s jurisdiction.
            </p>
            <p>
              <strong>3.8 Payment Details</strong>
            </p>
            <p>
              Licensee shall &nbsp;pay by credit card, or as otherwise agreed by the Parties in writing. Licensor
              advises to use credit card payment for all current and future payments under this Agreement in order to
              guarantee an uninterrupted Use of the Product. However, SEPA direct debit option is available in all EU
              Member States, Norway, Iceland, Liechtenstein, Switzerland, Monaco, San Marino, and Andorra. Licensee
              chooses to pay by SEPA direct debit through providing its IBAN account number via the Product or
              otherwise.
            </p>
            <p>
              By providing the credit card details, IBAN account number or any other payment details, Licensee is
              authorizing Payment Provider, to send instructions to Licensee’s bank and/or credit card provider to debit
              or credit Licensee’s bank account in accordance with those instructions.
            </p>
            <p>
              Licensee may provide such payment details for all current and future payments either during the
              registration process for the Use of the Product or at any time later when using the account. In this case,
              Licensee acknowledges that all amounts due under this Agreement will be deducted automatically from its
              credit card and/or bank account in accordance with the Licensor’s payment terms without any further action
              and separate authorization of Licensee, unless otherwise agreed upon between the Parties.
            </p>
            <p>
              In case Licensee decided not to provide its payment details for all current and future payments due under
              this Agreement, Licensor will provide an email with a payment request by credit card each month when a
              payment is due under this Agreement. Licensee shall execute each credit card payment within seven (7) days
              after receipt of Licensor’s payment request.
            </p>
            <p>
              For the avoidance of doubt, Licensee’s payment details are provided directly to the Payment Provider via
              the Product and will be linked to Licensee’s Stripe Account. At no time will such payment details be
              saved, stored with the Product itself or used otherwise by Licensor.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-49b31c1 elementor-widget elementor-widget-heading'
        data-id='49b31c1'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>4. Account Information</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-641d1cb elementor-widget elementor-widget-text-editor'
        data-id='641d1cb'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              Before Using the Product, Licensee must create a username and password as well as provide accurate contact
              and other information, such as email address, telephone number and name, credit card or other payment
              details (together “Account Information”). Licensee is strictly prohibited from: (i) using false Account
              Information, , (ii) using such information owned or controlled by another person with the intent to
              impersonate that person, or (iii) providing such information subject to any rights of a person other than
              Licensee without appropriate authorization. Licensor reserves the right to refuse registration or cancel
              any account that it reasonably believes is in violation of the foregoing. Licensee shall be responsible
              for maintaining the confidentiality of its password and is liable for all activities that occur on its
              account. Licensee agrees to immediately notify the Licensor of any unauthorized use of Licensee’s Account
              Information or other breach of security. To the extent required under this Agreement, Licensee authorizes
              Licensor to save and use such Account Information as provided by Licensee with the Product.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-3b7e4c2 elementor-widget elementor-widget-heading'
        data-id='3b7e4c2'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>5. Support</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-8b4cdba elementor-widget elementor-widget-text-editor'
        data-id='8b4cdba'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              The Licensor has no obligation under this Agreement to provide any maintenance, integration support,
              support or training to Licensee. Such services may be purchased by Licensee separately and subject to
              additional service fees.
            </p>
            <p>
              General documentation regarding the minimal technical requirements, the suitability, the integration, the
              features and compatibility of the Products will be made available on the Product website&nbsp;
              <a href='http://www.ventory.io'>www.ventory.io</a>.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-82dd6ae elementor-widget elementor-widget-heading'
        data-id='82dd6ae'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>6. Upgrades and updates</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-475fc44 elementor-widget elementor-widget-text-editor'
        data-id='475fc44'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              The Licensor has no obligation whatsoever under this Agreement to Upgrade, modify or release new versions
              of the Products.
            </p>
            <p>
              The Licensee shall only be entitled to Updates of the Products that have been made publicly available by
              the Licensor.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-e05681f elementor-widget elementor-widget-heading'
        data-id='e05681f'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>7. Statistics</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-9457b9b elementor-widget elementor-widget-text-editor'
        data-id='9457b9b'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              For statistics and invoicing purposes, Licensor will have at all times the right to track the Use of the
              Products. Licensor may use the gathered information to deliver the Products, manage the Licensee’s
              account, provide customer support, perform research and analysis about Licensee’s Use, enforce the
              Agreement and perform functions otherwise described to Licensee at the time of collection.
            </p>
            <p>
              Licensee grants Licensor a limited, royalty-free, worldwide license to use the information collected by
              the tracking and reporting functionality to facilitate the operation of Products, ensure compliance and
              for its internal business purposes.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-2fa5a62 elementor-widget elementor-widget-heading'
        data-id='2fa5a62'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>8. Marketing and use of name</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-c88e969 elementor-widget elementor-widget-text-editor'
        data-id='c88e969'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              The Licensor may refer to the Licensee’s name, trademarks, service marks, logos, and/or branding as well
              as the existence of the License in (i) marketing and publicity materials as an indication of its
              experience, and (ii) internal data systems.
            </p>
            <p>
              The Licensee may not use the Licensor’s trademarks, service marks, logos, and/or branding in any external
              publicity material without the Licensor’s prior written consent.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-060c283 elementor-widget elementor-widget-heading'
        data-id='060c283'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>9. Warranties</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-be2247b elementor-widget elementor-widget-text-editor'
        data-id='be2247b'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              <strong>9.1 Specifications</strong>
            </p>
            <p>
              The Licensor warrants that paid Use of the Products are in accordance with the minimum technical
              requirements provided by the Licensor to the Licensee.
            </p>
            <p>
              THE LICENSOR DOES NOT PROVIDE ANY GUARANTEE, EXPRESS OR IMPLIED, AS TO QUALITY, SUITABILITY, FEATURES,
              COMPATIBILITY OF THE PRODUCTS OTHER THAN AS MENTIONED IN THE GENERAL DOCUMENTATION. IN THE EVENT A CLAIM
              OF THE LICENSEE IS CONSIDERED VALID BY THE LICENSOR, THE SOLE RECOURSE CONSISTS OF A REPAIR OR A
              REPLACEMENT OF THE RELEVANT SOFTWARE ELEMENT, LIBRARY OR SDK.
            </p>
            <p>
              <strong>9.2 As Is</strong>
            </p>
            <p>
              EXCEPT TO THE EXTENT AS SPECIFIED IN THE PRECEDING CLAUSE TITLED “SPECIFICATIONS”, ANY PRODUCT (BE IT FREE
              OF CHARGE OR PAID) AND ITS USE IS&nbsp;&nbsp;PROVIDED “AS IS”,&nbsp;&nbsp;WITHOUT WARRANTY OF ANY KIND,
              EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO THE PRODUCT’S ACCURACY, TITLE,
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. LICENSOR DOES NOT WARRANT&nbsp;THE
              CORRECTION OF ALL DEFECTS OR ANY ERROR-FREE OR UNINTERRUPTED OPERATION OF THE PRODUCT. THE LICENSOR DOES
              NOT PROVIDE ANY WARRANTY AS TO QUALITY, SUITABILITY, FEATURES, COMPATIBILITY OF THE PRODUCT OTHER THAN AS
              MENTIONED IN SUCH GENERAL PRODUCT DOCUMENTATION. THIS AGREEMENT DOES NOT PROVIDE ANY REPRESENTATION OR
              WARRANTY OR LIABILITY AS TO ANY THIRD-PARTY SOFTWARE.
            </p>
            <p>
              <strong>9.3 Third Parties</strong>
            </p>
            <p>
              The Licensor will not indemnify nor hold harmless the Licensee against any infringements of any rights of
              third parties.
            </p>
            <p>
              Licensor shall have no obligation for payment of royalties or any other compensation to Licensee or third
              parties, if any, with respect to the Use of the Product by Licensee or its customers, clients, viewers,
              listeners for playing media content or in connection with third party products and software. The Licensee
              will be exclusively responsible for payment of royalties to third parties.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-b881e7f elementor-widget elementor-widget-heading'
        data-id='b881e7f'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>10. Limitation of liability</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-f259d04 elementor-widget elementor-widget-text-editor'
        data-id='f259d04'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              THE LICENSOR’S TOTAL AGGREGATE LIABILITY TO THE LICENSEE OR TO ANY THIRD PARTY, WHETHER IN CONTRACT
              (INCLUDING UNDER ANY INDEMNITY OR WARRANTY), IN TORT (INCLUDING NEGLIGENCE) UNDER A WARRANTY, UNDER
              STATUTE OR OTHERWISE, UNDER OR IN CONNECTION WITH THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT OF THE
              YEARLY LICENSE FEE PAID OR PAYABLE FOR THE TERM OF THIS AGREEMENT.
            </p>
            <p>
              NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT, THE LICENSOR SHALL NOT BE LIABLE VIS-À-VIS THE
              LICENSEE OR TO ANY THIRD PARTY, WHETHER IN CONTRACT, IN TORT (INCLUDING NEGLIGENCE) UNDER A WARRANTY OR
              INDEMNITY, UNDER STATUTE OR OTHERWISE, UNDER OR IN CONNECTION WITH THIS AGREEMENT FOR ANY LOST REVENUE,
              LOST PROFIT, LOST DATA OR CORRUPTED DATA, OR INDIRECT OR CONSEQUENTIAL DAMAGES, COSTS OF PROCUREMENT FOR
              SUBSTITUTION OF PRODUCTS OR SERVICES, THIRD PARTY SOFTWARE AND CLAIMS, PROVIDED INFORMATION, WASTED
              MANAGEMENT TIME, LOSS OF USE OF COMPUTER SYSTEMS AND RELATED EQUIPMENT, COMPUTER FAILURE AND MALFUNCTIONS,
              DOWNTIME COSTS, HOWEVER CAUSED, ARISING OUT OF THE USE OF OR INABILITY TO USE THE PRODUCTS EVEN IF (A) THE
              LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR (B) THE DAMAGES WERE FORESEEABLE.
            </p>
            <p>
              THE PROVISIONS OF THIS CLAUSE TITLED “LIMITATION OF LIABILITY” SHALL NOT APPLY TO THE EXTENT RESTRICTED OR
              PREVENTED BY MANDATORY APPLICABLE LAW THAT CANNOT BE AMENDED OR EXCLUDED BY CONTRACTUAL WAIVER SUCH AS
              DELIBERATE ACTS AND FRAUD.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-5796b21 elementor-widget elementor-widget-heading'
        data-id='5796b21'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>11. CONFIDENTIALITY</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-f3e72a1 elementor-widget elementor-widget-text-editor'
        data-id='f3e72a1'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              <strong>11.1 Treatment of Confidential Information</strong>
            </p>
            <p>
              The Licensee agrees and undertakes that for the duration of this Agreement as well as for ten (10) years
              thereafter, it will keep confidential and will not use for its own purposes nor without the prior written
              consent of the Licensor divulge to any third party any Confidential Information concerning the Licensor or
              its activity it has received or obtained in the framework of this Agreement.
            </p>
            <p>
              <strong>11.2 Release from Restrictions</strong>
            </p>
            <p>
              The provisions of the clause titled “CONFIDENTIALITY” shall not apply to any Confidential Information of
              the Licensor that:
            </p>
            <p style={{ paddingLeft: '40px' }}>
              a) at the time the Licensee discloses it to a third party or uses it, is generally known to the public
              through no fault of the Licensee; or
            </p>
            <p style={{ paddingLeft: '40px' }}>
              b) at the time the Licensee uses it or discloses it to a third party, has been made available to the
              Licensee by a third party having the lawful rights to do so without breaching any such obligation of
              non-use or confidentiality; or
            </p>
            <p style={{ paddingLeft: '40px' }}>
              c) is proven by the Licensee to have been independently developed by the Licensee without making use of
              the Confidential Information; or
            </p>
            <p style={{ paddingLeft: '40px' }}>
              d) the Licensee is required to disclose to comply with applicable laws, or to comply with governmental
              regulations, provided that the Licensee provides prior written notice of such disclosure to the Licensor
              and takes reasonable and lawful actions to avoid and/or minimize the degree of such disclosure and affords
              the Licensor as much notice as possible of such disclosure to allow the Licensor to do likewise.
            </p>
            <p>
              <strong>11.3 Survival</strong>
            </p>
            <p>
              The clause titled “CONFIDENTIALITY” shall remain in full force and effect notwithstanding any termination
              of this Agreement.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-ff0831b elementor-widget elementor-widget-heading'
        data-id='ff0831b'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>12. COMMENCEMENT AND TERM</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-7a1bf34 elementor-widget elementor-widget-text-editor'
        data-id='7a1bf34'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              This Agreement shall commence on the Effective Date and shall, subject to the provisions of the clause
              titled “TERMINATION” continue to be in effect for the Term of the Trial Period. After this first Term,
              this Agreement will be tacitly renewed for consecutive one (1) month or one (1) year (Terms, as chosen by
              the Licensee, unless one of the Parties terminates the Agreement in accordance with the clause titled
              “TERMINATION”.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-cca8084 elementor-widget elementor-widget-heading'
        data-id='cca8084'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>13. TERMINATION</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-7cb47aa elementor-widget elementor-widget-text-editor'
        data-id='7cb47aa'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              <strong>13.1 Termination</strong>
            </p>
            <p>
              At any time, either Party may terminate this Agreement at the end of each Term by giving a prior written
              notice to the other Party.
            </p>
            <p>
              Without prejudice to any other rights to which it may be entitled, either Party may give notice in writing
              to the other Party terminating this Agreement for cause with immediate effect and without court
              authorization:
            </p>
            <p style={{ paddingLeft: '40px' }}>
              a) if the other Party commits any material breach of any of the terms of this Agreement and (if such a
              breach is remediable) fails to remedy that breach within fifteen (15) calendar days of that Party being
              notified in writing of the breach; in any case, the following (non-exhaustive) list of events will be
              considered as a material breach:
            </p>
            <ol>
              <li style={{ listStyleType: 'none' }}>
                <ol>
                  <li>
                    the Licensee fails to pay the License Fee within the agreed payment terms or fails to provide a
                    valid payment details for such payment;
                  </li>
                  <li>the Licensee uses the Product in contravention with the Purpose;</li>
                  <li>if the Licensee violates any material term of this Agreement;</li>
                  <li>
                    if the Licensor has reasonable basis to believe that Licensee is not abiding by the spirit and the
                    conditions of this Agreement;
                  </li>
                  <li>
                    if the Licensor has a reasonable basis to believe that Licensee is impairing the Licensor’s
                    business;
                    <br />
                    <br />
                  </li>
                </ol>
              </li>
            </ol>
            <p style={{ paddingLeft: '40px' }}>
              b) if an order is made or a resolution is passed for the winding up of the other Party or if an order is
              made for the appointment of an administrator to manage the affairs, business and property of the other
              Party or if a receiver is appointed of any of the other Party’s assets or undertaking or if circumstances
              arise which entitle the Court or a creditor to appoint a receiver or manager or which entitle the Court to
              make a winding-up order or if the other Party takes or suffers any similar or analogous action in
              consequence of debt;
            </p>
            <p style={{ paddingLeft: '40px' }}>c) in case of a change of Control of the Licensee.</p>
            <p>
              In the event of any termination for cause by the Licensor pursuant to the rules set above, Licensor is
              under no obligation to provide a refund for the License Fees received from Licensee or apply such fees to
              future services.
            </p>
            <p>
              <strong>13.2 Effects of Termination</strong>
            </p>
            <p>
              All rights granted to the Licensee under this Agreement shall forthwith terminate and immediately revert
              to the Licensor. All Use of Products shall immediately be discontinued by Licensee and uninstall, delete
              and destroy any copies of the Products.
            </p>
            <p>
              Termination of this Agreement for any reason shall not release any Party hereto from any obligation which,
              at the time of such termination, has already accrued and become due to the other Party or which is
              attributable to a period prior to such termination nor preclude either Party from pursuing any rights and
              remedies it may have hereunder or at law or in equity with respect to any breach of this Agreement.
            </p>
            <p>
              In case of termination of this Agreement, for any reason whatsoever, all unpaid due License Fees shall
              become immediately collectible and the Licensor shall be entitled, in addition to any other remedies
              available to it, to take all necessary steps to collect such amounts, together with all costs,
              indemnities, compensations, damages, fees and expenses incurred by the Licensor.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-9247c39 elementor-widget elementor-widget-heading'
        data-id='9247c39'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>14. MISCELLANEOUS PROVISIONS</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-3e77d1e elementor-widget elementor-widget-text-editor'
        data-id='3e77d1e'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              <strong>14.1 Legal Capacity</strong>
            </p>
            <p>
              By accepting this Agreement, Licensee represents and warrants to have the legal capacity and authority to
              enter into legally binding contracts and this Agreement.
            </p>
            <p>
              <strong>14.2 Independent Contractors</strong>
            </p>
            <p>
              Both Parties are independent contractors under this Agreement. Consequently, nothing in this Agreement is
              intended or may be construed so as to establish a partnership or joint venture between the Parties and
              neither Party shall have the authority (actual or apparent) to bind the other Party.
            </p>
            <p>
              <strong>14.3 No Implied Rights</strong>
            </p>
            <p>
              Other than expressly provided for in this Agreement, nothing in this Agreement grants or shall be
              construed to grant to any Party any right and/or any license to any Intellectual Property Right or
              application therefore (including but not limited to patent applications or patents) which are held by
              and/or in the name of the other Party and/or which are controlled by the other Party, or to any
              Confidential Information received from the other Party.
            </p>
            <p>
              <strong>14.4 Force Majeure</strong>
            </p>
            <p>
              With the exception of payment obligations, neither Party shall be held in breach of its obligations
              hereunder to the extent only that due performance or observance of such obligation is prevented or delayed
              by war and other hostilities, civil commotion, accident, trade disputes, epidemics, pandemics, acts or
              restraints of government imposition or restrictions of imports or exports or any other cause not within
              the control of the Party concerned.
            </p>
            <p>
              The Party concerned shall forthwith notify the other Party of the nature and effect of such event and both
              Parties shall, where the same is practicable, use every reasonable endeavour to minimize such effect and
              to comply with the respective obligation herein contained as nearly as may be in their original form.
            </p>
            <p>
              <strong>14.5 Indemnification</strong>
            </p>
            <p>
              Licensee agrees to release, defend, at Licensor’s option, indemnify, and hold Licensor and its affiliates
              and subsidiaries, and their officers, directors, employees, contractors and agents, harmless from and
              against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable
              legal and accounting fees, arising out of or in any way connected with (i) Licensee’s breach of these
              Agreement (ii) Licensee’s negligent or improper use or misuse of the Product, (iii) Licensee’s breach of
              any laws, regulations or third party rights; or (iv) Licensee’s breach of any Payment Service related
              terms and conditions; or (v) Licensee’s negligence, misconduct or intentional omission in connection with
              or during the course of Licensee’s use of the Product or Payments Service.
            </p>
            <p>
              <strong>14.6 Notices</strong>
            </p>
            <p>
              All notices or other communication required or permitted to be given in writing under this Agreement must
              be given in the English language by email, to the addresses provided by Licensee or such other addresses
              as the Parties may have designated to each other.
            </p>
            <p>
              <strong>14.7 Other Agreements and Amendments</strong>
            </p>
            <p>
              This Agreement and any agreement entered into pursuant to this Agreement constitutes the entire agreement
              between the Parties and supersedes and replaces any and all prior negotiations, arrangements and
              understandings, whether or not in writing, between the Parties with respect to the subject matter of the
              Agreement. No variation of this Agreement is valid unless it is in writing and signed by or on behalf of
              each Party.
            </p>
            <p>
              <strong>14.8 Assignment</strong>
            </p>
            <p>
              This Agreement is binding upon and inures for the benefit of the successors of Licensee but may not be
              assigned by Licensee, except with the prior written consent of the Licensor.
            </p>
            <p>
              <strong>14.9 Waivers</strong>
            </p>
            <p>
              No failure or delay by any Party in exercising any right or remedy provided by law or pursuant to this
              Agreement will impair such right or remedy or be construed as a waiver of it and will not preclude its
              exercise at any subsequent time and no single or partial exercise of any such right or remedy will
              preclude any further exercise of it or the exercise of any other remedy.
            </p>
            <p>
              <strong>14.10 Severability</strong>
            </p>
            <p>
              If any provision of this Agreement or of any of the documents contemplated in it is held to be invalid or
              unenforceable, then such provision will (so far as it is invalid or unenforceable) have no effect and will
              be deemed not to be included in this Agreement or the relevant document, but without invalidating any of
              the remaining provisions of this Agreement or that document. The Parties must then use all reasonable
              endeavours to replace the invalid or unenforceable provision by a valid and enforceable substitute
              provision the effect of which is as close as possible to the intended effect of the invalid or
              unenforceable provision.
            </p>
            <p>
              <strong>14.11 Third Party Software</strong>
            </p>
            <p>
              Licensee agrees, that Licensor may use Third Party Software for its Product which shall be notified to
              Licensee upon its prior written request. This Third Party Software is licensed to Licensee by the Third
              Party Software publisher or provider and therefore, governed by the terms and conditions of the applicable
              Third Party Software terms and conditions which shall apply directly between the Licensee and the Third
              Party Software provider. Licensee understands to be bound by and to comply with these open source license
              terms and conditions in connection with the use of the Product. Licensor provides all Third-Party Software
              “AS IS” without any liability and warranties or representations, express or implied. Licensor is under no
              obligation, to indemnify Licensee against any third-party infringement claim. Licensor will also have no
              liability for any damages caused by the open source software. Licensee shall not modify or create any
              derivative work of the Third-Party Software.
            </p>
            <p>
              <strong>14.12 Data Privacy</strong>
            </p>
            <p>
              Unless otherwise agreed in this Agreement, with regards to the Use of the Product Licensor’s “Privacy
              Policy“ under&nbsp;www.ventory.io/privacy-policy/ applies, which shall become essential part of this
              Agreement by reference.
            </p>
            <p>
              <strong>14.13 Terms of Use</strong>
            </p>
            <p>
              Unless otherwise agreed in this Agreement, with regards to the Use of the Product Licensor’s “TERMS OF
              USE” under&nbsp;www.ventory.io/terms/ applies, which shall become essential part of this Agreement by
              reference.
            </p>
            <p>
              <strong>14.14 Modifications</strong>
            </p>
            <p>
              The Licensor may modify the terms of this Agreement in its sole discretion and such modifications shall
              take effect and be binding on Licensee on the earliest date which they are posted to the Licensor’s
              publicly available website or delivered to Licensee via electronic or physical delivery. No one other than
              the Licensor has the right to modify this Agreement.
            </p>
          </div>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-568543a elementor-widget elementor-widget-heading'
        data-id='568543a'
        data-element_type='widget'
        data-widget_type='heading.default'
      >
        <div className='elementor-widget-container'>
          <h4 className='elementor-heading-title elementor-size-default'>15. GOVERNING LAW AND JURISDICTION</h4>
        </div>
      </div>
      <div
        className='elementor-element elementor-element-5249e78 elementor-widget elementor-widget-text-editor'
        data-id='5249e78'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              The Agreement is governed by and must be construed, interpreted in accordance with the laws of Belgium
              without given effect to the conflict of law principles thereof. The courts of Leuven have exclusive
              jurisdiction over any dispute, legal action and proceedings arising out of or related to the Agreement,
              including its termination, which shall be binding and enforceable upon the Parties worldwide. In the event
              of any proceeding or litigation arising out of this Agreement, the prevailing Party shall be entitled to
              recover from the non-prevailing Party its legal fees, court fees and related costs to the extent and in
              ratio of its success. Notwithstanding the foregoing, Licensor may bring legal actions against Licensee in
              the country where Licensee has its seat, if it deems necessary for the enforceability of its rights
              regarding payments by Licensee under the Agreement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
