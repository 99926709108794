import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export enum ProductExportField {
  productName = 'productName',
  productNumber = 'productNumber',
  stockLocation = 'stockLocation',
  binName = 'binName',
  quantity = 'quantity',
  lpn = 'lpn',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  createdBy = 'createdBy',
  updatedBy = 'updatedBy',
  serialShippingContainerCode = 'serialShippingContainerCode',
  globalTradeItemNumber = 'globalTradeItemNumber',
  globalTradeItemNumberOfTradeItemsContained = 'globalTradeItemNumberOfTradeItemsContained',
  lotNumber = 'lotNumber',
  productionDate = 'productionDate',
  dueDate = 'dueDate',
  packagingDate = 'packagingDate',
  bestBeforeDate = 'bestBeforeDate',
  expirationDate = 'expirationDate',
  internalProductVariant = 'internalProductVariant',
  serialNumber = 'serialNumber',
  consumerProductVariant = 'consumerProductVariant',
  customerPartNumber = 'customerPartNumber',
  secondarySerialNumber = 'secondarySerialNumber',
  variableCountOfItems = 'variableCountOfItems',
  countOfTradeItemsContained = 'countOfTradeItemsContained',
  customerPurchaseOrderNumber = 'customerPurchaseOrderNumber',
  routingCode = 'routingCode',
  countryOfOriginOfTradeItem = 'countryOfOriginOfTradeItem',
  countryOfInitialProcessing = 'countryOfInitialProcessing',
  countryOfProcessing = 'countryOfProcessing',
  countryOfDisassembly = 'countryOfDisassembly',
  countryCoveringFullProcessChain = 'countryCoveringFullProcessChain',
  natoStockNumber = 'natoStockNumber',
  expirationDateAndTime = 'expirationDateAndTime',
  pricePerUnitOfMeasure = 'pricePerUnitOfMeasure',
  dateAndTimeOfProduction = 'dateAndTimeOfProduction',
  unitOfMeasure = 'unitOfMeasure',
}

export class ProductExportConfigurationCustomField {
  index: number;

  customField: string;

  constructor(customField: string, index: number) {
    this.index = index;
    this.customField = customField;
  }
}

export class ProductExportConfigurationMapping extends ProductExportConfigurationCustomField {
  field: ProductExportField;

  constructor(customField: string, index: number, field: ProductExportField) {
    super(customField, index);
    this.field = field;
  }
}

export class ProductExportConfiguration extends CompanyEntity {
  name!: string;
  filename: string = 'ventory_stock_${TIMESTAMP}.csv';
  prefix?: string;
  delimiter: string = ',';
  quote?: string;
  fieldMapping: ProductExportConfigurationMapping[] = [...DEFAULT_MAPPING().values()];
  default: boolean = false;

  forUpdate(): UpdateProductExportConfigurationInput {
    return UpdateProductExportConfigurationInput.from(this, UpdateProductExportConfigurationInput);
  }

  forDelete(): DeleteProductExportConfigurationInput {
    return DeleteProductExportConfigurationInput.from(this, DeleteProductExportConfigurationInput);
  }

  validate(fields: any[]) {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof ProductExportConfiguration)[],
      (field: keyof ProductExportConfiguration) => {
        return null;
      },
    );
  }

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    this.default = false;
    Object.assign(this, cloneDeep(obj));
    this.default = obj.default || false;
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withFilename(filename: string) {
    this.filename = filename;
    return cloneDeep(this);
  }

  withPrefix(prefix: string) {
    this.prefix = prefix;
    return cloneDeep(this);
  }

  withDelimiter(delimiter: string) {
    this.delimiter = delimiter;
    return cloneDeep(this);
  }

  withDefault(value: boolean) {
    this.default = value;
    return cloneDeep(this);
  }
}
export class CreateProductExportConfigurationInput extends forCreate(ProductExportConfiguration) {}

export class UpdateProductExportConfigurationInput extends forUpdate(ProductExportConfiguration) {}

export class DeleteProductExportConfigurationInput extends forDelete(ProductExportConfiguration) {}

const DEFAULT_MAPPING = () => {
  return new Map<ProductExportField, ProductExportConfigurationMapping>([
    [
      ProductExportField.stockLocation,
      {
        customField: 'Stock Location',
        field: ProductExportField.stockLocation,
        index: 0,
      },
    ],
    [
      ProductExportField.productName,
      {
        customField: 'Product Name',
        field: ProductExportField.productName,
        index: 1,
      },
    ],
    [
      ProductExportField.productNumber,
      {
        customField: 'Product Number',
        field: ProductExportField.productNumber,
        index: 2,
      },
    ],
    [
      ProductExportField.binName,
      {
        customField: 'Bin Name',
        field: ProductExportField.binName,
        index: 3,
      },
    ],
    [
      ProductExportField.serialNumber,
      {
        customField: 'Serial Number',
        field: ProductExportField.serialNumber,
        index: 4,
      },
    ],
    [ProductExportField.quantity, { customField: 'Quantity', field: ProductExportField.quantity, index: 5 }],
  ]);
};

export function productExportFieldToString(item: ProductExportField): string {
  switch (item) {
    case ProductExportField.productName:
      return 'Product Name';
    case ProductExportField.productNumber:
      return 'Product Number';
    case ProductExportField.stockLocation:
      return 'Stock Location';
    case ProductExportField.binName:
      return 'Bin Name';
    case ProductExportField.quantity:
      return 'Quantity';
    case ProductExportField.lpn:
      return 'LPN';
    case ProductExportField.createdAt:
      return 'Created At';
    case ProductExportField.updatedAt:
      return 'Updated At';
    case ProductExportField.createdBy:
      return 'Created By';
    case ProductExportField.updatedBy:
      return 'Updated By';
    case ProductExportField.serialShippingContainerCode:
      return 'Serial Shipping Container Code';
    case ProductExportField.globalTradeItemNumber:
      return 'Global Trade Item Number';
    case ProductExportField.globalTradeItemNumberOfTradeItemsContained:
      return 'Global Trade Item Number Of Trade Items Contained';
    case ProductExportField.lotNumber:
      return 'Lot Number';
    case ProductExportField.productionDate:
      return 'Production Data';
    case ProductExportField.dueDate:
      return 'Due Date';
    case ProductExportField.packagingDate:
      return 'Packaging Date';
    case ProductExportField.bestBeforeDate:
      return 'Best Before Date';
    case ProductExportField.expirationDate:
      return 'Expiration Date';
    case ProductExportField.internalProductVariant:
      return 'Internal Product Variant';
    case ProductExportField.serialNumber:
      return 'Serial Number';
    case ProductExportField.consumerProductVariant:
      return 'Consumer Product Variant';
    case ProductExportField.customerPartNumber:
      return 'Customer Part Number';
    case ProductExportField.secondarySerialNumber:
      return 'Secondary Serial Number';
    case ProductExportField.variableCountOfItems:
      return 'Variable Count Of Items';
    case ProductExportField.countOfTradeItemsContained:
      return 'Count Of Trade Items Contained';
    case ProductExportField.customerPurchaseOrderNumber:
      return 'Customer Purchase Order Number';
    case ProductExportField.routingCode:
      return 'Routing Code';
    case ProductExportField.countryOfOriginOfTradeItem:
      return 'Country Of Origin Of Trade Item';
    case ProductExportField.countryOfInitialProcessing:
      return 'Country Of Initial Processing';
    case ProductExportField.countryOfProcessing:
      return 'Country Of Processing';
    case ProductExportField.countryOfDisassembly:
      return 'Country Of Disassembly';
    case ProductExportField.countryCoveringFullProcessChain:
      return 'Country Covering Full Process Chain';
    case ProductExportField.natoStockNumber:
      return 'Nato Stock Number';
    case ProductExportField.expirationDateAndTime:
      return 'Expiration Date And Time';
    case ProductExportField.pricePerUnitOfMeasure:
      return 'Price Per Unit Of Measure';
    case ProductExportField.dateAndTimeOfProduction:
      return 'Date And Time Of Production';
    case ProductExportField.unitOfMeasure:
      return 'Unit Of Measure';
  }
}

export function stringToProductExportField(item: string): string {
  switch (item) {
    case 'Product Name':
      return ProductExportField.productName;
    case 'Product Number':
      return ProductExportField.productNumber;
    case 'Stock Location':
      return ProductExportField.stockLocation;
    case 'Bin Name':
      return ProductExportField.binName;
    case 'Quantity':
      return ProductExportField.quantity;
    case 'LPN':
      return ProductExportField.lpn;
    case 'Created At':
      return ProductExportField.createdAt;
    case 'Updated At':
      return ProductExportField.updatedAt;
    case 'Created By':
      return ProductExportField.createdBy;
    case 'Updated By':
      return ProductExportField.updatedBy;
    case 'Serial Shipping Container Code':
      return ProductExportField.serialShippingContainerCode;
    case 'Global Trade Item Number':
      return ProductExportField.globalTradeItemNumber;
    case 'Global Trade Item Number Of Trade Items Contained':
      return ProductExportField.globalTradeItemNumberOfTradeItemsContained;
    case 'Lot Number':
      return ProductExportField.lotNumber;
    case 'Production Data':
      return ProductExportField.productionDate;
    case 'Due Date':
      return ProductExportField.dueDate;
    case 'Packaging Date':
      return ProductExportField.packagingDate;
    case 'Best Before Date':
      return ProductExportField.bestBeforeDate;
    case 'Expiration Date':
      return ProductExportField.expirationDate;
    case 'Internal Product Variant':
      return ProductExportField.internalProductVariant;
    case 'Serial Number':
      return ProductExportField.serialNumber;
    case 'Consumer Product Variant':
      return ProductExportField.consumerProductVariant;
    case 'Customer Part Number':
      return ProductExportField.customerPartNumber;
    case 'Secondary Serial Number':
      return ProductExportField.secondarySerialNumber;
    case 'Variable Count Of Items':
      return ProductExportField.variableCountOfItems;
    case 'Count Of Trade Items Contained':
      return ProductExportField.countOfTradeItemsContained;
    case 'Customer Purchase Order Number':
      return ProductExportField.customerPurchaseOrderNumber;
    case 'Routing Code':
      return ProductExportField.routingCode;
    case 'Country Of Origin Of Trade Item':
      return ProductExportField.countryOfOriginOfTradeItem;
    case 'Country Of Initial Processing':
      return ProductExportField.countryOfInitialProcessing;
    case 'Country Of Processing':
      return ProductExportField.countryOfProcessing;
    case 'Country Of Disassembly':
      return ProductExportField.countryOfDisassembly;
    case 'Country Covering Full Process Chain':
      return ProductExportField.countryCoveringFullProcessChain;
    case 'Nato Stock Number':
      return ProductExportField.natoStockNumber;
    case 'Expiration Date And Time':
      return ProductExportField.expirationDateAndTime;
    case 'Price Per Unit Of Measure':
      return ProductExportField.pricePerUnitOfMeasure;
    case 'Date And Time Of Production':
      return ProductExportField.dateAndTimeOfProduction;
    case 'Unit Of Measure':
      return ProductExportField.unitOfMeasure;
    default:
      return 'Unknown Export Field';
  }
}
