import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function CloseIcon({ className, height, width, color, onClick }: IconInputProps) {
  return (
    <Icon className={className} onClick={onClick}>
      <svg
        width={width || '14'}
        height={height || '14'}
        viewBox='0 0 14 14'
        fill='none'
        stroke={color || 'currentColor'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M13 1L1 13M1 1L13 13' stroke='#667085' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </Icon>
  );
}
