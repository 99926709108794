import React from 'react';
import { Trigger } from '../types/trigger';

export interface TriggerContextProps {
  triggers: Map<string, Trigger>;
  setTriggers: (triggers: Map<string, Trigger>) => void;
  triggersLoading: boolean;
}

const defaultContext: TriggerContextProps = {
  triggers: new Map(),
  setTriggers: () => {},
  triggersLoading: false,
};

export const TriggerContext = React.createContext<TriggerContextProps>(defaultContext);
