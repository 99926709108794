import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import { ProductMasterDataExportConfigurationContext } from '../../../../../../context/ProductMasterDataExportConfigurationContext';
import {
  CreateProductMasterDataExportConfigurationResponse,
  CreateProductMasterDataExportConfigurationVariables,
  ProductMasterDataExportConfigurationMutations,
} from '../../../../../../graphql/productMasterDataExportConfiguration.graphql';
import {
  ProductMasterDataExportConfiguration,
  ProductMasterDataExportConfigurationMapping,
} from '../../../../../../types/productMasterDataExportConfiguration';
import BackButton from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import CreateButton from '../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import Pane from '../../../../../../VentoryUI/components/common/Pane/Pane';
import ProductMasterDataExportConfigurationInput, {
  DraggableProductMasterDataExportFieldIdentifier,
} from '../Common/ProductMasterDataExportConfigurationInput';

interface CreateProductMasterDataExportConfigurationPaneProps {}

export default function CreateProductMasterDataExportConfigurationPane({}: CreateProductMasterDataExportConfigurationPaneProps) {
  const navigate = useNavigate();
  const { currentCompany } = useContext(CompanyContext);
  const { productMasterDataExportConfigurations, setProductMasterDataExportConfigurations } = useContext(
    ProductMasterDataExportConfigurationContext,
  );

  const [productMasterDataExportConfigurationInput, setProductMasterDataExportConfigurationInput] =
    useState<ProductMasterDataExportConfiguration>(
      new ProductMasterDataExportConfiguration({ companyId: currentCompany.id }),
    );
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<
    CreateProductMasterDataExportConfigurationResponse,
    CreateProductMasterDataExportConfigurationVariables
  >(ProductMasterDataExportConfigurationMutations.create, {
    onCompleted: res => {
      const config = res.createProductMasterDataExportConfiguration[0];
      productMasterDataExportConfigurations.set(config.id, new ProductMasterDataExportConfiguration(config));
      setProductMasterDataExportConfigurations(new Map(productMasterDataExportConfigurations));
      navigate('/reference_data/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleCreate = async (
    configuration: ProductMasterDataExportConfiguration,
    items: DraggableProductMasterDataExportFieldIdentifier[],
  ) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new ProductMasterDataExportConfigurationMapping(i.customField, idx, i.field),
      );
      await create({
        variables: {
          productMasterDataExportConfigurations: [configuration],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const createFooter = (
    configuration: ProductMasterDataExportConfiguration,
    items: DraggableProductMasterDataExportFieldIdentifier[],
  ) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <BackButton disabled={loading} onClick={() => navigate('/reference_data/settings/report')} />
        </Grid>
        <Grid item>
          <CreateButton
            loading={loading}
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
            onClick={() => handleCreate(configuration, items)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Pane error={error}>
      <ProductMasterDataExportConfigurationInput
        configuration={productMasterDataExportConfigurationInput}
        setConfiguration={setProductMasterDataExportConfigurationInput}
        footer={createFooter}
      />
    </Pane>
  );
}
