import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { BillingContext } from '../../../context/BillingContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { SuperUserContext } from '../../../context/SuperUserContext';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import { SuperUserRole } from '../../../types/superUser';
import Dropdown from '../../Common/Dropdown';
import {
  VentorySubscription,
  VentorySubscriptionService,
  VentorySubscriptionState,
  VentorySubscriptionType,
} from '../../../types/billing';
import { t } from '../../../types/translation/Translator';
import { Button } from '../../../VentoryUI/components/common/Button/Button';
import { ColorStyleType } from '../../../VentoryUI/util/color.util';
import { useMutation } from '@apollo/client';
import {
  BillingMutations,
  CreateVentorySubscriptionResponse,
  CreateVentorySubscriptionVariables,
  UpdateVentorySubscriptionResponse,
  UpdateVentorySubscriptionVariables,
} from '../../../graphql/billing.graphql';
import ErrorBox from '../../Common/ErrorBox';

interface BillingSettingsPaneInputProps {}

export default function BillingSettingsPane({}: BillingSettingsPaneInputProps) {
  const { currentCompany, setCurrentCompany } = useContext(CompanyContext);
  const { subscription, setSubscription, billingLoading } = useContext(BillingContext);
  const { superUser } = useContext(SuperUserContext);

  const [subscriptionInput, setSubscriptionInput] = useState<VentorySubscription>(
    new VentorySubscription(subscription),
  );

  const [error, setError] = useState<string>('');
  const [createSubscription, { loading: createLoading }] = useMutation<
    CreateVentorySubscriptionResponse,
    CreateVentorySubscriptionVariables
  >(
    BillingMutations.create,

    {
      onCompleted: res => setSubscription(new VentorySubscription(res.createSubscription)),
      onError: err => setError(err.message),
    },
  );

  const [updateSubscription, { loading }] = useMutation<
    UpdateVentorySubscriptionResponse,
    UpdateVentorySubscriptionVariables
  >(
    BillingMutations.update,

    {
      onCompleted: res => setSubscription(new VentorySubscription(res.updateSubscription)),
      onError: err => setError(err.message),
    },
  );

  const handleUpdate = async () => {
    if (billingLoading) return;

    if (subscriptionInput.id) {
      await updateSubscription({
        variables: {
          subscription: subscriptionInput,
        },
      });
    } else {
      await createSubscription({
        variables: {
          subscription: subscriptionInput,
        },
      });
    }
  };

  useEffect(() => {
    setSubscriptionInput(new VentorySubscription(subscription));
  }, [subscription]);

  return (
    <Paper>
      <Grid container rowSpacing={2} alignContent={'start'}>
        {error ? (
          <Grid item xs={12}>
            <ErrorBox error={error} />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <p>{`Your subscription is currently ${subscription.state}`}</p>
        </Grid>
        {superUser?.role === SuperUserRole.administrator ? (
          <Grid item xs={12} mt={2}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <Dropdown
                      label={t().service.singular.label}
                      values={Object.keys(VentorySubscriptionService)}
                      selectedValue={subscriptionInput.service}
                      toText={item => item[0].toUpperCase() + item.substring(1)}
                      onChange={item => {
                        setSubscriptionInput(subscriptionInput.withService(item as VentorySubscriptionService));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Dropdown
                      label={t().type.singular.label}
                      values={Object.keys(VentorySubscriptionType)}
                      selectedValue={subscriptionInput.type}
                      toText={item => VentorySubscription.typeToString(item as VentorySubscriptionType)}
                      onChange={item => {
                        setSubscriptionInput(subscriptionInput.withType(item as VentorySubscriptionType));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Dropdown
                      label={t().status.singular.label}
                      values={Object.keys(VentorySubscriptionState)}
                      selectedValue={subscriptionInput.state}
                      toText={item => item[0].toUpperCase() + item.substring(1)}
                      onChange={item => {
                        setSubscriptionInput(subscriptionInput.withState(item as VentorySubscriptionState));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      style={ColorStyleType.secondary}
                      text={t().update.singular.label}
                      loading={loading || createLoading}
                      onClick={() => handleUpdate()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}
