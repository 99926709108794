import React, { useContext, useEffect } from 'react';
import { CustomField, CustomFieldEntitySubType, CustomFieldEntityType, CustomFieldType } from '../../types/customField';
import { Order, OrderType } from '../../types/order';
import { CustomFieldContext } from '../../context/CustomFieldContext';
import { Grid } from '@mui/material';
import CustomFieldInput from './CustomFieldInput';
import { classes, testIds } from '../../util/identifiers/identifiers.util';

interface CustomFieldOrderInputProps {
  order: Order;
  setOrder: (order: Order) => void;
  disabled?: boolean;
}

export default function CustomFieldOrderInput({ order, setOrder, disabled = false }: CustomFieldOrderInputProps) {
  const { customFields } = useContext(CustomFieldContext);

  const entitySubtype =
    order.type === OrderType.inbound ? CustomFieldEntitySubType.inbound : CustomFieldEntitySubType.outbound;

  const items = [...customFields.values()]
    .filter(
      v => v.entityType === CustomFieldEntityType.order && (!v.entitySubtype || v.entitySubtype === entitySubtype),
    )
    .sort((a, b) => b.index - a.index);

  const handleChange = (item: CustomField, value: any) => {
    switch (item.type) {
      case CustomFieldType.text:
        return setOrder(order.withCustomField({ ...item, value: String(value) }));
      case CustomFieldType.bool:
        return setOrder(order.withCustomField({ ...item, value: String(value) }));
      case CustomFieldType.date:
        return setOrder(order.withCustomField({ ...item, value: new Date(value).toISOString() }));
      case CustomFieldType.listOfValues:
        if (value === null) {
          return setOrder(order.removeCustomField(item.id));
        } else {
          return setOrder(order.withCustomField({ ...item, value: String(value) }));
        }
    }
  };

  useEffect(() => {
    if (!order.customFields.size) {
      [...customFields.values()]
        .filter(cf => cf.defaultValue)
        .map(cf =>
          setOrder(
            order.withCustomField({
              id: cf.id,
              mandatory: cf.mandatory,
              name: cf.name,
              value: String(cf.defaultValue),
              type: cf.type,
            }),
          ),
        );
    }
  }, [order]);

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      {items.map(item => {
        return (
          <Grid
            key={item.id}
            item
            xs={6}
            mt={item.type === CustomFieldType.bool ? 2 : 0}
            className={classes.customFieldInput.name}
            data-testid={testIds.customFieldInput(item.name)}
          >
            <CustomFieldInput
              disabled={disabled}
              value={order.customFields.get(item.id)?.value}
              item={item}
              onChange={handleChange}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
