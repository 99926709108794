import { Grid } from '@mui/material';
import React from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Paper from '../../VentoryUI/components/common/Paper/Paper';

export default function AdministratorNotAllowedScreen() {
  return (
    <Paper>
      <Grid container alignContent={'center'} height={'100vh'}>
        <Grid item xs={12} textAlign={'center'}>
          <ReportProblemIcon className='text-red-500' sx={{ fontSize: '50px' }} />
          <p className='font-semibold text-xl'>{'Access Denied'}</p>
        </Grid>
      </Grid>
    </Paper>
  );
}
