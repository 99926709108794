import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity } from './common/entity';

export enum CompanyRelationService {
  sap = 'SAP',
  businessCentral = 'BusinessCentral',
}

export class IntegrationSettings extends CompanyEntity {
  name!: string;
  type!: CompanyRelationService | null;
  settingsId!: string;

  // SAP
  apiKey?: string;
  url?: string;
  externalId?: string;

  // Business Central
  tenantId?: string;
  environment?: string;
  clientId?: string;
  clientSecret?: string;

  forUpdate() {
    throw new Error('Method not implemented.');
  }

  forDelete() {
    throw new Error('Method not implemented.');
  }

  validate(fields: any[]) {
    throw new Error('Method not implemented.');
  }

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  withType(type: CompanyRelationService | null) {
    this.type = type;
    return cloneDeep(this);
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  // SAP
  withExternalId(externalId: string) {
    this.externalId = externalId;
    return cloneDeep(this);
  }

  withUrl(url: string) {
    this.url = url;
    return cloneDeep(this);
  }

  withApiKey(key: string) {
    this.apiKey = key;
    return cloneDeep(this);
  }

  // Business Central
  withTenantId(id: string) {
    this.tenantId = id;
    return cloneDeep(this);
  }

  withEnvironment(environment: string) {
    this.environment = environment;
    return cloneDeep(this);
  }

  withClientId(clientId: string) {
    this.clientId = clientId;
    return cloneDeep(this);
  }

  withClientSecret(clientSecret: string) {
    this.clientSecret = clientSecret;
    return cloneDeep(this);
  }
}

export function companyRelationServiceToString(item: string) {
  switch (item) {
    case CompanyRelationService.businessCentral:
      return 'Business Central';
    case CompanyRelationService.sap:
      return 'SAP';
  }
  return '';
}
