import { Divider, Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { t } from '../../../../../types/translation/Translator';
import {
  ProductTransaction,
  ProductTransactionParentType,
  ProductTransactionStatus,
  ProductTransactionType,
  productTransactionTypeToCustomFieldEntitySubtype,
} from '../../../../../types/productTransaction';
import TextInput from '../../../../Common/TextInput';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { LotContext } from '../../../../../context/LotContext';
import DropdownSelect from '../../../../Common/DropdownSelect';
import { BinContext } from '../../../../../context/BinContext';
import { useMutation } from '@apollo/client';
import {
  ProcessProductTransactionsResponse,
  ProcessProductTransactionsVariables,
  ProductTransactionMutations,
  UpdateProductTransactionCustomFieldsResponse,
  UpdateProductTransactionCustomFieldsVariables,
  RollbackProductTransactionsResponse,
  RollbackProductTransactionsVariables,
} from '../../../../../graphql/productTransaction.graphql';
import { ProductContext } from '../../../../../context/ProductContext';
import ErrorBox from '../../../../Common/ErrorBox';
import { errorCodeToText } from '../../../../../util/error.util';
import { BinStatusContext } from '../../../../../context/BinStatusContext';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ProductTransactionContext } from '../../../../../context/ProductTransactionContext';
import { StockLocationRoleAssignmentContext } from '../../../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../../../types/stockLocationRoleAssignment';
import { getSuffix, toQuantityString } from '../../../../../types/unitOfMeasure';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import { CustomFieldEntityType, CustomFieldType } from '../../../../../types/customField';

import { OrderContext } from '../../../../../context/OrderContext';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';
import { UpdateButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/UpdateButton';
import { CancelButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { ProcessButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/ProcessButton';
import { FooterButton } from '../../../../../VentoryUI/components/common/Button/Button';
import CustomFieldInput from '../../../../Common/CustomFieldInput';
import { OrderParentType } from '../../../../../types/order';

interface ProcessOrderTransactionInfoPaneProps {
  transaction: ProductTransaction;
  setTransaction: (transaction: ProductTransaction) => void;
  setError: (error: string) => void;
  onClose: () => void;
  disabled?: boolean;
  parentInfo?: boolean;
}

export default function ProcessOrderTransactionInfoPane({
  transaction,
  setTransaction,
  setError,
  onClose,
  disabled,
  parentInfo = false,
}: ProcessOrderTransactionInfoPaneProps) {
  dayjs.extend(customParseFormat);

  const { productMasterData } = useContext(ProductMasterDataContext);
  const { orders } = useContext(OrderContext);
  const { lots } = useContext(LotContext);
  const { bins } = useContext(BinContext);
  const { products } = useContext(ProductContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { productTransactions, setProductTransactions } = useContext(ProductTransactionContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { customFields } = useContext(CustomFieldContext);

  const [process, { loading }] = useMutation<ProcessProductTransactionsResponse, ProcessProductTransactionsVariables>(
    ProductTransactionMutations.process,
    {
      onCompleted: res => {
        const transaction = res.processProductTransaction[0];
        productTransactions.set(transaction.id, new ProductTransaction(transaction));
        setProductTransactions(new Map(productTransactions));
        onClose();
      },
      onError: err => setError(errorCodeToText(err.message)),
    },
  );

  const [update, { loading: updateLoading }] = useMutation<
    UpdateProductTransactionCustomFieldsResponse,
    UpdateProductTransactionCustomFieldsVariables
  >(ProductTransactionMutations.updateCustomFields, {
    onCompleted: res => {
      const transaction = res.updateProductTransactionCustomFields[0];
      productTransactions.set(transaction.id, new ProductTransaction(transaction));
      setProductTransactions(new Map(productTransactions));
      onClose();
    },
  });

  const [rollback, { loading: rollbackLoading }] = useMutation<
    RollbackProductTransactionsResponse,
    RollbackProductTransactionsVariables
  >(ProductTransactionMutations.rollback, {
    onCompleted: res => {
      const transaction = res.rollbackProductTransaction[0];
      productTransactions.set(transaction.id, transaction);
      setProductTransactions(new Map(productTransactions));
      onClose();
    },
    onError: err => setError(errorCodeToText(err.message)),
  });

  const relevantCustomFields = useMemo(() => {
    return [...customFields.values()].filter(
      cf =>
        cf.entityType === CustomFieldEntityType.productTransaction &&
        cf.entitySubtype === productTransactionTypeToCustomFieldEntitySubtype(transaction.type),
    );
  }, [customFields]);

  const pmd = productMasterData.get(transaction.product.pmdId || '');
  if (!pmd) return null;

  const handleProcess = async () => {
    try {
      await process({
        variables: {
          productTransactions: [transaction.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleUpdate = async () => {
    try {
      await update({ variables: { productTransactions: [transaction.forUpdate()] } });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleCancel = async () => {
    try {
      transaction.status = ProductTransactionStatus.cancelled;

      await process({
        variables: {
          productTransactions: [transaction.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleRollback = async () => {
    try {
      transaction.status = ProductTransactionStatus.rollback;

      await rollback({
        variables: {
          productTransactions: [transaction.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const binsForProduct = new Map<string, string>();
  [...(products.get(pmd.id)?.values() || [])].forEach(p => binsForProduct.set(p.binId, p.quantity));

  const inboundAllowedBins = new Set<string>(
    [...bins.values()]
      .filter(bin => binStatuses.get(bins.get(bin.id)?.binStatusId || '')?.inboundAllowed)
      .map(bin => bin.id),
  );
  const outboundAllowedBins = new Set<string>(
    [...binsForProduct.keys()].filter(binId => binStatuses.get(bins.get(binId)?.binStatusId || '')?.outboundAllowed),
  );

  const stockLocationId =
    transaction.type === ProductTransactionType.inbound || transaction.type === ProductTransactionType.replenish
      ? transaction.product.toStockLocationId
      : transaction.product.fromStockLocationId;
  if (!stockLocationId) return null;

  const isViewer = !hasStockLocationRole(transaction.companyId, stockLocationId, StockLocationRole.STOCK_LOCATION_USER);

  disabled = disabled || isViewer;

  const buttons = useMemo(() => {
    const shown: FooterButton[] = [
      {
        align: 'left',
        text: t().cancelTransaction.singular.label,
        testId: testIds.cancelTransaction,
        disabled:
          transaction.status === ProductTransactionStatus.cancelled ||
          transaction.status === ProductTransactionStatus.failed ||
          transaction.status === ProductTransactionStatus.rollback ||
          rollbackLoading ||
          transaction.externalIdentifier?.type === 'purchaseOrder',
        loading: loading || rollbackLoading,
        onClick: transaction.status === ProductTransactionStatus.created ? handleCancel : handleRollback,
      },
      CancelButtonTemplate(onClose, { style: disabled ? 'secondary' : 'primary' }),
    ];

    if (!disabled) {
      if (relevantCustomFields.length && transaction.externalIdentifier?.type !== 'purchaseOrder') {
        shown.push(UpdateButtonTemplate(handleUpdate, { loading: loading }));
      }

      shown.push(
        ProcessButtonTemplate(handleProcess, {
          disabled:
            (pmd.serialManaged && !transaction.product.serialNbr) ||
            (pmd.lpnManaged && !transaction.product.lpn) ||
            (pmd.lotManaged && !transaction.product.lotId) ||
            (transaction.type === ProductTransactionType.inbound && !transaction.product.toBinId) ||
            (transaction.type === ProductTransactionType.outbound && !transaction.product.fromBinId) ||
            transaction.status !== ProductTransactionStatus.created ||
            isNaN(transaction.product.processedQuantity || parseFloat('')) ||
            disabled,
          loading: loading,
        }),
      );
    }

    return shown;
  }, [disabled, transaction, loading, rollbackLoading]);

  return (
    <ModalPane footerButtons={buttons}>
      <Grid container height={'100%'} columnSpacing={1} alignContent={'space-between'}>
        <Grid item xs={12}>
          <ErrorBox error={transaction.reasonForFailure ? `Reason for failure: ${transaction.reasonForFailure}` : ''} />
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={6}>
              <TextInput
                disabled
                value={pmd.productName}
                label={t().productName.singular.label}
                onChange={() => {}}
                testId={testIds.productName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                disabled
                value={pmd.productNumber}
                label={t().productNumber.singular.label}
                onChange={() => {}}
                testId={testIds.productNumber}
              />
            </Grid>
            {parentInfo && transaction.parentId && transaction.parentType === ProductTransactionParentType.order ? (
              <Grid item xs={12}>
                <TextInput
                  label={t().order.singular.label}
                  disabled
                  value={orders.get(transaction.parentId)?.number || ''}
                  onChange={() => {}}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>
            {transaction.type === ProductTransactionType.outbound ||
            transaction.type === ProductTransactionType.move ? (
              <Grid item xs={6}>
                <DropdownSelect
                  testId={testIds.bin}
                  mandatory
                  disabled={
                    transaction.status !== ProductTransactionStatus.created ||
                    disabled ||
                    orders.get(transaction.parentId || '')?.parentType === OrderParentType.task
                  }
                  placeholder={t().sourceBin.singular.label}
                  label={t().sourceNumber.singular.label}
                  values={[...bins.values()]
                    .filter(
                      b =>
                        b.stockLocationId === transaction.product.fromStockLocationId &&
                        binsForProduct.has(b.id) &&
                        outboundAllowedBins.has(b.id),
                    )
                    .sort(
                      (a, b) =>
                        parseFloat(binsForProduct.get(b.id) || '0') - parseFloat(binsForProduct.get(a.id) || '0'),
                    )}
                  selectedValue={bins.get(transaction.product.fromBinId || '') || null}
                  toText={item => `${item.name}`}
                  toElement={item => (
                    <Grid container>
                      <Grid item flexGrow={1}>
                        <p>{item.name}</p>
                      </Grid>
                      <Grid item my={'auto'}>
                        <p className='text-sm text-gray-400'>{`${toQuantityString(
                          (binsForProduct.get(item.id) || 0).toString(),
                          pmd.unitOfMeasure,
                        )}`}</p>
                      </Grid>
                    </Grid>
                  )}
                  onChange={bin => {
                    const availableQuantity = parseFloat(binsForProduct.get(bin?.id || '') || '0');
                    if (
                      transaction.product.quantity > availableQuantity &&
                      availableQuantity &&
                      (transaction.product.processedQuantity || 0) > availableQuantity
                    ) {
                      setTransaction(transaction.withFromBinId(bin?.id).withQuantityToProcess(availableQuantity));
                    } else {
                      setTransaction(transaction.withFromBinId(bin?.id));
                    }
                  }}
                />
              </Grid>
            ) : null}
            {transaction.type === ProductTransactionType.inbound || transaction.type === ProductTransactionType.move ? (
              <Grid item xs={6}>
                <DropdownSelect
                  testId={testIds.bin}
                  mandatory
                  disabled={
                    transaction.status !== ProductTransactionStatus.created ||
                    disabled ||
                    (orders.get(transaction.parentId || '')?.parentType === OrderParentType.task &&
                      transaction.type === ProductTransactionType.inbound)
                  }
                  placeholder={t().destinationBin.singular.label}
                  label={t().destinationNumber.singular.label}
                  values={[...bins.values()]
                    .filter(
                      b => b.stockLocationId === transaction.product.toStockLocationId && inboundAllowedBins.has(b.id),
                    )
                    .sort(
                      (a, b) =>
                        parseFloat(binsForProduct.get(b.id) || '0') - parseFloat(binsForProduct.get(a.id) || '0'),
                    )}
                  selectedValue={bins.get(transaction.product.toBinId || '') || null}
                  toText={item => `${item.name}`}
                  toElement={item => (
                    <Grid container>
                      <Grid item flexGrow={1}>
                        <p>{item.name}</p>
                      </Grid>
                      <Grid item my={'auto'}>
                        <p className='text-sm text-gray-400'>{`${toQuantityString(
                          (binsForProduct.get(item.id) || 0).toString(),
                          pmd.unitOfMeasure,
                        )}`}</p>
                      </Grid>
                    </Grid>
                  )}
                  onChange={bin => setTransaction(transaction.withToBinId(bin?.id))}
                />
              </Grid>
            ) : null}
            {pmd.serialManaged ? (
              <Grid item xs={6}>
                <TextInput
                  disabled={transaction.status !== ProductTransactionStatus.created || disabled}
                  dynamicUpdate
                  mandatory
                  label={t().serialNumber.singular.label}
                  placeholder={t().serialNumber.singular.label}
                  onChange={v => setTransaction(transaction.withSerial(v))}
                  testId={testIds.serial}
                  value={transaction.product.serialNbr}
                />
              </Grid>
            ) : null}
            {pmd.lpnManaged ? (
              <Grid item xs={6}>
                <TextInput
                  disabled={transaction.status !== ProductTransactionStatus.created || disabled}
                  mandatory
                  label={t().lpn.singular.label}
                  placeholder={t().lpn.singular.label}
                  onChange={v => setTransaction(transaction.withLPN(v))}
                  testId={testIds.lpn}
                  value={transaction.product.lpn}
                />
              </Grid>
            ) : null}
            {pmd.lotManaged ? (
              <Grid item xs={6}>
                <DropdownSelect
                  mandatory
                  disabled={transaction.status !== ProductTransactionStatus.created || disabled}
                  placeholder={t().lotNumber.singular.label}
                  label={t().lotNumber.singular.label}
                  values={[...lots.values()].filter(lot => lot.productMasterDataId === transaction.product.pmdId)}
                  selectedValue={lots.get(transaction.product.lotId || '') || null}
                  toText={item => item.number}
                  onChange={v => setTransaction(transaction.withLotId(v?.id))}
                  testId={testIds.lot}
                  toElement={item => {
                    const existingLotProducts = [...(products.get(transaction.product.pmdId!)?.values() ?? [])].filter(
                      element =>
                        element.lotId === item.id &&
                        element.stockLocationId ==
                          (transaction.product.fromStockLocationId || transaction.product.toStockLocationId),
                    );
                    let quantity;

                    if (!existingLotProducts.length) {
                      quantity = 0;
                    } else {
                      quantity = existingLotProducts
                        .map(element => parseFloat(element.quantity))
                        .reduce((a, b) => a + b);
                    }

                    return (
                      <Grid container>
                        <Grid item xs={10}>
                          <Grid container>
                            <Grid item>
                              <p>{item.number}</p>
                              {item.expirationDate ? (
                                <p className='text-sm text-gray-400'>{`${dayjs(item.expirationDate)
                                  .toDate()
                                  .toDateString()}`}</p>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} my={'auto'} textAlign={'end'}>
                          <p>{quantity}</p>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <TextInput
                label={t().expectedQuantity.singular.label}
                disabled
                onChange={() => {}}
                testId={testIds.expectedQuantity}
                value={transaction.product.quantity.toString()}
                suffix={getSuffix(productMasterData.get(transaction.product.pmdId || '')?.unitOfMeasure)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                dynamicUpdate
                label={t().processQuantity.singular.label}
                disabled={
                  transaction.status !== ProductTransactionStatus.created ||
                  pmd.lpnManaged ||
                  pmd.serialManaged ||
                  disabled
                }
                onChange={v => setTransaction(transaction.withQuantityToProcess(parseFloat(v)))}
                testId={testIds.processQuantity}
                value={transaction.product.processedQuantity?.toString()}
                suffix={getSuffix(productMasterData.get(transaction.product.pmdId || '')?.unitOfMeasure)}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container rowSpacing={2}>
                {relevantCustomFields
                  .sort((a, b) => a.index - b.index)
                  .map(item => {
                    if (item.type === CustomFieldType.date) {
                      return (
                        <Grid item xs={12}>
                          <Grid container justifyContent={'flex-end'}>
                            <Grid item xs={6}>
                              <CustomFieldInput
                                disabled={true}
                                value={transaction.customFields.get(item.id)?.value}
                                item={item}
                                onChange={(item, value) =>
                                  setTransaction(
                                    transaction.withCustomField({
                                      id: item.id,
                                      mandatory: item.mandatory,
                                      value: !value ? '' : value.toISOString(),
                                      name: item.name,
                                      type: item.type,
                                    }),
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    } else if (item.type === CustomFieldType.bool) {
                      return (
                        <Grid item xs={12}>
                          <Grid container justifyContent={'flex-end'}>
                            <Grid item xs={6}>
                              <CustomFieldInput
                                disabled={disabled}
                                value={transaction.customFields.get(item.id)?.value}
                                item={item}
                                onChange={(item, value) =>
                                  setTransaction(
                                    transaction.withCustomField({
                                      id: item.id,
                                      mandatory: item.mandatory,
                                      value: String(value),
                                      name: item.name,
                                      type: item.type,
                                    }),
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
