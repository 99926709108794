import { Grid } from '@mui/material';
import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { ChartContainer } from '../Chart';
import { RadialBar } from './RadialBar';
import { VentoryColor } from '../../../util/color.util';
import { LoadingSpinner } from '../../../../components/Common/LoadingSpinner';

interface RadialChartProps {
  value: number;
  max: number;
  text: string;
  color?: string;
  loading?: boolean;
  className?: string;
}

export function RadialChart({
  value,
  max,
  text,
  className,
  color = VentoryColor.primaryVentoryColor,
  loading,
}: RadialChartProps) {
  const container = useRef<HTMLDivElement>();
  const wrapper = useRef<SVGSVGElement>();

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const margin = { left: 40, bottom: 40 };

  const handleResize = () => {
    if (container.current?.offsetWidth) {
      setWidth(container.current.offsetWidth);
      setHeight(container.current.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    handleResize();
  }, [loading]);

  return (
    <ChartContainer color={color} text={text} className={className}>
      {loading ? (
        <Grid container display={'flex'} className={className} alignContent={'center'}>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <LoadingSpinner color={color.toString()} />
          </Grid>
        </Grid>
      ) : (
        <Grid container className='h-full'>
          <Grid item xs={12} sm={6} md={12} lg={7} display={'flex'}>
            <div ref={container as LegacyRef<HTMLDivElement>} className='h-full w-full '>
              <svg ref={wrapper as LegacyRef<SVGSVGElement>} className='h-full w-full'>
                <g>
                  <RadialBar height={height} width={width} value={value} max={max} color={color} />
                </g>
              </svg>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={5} className={`pt-4 px-2 pb-2 ${className}`}>
            <Grid container display='block' rowSpacing={1} width={'fit-content'}>
              <Grid item>
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} display={'flex'} marginY={'auto'}>
                    <div className='w-3 h-3 mr-1 rounded-full my-auto' style={{ backgroundColor: color }}></div>
                    <p className='font-ventory-400 text-ventory-grey-500 text-ventory-15 hover:text-black cursor-pointer hover:font-ventory-base'>
                      {`${'Counted'}`}
                    </p>
                  </Grid>
                  <Grid item xs={12} marginY={'auto'}>
                    <p className='font-ventory-400 pl-4 text-ventory-grey-300 text-ventory-15 '>
                      {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} display={'flex'} marginY={'auto'}>
                    <div
                      className='w-3 h-3 mr-1 rounded-full my-auto'
                      style={{ backgroundColor: 'rgba(234, 236, 240, 1)' }}
                    ></div>
                    <p className='font-ventory-400 text-ventory-grey-500 text-ventory-15 hover:text-black cursor-pointer hover:font-ventory-base'>
                      {`${'Uncounted'}`}
                    </p>
                  </Grid>
                  <Grid item xs={12} marginY={'auto'}>
                    <p className='font-ventory-400 pl-4 text-ventory-grey-300 text-ventory-15 '>
                      {(max - value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </ChartContainer>
  );
}
