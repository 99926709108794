import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { UpdateUserResponse, UpdateUserVariables, UserMutations } from '../../../../graphql/user.graphql';
import { User } from '../../../../types/user';
import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../Common/TextInput';
import DropdownSelect from '../../../Common/DropdownSelect';
import {
  LanguageRegionDesignator,
  isValidLanguageRegionDesignator,
  storageStringToLanguageRegionDesignator,
} from '../../../../types/common/languageRegionDesignator';
import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { CancelButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { SaveButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import UploadFileInput from '../../../../VentoryUI/components/common/File/UploadFileInput';
import { CreateFileInput, FileEntity, FileEntityType } from '../../../../types/file';
import { CreateFilesResponse, CreateFilesVariables, FileMutations } from '../../../../graphql/file.graphql';
import { FileContext } from '../../../../context/FileContext';
import ProfileImage from '../../../../VentoryUI/components/common/File/Image/ProfileImageViewLoader';
import { t } from '../../../../types/translation/Translator';

interface UpdateProfileModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function UpdateProfileModal({ open, setOpen }: UpdateProfileModalInputProps) {
  if (!open) return null;

  const { currentUser, setCurrentUser, signOut } = useContext(UserContext);
  const { files, setFiles } = useContext(FileContext);

  const [userInput, setUserInput] = useState(currentUser || new User({}));

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateFilesResponse, CreateFilesVariables>(FileMutations.create, {
    onCompleted: result => {
      result.createFiles.forEach(file => files.set(file.id, new FileEntity(file)));
      setFiles(new Map(files));
    },
    onError: error => setError(error.message),
  });

  const [update, { loading: updateLoading }] = useMutation<UpdateUserResponse, UpdateUserVariables>(
    UserMutations.update,
  );

  const handleUpdate = async () => {
    try {
      await update({
        variables: {
          user: userInput,
        },
        onCompleted: res => {
          signOut();
        },
        onError: res => setError(res.message),
      });
    } catch (e) {
      setError(String(e));
    }
    handleClose();
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const handleFile = async (files: FileList | null) => {
    if (!files?.length) {
      setUserInput(userInput.withProfilePicture(''));
    } else {
      const file = files[0];
      CreateFileInput.fromFile(
        file,
        userInput.id,
        FileEntityType.user,
        '00000000-0000-0000-0000-000000000000',
        async result => {
          const response = await create({ variables: { files: [result] } });
          const fileId = response.data?.createFiles[0].id;
          if (!fileId) return setError('Something went wrong uploading your file, please try again later!');
          await update({
            variables: { user: userInput.withProfilePicture(fileId) },
            onCompleted: response => {
              setUserInput(new User(response.updateUser));
              setCurrentUser(new User(response.updateUser));
            },
            onError: error => setError(error.message),
          });
        },
      );
    }
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().updateProfile.singular.label}>
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: updateLoading }),
          SaveButtonTemplate(handleUpdate, { loading: updateLoading }),
        ]}
      >
        <Grid container rowSpacing={1} alignContent={'space-between'}>
          <Grid item xs={12} className='bg-yellow-100 h3 py-3 px-4 border-2 border-yellow-200'>
            <p style={{ textAlign: 'center' }} className='font-semibold text-yellow-500 text-sm'>
              {t().updatingYourProfileWillSignYouOut.singular.label}
            </p>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t().firstName.singular.label}
                  placeholder={t().firstName.singular.label}
                  value={userInput.firstName}
                  onChange={v => (userInput.firstName = v)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t().lastName.singular.label}
                  placeholder={t().lastName.singular.label}
                  value={userInput.lastName}
                  onChange={v => (userInput.lastName = v)}
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelect<LanguageRegionDesignator>
                  label={t().language.singular.label}
                  values={Object.values(LanguageRegionDesignator).filter(isValidLanguageRegionDesignator)}
                  selectedValue={storageStringToLanguageRegionDesignator(userInput.language) || null}
                  toText={value => languages[value]}
                  onChange={value => {
                    setUserInput(userInput.withLanguage(value));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadFileInput
                  loading={loading || updateLoading}
                  height='240px'
                  acceptType='image'
                  file={userInput.profilePicture}
                  onFile={handleFile}
                  fileDisplay={<ProfileImage fileId={userInput.profilePicture || ''} displayPlaceholder={false} />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}

const languages = {
  afZA: 'Afrikaans (South Africa)',
  arAE: 'Arabic (United Arab Emirates)',
  arAR: 'Arabic (Arabic)',
  arBH: 'Arabic (Bahrain)',
  arDJ: 'Arabic (Djibouti)',
  arDZ: 'Arabic (Algeria)',
  arEG: 'Arabic (Egypt)',
  arEH: 'Arabic (Western Sahara)',
  arER: 'Arabic (Eritrea)',
  arIL: 'Arabic (Israel)',
  arIQ: 'Arabic (Iraq)',
  arJO: 'Arabic (Jordan)',
  arKM: 'Arabic (Comoros)',
  arKW: 'Arabic (Kuwait)',
  arLB: 'Arabic (Lebanon)',
  arLY: 'Arabic (Libya)',
  arMA: 'Arabic (Morocco)',
  arMR: 'Arabic (Mauritania)',
  arOM: 'Arabic (Oman)',
  arPS: 'Arabic (West Bank and Gaza)',
  arQA: 'Arabic (Qatar)',
  arSA: 'Arabic (Saudi Arabia)',
  arSD: 'Arabic (Sudan)',
  arSO: 'Arabic (Somalia)',
  arSY: 'Arabic (Syria)',
  arTD: 'Arabic (Chad)',
  arTN: 'Arabic (Tunisia)',
  arYE: 'Arabic (Yemen)',
  azAZ: 'Azerbaijani (Azerbaijan)',
  beBY: 'Belarusian (Belarus)',
  bgBG: 'Bulgarian (Bulgaria)',
  bnIN: 'Bengali (India)',
  bsBA: 'Bosnian (Bosnia and Herzegovina)',
  caAD: 'Catalan (Andorra)',
  caES: 'Catalan (Spain)',
  csCZ: 'Czech (Czech Republic)',
  csSK: 'Czech (Slovak Republic)',
  cyGB: 'Welsh (United Kingdom)',
  daDK: 'Danish (Denmark)',
  daFO: 'Danish (Faeroe Islands)',
  daGL: 'Danish (Greenland)',
  deAT: 'German (Austria)',
  deBE: 'German (Belgium)',
  deCH: 'German (Switzerland)',
  deDE: 'German (Germany)',
  deLI: 'German (Liechtenstein)',
  deLU: 'German (Luxembourg)',
  deNA: 'German (Namibia)',
  elCY: 'Greek (Cyprus)',
  elGR: 'Greek (Greece)',
  enAG: 'English (Antigua and Barbuda)',
  enAI: 'English (Anguilla)',
  enAS: 'English (American Samoa)',
  enAU: 'English (Australia)',
  enBB: 'English (Barbados)',
  enBD: 'English (Bangladesh)',
  enBM: 'English (Bermuda)',
  enBS: 'English (The Bahamas)',
  enBW: 'English (Botswana)',
  enBZ: 'English (Belize)',
  enCA: 'English (Canada)',
  enCK: 'English (Cook Islands)',
  enCM: 'English (Cameroon)',
  enCW: 'English (Curaçao)',
  enDM: 'English (Dominica)',
  enER: 'English (Eritrea)',
  enFJ: 'English (Fiji)',
  enFK: 'English (Falkland Islands)',
  enFM: 'English (Micronesia)',
  enGB: 'English (United Kingdom)',
  enGD: 'English (Grenada)',
  enGG: 'English (Guernsey)',
  enGH: 'English (Ghana)',
  enGI: 'English (Gibraltar)',
  enGM: 'English (The Gambia)',
  enGU: 'English (Guam)',
  enGY: 'English (Guyana)',
  enHK: 'English (Hong Kong)',
  enIE: 'English (Ireland)',
  enIM: 'English (Isle of Man)',
  enIN: 'English (India)',
  enJE: 'English (Jersey)',
  enJM: 'English (Jamaica)',
  enKE: 'English (Kenya)',
  enKI: 'English (Kiribati)',
  enKN: 'English (St. Kitts and Nevis)',
  enKY: 'English (Cayman Islands)',
  enLC: 'English (St. Lucia)',
  enLK: 'English (Sri Lanka)',
  enLR: 'English (Liberia)',
  enLS: 'English (Lesotho)',
  enMH: 'English (Marshall Islands)',
  enMP: 'English (Northern Mariana Islands)',
  enMS: 'English (Montserrat)',
  enMT: 'English (Malta)',
  enMU: 'English (Mauritius)',
  enMW: 'English (Malawi)',
  enMY: 'English (Malaysia)',
  enNA: 'English (Namibia)',
  enNG: 'English (Nigeria)',
  enNR: 'English (Nauru)',
  enNU: 'English (Niue)',
  enNZ: 'English (New Zealand)',
  enPG: 'English (Papua New Guinea)',
  enPH: 'English (Philippines)',
  enPI: 'English (Pirate)',
  enPK: 'English (Pakistan)',
  enPN: 'English (Pitcairn Islands)',
  enPR: 'English (Puerto Rico)',
  enPW: 'English (Palau)',
  enRW: 'English (Rwanda)',
  enSB: 'English (Solomon Islands)',
  enSC: 'English (Seychelles)',
  enSD: 'English (Sudan)',
  enSG: 'English (Singapore)',
  enSH: 'English (Saint Helena)',
  enSL: 'English (Sierra Leone)',
  enSO: 'English (Somalia)',
  enSS: 'English (South Sudan)',
  enSZ: 'English (Swaziland)',
  enTC: 'English (Turks and Caicos Islands)',
  enTO: 'English (Tonga)',
  enTT: 'English (Trinidad and Tobago)',
  enTV: 'English (Tuvalu)',
  enTZ: 'English (Tanzania)',
  enUD: 'English (Upside Down)',
  enUG: 'English (Uganda)',
  enUS: 'English (United States)',
  enVC: 'English (St. Vincent and the Grenadines)',
  enVG: 'English (British Virgin Islands)',
  enVI: 'English (United States Virgin Islands)',
  enVU: 'English (Vanuatu)',
  enWS: 'English (Samoa)',
  enZA: 'English (South Africa)',
  enZM: 'English (Zambia)',
  enZW: 'English (Zimbabwe)',
  eoEO: 'Esperanto (Esperanto)',
  esAR: 'Spanish (Argentina)',
  esBO: 'Spanish (Bolivia)',
  esCL: 'Spanish (Chile)',
  esCO: 'Spanish (Colombia)',
  esCR: 'Spanish (Costa Rica)',
  esCU: 'Spanish (Cuba)',
  esDO: 'Spanish (Dominican Republic)',
  esEC: 'Spanish (Ecuador)',
  esES: 'Spanish (Spain)',
  esGI: 'Spanish (Gibraltar)',
  esGQ: 'Spanish (Equatorial Guinea)',
  esGT: 'Spanish (Guatemala)',
  esHN: 'Spanish (Honduras)',
  esLA: 'Spanish (Spanish)',
  esMX: 'Spanish (Mexico)',
  esNI: 'Spanish (Nicaragua)',
  esPA: 'Spanish (Panama)',
  esPE: 'Spanish (Peru)',
  esPR: 'Spanish (Puerto Rico)',
  esPY: 'Spanish (Paraguay)',
  esSV: 'Spanish (El Salvador)',
  esUS: 'Spanish (United States)',
  esUY: 'Spanish (Uruguay)',
  esVE: 'Spanish (Venezuela)',
  etEE: 'Estonian (Estonia)',
  euES: 'Basque (Spain)',
  faIR: 'Persian (Iran)',
  fbLT: 'Leet Speak',
  fiFI: 'Finnish (Finland)',
  foFO: 'Faroese (Faeroe Islands)',
  frBE: 'French (Belgium)',
  frBF: 'French (Burkina Faso)',
  frBI: 'French (Burundi)',
  frBJ: 'French (Benin)',
  frCA: 'French (Canada)',
  frCD: 'French (Dem. Rep. Congo)',
  frCF: 'French (Central African Republic)',
  frCG: 'French (Congo)',
  frCH: 'French (Switzerland)',
  frCI: 'French (Côte d’Ivoire)',
  frCM: 'French (Cameroon)',
  frDJ: 'French (Djibouti)',
  frFR: 'French (France)',
  frGA: 'French (Gabon)',
  frGD: 'French (Grenada)',
  frGF: 'French (French Guianna)',
  frGN: 'French (Guinea)',
  frGP: 'French (Guadeloupe)',
  frGQ: 'French (Equatorial Guinea)',
  frHT: 'French (Haiti)',
  frKM: 'French (Comoros)',
  frLC: 'French (St. Lucia)',
  frLU: 'French (Luxembourg)',
  frMC: 'French (Monaco)',
  frMF: 'French (St. Martin (French part))',
  frMG: 'French (Madagascar)',
  frML: 'French (Mali)',
  frMQ: 'French (Martinique)',
  frMU: 'French (Mauritius)',
  frNC: 'French (New Caledonia)',
  frNE: 'French (Niger)',
  frPF: 'French (French Polynesia)',
  frPM: 'French (Saint Pierre and Miquelon)',
  frRE: 'French (Réunion)',
  frRW: 'French (Rwanda)',
  frSC: 'French (Seychelles)',
  frSN: 'French (Senegal)',
  frTD: 'French (Chad)',
  frTG: 'French (Togo)',
  frVU: 'French (Vanuatu)',
  frWF: 'French (Wallis and Futuna)',
  frYT: 'French (Mayotte)',
  fyNL: 'Frisian (Netherlands)',
  gaGB: 'Irish (United Kingdom)',
  gaIE: 'Irish (Ireland)',
  glES: 'Galician (Spain)',
  heIL: 'Hebrew (Israel)',
  hiFJ: 'Hindi (Fiji)',
  hiIN: 'Hindi (India)',
  hiPK: 'Hindi (Pakistan)',
  hrBA: 'Croatian (Bosnia and Herzegovina)',
  hrHR: 'Croatian (Croatia)',
  huHU: 'Hungarian (Hungary)',
  hyAM: 'Armenian (Armenia)',
  idID: 'Indonesian (Indonesia)',
  isIS: 'Icelandic (Iceland)',
  itCH: 'Italian (Switzerland)',
  itIT: 'Italian (Italy)',
  itSM: 'Italian (San Marino)',
  itVA: 'Italian (Vatican City)',
  jaJP: 'Japanese (Japan)',
  jaPW: 'Japanese (Palau)',
  kaGE: 'Georgian (Georgia)',
  kmKH: 'Khmer (Cambodia)',
  koKP: 'Korean (DPRK)',
  koKR: 'Korean (South Korea)',
  kuTR: 'Kurdish (Turkey)',
  laVA: 'Latin (Vatican City)',
  ltLT: 'Lithuanian (Lithuania)',
  lvLV: 'Latvian (Latvia)',
  mkMK: 'Macedonian (Macedonia)',
  mlIN: 'Malayalam (India)',
  msBN: 'Malay (Brunei)',
  msID: 'Malay (Indonesia)',
  msMY: 'Malay (Malaysia)',
  msSG: 'Malay (Singapore)',
  mtMT: 'Maltese (Malta)',
  nbNO: 'Norwegian Bokmål (Norway)',
  neNP: 'Nepali (Nepal)',
  nlAN: 'Dutch (Netherlands Antilles)',
  nlAW: 'Dutch (Aruba)',
  nlBE: 'Dutch (Belgium)',
  nlCW: 'Dutch (Curaçao)',
  nlNL: 'Dutch (Netherlands)',
  nlSR: 'Dutch (Suriname)',
  nlSX: 'Dutch (Sint Maarten (Dutch part))',
  nnNO: 'Norwegian Nynorsk (Norway)',
  noNO: 'Norwegian (Norway)',
  paIN: 'Punjabi (India)',
  plPL: 'Polish (Poland)',
  psAF: 'Pashto (Afghanistan)',
  ptAO: 'Portuguese (Angola)',
  ptBR: 'Portuguese (Brazil)',
  ptCV: 'Portuguese (Cape Verde)',
  ptGQ: 'Portuguese (Equatorial Guinea)',
  ptGW: 'Portuguese (Guinea-Bissau)',
  ptMO: 'Portuguese (Macao)',
  ptMZ: 'Portuguese (Mozambique)',
  ptPT: 'Portuguese (Portugal)',
  ptST: 'Portuguese (São Tomé and Principe)',
  ptTL: 'Portuguese (Timor-Leste)',
  roMD: 'Romanian (Moldova)',
  roRO: 'Romanian (Romania)',
  ruBY: 'Russian (Belarus)',
  ruKG: 'Russian (Kyrgyz Republic)',
  ruKZ: 'Russian (Kazakhstan)',
  ruRU: 'Russian (Russia)',
  ruTJ: 'Russian (Tajikistan)',
  skCZ: 'Slovak (Czech Republic)',
  skSK: 'Slovak (Slovakia)',
  slSI: 'Slovenian (Slovenia)',
  sqAL: 'Albanian (Albania)',
  sqKS: 'Albanian (Kosovo)',
  srBA: 'Serbian (Bosnia and Herzegovina)',
  srME: 'Serbian (Montenegro)',
  srRS: 'Serbian (Serbia)',
  svFI: 'Swedish (Finland)',
  svSE: 'Swedish (Sweden)',
  swKE: 'Swahili (Kenya)',
  taIN: 'Tamil (India)',
  teIN: 'Telugu (India)',
  thTH: 'Thai (Thailand)',
  tlPH: 'Filipino (Philippines)',
  trCY: 'Turkish (Cyprus)',
  trTR: 'Turkish (Turkey)',
  ukUA: 'Ukrainian (Ukraine)',
  viVN: 'Vietnamese (Vietnam)',
  zhCN: 'Chinese (China)',
  zhHK: 'Chinese (Hong Kong)',
  zhMO: 'Chinese (Macao)',
  zhSG: 'Chinese (Singapore)',
  zhTW: 'Chinese (Taiwan)',
};
