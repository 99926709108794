import { gql } from '@apollo/client';

const syncItems = gql`
  query _BC_SyncItems($companyId: String!, $settingsId: String!) {
    _BC_SyncItems(companyId: $companyId, settingsId: $settingsId)
  }
`;

export interface BusinessCentralSyncItemsVariables {
  companyId: string;
  settingsId: string;
}

export interface BusinessCentralSyncItemsResponse {
  _BC_SyncItems: boolean;
}

const syncLocations = gql`
  query _BC_SyncLocations($companyId: String!, $settingsId: String!) {
    _BC_SyncLocations(companyId: $companyId, settingsId: $settingsId)
  }
`;

export interface BusinessCentralSyncLocationsVariables {
  companyId: string;
  settingsId: string;
}

export interface BusinessCentralSyncLocationsResponse {
  _BC_SyncLocations: boolean;
}

const syncPurchaseOrders = gql`
  query _BC_SyncPurchaseOrders($companyId: String!, $settingsId: String!) {
    _BC_SyncPurchaseOrders(companyId: $companyId, settingsId: $settingsId)
  }
`;

export interface BusinessCentralSyncPurchaseOrdersVariables {
  companyId: string;
  settingsId: string;
}

export interface BusinessCentralSyncPurchaseOrdersResponse {
  _BC_SyncPurchaseOrders: boolean;
}

const syncJobs = gql`
  query _BC_SyncJobs($companyId: String!, $settingsId: String!) {
    _BC_SyncJobs(companyId: $companyId, settingsId: $settingsId)
  }
`;

export interface BusinessCentralSyncJobsVariables {
  companyId: string;
  settingsId: string;
}

export interface BusinessCentralSyncJobsResponse {
  _BC_SyncJobs: boolean;
}

const syncJournals = gql`
  query _BC_SyncJournals($companyId: String!, $settingsId: String!) {
    _BC_SyncJournals(companyId: $companyId, settingsId: $settingsId)
  }
`;

export interface BusinessCentralSyncJournalsVariables {
  companyId: string;
  settingsId: string;
}

export interface BusinessCentralSyncJournalsResponse {
  _BC_SyncJournals: boolean;
}

export const BusinessCentralQueries = {
  syncItems,
  syncLocations,
  syncPurchaseOrders,
  syncJobs,
  syncJournals,
};
