import React from 'react';
import { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { UserContext } from '../../../../../context/UserContext';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function UserFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { companyUsers } = useContext(UserContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.user || [])]}
      text={item => {
        const user = companyUsers.get(item);
        if (!user) return t().unknownUser.singular.label;
        return `${user.firstName} ${user.lastName}`;
      }}
      onRemove={i => {
        filter.user?.delete(i);
        if (!filter.user?.size) filter.user = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
