import React from 'react';
import { OrderStatus, orderStatusToLocalizedString } from '../../../../../types/order';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function OrderStatusFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.orderStatus || [])]}
      text={item => orderStatusToLocalizedString(item as OrderStatus)}
      onRemove={i => {
        filter.orderStatus?.delete(i);
        if (!filter.orderStatus?.size) filter.orderStatus = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
