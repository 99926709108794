import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function ChevronDownIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={`${className} pt-[1px]`}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M5 7.5L10 12.5L15 7.5' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </Icon>
  );
}
