import { gql } from '@apollo/client';
import { Coordinates } from '../types/common/address';

export enum MobileStockLocationEventType {
  start = 'start',
  stop = 'stop',
  update = 'update',
}

export interface MobileStockLocationEvent {
  clientIdentifier: string;
  companyId: string;
  stockLocationId: string;
  userId: string;
  type: MobileStockLocationEventType;
  coordinates: Coordinates;
  timestamp: string;
}

const getLastEvents = gql`
  query GetLastMobileStockLocationEvents($companyId: String!) {
    getLastMobileStockLocationEvents(companyId: $companyId) {
      clientIdentifier
      companyId
      stockLocationId
      userId
      coordinates {
        latitude
        longitude
      }
      timestamp
      type
    }
  }
`;

export interface GetLastMobileStockLocationEventsVariables {
  companyId: string;
}

export interface GetLastMobileStockLocationEventsResponse {
  getLastMobileStockLocationEvents: MobileStockLocationEvent[];
}

const subscribe = gql`
  subscription MobileStockLocationNotification($companyId: String!) {
    mobileStockLocationNotification(companyId: $companyId) {
      companyId
      date
      entities {
        clientIdentifier
        timestamp
        type
        userId
        stockLocationId
        coordinates {
          latitude
          longitude
        }
        companyId
      }
      topic
    }
  }
`;

export interface MobileStockLocationNotification extends Notification {
  event: MobileStockLocationEvent;
}

export interface MobileStockLocationNotificationVariables {
  companyId: string;
}

export interface MobileStockLocationNotificationResponse {
  mobileStockLocationNotification: {
    entities: MobileStockLocationEvent[];
  };
}

export const MobileStockLocationNotification = {
  subscribe,
};

export const MobileStockLocationQueries = {
  getLastEvents,
};
