import { StockLocation } from '../../../../types/stockLocation';
import { toFilterString } from '../../../../util/string.util';
import { EntityFilter } from '../filter.util';
import { AddressFilter } from './AddressFilter';

export namespace StockLocationFilter {
  export function search(item: StockLocation, textFilter: string) {
    if (
      toFilterString(item.name).includes(textFilter) ||
      toFilterString(item.identifier).includes(textFilter) ||
      (item.address && AddressFilter.search(item.address, textFilter))
    ) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: StockLocation[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      item.address?.addressLine1;

      return onFilter(item, textFilter);
    });

    return result;
  }
}
