import React from 'react';
import ErrorBox from '../../../../components/Common/ErrorBox';
import { TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { FlexPane } from '../FlexPane/FlexPane';
import TabBar, { Tab } from '../TabBar/TabBar';
import './Pane.scss';

interface PaneProps {
  children: React.ReactNode;
  tabs?: Tab[];
  testId?: TestIdIdentifier;
  overflow?: 'auto';
  error?: string;
}

export default function Pane({ children, tabs, testId, overflow, error }: PaneProps) {
  return (
    <FlexPane
      className={`pane py-4 px-16`}
      testId={testId}
      overflow={overflow}
      contentOverflow={overflow === 'auto' ? 'visible' : 'hidden'}
      header={tabs?.length ? <TabBar tabs={tabs} /> : null}
      content={
        <div className='w-full h-full flex flex-col'>
          <ErrorBox error={error} />
          {children}
        </div>
      }
      spacing={4}
    />
  );
}
