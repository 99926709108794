import React from 'react';
import { Alert } from '../types/alert';

export interface AlertContextProps {
  alerts: Map<string, Alert>;
  setAlerts: (bins: Map<string, Alert>) => void;
  alertsLoading: boolean;
}

const defaultContext: AlertContextProps = {
  alerts: new Map(),
  setAlerts: () => {},
  alertsLoading: false,
};

export const AlertContext = React.createContext<AlertContextProps>(defaultContext);
