import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

import { t } from './translation/Translator';

export enum CompanyInviteState {
  pending = 'pending',
  accepted = 'accepted',
}

export enum CompanyRole {
  administrator = 'COMPANY_ADMINISTRATOR',
  employee = 'COMPANY_EMPLOYEE',
}

enum CompanyRoleAssignmentValidationError {
  email = 'Please enter a valid email',
  role = 'Please select a role',
}

export class CompanyRoleAssignment extends CompanyEntity {
  userId!: string;
  email!: string;
  role: CompanyRole = CompanyRole.employee;
  state!: CompanyInviteState;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateCompanyRoleAssignmentInput {
    return UpdateCompanyRoleAssignmentInput.from(this, UpdateCompanyRoleAssignmentInput);
  }

  forDelete(): DeleteCompanyRoleAssignmentInput {
    return DeleteCompanyRoleAssignmentInput.from(this, DeleteCompanyRoleAssignmentInput);
  }

  validate(fields: (keyof CompanyRoleAssignment)[]) {
    return this.validateEntity(fields, field => {
      if (field === 'email' && !this.email) return CompanyRoleAssignmentValidationError.email;
      if (field === 'role' && !this.role) return CompanyRoleAssignmentValidationError.role;
    });
  }

  withRole(role: CompanyRole) {
    this.role = role;
    return cloneDeep(this);
  }

  withEmail(email: string) {
    this.email = email;
    return cloneDeep(this);
  }
}

export class CreateCompanyRoleAssignmentInput extends forCreate(CompanyRoleAssignment) {}

export class UpdateCompanyRoleAssignmentInput extends forUpdate(CompanyRoleAssignment) {}

export class DeleteCompanyRoleAssignmentInput extends forDelete(CompanyRoleAssignment) {}

export function companyRoleToString(type: string) {
  switch (type) {
    case CompanyRole.administrator:
      return t().administrator.singular.label;
    case CompanyRole.employee:
      return t().employee.singular.label;
    default:
      return 'Unknown company user role';
  }
}
