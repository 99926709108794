import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { CommentFragments } from '../../graphql/comment.graphql';
import { CommentContext } from '../../context/CommentContext';
import { Comment } from '../../types/comment';

const commentSubscription = gql`
  subscription commentNotification($companyId: String!) {
    commentNotification(companyId: $companyId) {
      companyId
      entities {
        ...Comment
      }
      topic
      date
    }
  }
  ${CommentFragments.comment}
`;

export class CommentNotificationResponse extends forNotification<Comment>('commentNotification') {}

export default function CommentNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { comments, setComments } = useContext(CommentContext);

  if (!currentCompany) return null;

  const { data: commentNotification } = useSubscription<CommentNotificationResponse>(commentSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleCommentNotification = (notification?: CommentNotificationResponse) => {
    if (!notification?.commentNotification) return;
    const commentNotification = notification.commentNotification;

    switch (commentNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        commentNotification.entities.forEach(comment => {
          const index = comment.parentId || comment.id;
          comments.set(index, (comments.get(index) || new Map()).set(comment.id, new Comment(comment)));
        });
        break;
      case 'DELETE':
        commentNotification.entities.forEach(comment => {
          if (comment.parentId) {
            comments.get(comment.parentId)?.delete(comment.id);
          } else {
            comments.delete(comment.id);
          }
        });
    }
    setComments(new Map(comments));
  };

  useEffect(() => {
    handleCommentNotification(commentNotification);
  }, [commentNotification]);

  return <></>;
}
