import { gql } from '@apollo/client';
import { User } from '../types/user';
import { forPaginated } from './common/paginated.graphql';
import { CompanyRoleAssignmentFragment } from './companyRoleAssignment.graphql';
import { CompanyRole, CompanyRoleAssignment } from '../types/companyRoleAssignment';
import { CreateStockLocationRoleAssignmentInput } from '../types/stockLocationRoleAssignment';

export enum AuthenticationResultType {
  normal = 'normal',
  authenticationCodeRequired = 'authenticationCodeRequired',
}

const user = gql`
  fragment User on User {
    id
    version
    firstName
    lastName
    email
    userId
    createdAt
    createdBy
    updatedAt
    updatedBy
    acceptedAgreements
    acceptedPrivacyPolicy
    acceptedTerms
    isOnboarded
    language
    notificationsToken
    unitSetting
    profilePicture
  }
`;

const signInToken = gql`
  fragment SignInToken on AuthenticationToken {
    accessToken
    refreshToken
    tokenType
    expiresIn
    scope
  }
`;

const signUp = gql`
  mutation SignUp($user: SignUpUserInput!) {
    signUp(user: $user)
  }
`;

export interface SignUpVariables {
  user: { firstName: string; lastName: string; email: string; password: string };
}

export interface SignUpResponse {
  signUp: boolean;
}

const signIn = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      user {
        ...User
      }
      token {
        ...SignInToken
      }
      type
    }
  }
  ${user}
  ${signInToken}
`;

export interface SignInVariables {
  email: string;
  password: string;
}

export interface SignInResponse {
  signIn: UserAuthResponse;
}

const signInWithCode = gql`
  mutation SignInWithCode($email: String!, $password: String!, $code: String!) {
    signInWithCode(email: $email, password: $password, code: $code) {
      user {
        ...User
      }
      token {
        ...SignInToken
      }
      type
    }
  }
  ${user}
  ${signInToken}
`;

export interface SignInWithCodeVariables {
  email: string;
  password: string;
  code: string;
}

export interface SignInWithCodeResponse {
  signInWithCode: UserAuthResponse;
}

const requestAuthenticationCode = gql`
  mutation RequestAuthenticationCode($email: String!, $password: String!) {
    requestAuthenticationCode(email: $email, password: $password)
  }
`;

export interface RequestAuthenticationCodeVariables {
  email: string;
  password: string;
}

export interface RequestAuthenticationCodeResponse {
  requestAuthenticationCode: boolean;
}

const refreshJwtToken = gql`
  query RefreshJwtToken($refreshToken: String!) {
    refreshJwtToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
      scope
      tokenType
    }
  }
`;

export interface RefreshJwtTokenVariables {
  refreshToken: string;
}

export interface RefreshJwtTokenResponse {
  refreshJwtToken: AuthenticationToken;
}

export interface UserAuthResponse {
  user: User;
  token: AuthenticationToken;
  type: AuthenticationResultType;
}

export interface AuthenticationToken {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  expiresIn: number;
  scope: string;
}

const get = gql`
  query User {
    user {
      ...User
    }
  }
  ${user}
`;

export interface GetUserResponse {
  user: User;
}

const getForCompany = gql`
  query Users($companyId: String!, $page: Float) {
    users(companyId: $companyId, page: $page) {
      data {
        userId
        ...User
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${user}
`;

export interface GetUsersVariables {
  companyId: string;
  page?: number;
}

export interface GetUsersResponse {
  users: PaginatedUser;
}

export class PaginatedUser extends forPaginated<User>() {}

const invite = gql`
  mutation InviteUser(
    $companyId: String!
    $email: String!
    $companyRole: String!
    $stockLocationRoles: [CreateStockLocationRoleAssignmentInput!]!
  ) {
    inviteUser(
      companyId: $companyId
      email: $email
      companyRole: $companyRole
      stockLocationRoles: $stockLocationRoles
    ) {
      ...CompanyRoleAssignment
    }
  }
  ${CompanyRoleAssignmentFragment.companyRoleAssignment}
`;

export interface InviteUserVariables {
  companyId: string;
  email: string;
  companyRole: CompanyRole;
  stockLocationRoles: CreateStockLocationRoleAssignmentInput[];
}

export interface InviteUserResponse {
  inviteUser: CompanyRoleAssignment;
}

const verify = gql`
  query VerifyUserEmail($validationCode: String!, $email: String!) {
    verifyUserEmail(validationCode: $validationCode, email: $email)
  }
`;

export interface VerifyUserEmailVariables {
  validationCode: string;
  email: string;
}

export interface VerifyUserEmailResponse {
  verifyUserEmail: boolean;
}

const acceptLicenseAgreement = gql`
  mutation AcceptLicenseAgreement {
    acceptLicenseAgreement {
      ...User
    }
  }
  ${user}
`;

export interface AcceptLicenseAgreementVariables {}

export interface AcceptLicenseAgreementResponse {
  acceptLicenseAgreement: User;
}

const acceptPrivacyPolicy = gql`
  mutation AcceptPrivacyPolicy {
    acceptPrivacyPolicy {
      ...User
    }
  }
  ${user}
`;

export interface AcceptPrivacyPolicyVariables {}

export interface AcceptPrivacyPolicyResponse {
  acceptPrivacyPolicy: User;
}

const acceptTermsOfUse = gql`
  mutation AcceptTermsOfUse {
    acceptTermsOfUse {
      ...User
    }
  }
  ${user}
`;

export interface AcceptTermsOfUseVariables {}

export interface AcceptTermsOfUseResponse {
  acceptTermsOfUse: User;
}

const update = gql`
  mutation UpdateUser($user: UserInput!) {
    updateUser(user: $user) {
      ...User
    }
  }
  ${user}
`;

export interface UpdateUserVariables {
  user: User;
}

export interface UpdateUserResponse {
  updateUser: User;
}

const changePassword = gql`
  mutation ChangePassword($newPassword: String!, $oldPassword: String!) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      ...User
    }
  }
  ${user}
`;

export interface ChangePasswordVariables {
  newPassword: string;
  oldPassword: string;
}

export interface ChangePasswordResponse {
  changePassword: User;
}

const resetPassword = gql`
  query ResetPasswordUrl($redirectUrl: String!) {
    resetPasswordUrl(redirectUrl: $redirectUrl)
  }
`;

export interface ResetPasswordVariables {
  redirectUrl: string;
}

export interface ResetPasswordResponse {
  resetPasswordUrl: string;
}

const forgotPassword = gql`
  query ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export interface ForgotPasswordVariables {
  email: string;
}

export interface ForgotPasswordResponse {
  resetPassword: boolean;
}

const activateMicrosoftSubscription = gql`
  query ActivateMicrosoftSubscription($token: String!) {
    activateMicrosoftSubscription(token: $token)
  }
`;

export interface ActivateMicrosoftSubscriptionVariables {
  token: string;
}

export interface ActivateMicrosoftSubscriptionResponse {
  activateMicrosoftSubscription: string;
}

export const UserQueries = {
  get,
  getForCompany,
  verify,
  resetPassword,
  forgotPassword,
  refreshJwtToken,
  activateMicrosoftSubscription,
};

export const UserMutations = {
  signIn,
  signInWithCode,
  signUp,
  invite,
  changePassword,
  acceptLicenseAgreement,
  acceptPrivacyPolicy,
  acceptTermsOfUse,
  update,
  requestAuthenticationCode,
};
