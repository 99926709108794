import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderExportConfigurationContext } from '../../../../../context/OrderExportConfigurationContext';
import Paper from '../../../../../VentoryUI/components/common/Paper/Paper';
import LoadingPackage from '../../../../Common/LoadingPackage';
import OrderExportConfigurationItem from './Export/OrderExportConfigurationItem';

interface OrderReportPaneProps {
  setError: (error: string) => void;
}

export default function OrderReportPane({ setError }: OrderReportPaneProps) {
  const navigate = useNavigate();

  const { orderExportConfigurations, setOrderExportConfigurations, orderExportConfigurationsLoading } = useContext(
    OrderExportConfigurationContext,
  );

  if (orderExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper>
      <Grid container>
        {[...orderExportConfigurations.values()].map(config => (
          <Grid key={config.id} item>
            <OrderExportConfigurationItem configuration={config} key={config.id} />
          </Grid>
        ))}
        <Grid item>
          <OrderExportConfigurationItem />
        </Grid>
      </Grid>
    </Paper>
  );
}
