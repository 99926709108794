import React from 'react';
import { IntegrationSettings } from '../types/integrationSettings';

export interface IntegrationContextProps {
  integrationSettings: Map<string, IntegrationSettings>;
  setIntegrationSettings: (settings: Map<string, IntegrationSettings>) => void;
  integrationSettingsLoading: boolean;
}

const defaultContext: IntegrationContextProps = {
  integrationSettings: new Map(),
  setIntegrationSettings: () => {},
  integrationSettingsLoading: false,
};

export const IntegrationContext = React.createContext<IntegrationContextProps>(defaultContext);
