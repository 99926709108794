import { cloneDeep } from '@apollo/client/utilities';
import { ClassConstructor } from 'class-transformer';

export type IdArray = [{ id: string }];

export type Entity = CompanyEntity | StockLocationEntity;

export class ExternalEntityIdentifier {
  settingsId: string;

  externalId: string;

  additionalInfo?: string;

  type?: string;

  constructor(settingsId: string, externalId: string, additionalInfo?: string, type?: string) {
    this.settingsId = settingsId;
    this.externalId = externalId;
    this.additionalInfo = additionalInfo;
    this.type = type;
  }
}

export abstract class CompanyEntity {
  id!: string;
  companyId: string;
  version!: number;
  createdAt!: Date;
  updatedAt!: Date;
  createdBy!: string;
  updatedBy!: string;
  public externalIdentifier?: ExternalEntityIdentifier;

  constructor(companyId: string) {
    this.companyId = companyId;
  }

  abstract forUpdate(): any;

  abstract forDelete(): any;

  abstract validate(fields: any[]): any;

  protected validateEntity<T, K>(fields: (keyof T)[], fn: (field: keyof T) => K | null): Map<keyof T, K> {
    const uniqueFields = new Set(fields);
    const map = new Map();

    for (const field of uniqueFields) {
      const item = fn(field);
      if (item) map.set(field, item);
    }

    return map;
  }
}

export abstract class StockLocationEntity extends CompanyEntity {
  stockLocationId!: string;

  constructor(companyId: string, stockLocationId: string) {
    super(companyId);
    this.stockLocationId = stockLocationId;
  }

  withStockLocationId(stockLocationId?: string) {
    (this.stockLocationId as any) = stockLocationId;
    return cloneDeep(this);
  }
}

interface ClassType<T = any> {
  new (...args: any[]): T;
}

const ENTITY_FIELDS = ['createdBy', 'createdAt', 'updatedBy', 'updatedAt'];

export function forCreate<T extends ClassType>(BaseClass: T) {
  return class CreateTrait extends BaseClass {
    constructor(...args: any[]) {
      super(...args);
      delete this.id;
      delete this.version;
      delete this.createdAt;
      delete this.createdBy;
      delete this.updatedAt;
      delete this.updatedBy;
    }
  };
}

export function forUpdate<T extends ClassType>(BaseClass: T) {
  return class UpdateTrait extends BaseClass {
    id!: string;
    version!: number;
    companyId!: string;

    static from<E extends Entity, C>(entity: E, type: ClassConstructor<C>) {
      Object.getOwnPropertyNames(entity).forEach(key => {
        const field = key as keyof E;
        if (ENTITY_FIELDS.includes(key)) delete entity[field];
      });
      return entity as any as C;
    }
  };
}

export function forDelete<T extends ClassType>(BaseClass: T) {
  return class DeleteTrait {
    id!: string;
    version!: number;
    companyId!: string;

    static from<C>(entity: Entity, type: ClassConstructor<C>) {
      const item = new type() as any;
      item.companyId = entity.companyId;
      item.version = entity.version;
      item.id = entity.id;
      if (entity instanceof StockLocationEntity) item.stockLocationId = entity.stockLocationId;
      return item;
    }
  };
}
