import { ScanRule } from '../../../../types/scanRule';
import { toFilterString } from '../../../../util/string.util';
import { EntityFilter } from '../filter.util';

export namespace ScanRuleFilter {
  export function search(item: ScanRule, textFilter: string) {
    if (
      toFilterString(item.configurationValues.join('')).includes(textFilter) ||
      toFilterString(item.type).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: ScanRule[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      return onFilter(item, textFilter);
    });

    return result;
  }
}
