import React from 'react';
import { ReorderUserRule } from '../../../../../../../types/reorderUserRule';
import { Grid } from '@mui/material';
import { testIds } from '../../../../../../../util/identifiers/identifiers.util';
import { t } from '../../../../../../../types/translation/Translator';
import TextInput from '../../../../../../Common/TextInput';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/ModalPane';
import { Button } from '../../../../../../../VentoryUI/components/common/Button/Button';

interface ReorderUserRuleNamePaneProps {
  rule: ReorderUserRule;
  setRule: (rule: ReorderUserRule) => void;
  next: () => void;
}

export default function ReorderUserRuleNamePane({ rule, setRule, next }: ReorderUserRuleNamePaneProps) {
  return (
    <ModalPane
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <Button
              onClick={() => next()}
              testId={testIds.next}
              text={t().next.singular.label}
              disabled={!rule.name}
              style='secondary'
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <TextInput
            label={t().enterNameOfReorderUserRule.singular.upper}
            placeholder={t().name.singular.label}
            onChange={v => setRule(rule.withName(v))}
            value={rule.name}
            mandatory
          />
        </Grid>
      </Grid>
    </ModalPane>
  );
}
