import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { Comment, CreateCommentInput } from '../types/comment';

const comment = gql`
  fragment Comment on Comment {
    id
    version
    createdAt
    companyId
    entityId
    parentId
    type
    content
    userId
  }
`;

const get = gql`
  query Comments($companyId: String!) {
    comments(companyId: $companyId) {
      data {
        ...Comment
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${comment}
`;

export interface GetCommentsVariables {
  companyId: string;
}

export interface GetCommentsResponse {
  comments: PaginatedComments;
}

class PaginatedComments extends forPaginated<Comment>() {}

const create = gql`
  mutation CreateComment($comments: [CreateCommentInput!]!) {
    createComment(comments: $comments) {
      ...Comment
    }
  }
  ${comment}
`;

export interface CreateCommentVariables {
  comments: CreateCommentInput[];
}

export interface CreateCommentResponse {
  createComment: Comment[];
}

export const CommentQueries = {
  get,
};

export const CommentMutations = {
  create,
};

export const CommentFragments = {
  comment,
};
