import dayjs from 'dayjs';
import { Order } from '../../../../types/order';
import { EntityFilter } from '../filter.util';

export namespace OrderFilter {
  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: Order[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      if (filter.orderStatus && filter.orderStatus.size && !filter.orderStatus.has(item.status)) return false;
      if (filter.orderType && filter.orderType.size && !filter.orderType.has(item.type)) return false;

      if (filter.stockLocation && filter.stockLocation.size && !filter.stockLocation.has(item.stockLocationId)) {
        return false;
      }

      if (
        filter.timeFrame &&
        (dayjs(filter.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filter.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      return onFilter(item, textFilter);
    });

    return result.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
  }
}
