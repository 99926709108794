import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import { forNotification } from '../Common/forNotification';
import { ProductTransactionFragments } from '../../graphql/productTransaction.graphql';
import { ProductTransaction } from '../../types/productTransaction';
import { ProductTransactionContext } from '../../context/ProductTransactionContext';
import React from 'react';

const productTransactionSubscription = gql`
  subscription productTransactionNotification($companyId: String!) {
    productTransactionNotification(companyId: $companyId) {
      companyId
      entities {
        ...ProductTransaction
      }
      topic
      date
    }
  }
  ${ProductTransactionFragments.productTransaction}
`;

export class ProductTransactionNotificationResponse extends forNotification<ProductTransaction>(
  'productTransactionNotification',
) {}

export default function ProductTransactionNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { productTransactions, setProductTransactions } = useContext(ProductTransactionContext);

  if (!currentCompany) return null;

  const { data: productTransactionNotifaction } = useSubscription<ProductTransactionNotificationResponse>(
    productTransactionSubscription,
    {
      variables: { companyId: currentCompany.id },
    },
  );

  const handleProductTransactionNotification = (notification?: ProductTransactionNotificationResponse) => {
    if (!notification?.productTransactionNotification) return;
    const productTransactionNotification = notification.productTransactionNotification;

    switch (productTransactionNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        productTransactionNotification.entities.forEach(t => {
          productTransactions.set(t.id, new ProductTransaction(t));
        });
        break;
      case 'DELETE':
    }
    setProductTransactions(new Map(productTransactions));
  };

  useEffect(() => {
    handleProductTransactionNotification(productTransactionNotifaction);
  }, [productTransactionNotifaction]);

  return <></>;
}
