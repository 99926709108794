import { CsvExportFile } from '../graphql/report.graphql';
import { DownloadBlobPrompt } from './download.util';
import { toGroupedMap } from './map.util';
import JSZip from 'jszip';

export type ExportConfigurations = Map<string, { name: string }>;

export async function zipAndDownloadExportFiles(
  files: CsvExportFile[],
  configurations: ExportConfigurations,
  zipName: string,
) {
  const filesByConfiguration = toGroupedMap(files, 'configurationId');

  const zip = new JSZip();
  for (const [configurationId, files] of filesByConfiguration) {
    const folder = zip.folder(configurations.get(configurationId)?.name || `ventory_reports`);

    for (let i = 0; i < files.length; i++) {
      const fileName = `${String(i + 1).padStart(3, '0')}_${files[i].fileName}`;
      folder?.file(fileName, files[i].content);
    }
  }
  const zipped = await zip.generateAsync({ type: 'blob' });
  DownloadBlobPrompt(zipped, zipName);
}
