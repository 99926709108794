import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { CsvUploadType } from '../../../types/csvUploadConfiguration';
import CsvUploadConfigurationsScreen from '../../Common/CsvUploadConfigurationsScreen';
import CsvUploadConfigurationUpdatePane from '../../Common/CsvUploadConfigurationUpdatePane';
import ReorderRulesOverviewPane from './Panes/ReferenceDataSettingsReorderRulesPane';
import ReferenceDataSettingsReportPane from './Panes/ReferenceDataSettingsReportPane';
import { t } from '../../../types/translation/Translator';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';

export default function ReferenceDataSettingsScreen() {
  const navigate = useNavigate();

  const tabs = [
    {
      text: t().importConfigurations.singular.label,
      path: '/reference_data/settings/import_configurations',
      key: 'import_configurations',
    },
    {
      text: t().stockTriggers.singular.label,
      path: '/reference_data/settings/stock_triggers',
      key: 'stock_triggers',
    },
    { text: t().reporting.singular.label, path: '/reference_data/settings/report', key: 'report' },
  ];

  return (
    <Pane tabs={tabs}>
      <Routes>
        <Route
          path={'import_configurations/*'}
          element={<CsvUploadConfigurationsScreen type={CsvUploadType.productMasterData} backPath='/reference_data' />}
        />
        <Route
          path={'import_configurations/:id/update/*'}
          element={
            <CsvUploadConfigurationUpdatePane
              type={CsvUploadType.productMasterData}
              onDone={() => navigate('import_configurations')}
            />
          }
        />
        <Route path={'stock_triggers'} element={<ReorderRulesOverviewPane />} />
        <Route path={'report/*'} element={<ReferenceDataSettingsReportPane />} />
      </Routes>
    </Pane>
  );
}
