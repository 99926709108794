import JSZip from 'jszip';

/**
 * Shows a download prompt for a url
 */
export function DownloadUrlPrompt(url: string, fileName: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * Shows a download prompt for a blob
 */
export function DownloadBlobPrompt(blob: Blob, fileName: string) {
  DownloadUrlPrompt(URL.createObjectURL(blob), fileName);
}

/**
 * Shows a download prompt for a zip file containing multiple "json encoded string with newlines" files
 */
export async function DownloadMultipleTextAsZip(files: { name: string; text: string }[], zipName: string) {
  try {
    const zip = new JSZip();
    for (const file of files) {
      zip.file(file.name, textToBlob(file.text));
    }
    const zipped = await zip.generateAsync({ type: 'blob' });
    DownloadBlobPrompt(zipped, zipName);
  } catch (e) {
    console.warn(e);
  }
}

/**
 * Shows a download prompt for a json encoded string containing newlines file
 */
export function DownloadTextPrompt(text: string, fileName: string) {
  DownloadBlobPrompt(textToBlob(text), fileName);
}

/**
 * Shows a download prompt for a json encoded string containing newlines
 */
export function DownloadJsonPrompt(json: string, fileName: string) {
  const jsonBlob = new Blob([json], { type: 'application/json', endings: 'native' });
  DownloadUrlPrompt(URL.createObjectURL(jsonBlob), fileName);
}

export function textToBlob(text: string) {
  var BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
  return new Blob([BOM, text], { type: 'text/plain;charset=UTF-8', endings: 'native' });
}
