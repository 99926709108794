import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { OrderExportConfigurationContext } from '../../../../../../../context/OrderExportConfigurationContext';
import {
  CreateOrderExportConfigurationResponse,
  CreateOrderExportConfigurationVariables,
  OrderExportConfigurationMutations,
} from '../../../../../../../graphql/orderExportConfiguration.graphql';
import {
  OrderExportConfiguration,
  OrderExportConfigurationMapping,
} from '../../../../../../../types/orderExportConfiguration';
import BackButton from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import CreateButton from '../../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import Pane from '../../../../../../../VentoryUI/components/common/Pane/Pane';
import OrderExportConfigurationInput, {
  DraggableOrderExportFieldIdentifier,
} from '../Common/OrderExportConfigurationInput';

interface CreateOrderExportConfigurationPaneProps {}

export default function CreateOrderExportConfigurationPane({}: CreateOrderExportConfigurationPaneProps) {
  const navigate = useNavigate();
  const { currentCompany } = useContext(CompanyContext);
  const { orderExportConfigurations, setOrderExportConfigurations } = useContext(OrderExportConfigurationContext);

  const [orderExportConfigurationInput, setOrderExportConfigurationInput] = useState<OrderExportConfiguration>(
    new OrderExportConfiguration({ companyId: currentCompany.id }),
  );
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<
    CreateOrderExportConfigurationResponse,
    CreateOrderExportConfigurationVariables
  >(OrderExportConfigurationMutations.create, {
    onCompleted: res => {
      const config = res.createOrderExportConfiguration[0];
      orderExportConfigurations.set(config.id, new OrderExportConfiguration(config));
      setOrderExportConfigurations(new Map(orderExportConfigurations));
      navigate('/operations/orders/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleCreate = async (
    configuration: OrderExportConfiguration,
    items: DraggableOrderExportFieldIdentifier[],
  ) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new OrderExportConfigurationMapping(i.customField, idx, i.field),
      );
      await create({
        variables: {
          orderExportConfigurations: [configuration],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const createFooter = (configuration: OrderExportConfiguration, items: DraggableOrderExportFieldIdentifier[]) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <BackButton disabled={loading} onClick={() => navigate('/operations/orders/settings/report')} />
        </Grid>
        <Grid item>
          <CreateButton
            loading={loading}
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
            onClick={() => handleCreate(configuration, items)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Pane error={error}>
      <OrderExportConfigurationInput
        configuration={orderExportConfigurationInput}
        setConfiguration={setOrderExportConfigurationInput}
        footer={createFooter}
      />
    </Pane>
  );
}
