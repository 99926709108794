import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateProductExportConfigurationInput,
  UpdateProductExportConfigurationInput,
  DeleteProductExportConfigurationInput,
  ProductExportConfiguration,
} from '../types/productExportConfiguration';

const productExportConfiguration = gql`
  fragment ProductExportConfiguration on ProductExportConfiguration {
    id
    version
    companyId
    name
    filename
    prefix
    delimiter
    quote
    default
    fieldMapping {
      index
      customField
      field
    }
  }
`;

const get = gql`
  query ProductExportConfigurations($companyId: String!, $page: Float) {
    productExportConfigurations(companyId: $companyId, page: $page) {
      data {
        ...ProductExportConfiguration
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${productExportConfiguration}
`;

export interface GetProductExportConfigurationsVariables {
  companyId: string;
  page?: number;
}

export interface GetProductExportConfigurationsResponse {
  productExportConfigurations: PaginatedProductExportConfigurations;
}

class PaginatedProductExportConfigurations extends forPaginated<ProductExportConfiguration>() {}

const create = gql`
  mutation CreateProductExportConfiguration($productExportConfigurations: [CreateProductExportConfigurationInput!]!) {
    createProductExportConfiguration(productExportConfigurations: $productExportConfigurations) {
      ...ProductExportConfiguration
    }
  }
  ${productExportConfiguration}
`;

export interface CreateProductExportConfigurationVariables {
  productExportConfigurations: CreateProductExportConfigurationInput[];
}

export interface CreateProductExportConfigurationResponse {
  createProductExportConfiguration: ProductExportConfiguration[];
}

const update = gql`
  mutation UpdateProductExportConfiguration($productExportConfigurations: [UpdateProductExportConfigurationInput!]!) {
    updateProductExportConfiguration(productExportConfigurations: $productExportConfigurations) {
      ...ProductExportConfiguration
    }
  }
  ${productExportConfiguration}
`;

export interface UpdateProductExportConfigurationVariables {
  productExportConfigurations: UpdateProductExportConfigurationInput[];
}

export interface UpdateProductExportConfigurationResponse {
  updateProductExportConfiguration: ProductExportConfiguration[];
}

const remove = gql`
  mutation DeleteProductExportConfiguration($productExportConfigurations: [DeleteProductExportConfigurationInput!]!) {
    deleteProductExportConfiguration(productExportConfigurations: $productExportConfigurations) {
      ...ProductExportConfiguration
    }
  }
  ${productExportConfiguration}
`;

export interface DeleteProductExportConfigurationVariables {
  productExportConfigurations: DeleteProductExportConfigurationInput[];
}

export interface DeleteProductExportConfigurationResponse {
  deleteProductExportConfiguration: ProductExportConfiguration[];
}

export const ProductExportConfigurationQueries = {
  get,
};

export const ProductExportConfigurationMutations = {
  create,
  update,
  remove,
};
