import React, { useMemo } from 'react';
import { FileEntityType } from '../../../../../types/file';
import FileViewLoader from '../FileViewLoader';
import ImageView from './ImageView';

interface ProfileImageViewLoader {
  fileId?: string;
  className?: string;
  displayPlaceholder?: boolean;
}

export default function ProfileImage({ fileId, className, displayPlaceholder = true }: ProfileImageViewLoader) {
  const fallback = useMemo(() => {
    return <ImageView className={className} url={`${process.env.REACT_APP_STORAGE_URL}/ventory/Profile.svg`} />;
  }, [className]);

  if (!fileId) {
    if (!displayPlaceholder) return null;
    return fallback;
  }

  return (
    <FileViewLoader fileId={fileId} className={className} fileEntityType={FileEntityType.user} fallback={fallback} />
  );
}
