import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProductTransactionReportPane from './Panes/ProductTransactionReportPane';
import { t } from '../../../../types/translation/Translator';
import Pane from '../../../../VentoryUI/components/common/Pane/Pane';

const tabs = () => [
  {
    text: t().reporting.singular.label,
    path: '/stock/history/settings/report',
    key: 'report',
  },
];

export default function ProductTransactionSettingsScreen() {
  return (
    <Pane tabs={tabs()}>
      <Routes>
        <Route path={'report/*'} element={<ProductTransactionReportPane />} />
      </Routes>
    </Pane>
  );
}
