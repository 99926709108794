import React, { useContext, useMemo, useState } from 'react';
import { Order } from '../../../../types/order';
import { t } from '../../../../types/translation/Translator';
import { CompanyRoleAssignment } from '../../../../types/companyRoleAssignment';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { UserContext } from '../../../../context/UserContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';

import SearchBar from '../../../../VentoryUI/components/common/SearchBar/SearchBar';
import Table from '../../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';

interface OrderUserPaneProps {
  order: Order;
  setOrder: (order: Order) => void;
  footer: (canUpdate: boolean) => JSX.Element;
}

export default function OrderUserPane({ order, setOrder, footer }: OrderUserPaneProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { companyUsers } = useContext(UserContext);
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);

  const [filter, setFilter] = useState<string>('');

  const handleFilter = (item: CompanyRoleAssignment) => {
    const lowerCaseFilter = removeDiacritics(toFilterString(filter));
    if (
      removeDiacritics(toFilterString(item.email.toLowerCase())).includes(lowerCaseFilter) ||
      toFilterString(item.role).includes(lowerCaseFilter) ||
      removeDiacritics(
        toFilterString(`${companyUsers.get(item.userId)?.firstName} ${companyUsers.get(item.userId)?.lastName}`),
      ).includes(lowerCaseFilter)
    ) {
      return true;
    }
    return false;
  };

  const headers = [
    {
      key: 'email',
      name: t().email.singular.label,
      text: (item: CompanyRoleAssignment) => item.email,
    },
    {
      key: 'name',
      name: t().firstAndLastName.singular.label,
      text: (item: CompanyRoleAssignment) =>
        `${companyUsers.get(item.userId)?.firstName} ${companyUsers.get(item.userId)?.lastName}` || '',
    },
  ];

  const filteredUsers = useMemo(() => {
    return [...companyRoles.values()]
      .flat()
      .filter(r => r.companyId === currentCompany.id && order.assignedTo.includes(r.userId))
      .filter(handleFilter);
  }, [filter, companyRoles]);

  return (
    <FlexPane
      header={<SearchBar onChange={value => setFilter(value)} placeholder={t().filterUsers.singular.label} />}
      content={<Table items={filteredUsers} headers={headers} />}
      footer={footer(false)}
    />
  );
}
