import React from 'react';
import { useState } from 'react';
import { FileEntityType, FileWithSas } from '../../../../types/file';
import FileLoader from './FileLoader';
import FileNotFound from './FileNotFound';
import FileView from './FileView';

interface FileViewLoaderProps {
  fileId: string;
  className?: string;
  fileEntityType?: FileEntityType;
  companyId?: string;
  fallback?: React.ReactNode;
}

export default function FileViewLoader({
  fileId,
  fileEntityType,
  companyId,
  className,
  fallback,
}: FileViewLoaderProps) {
  const [fileSas, setFileSas] = useState<FileWithSas | undefined>(undefined);

  return (
    <FileLoader
      fileId={fileId}
      fileEntityType={fileEntityType}
      companyId={companyId}
      onLoadEnd={sas => setFileSas(sas)}
    >
      {fileSas ? <FileView file={fileSas} className={className} /> : fallback || <FileNotFound />}
    </FileLoader>
  );
}
