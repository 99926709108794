import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { BinStatus, CreateBinStatusInput, DeleteBinStatusInput, UpdateBinStatusInput } from '../types/binStatus';

const binStatus = gql`
  fragment BinStatus on BinStatus {
    id
    version
    companyId
    status
    color
    inboundAllowed
    outboundAllowed
  }
`;

const get = gql`
  query BinStatuses($companyId: String!) {
    binStatuses(companyId: $companyId) {
      data {
        ...BinStatus
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${binStatus}
`;

export interface GetBinStatusesVariables {
  companyId: string;
}

export interface GetBinStatusesResponse {
  binStatuses: PaginatedBinStatuses;
}

class PaginatedBinStatuses extends forPaginated<BinStatus>() {}

const create = gql`
  mutation CreateBinStatus($binStatuses: [CreateBinStatusInput!]!) {
    createBinStatus(binStatuses: $binStatuses) {
      ...BinStatus
    }
  }
  ${binStatus}
`;

export interface CreateBinStatusVariables {
  binStatuses: CreateBinStatusInput[];
}

export interface CreateBinStatusResponse {
  createBinStatus: BinStatus[];
}

const update = gql`
  mutation UpdateBinStatus($binStatuses: [UpdateBinStatusInput!]!) {
    updateBinStatus(binStatuses: $binStatuses) {
      ...BinStatus
    }
  }
  ${binStatus}
`;

export interface UpdateBinStatusVariables {
  binStatuses: UpdateBinStatusInput[];
}

export interface UpdateBinStatusResponse {
  updateBinStatus: BinStatus[];
}

const remove = gql`
  mutation DeleteBinStatus($binStatuses: [DeleteBinStatusInput!]!) {
    deleteBinStatus(binStatuses: $binStatuses) {
      ...BinStatus
    }
  }
  ${binStatus}
`;

export interface DeleteBinStatusVariables {
  binStatuses: DeleteBinStatusInput[];
}

export interface DeleteBinStatusResponse {
  deleteBinStatus: BinStatus[];
}

export const BinStatusQueries = {
  get,
};

export const BinStatusMutations = {
  create,
  update,
  remove,
};

export const BinStatusFragments = {
  binStatus,
};
