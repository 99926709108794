import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CreateTriggerInput, Trigger, TriggerEntityType } from '../../../../../types/trigger';
import Selector from '../../../../Common/Selector';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { LotContext } from '../../../../../context/LotContext';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface CreateTriggerEntityIdsPaneInputProps {
  trigger: CreateTriggerInput;
  setTrigger: (trigger: Trigger) => void;
  next: () => void;
  back: () => void;
}

export function CreateTriggerEntityIdsPane({ trigger, setTrigger, next, back }: CreateTriggerEntityIdsPaneInputProps) {
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { lots } = useContext(LotContext);

  return (
    <ModalPane
      footerButtons={[BackButtonTemplate(back), NextButtonTemplate(next)]}
      testId={testIds.createTriggerEntityIdsPane}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {trigger.entityType === TriggerEntityType.pmd ? (
              <Selector
                testId={testIds.triggerEntityIds}
                placeholder={t().triggerEntityIds.singular.label}
                values={[...[...productMasterData.values()].map(pmd => pmd.id)]}
                checkedValues={trigger.entityIds}
                toText={item => productMasterData.get(item)?.productName || 'Unknown'}
                onChange={checked => setTrigger(trigger.withEntityIds(checked))}
              />
            ) : (
              <Selector
                testId={testIds.triggerEntityIds}
                placeholder={t().triggerEntityIds.singular.label}
                values={[...[...lots.values()].map(lot => lot.id)]}
                checkedValues={trigger.entityIds}
                toText={item => lots.get(item)?.number || 'Unknown'}
                onChange={checked => setTrigger(trigger.withEntityIds(checked))}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
