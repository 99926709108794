import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { t } from '../../../../../../../types/translation/Translator';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ForwardIcon from '@mui/icons-material/Forward';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  ProductExportConfigurationMapping,
  ProductExportConfiguration,
  ProductExportField,
  stringToProductExportField,
  productExportFieldToString,
} from '../../../../../../../types/productExportConfiguration';
import { DraggableItemData } from '../../../../../../Common/DraggableItem';
import { DraggableList } from '../../../../../../Common/DraggableList';
import DropdownSelect from '../../../../../../Common/DropdownSelect';
import TextInput from '../../../../../../Common/TextInput';
import Checkbox from '../../../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import Paper from '../../../../../../../VentoryUI/components/common/Paper/Paper';
import { FlexPane } from '../../../../../../../VentoryUI/components/common/FlexPane/FlexPane';

export interface DraggableProductExportFieldIdentifier extends DraggableItemData, ProductExportConfigurationMapping {}

interface ProductExportConfigurationInputProps {
  configuration: ProductExportConfiguration;
  setConfiguration: (config: ProductExportConfiguration) => void;
  footer: (configuration: ProductExportConfiguration, items: DraggableProductExportFieldIdentifier[]) => JSX.Element;
}

export default function ProductExportConfigurationInput({
  configuration,
  setConfiguration,
  footer,
}: ProductExportConfigurationInputProps) {
  const [value, setValue] = useState<null>(null);
  const [items, setItems] = useState<DraggableProductExportFieldIdentifier[]>([]);

  const ProductReportFieldItem = (item: DraggableProductExportFieldIdentifier) => {
    return (
      <Grid key={`parent-${item.id}`} container display='flex' marginY='auto' columnSpacing={2} alignItems={'center'}>
        <Grid item>
          <ImportExportIcon />
        </Grid>
        <Grid item xs={3}>
          <p style={{ marginBottom: '0px' }}>{productExportFieldToString(item.field)}</p>
        </Grid>
        <Grid item>
          <ForwardIcon />
        </Grid>
        <Grid item flexGrow={1}>
          <TextInput
            dynamicUpdate
            draggable={true}
            onDragStart={event => event.preventDefault()}
            value={item.customField}
            onChange={v => {
              item.customField = v;
              setItems([...items]);
            }}
            placeholder={productExportFieldToString(item.field)}
          />
        </Grid>
        <Grid item style={{ cursor: 'pointer' }}>
          <DeleteOutlineIcon color='error' onClick={() => handleDelete(item.id)} />
        </Grid>
      </Grid>
    );
  };

  const handleDelete = (id: number) => {
    const index = items.findIndex(item => item.id === id);
    if (index === -1) return;
    items.splice(index, 1);
    setItems([...items]);
  };

  const handleAdd = (item: DraggableProductExportFieldIdentifier) => {
    setItems([...items, item]);
  };

  useEffect(() => {
    if (!items.length)
      setItems(
        configuration.fieldMapping.map((m: ProductExportConfigurationMapping, id: number) => {
          return { ...m, id, key: `${m.field}${id}` };
        }),
      );
  }, [configuration]);

  const dropdownItems = [
    ...Object.keys(ProductExportField)
      .filter(i => items.findIndex(item => item.field === i) === -1)
      .map(k => productExportFieldToString(k as ProductExportField)),
  ];

  return (
    <FlexPane
      content={
        <Paper>
          <FlexPane
            contentOverflow='auto'
            header={
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={6}>
                  <TextInput
                    mandatory
                    value={configuration.name}
                    label={t().name.singular.label}
                    placeholder={t().name.singular.label}
                    onChange={v => setConfiguration(configuration.withName(v))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    mandatory
                    value={configuration.filename}
                    label={t().fileName.singular.label}
                    placeholder={t().fileName.singular.label}
                    onChange={v => setConfiguration(configuration.withFilename(v))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    mandatory
                    value={configuration.delimiter}
                    label={t().delimiter.singular.label}
                    placeholder={t().delimiter.singular.label}
                    onChange={v => setConfiguration(configuration.withDelimiter(v))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={configuration.prefix}
                    label={t().prefix.singular.label}
                    placeholder={t().prefix.singular.label}
                    onChange={v => setConfiguration(configuration.withPrefix(v))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    value={configuration.default}
                    label={t().defaultConfiguration.singular.label}
                    onChange={checked => setConfiguration(configuration.withDefault(checked))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    selectOnly
                    label={'Add Product Export Field'}
                    values={dropdownItems}
                    selectedValue={null}
                    toText={item => item || 'Add'}
                    onChange={item => {
                      if (!item) return;
                      handleAdd({
                        customField: item,
                        field: stringToProductExportField(item) as ProductExportField,
                        id: items.length,
                        index: items.length,
                        key: `${stringToProductExportField(item)}${items.length}`,
                      });
                    }}
                    placeholder={t().addAdditionalProductExportField.singular.label}
                  />
                </Grid>
              </Grid>
            }
            content={
              <DndProvider backend={HTML5Backend}>
                <DraggableList displayFn={ProductReportFieldItem} items={items} setItems={setItems} />
              </DndProvider>
            }
          />
        </Paper>
      }
      footer={footer(configuration, items)}
    />
  );
}
