import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { getBackgroundColor, getBorderColor, getTextColor } from '../../util/color.util';
import { CompanyContext } from '../../context/CompanyContext';
import DropdownSelect from '../Common/DropdownSelect';
import { UserContext } from '../../context/UserContext';
import { t } from '../../types/translation/Translator';
import {
  FeatureRequest,
  FeatureRequestCategory,
  FeatureRequestImportance,
  FeatureRequestType,
  FeatureRequestUser,
  featureRequestCategoryToString,
  featureRequestImportanceToString,
  featureRequestTypeToString,
  featureRequestUserToString,
} from '../../types/support';
import TextArea from '../Common/TextArea';
import { useMutation } from '@apollo/client';
import {
  SubmitFeatureRequestResponse,
  SubmitFeatureRequestVariables,
  SupportMutations,
} from '../../graphql/support.graphql';

import { Button } from '../../VentoryUI/components/common/Button/Button';
import { ColorStyleType } from '../../VentoryUI/util/color.util';
import Pane from '../../VentoryUI/components/common/Pane/Pane';
import { FlexPane } from '../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../VentoryUI/components/common/Paper/Paper';
import { UploadImagesInput } from '../Common/UploadImageInput';

export default function FeatureRequestScreen() {
  const { currentCompany } = useContext(CompanyContext);
  const { companyUsers } = useContext(UserContext);

  const [info, setInfo] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [requestInput, setRequestInput] = useState<FeatureRequest>(
    new FeatureRequest({ companyId: currentCompany.id }),
  );
  const [attachments, setAttachments] = useState<string[]>([]);

  const [submit, { loading }] = useMutation<SubmitFeatureRequestResponse, SubmitFeatureRequestVariables>(
    SupportMutations.submitFeatureRequest,
    {
      onCompleted: res => {
        setInfo('Thank you for submitting your idea!');
        setRequestInput(new FeatureRequest({ companyId: currentCompany.id }));
      },
      onError: err => setError(err.message),
    },
  );

  const handleSubmit = async () => {
    try {
      await submit({
        variables: {
          featureRequest: requestInput,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleFile = async (files: FileList | null, index: number) => {
    if (!files) {
      attachments.splice(index, 1);
      return setAttachments([...attachments]);
    }

    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = () => {
        requestInput.withAttachements([
          {
            fileName: file?.name || 'attachment',
            mimeType: 'image/png',
            data: (reader.result as string).replace('data:', '').replace(/^.+,/, ''),
          },
          ...requestInput.attachments,
        ]);
        setAttachments([...attachments, reader.result as string]);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Pane error={error}>
      <FlexPane
        content={
          <Paper>
            <Grid container rowSpacing={1} columnSpacing={1} alignContent={'flex-start'}>
              <Grid item xs={12}>
                <p className='text-xl font-semibold'>Submit an idea</p>
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    backgroundColor: getBackgroundColor(currentCompany, ColorStyleType.primary),
                    color: getTextColor(currentCompany, ColorStyleType.primary),
                    borderColor: getBorderColor(currentCompany, ColorStyleType.primary),
                  }}
                  className='font-semibold text-sm p-2 rounded-lg'
                >
                  Shape the Ventory future! Got an idea on how to improve Ventory? <br />
                  We would like to hear all about it!
                </p>
              </Grid>
              {info ? (
                <Grid item xs={12} textAlign={'center'}>
                  <p className='text-sm font-semibold p-2 border my-2 border-green-600 bg-green-100 rounded-sm text-green-400'>
                    {info}
                  </p>
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().raiseOnBehalfOf.singular.label}
                      placeholder={t().raiseOnBehalfOf.singular.label}
                      values={[...companyUsers.values()]}
                      selectedValue={companyUsers.get(requestInput.userId || '') || null}
                      toText={user => user.email}
                      onChange={user => setRequestInput(requestInput.withUserId(user?.id))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().requestType.singular.label}
                      placeholder={t().requestType.singular.label}
                      values={Object.keys(FeatureRequestType)}
                      selectedValue={requestInput.type || null}
                      toText={type => featureRequestTypeToString(type as FeatureRequestType)}
                      onChange={type =>
                        setRequestInput(requestInput.withType((type as FeatureRequestType) || undefined))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().importance.singular.label}
                      placeholder={t().importance.singular.label}
                      values={Object.keys(FeatureRequestImportance)}
                      selectedValue={requestInput.importance || null}
                      toText={type => featureRequestImportanceToString(type as FeatureRequestImportance)}
                      onChange={type =>
                        setRequestInput(requestInput.withImportance((type as FeatureRequestImportance) || undefined))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().primarilyBenifitFeature.singular.label}
                      placeholder={t().targetUsers.singular.label}
                      values={Object.keys(FeatureRequestUser)}
                      selectedValue={requestInput.targetUser || null}
                      toText={type => featureRequestUserToString(type as FeatureRequestUser)}
                      onChange={type =>
                        setRequestInput(requestInput.withTargetUser((type as FeatureRequestUser) || undefined))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownSelect
                      mandatory
                      label={t().featureCategory.singular.label}
                      placeholder={t().featureCategory.singular.label}
                      values={Object.keys(FeatureRequestCategory)}
                      selectedValue={requestInput.category || null}
                      toText={type => featureRequestCategoryToString(type as FeatureRequestCategory)}
                      onChange={type =>
                        setRequestInput(requestInput.withCategory((type as FeatureRequestCategory) || undefined))
                      }
                      maxHeight='100px'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                  <Grid item xs={12}>
                    <TextArea
                      mandatory
                      label={t().tellUsMoreFeature.singular.label}
                      placeholder={t().tellUsMore.singular.label}
                      onChange={v => setRequestInput(requestInput.withDescription(v))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p className='text-sm font-semibold mb-1'>{t().attachements.singular.label}</p>
                    <UploadImagesInput loading={false} height='240px' images={attachments} onFile={handleFile} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        }
        footer={
          <Grid container justifyContent={'flex-end'}>
            <Grid item>
              <Button
                disabled={
                  !requestInput.userId ||
                  !requestInput.type ||
                  !requestInput.importance ||
                  !requestInput.targetUser ||
                  !requestInput.category ||
                  !requestInput.description
                }
                loading={loading}
                onClick={handleSubmit}
                text={t().send.singular.label}
                style='secondary'
              />
            </Grid>
          </Grid>
        }
      />
    </Pane>
  );
}
