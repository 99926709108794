export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Address {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  countryCode?: string;
  coordinates?: Coordinates;
}

export interface Country {
  countryCode: string;
  countryName: string;
}

// export interface CountryResponse {
//   name: { common: string };
//   cca2: string;
// }

// export function getCountryFromCountryCode(code?: string, countries?: Map<string, Country>): Country | null {
//   if (!code || !countries) return null;

//   const country = countries.get(code);
//   if (country) return country;
//   return null;
// }

export const addressToString = (address?: Address) => {
  if (!address) return '';
  return `${address.addressLine1 ? address.addressLine1 : null} ${
    address.addressLine2 ? address.addressLine2 : null
  }, ${address.postalCode ? address.postalCode : null} ${address.city ? address.city : null}, ${
    address.region ? address.region : null
  }, ${address.countryCode ? address.countryCode : null}`;
};

// export function getAddressTextAsArray(address?: Address) {
//   if (!address) return [];

//   const addressArray = new Array<string>();
//   address.addressLine1 ? addressArray.push(address.addressLine1) : null;
//   address.addressLine2 ? addressArray.push(address.addressLine2) : null;
//   address.postalCode || address.city || address.region
//     ? addressArray.push(
//         `${address.postalCode + ' ' || ''}${address.city || ''}${
//           ((address.city || address.postalCode) && address.region ? ', ' : '') + (address.region || '')
//         }`,
//       )
//     : null;
//   address.countryCode ? addressArray.push(address.countryCode) : null;
//   return addressArray;
// }
