import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export enum OrderExportField {
  company = 'company',
  orderId = 'orderId',
  orderCreatedAt = 'orderCreatedAt',
  orderCreatedBy = 'orderCreatedBy',
  orderType = 'orderType',
  stockLocationName = 'stockLocationName',
  contactName = 'contactName',
  contactLocation = 'contactLocation',
  type = 'type',
  status = 'status',
  externalReferenceId = 'externalReferenceId',
  estimatedTimeOfArrival = 'estimatedTimeOfArrival',
  purchaseOrderNumber = 'purchaseOrderNumber',
  fromBinName = 'fromBinName',
  toBinName = 'toBinName',
  productName = 'productName',
  productNumber = 'productNumber',
  serial = 'serial',
  lpn = 'lpn',
  countryOfOrigin = 'countryOfOrigin',
  lotNumber = 'lotNumber',
  processedBy = 'processedBy',
  processedAt = 'processedAt',
  countedQuantity = 'countedQuantity',
  discrepancy = 'discrepancy',
  quantityDifference = 'quantityDifference',
}

export class OrderExportConfigurationCustomField {
  index: number;

  customField: string;

  constructor(customField: string, index: number) {
    this.index = index;
    this.customField = customField;
  }
}

export class OrderExportConfigurationMapping extends OrderExportConfigurationCustomField {
  field: OrderExportField;

  constructor(customField: string, index: number, field: OrderExportField) {
    super(customField, index);
    this.field = field;
  }
}

export class OrderExportConfiguration extends CompanyEntity {
  name!: string;
  filename: string = 'ventory_order_${TIMESTAMP}.csv';
  splitByOrder: boolean = false;
  prefix?: string;
  delimiter: string = ',';
  quote?: string;
  fieldMapping: OrderExportConfigurationMapping[] = [...DEFAULT_MAPPING().values()];
  default: boolean = false;

  forUpdate(): UpdateOrderExportConfigurationInput {
    return UpdateOrderExportConfigurationInput.from(this, UpdateOrderExportConfigurationInput);
  }

  forDelete(): DeleteOrderExportConfigurationInput {
    return DeleteOrderExportConfigurationInput.from(this, DeleteOrderExportConfigurationInput);
  }

  validate(fields: any[]) {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof OrderExportConfiguration)[],
      (field: keyof OrderExportConfiguration) => {
        return null;
      },
    );
  }

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    this.default = false;
    Object.assign(this, cloneDeep(obj));
    this.default = obj.default || false;
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withFilename(filename: string) {
    this.filename = filename;
    return cloneDeep(this);
  }

  withPrefix(prefix: string) {
    this.prefix = prefix;
    return cloneDeep(this);
  }

  withDelimiter(delimiter: string) {
    this.delimiter = delimiter;
    return cloneDeep(this);
  }

  withSplitByOrder(split: boolean) {
    this.splitByOrder = split;
    return cloneDeep(this);
  }

  withDefault(value: boolean) {
    this.default = value;
    return cloneDeep(this);
  }
}
export class CreateOrderExportConfigurationInput extends forCreate(OrderExportConfiguration) {}

export class UpdateOrderExportConfigurationInput extends forUpdate(OrderExportConfiguration) {}

export class DeleteOrderExportConfigurationInput extends forDelete(OrderExportConfiguration) {}

const DEFAULT_MAPPING = () => {
  const map = new Map<OrderExportField, OrderExportConfigurationMapping>();
  map.set(OrderExportField.orderId, { customField: 'Order', field: OrderExportField.orderId, index: 0 });
  map.set(OrderExportField.productName, { customField: 'Product Name', field: OrderExportField.productName, index: 1 });
  map.set(OrderExportField.productNumber, {
    customField: 'Product Number',
    field: OrderExportField.productNumber,
    index: 2,
  });
  map.set(OrderExportField.stockLocationName, {
    customField: 'Stock Location',
    field: OrderExportField.stockLocationName,
    index: 3,
  });
  map.set(OrderExportField.fromBinName, { customField: 'From Bin', field: OrderExportField.fromBinName, index: 4 });
  map.set(OrderExportField.toBinName, { customField: 'To Bin', field: OrderExportField.toBinName, index: 5 });
  map.set(OrderExportField.status, { customField: 'Status', field: OrderExportField.status, index: 6 });
  map.set(OrderExportField.processedAt, { customField: 'Processed At', field: OrderExportField.processedAt, index: 7 });
  map.set(OrderExportField.processedBy, { customField: 'Processed By', field: OrderExportField.processedBy, index: 8 });
  map.set(OrderExportField.countedQuantity, {
    customField: 'Processed Quantity',
    field: OrderExportField.countedQuantity,
    index: 9,
  });
  return map;
};

export function orderExportFieldToString(item: OrderExportField) {
  switch (item) {
    case OrderExportField.company:
      return 'Company';
    case OrderExportField.orderId:
      return 'Order Id';
    case OrderExportField.orderCreatedAt:
      return 'Order Created At';
    case OrderExportField.orderCreatedBy:
      return 'Order Created By';
    case OrderExportField.stockLocationName:
      return 'Stock Location Name';
    case OrderExportField.contactName:
      return 'Contact Name';
    case OrderExportField.contactLocation:
      return 'Contact Location';
    case OrderExportField.type:
      return 'Type';
    case OrderExportField.status:
      return 'Status';
    case OrderExportField.externalReferenceId:
      return 'External Reference Id';
    case OrderExportField.estimatedTimeOfArrival:
      return 'Estimated Time Of Arrival';
    case OrderExportField.purchaseOrderNumber:
      return 'Purchase Order Number';
    case OrderExportField.fromBinName:
      return 'From Bin';
    case OrderExportField.toBinName:
      return 'To Bin';
    case OrderExportField.productName:
      return 'Product Name';
    case OrderExportField.productNumber:
      return 'Product Number';
    case OrderExportField.serial:
      return 'Serial Number';
    case OrderExportField.lpn:
      return 'LPN';
    case OrderExportField.countryOfOrigin:
      return 'Country Of Origin';
    case OrderExportField.lotNumber:
      return 'Lot Number';
    case OrderExportField.processedBy:
      return 'Processed By';
    case OrderExportField.processedAt:
      return 'Processed At';
    case OrderExportField.countedQuantity:
      return 'Counted Quantity';
    case OrderExportField.discrepancy:
      return 'Discrepancy';
    case OrderExportField.quantityDifference:
      return 'Quantity Difference';
    case OrderExportField.orderType:
      return 'Order Type';
  }
  return item;
}

export function stringToOrderExportField(item: string) {
  switch (item) {
    case 'Company':
      return OrderExportField.company;
    case 'Order Id':
      return OrderExportField.orderId;
    case 'Order Created At':
      return OrderExportField.orderCreatedAt;
    case 'Order Created By':
      return OrderExportField.orderCreatedBy;
    case 'Stock Location Name':
      return OrderExportField.stockLocationName;
    case 'Contact Name':
      return OrderExportField.contactName;
    case 'Contact Location':
      return OrderExportField.contactLocation;
    case 'Type':
      return OrderExportField.type;
    case 'Status':
      return OrderExportField.status;
    case 'External Reference Id':
      return OrderExportField.externalReferenceId;
    case 'Estimated Time Of Arrival':
      return OrderExportField.estimatedTimeOfArrival;
    case 'Purchase Order Number':
      return OrderExportField.purchaseOrderNumber;
    case 'From Bin':
      return OrderExportField.fromBinName;
    case 'To Bin':
      return OrderExportField.toBinName;
    case 'Product Name':
      return OrderExportField.productName;
    case 'Product Number':
      return OrderExportField.productNumber;
    case 'Serial Number':
      return OrderExportField.serial;
    case 'LPN':
      return OrderExportField.lpn;
    case 'Country Of Origin':
      return OrderExportField.countryOfOrigin;
    case 'Lot Number':
      return OrderExportField.lotNumber;
    case 'Processed By':
      return OrderExportField.processedBy;
    case 'Processed At':
      return OrderExportField.processedAt;
    case 'Counted Quantity':
      return OrderExportField.countedQuantity;
    case 'Discrepancy':
      return OrderExportField.discrepancy;
    case 'Quantity Difference':
      return OrderExportField.quantityDifference;
    case 'Order Type':
      return OrderExportField.orderType;
  }
  return item;
}
