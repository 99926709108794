import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function StockIcon({ height = 18, width = 18 }: IconInputProps) {
  return (
    <Icon>
      <svg
        width={width}
        height={height}
        viewBox='0 0 18 18'
        fill='none'
        stroke='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.6665 17.3334C16.8999 17.3334 17.0165 17.3334 17.1057 17.288C17.1841 17.2481 17.2478 17.1843 17.2878 17.1059C17.3332 17.0168 17.3332 16.9001 17.3332 16.6667V8.00008C17.3332 7.76673 17.3332 7.65005 17.2878 7.56092C17.2478 7.48252 17.1841 7.41878 17.1057 7.37883C17.0165 7.33342 16.8999 7.33342 16.6665 7.33342L14.6665 7.33342C14.4331 7.33342 14.3165 7.33342 14.2273 7.37883C14.1489 7.41878 14.0852 7.48252 14.0453 7.56092C13.9998 7.65005 13.9998 7.76673 13.9998 8.00008V10.0001C13.9998 10.2334 13.9998 10.3501 13.9544 10.4392C13.9145 10.5176 13.8507 10.5814 13.7723 10.6213C13.6832 10.6667 13.5665 10.6667 13.3332 10.6667H11.3332C11.0998 10.6667 10.9831 10.6667 10.894 10.7122C10.8156 10.7521 10.7519 10.8159 10.7119 10.8943C10.6665 10.9834 10.6665 11.1001 10.6665 11.3334V13.3334C10.6665 13.5668 10.6665 13.6834 10.6211 13.7726C10.5811 13.851 10.5174 13.9147 10.439 13.9547C10.3499 14.0001 10.2332 14.0001 9.99984 14.0001H7.99984C7.76648 14.0001 7.6498 14.0001 7.56067 14.0455C7.48227 14.0854 7.41853 14.1492 7.37858 14.2276C7.33317 14.3167 7.33317 14.4334 7.33317 14.6667V16.6667C7.33317 16.9001 7.33317 17.0168 7.37858 17.1059C7.41853 17.1843 7.48227 17.2481 7.56067 17.288C7.6498 17.3334 7.76648 17.3334 7.99984 17.3334L16.6665 17.3334Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.33317 4.66675C7.33317 4.43339 7.33317 4.31672 7.37858 4.22759C7.41853 4.14918 7.48227 4.08544 7.56067 4.0455C7.6498 4.00008 7.76648 4.00008 7.99984 4.00008H9.99984C10.2332 4.00008 10.3499 4.00008 10.439 4.0455C10.5174 4.08544 10.5811 4.14918 10.6211 4.22759C10.6665 4.31672 10.6665 4.43339 10.6665 4.66675V6.66675C10.6665 6.9001 10.6665 7.01678 10.6211 7.10591C10.5811 7.18431 10.5174 7.24805 10.439 7.288C10.3499 7.33342 10.2332 7.33342 9.99984 7.33342H7.99984C7.76648 7.33342 7.6498 7.33342 7.56067 7.288C7.48227 7.24805 7.41853 7.18431 7.37858 7.10591C7.33317 7.01678 7.33317 6.9001 7.33317 6.66675V4.66675Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.49984 9.66675C1.49984 9.43339 1.49984 9.31672 1.54525 9.22759C1.5852 9.14918 1.64894 9.08544 1.72734 9.0455C1.81647 9.00008 1.93315 9.00008 2.1665 9.00008H4.1665C4.39986 9.00008 4.51654 9.00008 4.60567 9.0455C4.68407 9.08544 4.74781 9.14918 4.78776 9.22759C4.83317 9.31672 4.83317 9.43339 4.83317 9.66675V11.6667C4.83317 11.9001 4.83317 12.0168 4.78776 12.1059C4.74781 12.1843 4.68407 12.2481 4.60567 12.288C4.51654 12.3334 4.39986 12.3334 4.1665 12.3334H2.1665C1.93315 12.3334 1.81647 12.3334 1.72734 12.288C1.64894 12.2481 1.5852 12.1843 1.54525 12.1059C1.49984 12.0168 1.49984 11.9001 1.49984 11.6667V9.66675Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.666504 1.33341C0.666504 1.10006 0.666504 0.983382 0.711918 0.894252C0.751865 0.815851 0.815607 0.752109 0.894008 0.712162C0.983138 0.666748 1.09982 0.666748 1.33317 0.666748H3.33317C3.56653 0.666748 3.6832 0.666748 3.77233 0.712162C3.85073 0.752109 3.91448 0.815851 3.95442 0.894252C3.99984 0.983382 3.99984 1.10006 3.99984 1.33341V3.33341C3.99984 3.56677 3.99984 3.68345 3.95442 3.77258C3.91448 3.85098 3.85073 3.91472 3.77233 3.95467C3.6832 4.00008 3.56653 4.00008 3.33317 4.00008H1.33317C1.09982 4.00008 0.983138 4.00008 0.894008 3.95467C0.815607 3.91472 0.751865 3.85098 0.711918 3.77258C0.666504 3.68345 0.666504 3.56677 0.666504 3.33341V1.33341Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
