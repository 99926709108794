import React from 'react';
import { ProductTransactionExportConfiguration } from '../types/productTransactionExportConfiguration';

export interface ProductTransactionExportConfigurationContextProps {
  productTransactionExportConfigurations: Map<string, ProductTransactionExportConfiguration>;
  setProductTransactionExportConfigurations: (
    productTransactionExportConfigurations: Map<string, ProductTransactionExportConfiguration>,
  ) => void;
  productTransactionExportConfigurationsLoading: boolean;
}

const defaultContext: ProductTransactionExportConfigurationContextProps = {
  productTransactionExportConfigurations: new Map<string, ProductTransactionExportConfiguration>(),
  setProductTransactionExportConfigurations: () => {},
  productTransactionExportConfigurationsLoading: false,
};

export const ProductTransactionExportConfigurationContext =
  React.createContext<ProductTransactionExportConfigurationContextProps>(defaultContext);
