import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function LocationMarkerIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.00033 8.33325C9.10489 8.33325 10.0003 7.43782 10.0003 6.33325C10.0003 5.22868 9.10489 4.33325 8.00033 4.33325C6.89576 4.33325 6.00033 5.22868 6.00033 6.33325C6.00033 7.43782 6.89576 8.33325 8.00033 8.33325Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.00033 14.6666C9.33366 11.9999 13.3337 10.2788 13.3337 6.66659C13.3337 3.72107 10.9458 1.33325 8.00033 1.33325C5.05481 1.33325 2.66699 3.72107 2.66699 6.66659C2.66699 10.2788 6.66699 11.9999 8.00033 14.6666Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
