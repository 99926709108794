import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export enum TaskExportField {
  company = 'company',
  productName = 'productName',
  productNumber = 'productNumber',
  scannedProductNumber = 'scannedProductNumber',
  barcode = 'barcode',
  stockLocationName = 'stockLocationName',
  binName = 'binName',
  lotNumber = 'lotNumber',
  scannedLotNumber = 'scannedLotNumber',
  lotExpiryDate = 'lotExpiryDate',

  serial = 'serial',
  scannedSerial = 'scannedSerial',
  serialManaged = 'serialManaged',
  lpn = 'lpn',
  scannedLPN = 'scannedLPN',
  countryOfOrigin = 'countryOfOrigin',
  scannedCountryOfOrigin = 'scannedCountryOfOrigin',
  expectedQuantity = 'expectedQuantity',
  assignedBy = 'assignedBy',
  countedAt = 'countedAt',
  countedBy = 'countedBy',
  countedQuantity = 'countedQuantity',
  quantityDifference = 'quantityDifference',
  discrepancy = 'discrepancy',
}

export class TaskExportConfigurationCustomField {
  index: number;

  customField: string;

  constructor(customField: string, index: number) {
    this.index = index;
    this.customField = customField;
  }
}

export class TaskExportConfigurationMapping extends TaskExportConfigurationCustomField {
  field: TaskExportField;

  constructor(customField: string, index: number, field: TaskExportField) {
    super(customField, index);
    this.field = field;
  }
}

export class TaskExportConfiguration extends CompanyEntity {
  name!: string;
  filename: string = 'ventory_task_${TIMESTAMP}.csv';
  discrepancy: boolean = false;
  countedOnly: boolean = false;
  prefix?: string;
  delimiter: string = ',';
  quote?: string;
  fieldMapping: TaskExportConfigurationMapping[] = [...DEFAULT_MAPPING().values()];

  forUpdate(): UpdateTaskExportConfigurationInput {
    return UpdateTaskExportConfigurationInput.from(this, UpdateTaskExportConfigurationInput);
  }

  forDelete(): DeleteTaskExportConfigurationInput {
    return DeleteTaskExportConfigurationInput.from(this, DeleteTaskExportConfigurationInput);
  }

  validate(fields: any[]) {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof TaskExportConfiguration)[],
      (field: keyof TaskExportConfiguration) => {
        return null;
      },
    );
  }

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }

  withFilename(filename: string) {
    this.filename = filename;
    return cloneDeep(this);
  }

  withDiscrepancy(discrepancy: boolean) {
    this.discrepancy = discrepancy;
    return cloneDeep(this);
  }

  withCountedOnly(countedOnly: boolean) {
    this.countedOnly = countedOnly;
    return cloneDeep(this);
  }

  withPrefix(prefix: string) {
    this.prefix = prefix;
    return cloneDeep(this);
  }

  withDelimiter(delimiter: string) {
    this.delimiter = delimiter;
    return cloneDeep(this);
  }
}
export class CreateTaskExportConfigurationInput extends forCreate(TaskExportConfiguration) {}

export class UpdateTaskExportConfigurationInput extends forUpdate(TaskExportConfiguration) {}

export class DeleteTaskExportConfigurationInput extends forDelete(TaskExportConfiguration) {}

const DEFAULT_MAPPING = () => {
  const map = new Map<TaskExportField, TaskExportConfigurationMapping>();
  map.set(TaskExportField.productName, { customField: 'Product Name', field: TaskExportField.productName, index: 0 });
  map.set(TaskExportField.productNumber, {
    customField: 'Product Number',
    field: TaskExportField.productNumber,
    index: 1,
  });
  map.set(TaskExportField.stockLocationName, {
    customField: 'Stock Location',
    field: TaskExportField.stockLocationName,
    index: 2,
  });
  map.set(TaskExportField.binName, { customField: 'Bin Name', field: TaskExportField.binName, index: 3 });
  map.set(TaskExportField.serial, { customField: 'Serial', field: TaskExportField.serial, index: 4 });
  map.set(TaskExportField.countedAt, { customField: 'Counted At', field: TaskExportField.countedAt, index: 5 });
  map.set(TaskExportField.countedBy, { customField: 'Counted By', field: TaskExportField.countedBy, index: 6 });
  map.set(TaskExportField.expectedQuantity, {
    customField: 'Expected Quantity',
    field: TaskExportField.expectedQuantity,
    index: 7,
  });
  map.set(TaskExportField.countedQuantity, {
    customField: 'Counted Quantity',
    field: TaskExportField.countedQuantity,
    index: 8,
  });
  map.set(TaskExportField.quantityDifference, {
    customField: 'Quantity Difference',
    field: TaskExportField.quantityDifference,
    index: 9,
  });
  return map;
};

export function taskExportFieldToString(item: TaskExportField) {
  switch (item) {
    case TaskExportField.company:
      return 'Company';
    case TaskExportField.productName:
      return 'Product Name';
    case TaskExportField.productNumber:
      return 'Product Number';
    case TaskExportField.scannedProductNumber:
      return 'Scanned Product Number';
    case TaskExportField.barcode:
      return 'Barcode';
    case TaskExportField.stockLocationName:
      return 'Stock Location Name';
    case TaskExportField.binName:
      return 'Bin Name';
    case TaskExportField.lotNumber:
      return 'Lot Number';
    case TaskExportField.lotExpiryDate:
      return 'Lot Expiry Date';
    case TaskExportField.serial:
      return 'Serial';
    case TaskExportField.scannedSerial:
      return 'Scanned Serial';
    case TaskExportField.serialManaged:
      return 'Serial Managed';
    case TaskExportField.lpn:
      return 'LPN';
    case TaskExportField.scannedLPN:
      return 'Scanned LPN';
    case TaskExportField.countryOfOrigin:
      return 'Country Of Origin';
    case TaskExportField.scannedCountryOfOrigin:
      return 'Scanned Country Of Origin';
    case TaskExportField.expectedQuantity:
      return 'Expected Quantity';
    case TaskExportField.assignedBy:
      return 'Assigned By';
    case TaskExportField.countedAt:
      return 'Counted At';
    case TaskExportField.countedBy:
      return 'Counted By';
    case TaskExportField.countedQuantity:
      return 'Counted Quantity';
    case TaskExportField.quantityDifference:
      return 'Quantity Difference';
    case TaskExportField.discrepancy:
      return 'Discrepancy';
    case TaskExportField.scannedLotNumber:
      return 'Scanned Lot Number';
  }
}

export function stringToTaskExportField(item: string) {
  switch (item) {
    case 'Company':
      return TaskExportField.company;
    case 'Product Name':
      return TaskExportField.productName;
    case 'Product Number':
      return TaskExportField.productNumber;
    case 'Scanned Product Number':
      return TaskExportField.scannedProductNumber;
    case 'Scanned Lot Number':
      return TaskExportField.scannedLotNumber;
    case 'Barcode':
      return TaskExportField.barcode;
    case 'StockLocation Name':
      return TaskExportField.stockLocationName;
    case 'Bin Name':
      return TaskExportField.binName;
    case 'Lot Number':
      return TaskExportField.lotNumber;
    case 'Lot Expiry Date':
      return TaskExportField.lotExpiryDate;
    case 'Serial':
      return TaskExportField.serial;
    case 'Scanned Serial':
      return TaskExportField.scannedSerial;
    case 'Serial Managed':
      return TaskExportField.serialManaged;
    case 'LPN':
      return TaskExportField.lpn;
    case 'Scanned LPN':
      return TaskExportField.scannedLPN;
    case 'Country Of Origin':
      return TaskExportField.countryOfOrigin;
    case 'Scanned Country Of Origin':
      return TaskExportField.scannedCountryOfOrigin;
    case 'Expected Quantity':
      return TaskExportField.expectedQuantity;
    case 'Assigned By':
      return TaskExportField.assignedBy;
    case 'Counted At':
      return TaskExportField.countedAt;
    case 'Counted By':
      return TaskExportField.countedBy;
    case 'Counted Quantity':
      return TaskExportField.countedQuantity;
    case 'Quantity Difference':
      return TaskExportField.quantityDifference;
    case 'Discrepancy':
      return TaskExportField.discrepancy;
  }
}
