import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { BinStatusFragments } from '../../graphql/binStatus.graphql';
import { BinStatus } from '../../types/binStatus';
import { BinStatusContext } from '../../context/BinStatusContext';

const binStatusSubscription = gql`
  subscription binStatusNotification($companyId: String!) {
    binStatusNotification(companyId: $companyId) {
      companyId
      entities {
        ...BinStatus
      }
      topic
      date
    }
  }
  ${BinStatusFragments.binStatus}
`;

export class BinStatusNotificationResponse extends forNotification<BinStatus>('binStatusNotification') {}

export default function BinStatusNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { binStatuses, setBinStatuses } = useContext(BinStatusContext);

  if (!currentCompany) return null;

  const { data: binStatusNotification } = useSubscription<BinStatusNotificationResponse>(binStatusSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleBinStatusNotification = (notification?: BinStatusNotificationResponse) => {
    if (!notification?.binStatusNotification) return;
    const binStatusNotification = notification.binStatusNotification;

    switch (binStatusNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        binStatusNotification.entities.forEach(bs => binStatuses.set(bs.id, new BinStatus(bs)));
        break;
      case 'DELETE':
        binStatusNotification.entities.forEach(bs => (binStatuses.has(bs.id) ? binStatuses.delete(bs.id) : null));
    }
    setBinStatuses(new Map(binStatuses));
  };

  useEffect(() => {
    handleBinStatusNotification(binStatusNotification);
  }, [binStatusNotification]);

  return <></>;
}
