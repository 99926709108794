import React from 'react';
import { TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { FooterButton } from '../Button/Button';
import ButtonRow from '../Button/ButtonRow';

interface FlexPaneProps {
  content: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  footerButtons?: FooterButton[];
  testId?: TestIdIdentifier;
  className?: string;
  overflow?: 'visible' | 'auto';
  contentOverflow?: 'visible' | 'hidden' | 'auto';
  wrapContent?: boolean;
  spacing?: number;
}

export function FlexPane({
  content,
  header,
  footer,
  footerButtons,
  testId,
  className,
  overflow = 'visible',
  contentOverflow = 'hidden',
  wrapContent = false,
  spacing = 2,
}: FlexPaneProps) {
  const templateRows = () => {
    let style = '';

    if (header) {
      style += 'auto ';
    }

    style += '1fr';

    if (footer || footerButtons?.length) {
      style += ' auto';
    }

    return style;
  };

  return (
    <div
      data-testid={testId?.name}
      className={`grid h-full w-full ${className} `}
      style={{ gridTemplateRows: templateRows(), overflow, gap: `${4 * spacing}px 0` }}
    >
      {header || null}

      {wrapContent ? content : <div style={{ overflow: contentOverflow }}>{content}</div>}

      {footer ? footer : footerButtons?.length ? <ButtonRow buttons={footerButtons} /> : null}
    </div>
  );
}
