import React, { useContext, useState } from 'react';
import { CsvUploadConfiguration, CsvUploadType } from '../../../../types/csvUploadConfiguration';
import { Divider, Grid } from '@mui/material';
import { t } from '../../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import {
  CsvUploadConfigurationMutations,
  CsvUploadResponse,
  CsvUploadVariables,
  DeleteCsvUploadConfigurationResponse,
  DeleteCsvUploadConfigurationVariables,
} from '../../../../graphql/csvUploadConfiguration';
import { CompanyContext } from '../../../../context/CompanyContext';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { handleKeyEvent } from '../../../../util/events.util';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { Button } from '../../../../VentoryUI/components/common/Button/Button';
import DeleteButton from '../../../../VentoryUI/components/common/Button/Templates/DeleteButton';

interface CsvUploadConfigurationSelectInputProps {
  configurations: CsvUploadConfiguration[];
  otherConfigurations?: CsvUploadConfiguration[];
  stockLocationId?: string;
  type: CsvUploadType;
  csvFileAsBase64Data: string;
  onBack: () => void;
  onClose: () => void;
  onCreate: () => void;
  deleteExisting: boolean;
  setError: (err: string) => void;
  handleUploadOverride?: (csvFileAsBase64Data: string, configurationId: string) => Promise<void>;
  onInspect: (id: string) => void;
}

export function CsvUploadConfigurationSelect({
  configurations,
  otherConfigurations = [],
  stockLocationId,
  type,
  csvFileAsBase64Data,
  deleteExisting,
  onBack,
  onClose,
  onCreate,
  setError,
  handleUploadOverride,
  onInspect,
}: CsvUploadConfigurationSelectInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [configurationInput, setConfigurationInput] = useState<CsvUploadConfiguration[]>(configurations);
  const [selected, setSelected] = useState<string | null>(null);

  const [upload, { loading: uploadLoading }] = useMutation<CsvUploadResponse, CsvUploadVariables>(
    CsvUploadConfigurationMutations.upload,
    {
      onCompleted: res => (res.csvUpload.upload === true ? onClose() : null),
      onError: err => setError(err.message),
    },
  );

  const [remove, { loading: deleteLoading }] = useMutation<
    DeleteCsvUploadConfigurationResponse,
    DeleteCsvUploadConfigurationVariables
  >(CsvUploadConfigurationMutations.remove, {
    onCompleted: res => {
      for (const config of res.deleteCsvUploadConfiguration) {
        const index = configurationInput.findIndex(c => c.id === config.id);
        if (index > -1) configurationInput.splice(index, 1);
        setConfigurationInput([...configurationInput]);
        setSelected(null);
        if (!configurationInput.length) onCreate();
      }
    },
    onError: err => setError(err.message),
  });

  const handleUpload = async () => {
    if (!selected) return;
    if (selected === '-1') {
      onCreate();
      return;
    }

    if (handleUploadOverride) await handleUploadOverride(csvFileAsBase64Data, selected);
    else {
      await upload({
        variables: {
          configurationId: selected,
          input: {
            companyId: currentCompany.id,
            stockLocationId: stockLocationId || '',
            type,
            csvFileAsBase64Data,
            deleteExisting,
          },
        },
      });
    }
  };

  const handleRemove = async () => {
    if (!selected || selected === '-1') return;

    const index = configurationInput.findIndex(c => c.id === selected);
    if (index > -1) {
      const toDelete = configurationInput[index].forDelete();
      await remove({
        variables: {
          csvUploadConfigurations: [toDelete],
        },
      });
    }
  };

  const handleSelect = (configurationId: string) => {
    setSelected(selected === configurationId ? null : configurationId);
  };

  return (
    <ModalPane
      testId={testIds.csvUploadConfigurationSelect}
      footer={
        <Grid container height={'100%'} alignContent={'flex-end'}>
          <Grid item>
            {selected && selected !== '-1' ? (
              <DeleteButton
                disabled={selected === null || uploadLoading}
                loading={deleteLoading}
                onClick={handleRemove}
              />
            ) : null}
          </Grid>
          <Grid item flexGrow={1}>
            <Grid container columnSpacing={1} justifyContent={'flex-end'}>
              <Grid item marginTop={'auto'}>
                <Button
                  style={'primary'}
                  onClick={onBack}
                  testId={testIds.back}
                  text={t().back.singular.label}
                  disabled={uploadLoading || deleteLoading}
                />
              </Grid>
              <Grid item marginTop={'auto'}>
                <Button
                  disabled={selected === null || deleteLoading}
                  loading={uploadLoading}
                  onClick={handleUpload}
                  testId={testIds.next}
                  style={'secondary'}
                  text={t().next.singular.label}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Grid container height={'100%'} justifyItems={'space-between'}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <p className='text-sm font-semibold select-none'>{t().selectCsvUploadConfiguration.singular.upper}</p>
            </Grid>
            <Grid item xs={12}>
              <Grid container mt={2} justifyContent={'start'}>
                {configurationInput.map(config => (
                  <Grid
                    key={config.id}
                    item
                    p={2}
                    mb={1}
                    mr={2}
                    width={'150px'}
                    onClick={() => (selected === config.id ? setSelected(null) : setSelected(config.id))}
                    className={`border-dashed cursor-pointer border-2 rounded ${
                      config.id === selected ? 'border-ventory-blue-900' : ''
                    }`}
                    tabIndex={0}
                    onKeyDown={event => handleKeyEvent(event, 'Enter', () => handleSelect(config.id))}
                  >
                    <Grid container height={'80px'}>
                      <Grid item xs={12} textAlign={'center'}>
                        <span className='fiv-viv fiv-icon-conf fiv-size-lg file_icon'></span>
                      </Grid>
                      <Grid item xs={12} mt={0.5} textAlign={'center'}>
                        <p
                          style={{
                            maxWidth: '100px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            overflow: 'hidden',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                          className='text-sm font-semibold select-none'
                        >
                          {config.name}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid
                  key={'new_config'}
                  item
                  p={2}
                  mb={1}
                  mr={2}
                  width={'150px'}
                  onClick={() => handleSelect('-1')}
                  className={`border-dashed cursor-pointer border-2 rounded ${
                    selected === '-1' ? 'border-ventory-blue-900' : ''
                  }`}
                  tabIndex={0}
                  data-testid={testIds.newConfig}
                  onKeyDown={event => handleKeyEvent(event, 'Enter', () => handleSelect('-1'))}
                >
                  <Grid container height={'80px'} alignContent={'center'}>
                    <Grid item xs={12} textAlign={'center'}>
                      <AddIcon />
                    </Grid>
                    <Grid item xs={12} mt={0.5} textAlign={'center'}>
                      <p className='text-sm font-semibold select-none'>{'New'}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!configurations.length && otherConfigurations.length ? (
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Grid container>
                <Grid item xs={12} textAlign={'center'}>
                  <p className='text-sm font-medium select-none bg-yellow-100 rounded-sm text-yellow-400 py-1'>
                    {t().productImportExistingConfigurationPlaceholder.singular.upper}
                  </p>
                </Grid>
                <Grid item xs={12} mt={1} flexDirection={'column'}>
                  <Grid container>
                    {otherConfigurations.map(config => (
                      <Grid
                        key={config.id}
                        item
                        p={2}
                        mb={1}
                        mr={2}
                        width={'150px'}
                        onClick={() => onInspect(config.id)}
                        className={`border-dashed cursor-pointer border-2 rounded ${
                          config.id === selected ? 'border-ventory-blue-900' : ''
                        }`}
                        tabIndex={0}
                      >
                        <Grid container height={'80px'}>
                          <Grid item xs={12} textAlign={'center'}>
                            <span className='fiv-viv fiv-icon-conf fiv-size-lg file_icon'></span>
                          </Grid>
                          <Grid item xs={12} mt={0.5} textAlign={'center'}>
                            <p
                              style={{
                                maxWidth: '100px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block',
                                overflow: 'hidden',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              className='text-sm font-semibold select-none'
                            >
                              {config.name}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </ModalPane>
  );
}
