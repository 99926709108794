import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { t } from '../../../../types/translation/Translator';
import { CreateTaskInput, Task } from '../../../../types/task';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ScanConfigurationType } from '../../../../types/company';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface CreateTaskValidationPaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next: () => void;
  back: () => void;
}

export function CreateTaskValidationPane({ task, setTask, next, back }: CreateTaskValidationPaneInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const hasLPN = !!currentCompany.settings.scanConfigurations?.filter(sc => sc.type === ScanConfigurationType.lpn)
    .length;
  const hasSerial = !!currentCompany.settings.scanConfigurations?.filter(sc => sc.type === ScanConfigurationType.serial)
    .length;
  const hasCOO = !!currentCompany.settings.scanConfigurations?.filter(sc => sc.type === ScanConfigurationType.coo)
    .length;
  const hasLot = !!currentCompany.settings.scanConfigurations?.filter(sc => sc.type === ScanConfigurationType.lot)
    .length;
  const hasProductNumber = !!currentCompany.settings.scanConfigurations?.filter(
    sc => sc.type === ScanConfigurationType.productNumber,
  ).length;

  return (
    <ModalPane
      footerButtons={[BackButtonTemplate(back), NextButtonTemplate(next)]}
      testId={testIds.createTaskValidationPane}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} className='text-sm font-semibold'>
              <Checkbox
                label={t().validateQuantity.singular.label}
                value={task.validation.quantity}
                onChange={value => task.withQuantityValidation(value)}
                testId={testIds.validateQuantity}
              />
            </Grid>
            {hasLPN || hasSerial || hasCOO || hasLot || hasProductNumber ? (
              <Grid item xs={12} className='text-sm font-semibold'>
                <Checkbox
                  testId={testIds.validateBarcodes}
                  label={t().validateBarcodes.singular.label}
                  value={!!task.validation.validationFields.length}
                  onChange={value => {
                    if (value) {
                      hasLPN
                        ? setTask(task.withLPNValidation(true))
                        : hasSerial
                        ? setTask(task.withSerialValidation(true))
                        : null;
                    } else {
                      setTask(task.resetValidation());
                    }
                  }}
                />
              </Grid>
            ) : null}
            <Grid pl={4} item xs={12} className='text-sm font-semibold'>
              {task.validation.validationFields.length ? (
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    {hasLPN ? (
                      <Checkbox
                        testId={testIds.lpn}
                        dynamicUpdate
                        key={'lpnValidationCheckbox'}
                        disabled={true}
                        label={t().lpn.singular.label}
                        value={!!task.hasLPNValidation()}
                        onChange={value => task.withLPNValidation(value)}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {hasSerial ? (
                      <Checkbox
                        testId={testIds.serial}
                        disabled={!hasLPN && hasSerial}
                        label={t().serial.singular.label}
                        value={!!task.hasSerialValidation()}
                        onChange={value => task.withSerialValidation(value)}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {hasProductNumber ? (
                      <Checkbox
                        testId={testIds.productNumber}
                        label={t().productNumber.singular.label}
                        value={!!task.hasProductNumberValidation()}
                        onChange={value => task.withProductNumberValidation(value)}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {hasCOO ? (
                      <Checkbox
                        testId={testIds.countryOfOrigin}
                        label={t().countryOfOrigin.singular.label}
                        value={!!task.hasCOOValidation()}
                        onChange={value => task.withCOOValidation(value)}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    {hasLot ? (
                      <Checkbox
                        testId={testIds.lot}
                        label={t().lotNumber.singular.label}
                        value={!!task.hasLotValidation()}
                        onChange={value => task.withLotValidation(value)}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
