export enum LanguageRegionDesignator {
  afZA = 'afZA',
  arAE = 'arAE',
  arAR = 'arAR',
  arBH = 'arBH',
  arDJ = 'arDJ',
  arDZ = 'arDZ',
  arEG = 'arEG',
  arEH = 'arEH',
  arER = 'arER',
  arIL = 'arIL',
  arIQ = 'arIQ',
  arJO = 'arJO',
  arKM = 'arKM',
  arKW = 'arKW',
  arLB = 'arLB',
  arLY = 'arLY',
  arMA = 'arMA',
  arMR = 'arMR',
  arOM = 'arOM',
  arPS = 'arPS',
  arQA = 'arQA',
  arSA = 'arSA',
  arSD = 'arSD',
  arSO = 'arSO',
  arSY = 'arSY',
  arTD = 'arTD',
  arTN = 'arTN',
  arYE = 'arYE',
  azAZ = 'azAZ',
  beBY = 'beBY',
  bgBG = 'bgBG',
  bnIN = 'bnIN',
  bsBA = 'bsBA',
  caAD = 'caAD',
  caES = 'caES',
  csCZ = 'csCZ',
  csSK = 'csSK',
  cyGB = 'cyGB',
  daDK = 'daDK',
  daFO = 'daFO',
  daGL = 'daGL',
  deAT = 'deAT',
  deBE = 'deBE',
  deCH = 'deCH',
  deDE = 'deDE',
  deLI = 'deLI',
  deLU = 'deLU',
  deNA = 'deNA',
  elCY = 'elCY',
  elGR = 'elGR',
  enAG = 'enAG',
  enAI = 'enAI',
  enAS = 'enAS',
  enAU = 'enAU',
  enBB = 'enBB',
  enBD = 'enBD',
  enBM = 'enBM',
  enBS = 'enBS',
  enBW = 'enBW',
  enBZ = 'enBZ',
  enCA = 'enCA',
  enCK = 'enCK',
  enCM = 'enCM',
  enCW = 'enCW',
  enDM = 'enDM',
  enER = 'enER',
  enFJ = 'enFJ',
  enFK = 'enFK',
  enFM = 'enFM',
  enGB = 'enGB',
  enGD = 'enGD',
  enGG = 'enGG',
  enGH = 'enGH',
  enGI = 'enGI',
  enGM = 'enGM',
  enGU = 'enGU',
  enGY = 'enGY',
  enHK = 'enHK',
  enIE = 'enIE',
  enIM = 'enIM',
  enIN = 'enIN',
  enJE = 'enJE',
  enJM = 'enJM',
  enKE = 'enKE',
  enKI = 'enKI',
  enKN = 'enKN',
  enKY = 'enKY',
  enLC = 'enLC',
  enLK = 'enLK',
  enLR = 'enLR',
  enLS = 'enLS',
  enMH = 'enMH',
  enMP = 'enMP',
  enMS = 'enMS',
  enMT = 'enMT',
  enMU = 'enMU',
  enMW = 'enMW',
  enMY = 'enMY',
  enNA = 'enNA',
  enNG = 'enNG',
  enNR = 'enNR',
  enNU = 'enNU',
  enNZ = 'enNZ',
  enPG = 'enPG',
  enPH = 'enPH',
  enPI = 'enPI',
  enPK = 'enPK',
  enPN = 'enPN',
  enPR = 'enPR',
  enPW = 'enPW',
  enRW = 'enRW',
  enSB = 'enSB',
  enSC = 'enSC',
  enSD = 'enSD',
  enSG = 'enSG',
  enSH = 'enSH',
  enSL = 'enSL',
  enSO = 'enSO',
  enSS = 'enSS',
  enSZ = 'enSZ',
  enTC = 'enTC',
  enTO = 'enTO',
  enTT = 'enTT',
  enTV = 'enTV',
  enTZ = 'enTZ',
  enUD = 'enUD',
  enUG = 'enUG',
  enUS = 'enUS',
  enVC = 'enVC',
  enVG = 'enVG',
  enVI = 'enVI',
  enVU = 'enVU',
  enWS = 'enWS',
  enZA = 'enZA',
  enZM = 'enZM',
  enZW = 'enZW',
  eoEO = 'eoEO',
  esAR = 'esAR',
  esBO = 'esBO',
  esCL = 'esCL',
  esCO = 'esCO',
  esCR = 'esCR',
  esCU = 'esCU',
  esDO = 'esDO',
  esEC = 'esEC',
  esES = 'esES',
  esGI = 'esGI',
  esGQ = 'esGQ',
  esGT = 'esGT',
  esHN = 'esHN',
  esLA = 'esLA',
  esMX = 'esMX',
  esNI = 'esNI',
  esPA = 'esPA',
  esPE = 'esPE',
  esPR = 'esPR',
  esPY = 'esPY',
  esSV = 'esSV',
  esUS = 'esUS',
  esUY = 'esUY',
  esVE = 'esVE',
  etEE = 'etEE',
  euES = 'euES',
  faIR = 'faIR',
  fbLT = 'fbLT',
  fiFI = 'fiFI',
  foFO = 'foFO',
  frBE = 'frBE',
  frBF = 'frBF',
  frBI = 'frBI',
  frBJ = 'frBJ',
  frCA = 'frCA',
  frCD = 'frCD',
  frCF = 'frCF',
  frCG = 'frCG',
  frCH = 'frCH',
  frCI = 'frCI',
  frCM = 'frCM',
  frDJ = 'frDJ',
  frFR = 'frFR',
  frGA = 'frGA',
  frGD = 'frGD',
  frGF = 'frGF',
  frGN = 'frGN',
  frGP = 'frGP',
  frGQ = 'frGQ',
  frHT = 'frHT',
  frKM = 'frKM',
  frLC = 'frLC',
  frLU = 'frLU',
  frMC = 'frMC',
  frMF = 'frMF',
  frMG = 'frMG',
  frML = 'frML',
  frMQ = 'frMQ',
  frMU = 'frMU',
  frNC = 'frNC',
  frNE = 'frNE',
  frPF = 'frPF',
  frPM = 'frPM',
  frRE = 'frRE',
  frRW = 'frRW',
  frSC = 'frSC',
  frSN = 'frSN',
  frTD = 'frTD',
  frTG = 'frTG',
  frVU = 'frVU',
  frWF = 'frWF',
  frYT = 'frYT',
  fyNL = 'fyNL',
  gaGB = 'gaGB',
  gaIE = 'gaIE',
  glES = 'glES',
  heIL = 'heIL',
  hiFJ = 'hiFJ',
  hiIN = 'hiIN',
  hiPK = 'hiPK',
  hrBA = 'hrBA',
  hrHR = 'hrHR',
  huHU = 'huHU',
  hyAM = 'hyAM',
  idID = 'idID',
  isIS = 'isIS',
  itCH = 'itCH',
  itIT = 'itIT',
  itSM = 'itSM',
  itVA = 'itVA',
  jaJP = 'jaJP',
  jaPW = 'jaPW',
  kaGE = 'kaGE',
  kmKH = 'kmKH',
  koKP = 'koKP',
  koKR = 'koKR',
  kuTR = 'kuTR',
  laVA = 'laVA',
  ltLT = 'ltLT',
  lvLV = 'lvLV',
  mkMK = 'mkMK',
  mlIN = 'mlIN',
  msBN = 'msBN',
  msID = 'msID',
  msMY = 'msMY',
  msSG = 'msSG',
  mtMT = 'mtMT',
  nbNO = 'nbNO',
  neNP = 'neNP',
  nlAN = 'nlAN',
  nlAW = 'nlAW',
  nlBE = 'nlBE',
  nlCW = 'nlCW',
  nlNL = 'nlNL',
  nlSR = 'nlSR',
  nlSX = 'nlSX',
  nnNO = 'nnNO',
  noNO = 'noNO',
  paIN = 'paIN',
  plPL = 'plPL',
  psAF = 'psAF',
  ptAO = 'ptAO',
  ptBR = 'ptBR',
  ptCV = 'ptCV',
  ptGQ = 'ptGQ',
  ptGW = 'ptGW',
  ptMO = 'ptMO',
  ptMZ = 'ptMZ',
  ptPT = 'ptPT',
  ptST = 'ptST',
  ptTL = 'ptTL',
  roMD = 'roMD',
  roRO = 'roRO',
  ruBY = 'ruBY',
  ruKG = 'ruKG',
  ruKZ = 'ruKZ',
  ruRU = 'ruRU',
  ruTJ = 'ruTJ',
  skCZ = 'skCZ',
  skSK = 'skSK',
  slSI = 'slSI',
  sqAL = 'sqAL',
  sqKS = 'sqKS',
  srBA = 'srBA',
  srME = 'srME',
  srRS = 'srRS',
  svFI = 'svFI',
  svSE = 'svSE',
  swKE = 'swKE',
  taIN = 'taIN',
  teIN = 'teIN',
  thTH = 'thTH',
  tlPH = 'tlPH',
  trCY = 'trCY',
  trTR = 'trTR',
  ukUA = 'ukUA',
  viVN = 'viVN',
  zhCN = 'zhCN',
  zhHK = 'zhHK',
  zhMO = 'zhMO',
  zhSG = 'zhSG',
  zhTW = 'zhTW',
}

export function isValidLanguageRegionDesignator(designator: LanguageRegionDesignator): boolean {
  const languageCode = languageRegionDesignatorToLanguageCode(designator);
  switch (languageCode) {
    case 'en':
      return designator === 'enUS' || designator === 'enGB';
    case 'lt':
      return true;
    default:
      return false;
  }
}

export function languageRegionDesignatorToLanguageCode(designator: LanguageRegionDesignator) {
  return `${designator[0]}${designator[1]}`;
}

export function languageRegionDesignatorToCountryCode(designator: LanguageRegionDesignator) {
  return `${designator[2]}${designator[3]}`;
}

export function languageRegionDesignatorToStorageString(designator?: LanguageRegionDesignator | undefined | null) {
  if (!designator) return;
  return `${languageRegionDesignatorToLanguageCode(designator)}-${languageRegionDesignatorToCountryCode(designator)}`;
}

export function storageStringToLanguageRegionDesignator(storageString?: string) {
  if (!storageString) return;
  return storageString.split('-').join('') as LanguageRegionDesignator;
}
