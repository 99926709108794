import React from 'react';
import Icon from '../Icon';

export default function BulbIcon() {
  return (
    <Icon>
      <svg
        width='14'
        height='18'
        viewBox='0 0 14 18'
        stroke='currentColor'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.9165 17.3333H9.08317M5.33317 7.33329H8.6665M6.99984 7.33329L6.99984 12.3333M9.49984 11.7719C11.4706 10.8357 12.8332 8.82696 12.8332 6.49996C12.8332 3.2783 10.2215 0.666626 6.99984 0.666626C3.77818 0.666626 1.1665 3.2783 1.1665 6.49996C1.1665 8.82696 2.52905 10.8357 4.49984 11.7719V12.3333C4.49984 13.1099 4.49984 13.4981 4.6267 13.8044C4.79586 14.2128 5.12032 14.5373 5.5287 14.7064C5.83498 14.8333 6.22327 14.8333 6.99984 14.8333C7.77641 14.8333 8.16469 14.8333 8.47098 14.7064C8.87936 14.5373 9.20381 14.2128 9.37297 13.8044C9.49984 13.4981 9.49984 13.1099 9.49984 12.3333V11.7719Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
