export enum ProductTransactionReasonForFailure {
  product_master_data_not_serial_managed = 'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_SERIAL_MANAGED',
  product_master_data_not_lpn_managed = 'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_LPN_MANAGED',
  product_master_data_not_lot_managed = 'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_LOT_MANAGED',
  bin_no_outbound_allowed = 'PRODUCT_TRANSACTION_NO_OUTBOUND_ALLOWED',
  bin_no_inbound_allowed = 'PRODUCT_TRANSACTION_NO_INBOUND_ALLOWED',
  quantity_negative = 'PRODUCT_TRANSACTION_QUANTITY_NEGATIVE',
  quantity_zero = 'PRODUCT_TRANSACTION_QUANTITY_ZERO',
  quantity_serial = 'PRODUCT_TRANSACTION_QUANTITY_SERIAL',
  quantity_lpn = 'PRODUCT_TRANSACTION_QUANTITY_LPN',
  interal_error = 'PRODUCT_TRANSACTION_INTERNAL_ERROR',
  manual_rollback = 'PRODUCT_TRANSACTION_MANUAL_ROLLBACK',
  multiple_products_found = 'PRODUCT_TRANSACTION_MULTIPLE_PRODUCTS_FOUND',
  processed_quantity_to_big = 'PRODUCT_TRANSACTION_PROCESSED_QUANTITY_TO_BIG',
  product_master_data_id_missing = 'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_ID_MISSING',
  product_master_data_not_found = 'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_FOUND',
  product_not_found = 'PRODUCT_TRANSACTION_PRODUCT_NOT_FOUND',
  product_master_data_not_defined = 'PRODUCT_TRANSACTION_PRODUCT_MASTER_DATA_NOT_DEFINED',
  to_bin_not_defined = 'PRODUCT_TRANSACTION_TO_BIN_NOT_DEFINED',
  from_bin_not_defined = 'PRODUCT_TRANSACTION_FROM_BIN_NOT_DEFINED',
  to_stock_location_not_defined = 'PRODUCT_TRANSACTION_TO_STOCK_LOCATION_NOT_DEFINED',
  from_stock_location_not_defined = 'PRODUCT_TRANSACTION_FROM_STOCK_LOCATION_NOT_DEFINED',
  serial_already_exists = 'PRODUCT_TRANSACTION_PRODUCT_WITH_SERIAL_ALREADY_EXISTS',
  lpn_already_exists = 'PRODUCT_TRANSACTION_PRODUCT_WITH_LPN_ALREADY_EXISTS',
  to_bin_not_found = 'PRODUCT_TRANSACTION_TO_BIN_NOT_FOUND',
  from_bin_not_found = 'PRODUCT_TRANSACTION_FROM_BIN_NOT_FOUND',
  to_container_not_found = 'PRODUCT_TRANSACTION_TO_CONTAINER_NOT_FOUND',
  from_container_not_found = 'PRODUCT_TRANSACTION_FROM_CONTAINER_NOT_FOUND',
  product_serial_not_found = 'PRODUCT_TRANSACTION_PRODUCT_SERIAL_NOT_FOUND',
  product_lpn_not_found = 'PRODUCT_TRANSACTION_PRODUCT_LPN_NOT_FOUND',
  serial_missing = 'PRODUCT_TRANSACTION_PRODUCT_SERIAL_MISSING',
  lpn_missing = 'PRODUCT_TRANSACTION_PRODUCT_LPN_MISSING',
  lot_missing = 'PRODUCT_TRANSACTION_PRODUCT_LOT_MISSING',
}
