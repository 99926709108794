import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import { ChartData } from '../Chart';
import React from 'react';

export interface HorizontalAxisProps {
  width: number;
  height: number;
  data: ChartData[];
  margin: { left: number; bottom: number };
}

export default function HorizontalAxis({ width, height, data, margin }: HorizontalAxisProps) {
  const ref = useRef<SVGGElement>(null);

  const transform = `translate(${margin.left}, ${height - margin.bottom})`;

  const scale = d3
    .scaleBand()
    .paddingInner(0.6)
    .domain(data.map(({ label }) => label))
    .range([margin.left, width - 2 * margin.left]);

  const emptyTicks = data.filter(d => d.value).length;
  let showNext = 0;
  const xAxis = d3.axisBottom(scale);
  xAxis.tickFormat((tick: any, idx: number) => {
    if (idx === 0 && data[idx].value !== 0) return tick;
    if (idx === 0 && data[idx].value === 0) return '';

    if (data.length < 15) return tick;

    if (data[idx].value === 0 && data[idx - 1].value === 0 && showNext) {
      showNext = 0;
      if (data[idx + 1] && data[idx + 1].value !== 0) return '';
      return tick;
    } else if (data[idx].value === 0) {
      showNext++;
      return '';
    }

    if (showNext) {
      showNext = 0;
      return tick;
    }

    if (data[idx - 1].value !== 0) {
      showNext++;
      return '';
    }

    return tick;
  });

  const axisColor = 'transparant';
  const gridColor = '#000000';
  const showGrid = false;

  useEffect(() => {
    if (ref.current) {
      const svg = d3.select(ref.current).attr('class', 'xAxis');

      svg.call(xAxis);
      svg.selectAll('path').attr('stroke', axisColor);
      svg.selectAll('line').attr('stroke', axisColor);

      svg
        .selectAll('text')
        .attr('fill', 'rgba(71, 84, 103, 1)')
        .attr('font-size', `12px`)
        .attr('font-weight', '400')
        .attr('font-family', 'Inter');

      if (showGrid) {
        svg
          .selectAll('g.xAxis g.tick')
          .append('line')
          .attr('class', 'gridline')
          .attr('x1', 0)
          .attr('y1', -height)
          .attr('x2', 0)
          .attr('y2', 0)
          .attr('stroke', gridColor)
          .attr('strokeWidth', 1);
      }
    }
  }, [scale, height, showGrid]);

  return <g ref={ref} transform={transform} />;
}
