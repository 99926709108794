import { ReorderRuleProduct } from '../../../../components/Common/ReorderRules/ReorderRuleScreen';
import { ProductMasterData } from '../../../../types/productMasterData';
import { toFilterString } from '../../../../util/string.util';
import { EntityFilter } from '../filter.util';

export namespace ReorderRuleProductFilter {
  export function search(
    item: ReorderRuleProduct,
    textFilter: string,
    productMasterData: Map<string, ProductMasterData>,
  ) {
    const productName = productMasterData.get(item.productMasterDataId)?.productName;
    const productNumber = productMasterData.get(item.productMasterDataId)?.productNumber;

    if (toFilterString(productName).includes(textFilter) || toFilterString(productNumber).includes(textFilter)) {
      return true;
    }
    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: ReorderRuleProduct[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      return onFilter(item, textFilter);
    });

    return result;
  }
}
