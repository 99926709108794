import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function BellIcon({}: IconInputProps) {
  return (
    <Icon>
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        stroke='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.5004 14.8333C11.5004 16.214 10.3811 17.3333 9.00037 17.3333C7.61966 17.3333 6.50037 16.214 6.50037 14.8333M10.4975 4.19876C10.8604 3.82383 11.0837 3.31297 11.0837 2.74996C11.0837 1.59937 10.151 0.666626 9.00037 0.666626C7.84978 0.666626 6.91704 1.59937 6.91704 2.74996C6.91704 3.31297 7.14037 3.82383 7.50328 4.19876M1.12272 5.93576C1.11076 4.7263 1.76283 3.59689 2.81623 3.00251M16.878 5.93576C16.89 4.7263 16.2379 3.59689 15.1845 3.00251M14.0004 8.33329C14.0004 7.18402 13.4736 6.08182 12.5359 5.26916C11.5982 4.45651 10.3265 3.99996 9.00037 3.99996C7.67429 3.99996 6.40252 4.45651 5.46484 5.26916C4.52715 6.08182 4.00037 7.18402 4.00037 8.33329C4.00037 10.2348 3.52881 11.6254 2.94042 12.6205C2.26982 13.7546 1.93452 14.3217 1.94776 14.4572C1.96291 14.6121 1.9908 14.661 2.11649 14.753C2.22635 14.8333 2.7783 14.8333 3.88219 14.8333H14.1185C15.2224 14.8333 15.7744 14.8333 15.8843 14.753C16.0099 14.661 16.0378 14.6121 16.053 14.4572C16.0662 14.3217 15.7309 13.7546 15.0603 12.6205C14.4719 11.6254 14.0004 10.2348 14.0004 8.33329Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
