import { EntityFilter } from '../filter.util';
import { Bin } from '../../../../types/bin';
import { toFilterString } from '../../../../util/string.util';
import { BinStatus } from '../../../../types/binStatus';

export namespace BinFilter {
  export function search(item: Bin, textFilter: string, binStatuses: Map<string, BinStatus>) {
    if (
      toFilterString(item.name).includes(textFilter) ||
      toFilterString(binStatuses.get(item.binStatusId)?.status).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: Bin[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      if (filter.stockLocation && filter.stockLocation.size && !filter.stockLocation.has(item.stockLocationId)) {
        return false;
      }

      return onFilter(item, textFilter);
    });

    return result;
  }
}
