import React from 'react';
import { CustomTranslation } from '../types/translation/customTranslation';

export interface CustomTranslationContextProps {
  customTranslations: Map<string, CustomTranslation>;
  setCustomTranslations: (customTranslations: Map<string, CustomTranslation>) => void;
  customTranslationsLoading: boolean;
}

const defaultContext: CustomTranslationContextProps = {
  customTranslations: new Map(),
  setCustomTranslations: () => {},
  customTranslationsLoading: false,
};

export const CustomTranslationContext = React.createContext<CustomTranslationContextProps>(defaultContext);
