import React from 'react';
import { Task, taskStatusToColorClass, taskStatusToString } from '../../types/task';
import StatusLabel from '../../VentoryUI/components/common/StatusLabel/StatusLabel';

interface TaskStatusLabelProps {
  task: Task;
}

export default function TaskStatusLabel({ task }: TaskStatusLabelProps) {
  return (
    <StatusLabel
      color={taskStatusToColorClass(task.status, task.overdue)}
      text={taskStatusToString(task.status, task.overdue)}
    />
  );
}
