import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import ProductMasterDataInfoPane from './Panes/ProductMasterDataInfoPane';
import { ProductMasterData } from '../../../types/productMasterData';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import LoadingPackage from '../../Common/LoadingPackage';
import {
  ProductMasterDataMutations,
  UpdateProductMasterDataResponse,
  UpdateProductMasterDataVariables,
} from '../../../graphql/productMasterData.graphql';
import ProductMasterDataAttributePane from './Panes/ProductMasterDataAttributePane';
import ProductMasterDataCommentPane from './Panes/ProductMasterDataCommentPane';
import { testIds } from '../../../util/identifiers/identifiers.util';
import ProductMasterDataReorderRulesPane from './Panes/ProductMasterDataReorderRulesPane';
import NotFound from '../../../assets/html/notFound';
import BackButton from '../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../VentoryUI/components/common/Button/Templates/SaveButton';
import { CompanyContext } from '../../../context/CompanyContext';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';
import FilesForEntityPane from '../../../VentoryUI/components/common/File/FilesForEntityPane';
import { FileEntityType } from '../../../types/file';

const tabs = () => [
  {
    text: t().info.singular.label,
    path: 'info',
    key: 'info',
  },
  {
    text: t().attributes.singular.label,
    path: 'attributes',
    key: 'attributes',
  },
  {
    text: t().comment.plural.label,
    path: 'comments',
    key: 'comments',
  },
  {
    text: t().stockTriggers.singular.label,
    path: 'stock_triggers',
    key: 'stock_triggers',
  },
  {
    text: t().filesAndAssets.singular.label,
    path: 'files',
    key: 'files',
  },
];

export default function UpdateProductMasterDataPane({}) {
  const navigate = useNavigate();

  const id = useParams()['id'] || '';

  const { productMasterData, setProductMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');

  const existing = productMasterData.get(id);

  const [productMasterDataInput, setProductMasterDataInput] = useState<ProductMasterData>(
    existing || new ProductMasterData({ companyId: currentCompany.id }),
  );

  const [update, { loading }] = useMutation<UpdateProductMasterDataResponse, UpdateProductMasterDataVariables>(
    ProductMasterDataMutations.update,
    {
      onCompleted: res => {
        const pmd = res.updateProductMasterData[0];
        setProductMasterData(new Map(productMasterData).set(pmd.id, new ProductMasterData(pmd)));
        navigate('/reference_data/products');
      },
    },
  );

  useEffect(() => {
    if (!productMasterDataInput.id) {
      setProductMasterDataInput(productMasterData.get(id) || new ProductMasterData({ companyId: currentCompany.id }));
    }
  }, [productMasterData]);

  if (!existing) return <NotFound />;

  const handleUpdate = async (pmd: ProductMasterData) => {
    try {
      pmd.validate();

      await update({
        variables: {
          productMasterData: [pmd.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const footer = (pmd: ProductMasterData) => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={() => navigate('/reference_data/products')} />
      </Grid>
      <Grid item>
        <SaveButton
          loading={loading}
          onClick={() => handleUpdate(pmd)}
          disabled={!productMasterDataInput.productName || !productMasterDataInput.productNumber}
        />
      </Grid>
    </Grid>
  );

  return (
    <Pane error={error} tabs={tabs()} testId={testIds.updateProductMasterDataPane}>
      {productMasterDataLoading && !productMasterData.get(id) ? (
        <Grid container alignItems={'center'}>
          <Grid item className='fill-gray-300 ' marginX={'auto'}>
            <LoadingPackage />
          </Grid>
        </Grid>
      ) : (
        <Routes>
          <Route
            path='info'
            element={
              <ProductMasterDataInfoPane
                setError={setError}
                productMasterDataInput={productMasterDataInput}
                setProductMasterDataInput={setProductMasterDataInput}
                footer={footer}
              />
            }
          />
          <Route
            path='attributes'
            element={
              <ProductMasterDataAttributePane
                productMasterDataInput={productMasterDataInput}
                setProductMasterDataInput={setProductMasterDataInput}
                footer={footer}
              />
            }
          />
          <Route
            path='comments'
            element={<ProductMasterDataCommentPane productMasterData={productMasterData.get(id)} footer={footer} />}
          />
          <Route
            path='stock_triggers/*'
            element={<ProductMasterDataReorderRulesPane productMasterData={productMasterData.get(id)} />}
          />
          <Route
            path='files'
            element={<FilesForEntityPane entityId={id} entityType={FileEntityType.pmd} setError={setError} />}
          />
        </Routes>
      )}
    </Pane>
  );
}
