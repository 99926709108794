import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface TextAreaProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  mandatory?: boolean;
  dynamicUpdate?: boolean;
  width?: string;
  rows?: number;
}

export default function TextArea({
  value,
  onChange,
  placeholder = '',
  disabled,
  type,
  label,
  mandatory = false,
  dynamicUpdate = false,
  width,
  rows = 15,
}: TextAreaProps) {
  const [text, setText] = useState<string>(value || '');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
    onChange(event.currentTarget.value);
  };

  useEffect(() => {
    if (dynamicUpdate) setText(value || '');
  }, [value]);

  return (
    <Grid container width={width || 'auto'} marginX={'auto'}>
      <Grid item display='flex'>
        {label ? <p className='text-sm font-medium text-slate-800'>{label}</p> : null}
        {mandatory ? <p className='ml-1 text-sm font-medium text-red-500'>*</p> : null}
      </Grid>
      <Grid item xs={12}>
        <textarea
          value={text}
          disabled={disabled}
          onChange={handleChange}
          rows={rows}
          className='mt-1 px-3 py-2 rounded disabled:bg-gray-100 disabled:text-gray-400 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full sm:text-sm focus:ring-1'
          placeholder={placeholder}
        />
      </Grid>
    </Grid>
  );
}
