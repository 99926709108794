import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function FilterIcon({ className, color, width = '16', height = '16' }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4 8H12M2 4H14M6 12H10'
          stroke={color || 'currentColor'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
