import React, { useContext, useState } from 'react';
import { BinStatus } from '../../../../../../types/binStatus';
import { Grid } from '@mui/material';
import BinStatusInfoPane from '../Panes/BinStatusInfoPane';
import { t } from '../../../../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import {
  BinStatusMutations,
  CreateBinStatusResponse,
  CreateBinStatusVariables,
} from '../../../../../../graphql/binStatus.graphql';
import { BinStatusContext } from '../../../../../../context/BinStatusContext';
import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';
import CancelButton from '../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import CreateButton from '../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';

interface CreateBinStatusModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  binStatus?: BinStatus;
}

export default function CreateBinStatusModal({ open, setOpen, binStatus }: CreateBinStatusModalInputProps) {
  const { binStatuses, setBinStatuses } = useContext(BinStatusContext);

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateBinStatusResponse, CreateBinStatusVariables>(
    BinStatusMutations.create,
    {
      onCompleted: res => {
        const binStatus = res.createBinStatus[0];
        setBinStatuses(new Map(binStatuses).set(binStatus.id, new BinStatus(binStatus)));
        setOpen(false);
      },
      onError: res => setError(res.message),
    },
  );

  if (!binStatus) return null; // TODO: Not found

  const handleCreate = async (binStatus: BinStatus) => {
    try {
      binStatus.validate();

      await create({
        variables: {
          binStatuses: [binStatus.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (binStatus: BinStatus) => (
    <Grid container marginTop={'auto'} columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <CancelButton disabled={loading} onClick={handleClose} />
      </Grid>
      <Grid item>
        <CreateButton loading={loading} onClick={() => handleCreate(binStatus)} />
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      error={error}
      onClose={handleClose}
      title={t().createBinStatus.singular.label}
      testId={testIds.createBinStatusModal}
    >
      <BinStatusInfoPane binStatus={binStatus} footer={footer} />
    </Modal>
  );
}
