import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import TaskExportConfigurationItem from './Export/TaskExportConfigurationItem';
import { useNavigate } from 'react-router-dom';
import { TaskExportConfigurationContext } from '../../../../context/TaskExportConfigurationContext';
import LoadingPackage from '../../../Common/LoadingPackage';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';

interface TaskExportPaneProps {
  setError: (error: string) => void;
}

export default function TaskExportPane({ setError }: TaskExportPaneProps) {
  const navigate = useNavigate();

  const { taskExportConfigurations, setTaskExportConfigurations, taskExportConfigurationsLoading } =
    useContext(TaskExportConfigurationContext);

  if (taskExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper>
      <Grid container>
        {[...taskExportConfigurations.values()].map(config => (
          <Grid key={config.id} item>
            <TaskExportConfigurationItem configuration={config} key={config.id} />
          </Grid>
        ))}
        <Grid item>
          <TaskExportConfigurationItem />
        </Grid>
      </Grid>
    </Paper>
  );
}
