import React from 'react';
import './Image.scss';

interface ImageViewProps {
  url: string;
  className?: string;
}

export default function ImageView({ url, className }: ImageViewProps) {
  return <img src={url} className={className} />;
}
