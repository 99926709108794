import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import ContactOverviewPane from './Contact/ContactOverviewPane';
import OrderOverviewPane from './Order/OrderOverviewPane';
import { FeatureToggles } from '../../types/company';
import { CompanyContext } from '../../context/CompanyContext';
import { testIds } from '../../util/identifiers/identifiers.util';
import { t } from '../../types/translation/Translator';
import Pane from '../../VentoryUI/components/common/Pane/Pane';

const tabs = (ft: FeatureToggles) =>
  [
    {
      text: t().order.plural.label,
      path: '/operations/orders',
      key: 'orders',
    },
    {
      text: t().contacts.singular.label,
      path: '/operations/contacts',
      key: 'contacts',
    },
  ].filter(item => {
    if (item.key === 'contacts' && !ft.orders.customer && !ft.orders.supplier) return false;
    return true;
  });

export default function OperationScreen() {
  const { currentCompany } = useContext(CompanyContext);

  return (
    <Pane tabs={tabs(currentCompany.settings.featureToggles)} testId={testIds.operationScreen}>
      <Routes>
        <Route path='orders' element={<OrderOverviewPane />} />
        <Route path='contacts' element={<ContactOverviewPane />} />
      </Routes>
    </Pane>
  );
}
