import { gql } from '@apollo/client';
import {
  CreateCsvUploadConfigurationInput,
  CsvUploadConfiguration,
  CsvUploadType,
  DeleteCsvUploadConfigurationInput,
  UpdateCsvUploadConfigurationInput,
} from '../types/csvUploadConfiguration';
import { forPaginated } from './common/paginated.graphql';

const configuration = gql`
  fragment CsvUploadConfiguration on CsvUploadConfiguration {
    id
    version
    companyId
    type
    name
    headerMapping {
      field
      customField
    }
    headerNames
  }
`;

const get = gql`
  query CsvUploadConfigurations($companyId: String!, $page: Float!) {
    csvUploadConfigurations(companyId: $companyId, page: $page) {
      data {
        ...CsvUploadConfiguration
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${configuration}
`;

export interface GetCsvUploadConfigurationsVariables {
  companyId: string;
  page: number;
}

export interface GetCsvUploadConfigurationsResponse {
  csvUploadConfigurations: PaginatedCsvUploadConfigurations;
}

export class PaginatedCsvUploadConfigurations extends forPaginated<CsvUploadConfiguration>() {}

const getForFile = gql`
  query CsvConfigurationsForFile($companyId: String!, $type: String!, $csvFileAsBase64Data: String!) {
    csvConfigurationsForFile(companyId: $companyId, type: $type, csvFileAsBase64Data: $csvFileAsBase64Data) {
      configurations {
        ...CsvUploadConfiguration
      }
      requiredHeaders
      optionalHeaders
      fileHeaders
      otherConfigurations {
        ...CsvUploadConfiguration
      }
    }
  }
  ${configuration}
`;

export interface GetCsvUploadConfigurationForFileVariables {
  companyId: string;
  type: CsvUploadType;
  csvFileAsBase64Data: string;
}

export interface GetCsvUploadConfigurationForFileResponse {
  csvConfigurationsForFile: {
    configurations: CsvUploadConfiguration[];
    otherConfigurations: CsvUploadConfiguration[];
    fileHeaders: string[];
    requiredHeaders: string[];
    optionalHeaders: string[];
  };
}

const create = gql`
  mutation CreateCsvUploadConfiguration($csvUploadConfigurations: [CreateCsvUploadConfigurationInput!]!) {
    createCsvUploadConfiguration(csvUploadConfigurations: $csvUploadConfigurations) {
      ...CsvUploadConfiguration
    }
  }
  ${configuration}
`;

export interface CreateCsvUploadConfigurationVariables {
  csvUploadConfigurations: CreateCsvUploadConfigurationInput[];
}

export interface CreateCsvUploadConfigurationResponse {
  createCsvUploadConfiguration: CsvUploadConfiguration[];
}

const update = gql`
  mutation UpdateCsvUploadConfiguration($csvUploadConfigurations: [UpdateCsvUploadConfigurationInput!]!) {
    updateCsvUploadConfiguration(csvUploadConfigurations: $csvUploadConfigurations) {
      ...CsvUploadConfiguration
    }
  }
  ${configuration}
`;

export interface UpdateCsvUploadConfigurationVariables {
  csvUploadConfigurations: UpdateCsvUploadConfigurationInput[];
}

export interface UpdateCsvUploadConfigurationResponse {
  updateCsvUploadConfiguration: CsvUploadConfiguration[];
}

const remove = gql`
  mutation DeleteCsvUploadConfiguration($csvUploadConfigurations: [DeleteCsvUploadConfigurationInput!]!) {
    deleteCsvUploadConfiguration(csvUploadConfigurations: $csvUploadConfigurations) {
      ...CsvUploadConfiguration
    }
  }
  ${configuration}
`;

export interface DeleteCsvUploadConfigurationVariables {
  csvUploadConfigurations: DeleteCsvUploadConfigurationInput[];
}

export interface DeleteCsvUploadConfigurationResponse {
  deleteCsvUploadConfiguration: CsvUploadConfiguration[];
}

const upload = gql`
  mutation CsvUpload($input: CsvUploadInput!, $configurationId: String!) {
    csvUpload(input: $input, configurationId: $configurationId) {
      upload
      message
    }
  }
`;

export interface CsvUploadVariables {
  input: {
    companyId: string;
    stockLocationId: string;
    type: CsvUploadType;
    csvFileAsBase64Data: string;
    deleteExisting?: boolean;
  };
  configurationId: string;
}

export interface CsvUploadResponse {
  csvUpload: {
    upload: boolean;
    message: string;
  };
}

export const CsvUploadConfigurationQueries = {
  getForFile,
  get,
};

export const CsvUploadConfigurationMutations = {
  create,
  update,
  remove,
  upload,
};
