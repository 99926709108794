import { gql } from '@apollo/client';
import {
  CreateTaskExportConfigurationInput,
  DeleteTaskExportConfigurationInput,
  TaskExportConfiguration,
  UpdateTaskExportConfigurationInput,
} from '../types/taskExportConfiguration';
import { forPaginated } from './common/paginated.graphql';

const taskExportConfiguration = gql`
  fragment TaskExportConfiguration on TaskExportConfiguration {
    id
    version
    companyId
    name
    filename
    discrepancy
    countedOnly
    prefix
    delimiter
    quote
    fieldMapping {
      index
      customField
      field
    }
  }
`;

const get = gql`
  query TaskExportConfigurations($companyId: String!, $page: Float) {
    taskExportConfigurations(companyId: $companyId, page: $page) {
      data {
        ...TaskExportConfiguration
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${taskExportConfiguration}
`;

export interface GetTaskExportConfigurationsVariables {
  companyId: string;
  page?: number;
}

export interface GetTaskExportConfigurationsResponse {
  taskExportConfigurations: PaginatedTaskExportConfigurations;
}

class PaginatedTaskExportConfigurations extends forPaginated<TaskExportConfiguration>() {}

const create = gql`
  mutation CreateTaskExportConfiguration($taskExportConfigurations: [CreateTaskExportConfigurationInput!]!) {
    createTaskExportConfiguration(taskExportConfigurations: $taskExportConfigurations) {
      ...TaskExportConfiguration
    }
  }
  ${taskExportConfiguration}
`;

export interface CreateTaskExportConfigurationVariables {
  taskExportConfigurations: CreateTaskExportConfigurationInput[];
}

export interface CreateTaskExportConfigurationResponse {
  createTaskExportConfiguration: TaskExportConfiguration[];
}

const update = gql`
  mutation UpdateTaskExportConfiguration($taskExportConfigurations: [UpdateTaskExportConfigurationInput!]!) {
    updateTaskExportConfiguration(taskExportConfigurations: $taskExportConfigurations) {
      ...TaskExportConfiguration
    }
  }
  ${taskExportConfiguration}
`;

export interface UpdateTaskExportConfigurationVariables {
  taskExportConfigurations: UpdateTaskExportConfigurationInput[];
}

export interface UpdateTaskExportConfigurationResponse {
  updateTaskExportConfiguration: TaskExportConfiguration[];
}

const remove = gql`
  mutation DeleteTaskExportConfiguration($taskExportConfigurations: [DeleteTaskExportConfigurationInput!]!) {
    deleteTaskExportConfiguration(taskExportConfigurations: $taskExportConfigurations) {
      ...TaskExportConfiguration
    }
  }
  ${taskExportConfiguration}
`;

export interface DeleteTaskExportConfigurationVariables {
  taskExportConfigurations: DeleteTaskExportConfigurationInput[];
}

export interface DeleteTaskExportConfigurationResponse {
  deleteTaskExportConfiguration: TaskExportConfiguration[];
}

export const TaskExportConfigurationQueries = {
  get,
};

export const TaskExportConfigurationMutations = {
  create,
  update,
  remove,
};
