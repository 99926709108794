import { Grid } from '@mui/material';
import { HashtagIcon } from '../../../icons/Hashtag/HashtagIcon';
import { VentoryColor } from '../../../util/color.util';
import React from 'react';
import { DashboardSize } from '../../../../components/Dashboard/Common/dashboard.util';
import { LoadingSpinner } from '../../../../components/Common/LoadingSpinner';

export enum NumberChartType {
  percentage = 'percentage',
  text = 'text',
  both = 'both',
}

export interface NumberChartProps {
  text: string;
  value: number;
  max?: number;
  type?: NumberChartType;
  color?: string;
  size?: DashboardSize;
  loading?: boolean;
  className?: string;
}

export function NumberChart({
  text,
  value,
  max = 1,
  type = NumberChartType.text,
  color = VentoryColor.primaryVentoryColor,
  size,
  loading,
  className = 'min-h-[140px]',
}: NumberChartProps) {
  const getText = () => {
    let percentage = ((value / max) * 100).toFixed(2);
    if (max === 0) percentage = '0.00';
    if (percentage === '100.00') percentage = '100';

    switch (type) {
      case NumberChartType.text:
        return (
          <p className='font-[500] text-[30px] text-ventory-grey-modern-600'>
            {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        );
      case NumberChartType.both:
        return (
          <>
            <p className='font-[500] text-[30px] text-ventory-grey-modern-600'>
              {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </p>
            <p className='font-[500] text-[15px] text-ventory-grey-modern-500 px-1 mt-auto mb-1'>{`(${percentage}%)`}</p>
          </>
        );
      case NumberChartType.percentage:
        return <p className='font-[500] text-[30px] text-ventory-grey-modern-600'>{`${percentage}%`}</p>;
    }
  };

  return (
    <Grid item xs={size?.xs} sm={size?.sm} md={size?.md} lg={size?.lg} xl={size?.xl} mb={1}>
      <Grid
        container
        flexDirection={'column'}
        className={`flex rounded-sm select-none ${className} h-full w-full bg-white ventory-border`}
      >
        <Grid item>
          <Grid container flexDirection={'row'}>
            <Grid item xs={12} display='flex' className='border-ventory-grey-100 border-b'>
              <HashtagIcon className='pl-3 pr-2' color={color} />
              <p className='font-[500] text-[16px] text-ventory-grey-500 py-2'>{text}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item flexGrow={1} alignContent={'center'}>
          <Grid container flexDirection={'row'}>
            <Grid item xs={12} display='flex' justifyContent={'center'}>
              {loading ? <LoadingSpinner color={color.toString()} /> : getText()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container className='px-2 pb-4'>
            <div style={{ height: '1px', backgroundColor: color }} className='w-full rounded-sm'></div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
