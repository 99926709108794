import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { t } from '../../../../types/translation/Translator';
import { UserContext } from '../../../../context/UserContext';
import { CreateTaskInput, Task } from '../../../../types/task';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import Selector from '../../../Common/Selector';
import { User } from '../../../../types/user';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import ModalPane from '../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { FinishButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/FinishButton';

interface CreateTaskReportReceiversPaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next: () => void;
  back: () => void;
  loading: boolean;
}

export default function CreateTaskReportReceiversPane({
  task,
  setTask,
  next,
  back,
  loading,
}: CreateTaskReportReceiversPaneInputProps) {
  const { companyUsers } = useContext(UserContext);

  const handleUsersChanged = (users: User[]) => {
    setTask(task.withAdditionalEmails(users.map(u => u.email)));
  };

  return (
    <ModalPane
      testId={testIds.createTaskReportReceiversPane}
      footerButtons={[
        BackButtonTemplate(back, { disabled: loading }),
        FinishButtonTemplate(next, { loading: loading }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} marginBottom={'0.5rem'}>
              <p className={'text-sm font-semibold'}>{t().selectReportReceivers.singular.upper}</p>
            </Grid>
            <Grid item xs={12}>
              <Selector
                placeholder={t().filterUsers.singular.label}
                values={[...[...companyUsers.values()].map(user => user)]}
                checkedValues={task.additionalEmails.map(
                  email => [...companyUsers.values()].find(user => user.email === email)!,
                )}
                onChange={handleUsersChanged}
                filterFn={(item, filter) => {
                  const user = companyUsers.get(item.userId);
                  if (!user) return false;

                  if (
                    removeDiacritics(toFilterString(user.email)).includes(filter) ||
                    removeDiacritics(toFilterString(user.firstName)).includes(filter) ||
                    removeDiacritics(toFilterString(user.lastName)).includes(filter) ||
                    removeDiacritics(toFilterString(`${user.firstName} ${user.lastName}`)).includes(filter)
                  ) {
                    return true;
                  }
                  return false;
                }}
                toText={item => companyUsers.get(item.userId)?.email || 'Unknown user'}
                toElement={item => (
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12}>
                      <p className='text-normal font-normal'>{`${companyUsers.get(item.userId)?.firstName} ${
                        companyUsers.get(item.userId)?.lastName
                      }`}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='text-sm text-gray-400'>{`${companyUsers.get(item.userId)?.email}`}</p>
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
