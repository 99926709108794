import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function AddIcon({ className }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg
        width='14'
        height='14'
        stroke='currentColor'
        fill='currentColor'
        viewBox='0 0 14 14'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.00033 1.16675V12.8334M1.16699 7.00008H12.8337'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
