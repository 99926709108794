import React from 'react';
import { StringUtil } from '../../../../../util/string.util';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function FileTypeCategoryFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.fileTypeCategory || [])]}
      text={item => StringUtil.upperCaseFirstCharacter(item)}
      onRemove={i => {
        filter.fileTypeCategory?.delete(i);
        if (!filter.fileTypeCategory?.size) filter.fileTypeCategory = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
