import React from 'react';

interface VideoViewProps {
  url: string;
  className?: string;
}

export default function VideoView({ url, className }: VideoViewProps) {
  return <video src={url} controls autoPlay className={className} />;
}
