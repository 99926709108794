import React from 'react';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function FileTypeFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.fileType || [])]}
      text={item => item}
      onRemove={i => {
        filter.fileType?.delete(i);
        if (!filter.fileType?.size) filter.fileType = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
