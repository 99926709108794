import Color from 'color';
import { Company } from '../types/company';
import { ColorStyleType } from '../VentoryUI/util/color.util';

export const getBackgroundColor = (company: Company, type: ColorStyleType) => {
  const primaryColor = company.settings.primaryColor;
  const secondaryColor = company.settings.secondaryColor;
  if (type === ColorStyleType.primary) {
    return Color(primaryColor).isLight() ? secondaryColor : primaryColor;
  }
};

export const getTextColor = (company: Company, type: ColorStyleType) => {
  const primaryColor = company.settings.primaryColor;
  const secondaryColor = company.settings.secondaryColor;
  if (type === ColorStyleType.primary) {
    return Color(getBackgroundColor(company, type)).isDark() ? 'white' : 'black';
  } else if (type === ColorStyleType.secondary) {
    return Color(secondaryColor).isLight() ? (Color(primaryColor).isDark() ? primaryColor : 'dark') : secondaryColor;
  }
};

export const getBorderColor = (company: Company, type: ColorStyleType) => {
  const primaryColor = company.settings.primaryColor;
  const secondaryColor = company.settings.secondaryColor;
  if (type === ColorStyleType.primary || type === ColorStyleType.secondary) {
    return Color(primaryColor).isLight() ? secondaryColor : primaryColor;
  }
};

// R7: 62 => 0.5
// Ventory: 33 => 1.5
// Elteros: 21 => 2.50

export const getLightness = (x: number) => {
  return 0.0012 * (x * x) - 0.1495 * x + 5.1;
};
