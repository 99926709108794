import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function ArrowRightIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        stroke={color || 'currentColor'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.3335 6.00004H10.6668M10.6668 6.00004L6.00016 1.33337M10.6668 6.00004L6.00016 10.6667'
          stroke='#667085'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
