import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { Order, OrderStatus, OrderType, orderTypeToLocalizedString } from '../../../../types/order';
import TextInput from '../../../Common/TextInput';
import { ProductTransaction } from '../../../../types/productTransaction';
import { t } from '../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { ProductTransactionContext } from '../../../../context/ProductTransactionContext';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { UserContext } from '../../../../context/UserContext';
import ProcessOrderTransactionModal from '../Modals/ProcessOrderTransactionModal';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { ProductTransactionTableSettingsContext } from '../../../../context/ProductTransactionTableSettingsContext';
import {
  ProductTransactionTableSettings,
  ProductTransactionTableType,
} from '../../../../types/productTransactionTableSettings';
import { CompanyContext } from '../../../../context/CompanyContext';
import { productTransactionTableHeaders } from '../../../Common/ProductTransactionTable';
import { BinContext } from '../../../../context/BinContext';
import { LotContext } from '../../../../context/LotContext';

import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../../VentoryUI/components/common/Table/Table';

interface OrderDetailPaneProps {
  order: Order;
  setOrder: (order: Order) => void;
  footer: () => JSX.Element;
}

export default function OrderDetailPaneProps({ order, setOrder, footer }: OrderDetailPaneProps) {
  const navigate = useNavigate();

  const { filteredStockLocations, stockLocations } = useContext(StockLocationContext);
  const { bins } = useContext(BinContext);
  const { lots } = useContext(LotContext);
  const { productTransactions, productTransactionsLoading } = useContext(ProductTransactionContext);
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { companyUsers } = useContext(UserContext);
  const { productTransactionTableSettings } = useContext(ProductTransactionTableSettingsContext);
  const { currentCompany } = useContext(CompanyContext);

  const tableSettings = useMemo(() => {
    const existingSettings = [...productTransactionTableSettings.values()].find(
      el => el.type === ProductTransactionTableType.orderTransaction,
    );
    return new ProductTransactionTableSettings(
      existingSettings || { companyId: currentCompany.id },
      existingSettings?.type || ProductTransactionTableType.orderTransaction,
    );
  }, [productTransactionTableSettings]);

  const [selected, setSelected] = useState<ProductTransaction | null>(null);
  const [openProcessModal, setOpenProcessModal] = useState<boolean>(false);

  const orderTransactions = useMemo(() => {
    return [...productTransactions.values()].flat().filter(p => p.parentId === order.id);
  }, [productTransactions]);

  return (
    <>
      {selected ? (
        <ProcessOrderTransactionModal
          disabled={order.status === OrderStatus.disabled}
          open={openProcessModal}
          setOpen={v => {
            setOpenProcessModal(v);
            setSelected(null);
          }}
          transaction={selected}
        />
      ) : null}

      <FlexPane
        header={
          <Paper>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  dynamicUpdate
                  onChange={() => {}}
                  label={t().order.singular.label}
                  value={order.number || '0'}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  dynamicUpdate
                  onChange={() => {}}
                  label={t().orderType.singular.label}
                  testId={testIds.type}
                  value={orderTypeToLocalizedString(order.type)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  dynamicUpdate
                  onChange={() => {}}
                  label={
                    order.type === OrderType.inbound
                      ? t().destinationStockLocation.singular.label
                      : t().sourceStockLocation.singular.label
                  }
                  testId={testIds.stockLocation}
                  value={filteredStockLocations.get(order.stockLocationId)?.name || ''}
                />
              </Grid>
            </Grid>
          </Paper>
        }
        content={
          <Table
            title={t().transactions.singular.label}
            loading={productTransactionsLoading || productMasterDataLoading}
            items={orderTransactions}
            onClick={item => {
              setSelected(item);
              setOpenProcessModal(true);
            }}
            headers={productTransactionTableHeaders(
              tableSettings,
              stockLocations,
              bins,
              productMasterData,
              lots,
              companyUsers,
            )}
          />
        }
        footer={footer()}
      />
    </>
  );
}
