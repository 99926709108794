import React from 'react';
import {
  ProductTransactionStatus,
  productTransactionStatusToColorClass,
  productTransactionStatusToLocalizedString,
} from '../../types/productTransaction';
import StatusLabel from '../../VentoryUI/components/common/StatusLabel/StatusLabel';

interface ProductTransactionStatusLabelProps {
  status: ProductTransactionStatus;
  justify?: 'start' | 'center' | 'end';
}

export default function ProductTransactionStatusLabel({ status, justify }: ProductTransactionStatusLabelProps) {
  return (
    <StatusLabel
      color={productTransactionStatusToColorClass(status)}
      text={productTransactionStatusToLocalizedString(status)}
    />
  );
}
