import { cloneDeep } from '@apollo/client/utilities';

export enum FeatureRequestType {
  new_feature = 'new_feature',
  upgrade = 'upgrade',
}

export function featureRequestTypeToString(type: FeatureRequestType) {
  switch (type) {
    case FeatureRequestType.new_feature:
      return 'New feature';
    case FeatureRequestType.upgrade:
      return 'Existing feature upgrade';
  }
}

export enum FeatureRequestImportance {
  crucial = 'crucial',
  nice_to_have = 'nice_to_have',
  important_but_can_work_without = 'important_but_can_work_without',
}

export function featureRequestImportanceToString(type: FeatureRequestImportance) {
  switch (type) {
    case FeatureRequestImportance.crucial:
      return "Can't live without it";
    case FeatureRequestImportance.nice_to_have:
      return 'Nice to have';
    case FeatureRequestImportance.important_but_can_work_without:
      return 'Important but can live without it';
  }
}

export enum FeatureRequestUser {
  manager = 'manager',
  field_engineer = 'field_engineer',
}

export function featureRequestUserToString(type: FeatureRequestUser) {
  switch (type) {
    case FeatureRequestUser.manager:
      return 'Manager(s)';
    case FeatureRequestUser.field_engineer:
      return 'Field Engineer(s)';
  }
}

export enum FeatureRequestCategory {
  dashboard = 'dashboard',
  stock = 'stock',
  reference_data = 'reference_data',
  operations = 'operations',
  tasks = 'tasks',
  alerts = 'alerts',
  settings = 'settings',
  mobile_app = 'mobile_app',
  other = 'other',
}

export function featureRequestCategoryToString(type: FeatureRequestCategory) {
  switch (type) {
    case FeatureRequestCategory.dashboard:
      return 'Dashboard';
    case FeatureRequestCategory.stock:
      return 'Stock';
    case FeatureRequestCategory.reference_data:
      return 'Reference data';
    case FeatureRequestCategory.operations:
      return 'Operations';
    case FeatureRequestCategory.tasks:
      return 'Tasks';
    case FeatureRequestCategory.alerts:
      return 'Alerts';
    case FeatureRequestCategory.settings:
      return 'Settings';
    case FeatureRequestCategory.mobile_app:
      return 'Mobile Application';
    case FeatureRequestCategory.other:
      return 'Other';
  }
}

export class FeatureRequest {
  companyId!: string;
  userId!: string;
  type!: FeatureRequestType;
  importance!: FeatureRequestImportance;
  targetUser!: FeatureRequestUser;
  category!: FeatureRequestCategory;
  description!: string;
  attachments: HelpScoutAttachment[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  withUserId(userId?: string) {
    (this.userId as any) = userId;
    return cloneDeep(this);
  }

  withType(type?: FeatureRequestType) {
    (this.type as any) = type;
    return cloneDeep(this);
  }

  withImportance(importance?: FeatureRequestImportance) {
    (this.importance as any) = importance;
    return cloneDeep(this);
  }

  withTargetUser(targetUser?: FeatureRequestUser) {
    (this.targetUser as any) = targetUser;
    return cloneDeep(this);
  }

  withCategory(category?: FeatureRequestCategory) {
    (this.category as any) = category;
    return cloneDeep(this);
  }

  withDescription(description: string) {
    this.description = description;
    return cloneDeep(this);
  }

  withAttachements(attachments: HelpScoutAttachment[]) {
    this.attachments = attachments;
    return cloneDeep(this);
  }
}

export enum IncidentPriorityLevel {
  p1 = 'p1',
  p2 = 'p2',
  p3 = 'p3',
}

export function incidentPriorityLevelToString(type: IncidentPriorityLevel) {
  switch (type) {
    case IncidentPriorityLevel.p1:
      return 'Major incident';
    case IncidentPriorityLevel.p2:
      return 'Severe incident';
    case IncidentPriorityLevel.p3:
      return 'Incident';
  }
}

export enum IncidentNetworkConnection {
  home = 'home',
  office = 'office',
  warehouse = 'warehouse',
  public = 'public',
  mobile = 'mobile',
}

export function incidentNetworkConnectionToString(type: IncidentNetworkConnection) {
  switch (type) {
    case IncidentNetworkConnection.home:
      return 'Home';
    case IncidentNetworkConnection.office:
      return 'Office';
    case IncidentNetworkConnection.warehouse:
      return 'Warehouse';
    case IncidentNetworkConnection.public:
      return 'Public';
    case IncidentNetworkConnection.mobile:
      return 'Mobile';
  }
}

export enum IncidentEnvironment {
  staging = 'staging',
  production = 'production',
}

export function incidentEnvironmentToString(type: IncidentEnvironment) {
  switch (type) {
    case IncidentEnvironment.production:
      return 'Production';
    case IncidentEnvironment.staging:
      return 'Staging';
  }
}

export enum IncidentDeviceType {
  android = 'android',
  apple = 'apple',
  zebra = 'zebra',
}

export function incidentDeviceTypeToString(type: IncidentDeviceType) {
  switch (type) {
    case IncidentDeviceType.android:
      return 'Android';
    case IncidentDeviceType.apple:
      return 'Apple';
    case IncidentDeviceType.zebra:
      return 'Zebra';
  }
}

export enum IncidentPlatform {
  web = 'web',
  mobile = 'mobile',
}

export function incidentApplicationToString(type: IncidentPlatform) {
  switch (type) {
    case IncidentPlatform.web:
      return 'Web';
    case IncidentPlatform.mobile:
      return 'Mobile';
  }
}

export enum IncidentElementWeb {
  dashboard = 'dashboard',
  stock = 'stock',
  reference_data = 'reference_data',
  orders = 'orders',
  tasks = 'tasks',
  alerts = 'alerts',
  settings = 'settings',
  general = 'general',
}

export function incidentElementWebToString(type: IncidentElementWeb) {
  switch (type) {
    case IncidentElementWeb.dashboard:
      return 'Dashboard';
    case IncidentElementWeb.stock:
      return 'Stock';
    case IncidentElementWeb.reference_data:
      return 'Reference data';
    case IncidentElementWeb.orders:
      return 'Orders';
    case IncidentElementWeb.tasks:
      return 'Tasks';
    case IncidentElementWeb.alerts:
      return 'Alerts';
    case IncidentElementWeb.settings:
      return 'Settings';
    case IncidentElementWeb.general:
      return 'General';
  }
}

export enum IncidentElementMobile {
  stock = 'stock',
  product = 'product',
  quick_actions = 'quick_actions',
  tasks = 'tasks',
  orders = 'orders',
  settings = 'settings',
  general = 'general',
}

export function incidentElementMobileToString(type: IncidentElementMobile) {
  switch (type) {
    case IncidentElementMobile.stock:
      return 'Stock';
    case IncidentElementMobile.product:
      return 'Product';
    case IncidentElementMobile.quick_actions:
      return 'Quick actions';
    case IncidentElementMobile.tasks:
      return 'Tasks';
    case IncidentElementMobile.orders:
      return 'Orders';
    case IncidentElementMobile.settings:
      return 'Settings';
    case IncidentElementMobile.general:
      return 'General';
  }
}

export interface HelpScoutAttachment {
  fileName: string;
  mimeType: string;
  data: string;
}

export class IncidentReport {
  companyId!: string;
  userId!: string;
  phone?: string;
  priority!: IncidentPriorityLevel;
  network!: IncidentNetworkConnection;
  environment!: IncidentEnvironment;
  elementMobile?: IncidentElementMobile;
  elementWeb?: IncidentElementWeb;
  platform!: IncidentPlatform;
  device?: IncidentDeviceType;
  operatingSystemVersion?: string;
  description!: string;
  attachments: HelpScoutAttachment[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
  }

  withUserId(userId?: string) {
    (this.userId as any) = userId;
    return cloneDeep(this);
  }

  withPhone(phone: string) {
    this.phone = phone;
    return cloneDeep(this);
  }

  withPriority(priority?: IncidentPriorityLevel) {
    (this.priority as any) = priority;
    return cloneDeep(this);
  }

  withNetwork(network?: IncidentNetworkConnection) {
    (this.network as any) = network;
    return cloneDeep(this);
  }

  withEnvironment(environment?: IncidentEnvironment) {
    (this.environment as any) = environment;
    return cloneDeep(this);
  }

  withElementMobile(elementMobile?: IncidentElementMobile) {
    this.elementMobile = elementMobile;
    return cloneDeep(this);
  }

  withElementWeb(elementWeb?: IncidentElementWeb) {
    this.elementWeb = elementWeb;
    return cloneDeep(this);
  }

  withPlatform(platform?: IncidentPlatform) {
    (this.platform as any) = platform;
    return cloneDeep(this);
  }

  withDevice(device: IncidentDeviceType) {
    this.device = device;
    return cloneDeep(this);
  }

  withOperatingSystemVersion(operatingSystemVersion?: string) {
    this.operatingSystemVersion = operatingSystemVersion;
    return cloneDeep(this);
  }

  withDescription(description: string) {
    this.description = description;
    return cloneDeep(this);
  }

  withAttachements(attachments: HelpScoutAttachment[]) {
    this.attachments = attachments;
    return cloneDeep(this);
  }
}
