import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { testIds } from '../../../../../../../util/identifiers/identifiers.util';
import { t } from '../../../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../../../context/StockLocationContext';
import Selector from '../../../../../../Common/Selector';
import Checkbox from '../../../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { CompanyRoleAssignmentContext } from '../../../../../../../context/CompanyRoleAssignmentContext';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { CompanyRole } from '../../../../../../../types/companyRoleAssignment';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface ReorderUserRuleStockLocationPaneProps {
  next: () => void;
  back: () => void;
  appliesToAllLocations: boolean;
  setAppliesToAllLocations: (applies: boolean) => void;
  stockLocationIds: string[];
  setStockLocationIds: (stockLocationIds: string[]) => void;
}

export default function ReorderUserRuleStockLocationPane({
  back,
  next,
  appliesToAllLocations,
  setAppliesToAllLocations,
  stockLocationIds,
  setStockLocationIds,
}: ReorderUserRuleStockLocationPaneProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);

  return (
    <ModalPane
      overflow='auto'
      footerButtons={[
        BackButtonTemplate(back),
        NextButtonTemplate(next, { disabled: !appliesToAllLocations && !stockLocationIds.length }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {hasCompanyRole(currentCompany.id, CompanyRole.administrator) ? (
              <Grid item xs={12}>
                <Checkbox
                  label={t().appliesToAllLocations.singular.label}
                  value={appliesToAllLocations}
                  onChange={value => setAppliesToAllLocations(value)}
                />
              </Grid>
            ) : null}
            {!appliesToAllLocations ? (
              <Grid item xs={12}>
                <Selector
                  testId={testIds.stockLocation}
                  placeholder={t().ruleStockLocationText.singular.label}
                  values={[...[...stockLocations.values()].map(sl => sl.id)]}
                  checkedValues={stockLocationIds}
                  toText={item => stockLocations.get(item)?.name || 'Unknown Stock Location'}
                  onChange={checked => setStockLocationIds(checked)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
