import { gql } from '@apollo/client';
import { TaskSettings } from '../types/taskSettings';
import { forPaginated } from './common/paginated.graphql';

const taskSettings = gql`
  fragment TaskSettings on TaskSettings {
    id
    version
    companyId
    discrepancyThreshold
    stockLocationRolesForInitialCount
    stockLocationRolesForRecount
    companyRolesForInitialCount
    companyRolesForRecount
    requireUniqueRecountUser
    dueDateMandatory
    assignInitialAssigneesToRecount
    entityType
    entityId
    sendPushNotificationToAssignees
    stockLocationRoleForUpdateStock
    prefix
    suffix
    digits
  }
`;

const get = gql`
  query TaskSettings($companyId: String!, $page: Float) {
    taskSettings(companyId: $companyId, page: $page) {
      data {
        ...TaskSettings
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${taskSettings}
`;

export interface GetTaskSettingsVariables {
  companyId: string;
  page: number;
}

export interface GetTaskSettingsResponse {
  taskSettings: PaginatedTaskSettings;
}

class PaginatedTaskSettings extends forPaginated<TaskSettings>() {}

const create = gql`
  mutation CreateTaskSettings($taskSettings: CreateTaskSettingsInput!) {
    createTaskSettings(taskSettings: $taskSettings) {
      ...TaskSettings
    }
  }
  ${taskSettings}
`;

export interface CreateTaskSettingsVariables {
  taskSettings: TaskSettings;
}

export interface CreateTaskSettingsResponse {
  createTaskSettings: TaskSettings;
}

const update = gql`
  mutation UpdateTaskSettings($taskSettings: UpdateTaskSettingsInput!) {
    updateTaskSettings(taskSettings: $taskSettings) {
      ...TaskSettings
    }
  }
  ${taskSettings}
`;

export interface UpdateTaskSettingsVariables {
  taskSettings: TaskSettings;
}

export interface UpdateTaskSettingsResponse {
  updateTaskSettings: TaskSettings;
}

export const TaskSettingsQueries = {
  get,
};

export const TaskSettingsMutations = {
  create,
  update,
};
