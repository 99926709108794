import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import TaskSettingsPane from './Panes/TaskSettingsPane';
import TaskExportPane from './Panes/TaskExportPane';
import Pane from '../../../VentoryUI/components/common/Pane/Pane';
import { t } from '../../../types/translation/Translator';

const tabs = () => [
  {
    text: t().settings.singular.label,
    path: '/tasks/settings/overview',
    key: 'overview',
  },
  {
    text: t().reporting.singular.label,
    path: '/tasks/settings/report',
    key: 'settings/report',
  },
];

export default function TaskSettingsScreen() {
  const [error, setError] = useState<string>('');

  return (
    <Pane error={error} tabs={tabs()}>
      <Routes>
        <Route path='report' element={<TaskExportPane setError={setError} />} />
        <Route path='overview' element={<TaskSettingsPane setError={setError} />} />
      </Routes>
    </Pane>
  );
}
