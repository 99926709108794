import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateProductTransactionExportConfigurationInput,
  UpdateProductTransactionExportConfigurationInput,
  DeleteProductTransactionExportConfigurationInput,
  ProductTransactionExportConfiguration,
} from '../types/productTransactionExportConfiguration';

const productTransactionExportConfiguration = gql`
  fragment ProductTransactionExportConfiguration on ProductTransactionExportConfiguration {
    id
    version
    companyId
    name
    filename
    prefix
    delimiter
    quote
    default
    fieldMapping {
      index
      customField
      field
    }
  }
`;

const get = gql`
  query ProductTransactionExportConfigurations($companyId: String!, $page: Float) {
    productTransactionExportConfigurations(companyId: $companyId, page: $page) {
      data {
        ...ProductTransactionExportConfiguration
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${productTransactionExportConfiguration}
`;

export interface GetProductTransactionExportConfigurationsVariables {
  companyId: string;
  page?: number;
}

export interface GetProductTransactionExportConfigurationsResponse {
  productTransactionExportConfigurations: PaginatedProductTransactionExportConfigurations;
}

class PaginatedProductTransactionExportConfigurations extends forPaginated<ProductTransactionExportConfiguration>() {}

const create = gql`
  mutation CreateProductTransactionExportConfiguration(
    $productTransactionExportConfigurations: [CreateProductTransactionExportConfigurationInput!]!
  ) {
    createProductTransactionExportConfiguration(
      productTransactionExportConfigurations: $productTransactionExportConfigurations
    ) {
      ...ProductTransactionExportConfiguration
    }
  }
  ${productTransactionExportConfiguration}
`;

export interface CreateProductTransactionExportConfigurationVariables {
  productTransactionExportConfigurations: CreateProductTransactionExportConfigurationInput[];
}

export interface CreateProductTransactionExportConfigurationResponse {
  createProductTransactionExportConfiguration: ProductTransactionExportConfiguration[];
}

const update = gql`
  mutation UpdateProductTransactionExportConfiguration(
    $productTransactionExportConfigurations: [UpdateProductTransactionExportConfigurationInput!]!
  ) {
    updateProductTransactionExportConfiguration(
      productTransactionExportConfigurations: $productTransactionExportConfigurations
    ) {
      ...ProductTransactionExportConfiguration
    }
  }
  ${productTransactionExportConfiguration}
`;

export interface UpdateProductTransactionExportConfigurationVariables {
  productTransactionExportConfigurations: UpdateProductTransactionExportConfigurationInput[];
}

export interface UpdateProductTransactionExportConfigurationResponse {
  updateProductTransactionExportConfiguration: ProductTransactionExportConfiguration[];
}

const remove = gql`
  mutation DeleteProductTransactionExportConfiguration(
    $productTransactionExportConfigurations: [DeleteProductTransactionExportConfigurationInput!]!
  ) {
    deleteProductTransactionExportConfiguration(
      productTransactionExportConfigurations: $productTransactionExportConfigurations
    ) {
      ...ProductTransactionExportConfiguration
    }
  }
  ${productTransactionExportConfiguration}
`;

export interface DeleteProductTransactionExportConfigurationVariables {
  productTransactionExportConfigurations: DeleteProductTransactionExportConfigurationInput[];
}

export interface DeleteProductTransactionExportConfigurationResponse {
  deleteProductTransactionExportConfiguration: ProductTransactionExportConfiguration[];
}

export const ProductTransactionExportConfigurationQueries = {
  get,
};

export const ProductTransactionExportConfigurationMutations = {
  create,
  update,
  remove,
};
