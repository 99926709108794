import { Grid } from '@mui/material';
import React, { useMemo } from 'react';

interface PaperProps {
  children: React.ReactNode;
  padding?: 'none' | 'x' | 'y' | 'xy' | string;
  border?: boolean;
  className?: string;
  fit?: boolean;
  overflow?: 'auto' | 'hidden' | 'visible';
}

export default function Paper({
  children,
  padding = 'xy',
  border = true,
  className = '',
  fit = false,
  overflow = 'visible',
}: PaperProps) {
  const paddingClass = useMemo(() => {
    switch (padding) {
      case 'none':
        return '';
      case 'x':
        return 'px-6';
      case 'y':
        return 'py-4';
      case 'xy':
        return 'px-6 py-4';
      default:
        return padding || '';
    }
  }, [padding]);

  const borderClass = useMemo(() => {
    if (!border) return '';
    return 'border border-ventory-light-border';
  }, [border]);

  const heightClass = useMemo(() => {
    return fit ? 'h-fit' : 'h-full';
  }, [fit]);

  return (
    <Grid
      container
      className={`bg-white rounded-4 ${heightClass} ${borderClass} ${paddingClass} ${className}`}
      style={{ overflow }}
    >
      <Grid item xs={12} className='h-full'>
        {children}
      </Grid>
    </Grid>
  );
}
