import React from 'react';
import { CsvUploadConfiguration } from '../types/csvUploadConfiguration';

export interface CsvUploadConfigurationContext {
  csvUploadConfigurations: Map<string, CsvUploadConfiguration>;
  setCsvUploadConfigurations: (configurations: Map<string, CsvUploadConfiguration>) => void;
  csvUploadConfigurationsLoading: boolean;
}

const defaultContext: CsvUploadConfigurationContext = {
  csvUploadConfigurations: new Map(),
  setCsvUploadConfigurations: configurations => {},
  csvUploadConfigurationsLoading: false,
};

export const CsvUploadConfigurationContext = React.createContext<CsvUploadConfigurationContext>(defaultContext);
