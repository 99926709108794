import React from 'react';
import { Product } from '../types/product';

export interface ProductContextProps {
  products: Map<string, Map<string, Product>>;
  setProducts: (products: Map<string, Map<string, Product>>) => void;
  productsLoading: boolean;
}

const defaultContext: ProductContextProps = {
  products: new Map(),
  setProducts: () => {},
  productsLoading: false,
};

export const ProductContext = React.createContext<ProductContextProps>(defaultContext);
