import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { ProductMasterDataFragments } from '../../graphql/productMasterData.graphql';
import { ProductMasterDataContext } from '../../context/ProductMasterDataContext';
import { ProductMasterData } from '../../types/productMasterData';

const productMasterDataSubscription = gql`
  subscription productMasterDataNotification($companyId: String!) {
    productMasterDataNotification(companyId: $companyId) {
      companyId
      entities {
        ...ProductMasterData
      }
      topic
      date
    }
  }
  ${ProductMasterDataFragments.productMasterData}
`;

export class ProductMasterDataNotificationResponse extends forNotification<ProductMasterData>(
  'productMasterDataNotification',
) {}

export default function ProductMasterDataNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { productMasterData, setProductMasterData } = useContext(ProductMasterDataContext);

  if (!currentCompany) return null;

  const { data: productMasterDataNotification } = useSubscription<ProductMasterDataNotificationResponse>(
    productMasterDataSubscription,
    {
      variables: { companyId: currentCompany.id },
    },
  );

  const handleProductMasterDataNotification = (notification?: ProductMasterDataNotificationResponse) => {
    if (!notification?.productMasterDataNotification) return;
    const productMasterDataNotification = notification.productMasterDataNotification;

    switch (productMasterDataNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        productMasterDataNotification.entities.forEach(pmd =>
          productMasterData.set(pmd.id, new ProductMasterData(pmd)),
        );
        break;
      case 'DELETE':
        productMasterDataNotification.entities.forEach(pmd =>
          productMasterData.has(pmd.id) ? productMasterData.delete(pmd.id) : null,
        );
    }
    setProductMasterData(new Map(productMasterData));
  };

  useEffect(() => {
    handleProductMasterDataNotification(productMasterDataNotification);
  }, [productMasterDataNotification]);

  return <></>;
}
