export class FilterParser {
  static recursiveParse(filter: string, expressions: string[]) {
    if (filter.includes('OR')) {
      const split = filter.split('OR');
      if (split.length !== 2) return;

      expressions.push(split[0]);
      expressions.push('OR');
      this.recursiveParse(split[1], expressions);
    } else if (filter.includes('AND')) {
      const split = filter.split('AND');
      if (split.length !== 2) return;

      expressions.push(split[0]);
      expressions.push('AND');
      this.recursiveParse(split[1], expressions);
    } else {
      expressions.push(filter);
      return;
    }
  }

  static parse(filter?: string) {
    if (!filter) return undefined;

    const expressions: string[] = [];
    this.recursiveParse(filter, expressions);
    return expressions;
  }
}
