import React from 'react';

export default function TermsOfUse() {
  return (
    <div className='elementor-widget-wrap' style={{ padding: '1rem' }}>
      <div
        className='elementor-element elementor-element-fd85e8a elementor-widget elementor-widget-text-editor'
        data-id='fd85e8a'
        data-element_type='widget'
        data-widget_type='text-editor.default'
      >
        <div className='elementor-widget-container'>
          <div className='elementor-text-editor elementor-clearfix'>
            <p>
              By using the website, platform and application services provided by Ventory you agree to the following
              terms and conditions:
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                All information, content and analytic data and services provided under your registered user account on
                app.ventory.io (“<strong>User Account</strong>”), including the related graphics and other analytical
                information (“<strong>Information</strong>”), are for informative purpose only and considered to be
                confidential.
              </li>
            </ol>
            <ol start={2}>
              <li>
                The User Account is owned and licensed by Ventory to you for your own use only. Any intellectual
                property related to the User Account shall be and remain, at all times, the sole and exclusive property
                of Ventory. You may not deploy or resell the User Account for your commercial benefit, unless otherwise
                agreed. Without explicit authorization by Ventory, you agree not to use, transfer, distribute, or
                dispose of any the Information provided to you in any manner that could compete with the business of
                Ventory. You may not recirculate, redistribute or publish the Information to any third party which is
                not an affiliate or a subsidiary of yours without Ventory’ prior written consent. Any unauthorized use,
                copying, reproduction, modification, publication, uploading, posting, framing, transmitting,
                distributing, duplicating or any other misuse of the Information or abuse of your User Account is
                strictly prohibited. You shall not violate the security measures governing the User Account sor attempt
                to gain unauthorized access to it, related data, materials, relevant computer systems or networks
                connected to any server associated with the Information, through hacking, password timing or any other
                means.<p>&nbsp;</p>
              </li>
            </ol>
            <ol start={3}>
              <li>
                TO THE FULLEST EXTENT AS PERMISSIBLE BY APPICABLE LAW, ANY INFORMATION UNDER YOUR USER ACCOUNT IS
                PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY AND LIABILITY OF ANY KIND:
                <p>
                  Ventory shall not be responsible for the User Account provided by it and makes no representations and
                  disclaims all express, implied, and statutory warranties of any kind to you and or any third party,
                  including warranties as to sequence, accuracy, correctness, reliability, timeliness, completeness,
                  merchantability, non-infringement or fitness for any particular purpose of such information, its use
                  and the expected results thereof. Ventory does not warrant an error-free or uninterrupted operation of
                  your User Account, or that defects will be corrected including relevant functions and features
                  provided under it. Insofar, Ventory shall not, directly or indirectly, be liable, in any way, for any:
                  (a) inaccuracies or errors in or omissions from providing any Information; (b) delays, errors, or
                  interruptions in the transmission or delivery of it; or (c) reason of nonperformance of the
                  functionalities under your User Account.
                </p>
                <p>
                  Ventory, its directors, employees, representatives, successors or assigns shall not be liable to you
                  or to any third party, under any legal theory whether in contract (including under any indemnity or
                  warranty), in tort (including negligence), statute or otherwise, under or in connection with the use
                  of the User Account for any actual, direct and indirect, punitive, incidental or consequential damages
                  and other commercial damages, lost revenue, lost profit, lost data, costs of procurement for
                  substitution of products or services, third party claims, wasted management time, downtime of your
                  User Account, however caused or in relation to the use of the Information and the User Account, even
                  if Ventory has been advised specifically of the possibility of such damages.
                </p>
                <p>
                  The forgoing limitations shall not apply to the extent restricted or prevented by mandatory applicable
                  law that cannot be amended or excluded by contractual waiver, such as deliberate acts and fraud.
                </p>
              </li>
              <li>
                Any disputes arising out or in connection with the use of the Information and the User Account shall be
                subject to Belgian law and the competent courts of Brussels.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
