import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { ContactFragments } from '../../graphql/contact.graphql';
import { Contact } from '../../types/contact';
import { ContactContext } from '../../context/ContactContext';

const contactSubscription = gql`
  subscription contactNotification($companyId: String!) {
    contactNotification(companyId: $companyId) {
      companyId
      entities {
        ...Contact
      }
      topic
      date
    }
  }
  ${ContactFragments.contact}
`;

export class ContactNotificationResponse extends forNotification<Contact>('contactNotification') {}

export default function ContactNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { contacts, setContacts } = useContext(ContactContext);

  if (!currentCompany) return null;

  const { data: contactNotifaction } = useSubscription<ContactNotificationResponse>(contactSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleContactNotification = (notification?: ContactNotificationResponse) => {
    if (!notification?.contactNotification) return;
    const contactNotification = notification.contactNotification;

    switch (contactNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        contactNotification.entities.forEach(contact => contacts.set(contact.id, new Contact(contact)));
        break;
      case 'DELETE':
        contactNotification.entities.forEach(contact =>
          contacts.has(contact.id) ? contacts.delete(contact.id) : null,
        );
    }
    setContacts(new Map(contacts));
  };

  useEffect(() => {
    handleContactNotification(contactNotifaction);
  }, [contactNotifaction]);

  return <></>;
}
