import { TranslationKey } from '../../../../types/translation/TranslationKey';
import { Translations } from '../../../../types/translation/common';
import { toFilterString } from '../../../../util/string.util';

export namespace TranslationFilter {
  export function search(textFilter: string, key: TranslationKey, english: Translations, current: Translations) {
    const englishRecord = english[key];
    const currentRecord = current[key];

    if (englishRecord) {
      if (englishRecord.singular.lower.includes(textFilter)) return true;
      if (englishRecord.plural.lower.includes(textFilter)) return true;
    }

    if (currentRecord) {
      if (currentRecord.singular.lower.includes(textFilter)) return true;
      if (currentRecord.plural.lower.includes(textFilter)) return true;
    }

    if (toFilterString(key).includes(textFilter)) return true;

    return false;
  }

  export function filter(textFilter: string, items: TranslationKey[], onFilter: (item: any, text: string) => boolean) {
    const result = items.filter(item => {
      return onFilter(item, textFilter);
    });

    return result;
  }
}
