import React from 'react';
import { BinStatus } from '../types/binStatus';

export interface BinStatusContextProps {
  binStatuses: Map<string, BinStatus>;
  setBinStatuses: (binStatuses: Map<string, BinStatus>) => void;
}

const defaultContext: BinStatusContextProps = {
  binStatuses: new Map(),
  setBinStatuses: () => {},
};

export const BinStatusContext = React.createContext<BinStatusContextProps>(defaultContext);
