import { Grid } from '@mui/material';
import React, { useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import { t } from '../../../../types/translation/Translator';

interface MultiSelectionListProps {
  selected?: Set<string>;
  values: Set<string>;
  onChange: (selected: Set<string>) => void;
  toText: (id: string) => string | undefined;
  toSubText?: (id: string) => string | undefined;
  color?: string;
}

export default function MultiSelectionList({
  values,
  selected = new Set(),
  toText,
  color,
  onChange,
  toSubText = (id: string) => undefined,
}: MultiSelectionListProps) {
  const [scrollTop, setScrollTop] = useState<number>(0);

  const handleChange = (item: string) => {
    if (selected.has(item)) selected.delete(item);
    else selected.add(item);

    onChange(new Set(selected));
  };

  const handleScroll = (event?: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(event?.currentTarget.scrollTop || 0);
  };

  return (
    <Grid container className='max-h-[300px] overflow-auto w-fit px-4 py-2' onScroll={handleScroll}>
      {values.size ? (
        <Grid item xs={12}>
          <Grid container>
            {[...values.values()]
              .sort((a, b) => (selected.has(b) ? 1 : 0) - (selected.has(a) ? 1 : 0))
              .splice(0, 15 + scrollTop / 20)
              .map((item, idx) => {
                return (
                  <Grid key={`${item}${idx}`} item xs={12} className={`${idx ? 'py-1' : 'pb-1'}`}>
                    <Grid container>
                      <Checkbox
                        label={toText(item) || ''}
                        value={selected.has(item)}
                        onChange={() => handleChange(item)}
                        subText={toSubText(item) || ''}
                      />
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} className='text-center'>
          <p className='text-[13px] pb-1 font-[500] text-ventory-grey-300'>{t().noItemsFound.singular.label}</p>
        </Grid>
      )}
    </Grid>
  );
}
