import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import {
  CreateCustomFieldInput,
  CustomField,
  DeleteCustomFieldInput,
  UpdateCustomFieldInput,
} from '../types/customField';

const customField = gql`
  fragment CustomField on CustomField {
    id
    version
    companyId
    createdAt
    type
    entityType
    entityParentType
    entitySubtype
    defaultValue
    name
    mandatory
    index
    values
  }
`;

const get = gql`
  query CustomFields($companyId: String!, $page: Float) {
    customFields(companyId: $companyId, page: $page) {
      data {
        ...CustomField
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${customField}
`;

export interface GetCustomFieldVariables {
  companyId: string;
  page?: number;
}

export interface GetCustomFieldResponse {
  customFields: PaginatedCustomFields;
}

class PaginatedCustomFields extends forPaginated<CustomField>() {}

const create = gql`
  mutation CreateCustomField($customFields: [CreateCustomFieldInput!]!) {
    createCustomField(customFields: $customFields) {
      ...CustomField
    }
  }
  ${customField}
`;

export interface CreateCustomFieldVariables {
  customFields: CreateCustomFieldInput[];
}

export interface CreateCustomFieldResponse {
  createCustomField: CustomField[];
}

const update = gql`
  mutation UpdateCustomField($customFields: [UpdateCustomFieldInput!]!) {
    updateCustomField(customFields: $customFields) {
      ...CustomField
    }
  }
  ${customField}
`;

export interface UpdateCustomFieldVariables {
  customFields: UpdateCustomFieldInput[];
}

export interface UpdateCustomFieldResponse {
  updateCustomField: CustomField[];
}

const remove = gql`
  mutation DeleteCustomField($customFields: [DeleteCustomFieldInput!]!) {
    deleteCustomField(customFields: $customFields) {
      ...CustomField
    }
  }
  ${customField}
`;

export interface DeleteCustomFieldVariables {
  customFields: DeleteCustomFieldInput[];
}

export interface DeleteCustomFieldResponse {
  deleteCustomField: CustomField[];
}

export const CustomFieldQueries = {
  get,
};

export const CustomFieldMutations = {
  create,
  update,
  remove,
};

export const CustomFieldFragment = {
  customField,
};
