import React, { useContext, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { CreateTaskInput, Task } from '../../../types/task';
import { CompanyContext } from '../../../context/CompanyContext';
import { CreateTaskTypePane } from './Panes/CreateTaskTypePane';
import { CreateTaskStockLocationPane } from './Panes/CreateTaskStockLocationPane';
import { CreateTaskEntityPane } from './Panes/CreateTaskEntityPane';
import { CreateTaskValidationPane } from './Panes/CreateTaskValidationPane';
import { CreateTaskAssigneesPane } from './Panes/CreateTaskAssigneesPane';
import { CreateTaskDueDatePane } from './Panes/CreateTaskDueDatePane';
import { useMutation } from '@apollo/client';
import { CreateTaskResponse, CreateTaskVariables, TaskMutations } from '../../../graphql/task.graphql';
import { TaskContext } from '../../../context/TaskContext';
import { testIds } from '../../../util/identifiers/identifiers.util';
import CreateTaskReportReceiversPane from './Panes/CreateTaskReportReceiversPane';
import CreateTaskRecountAssigneesPane from './Panes/CreateTaskRecountAssigneesPane';
import { TaskSettingsContext } from '../../../context/TaskSettingsContext';
import { TaskSettings } from '../../../types/taskSettings';
import Modal from '../../../VentoryUI/components/common/Modal/Modal';

interface CreateTaskModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

enum CreateTaskState {
  type = 'type',
  stockLocation = 'stockLocation',
  entitySelect = 'entitySelect',
  validation = 'validation',
  assignees = 'assignees',
  recountAssignees = 'recountAssignees',
  date = 'date',
  reportReceivers = 'reportReceivers',
}

export default function CreateTaskModal({ open, setOpen }: CreateTaskModalInputProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { tasks, setTasks } = useContext(TaskContext);
  const { taskSettings } = useContext(TaskSettingsContext);
  const taskSetting = new TaskSettings(
    taskSettings.size ? [...taskSettings.values()][0] : { companyId: currentCompany.id },
  );

  const [taskInput, setTaskInput] = useState<CreateTaskInput>(
    new CreateTaskInput({ companyId: currentCompany.id, type: currentCompany.validTaskTypes[0] }),
  );
  const [currentPane, setCurrentPane] = useState<CreateTaskState>(CreateTaskState.type);
  const [error, setError] = useState<string>('');

  const [create, { loading: taskLoading }] = useMutation<CreateTaskResponse, CreateTaskVariables>(
    TaskMutations.create,
    {
      onCompleted: res => {
        res.createTask.forEach(task => tasks.set(task.id, new Task(task)));
        setTasks(new Map(tasks));
        handleClose();
      },
      onError: err => setError(err.message),
    },
  );

  const handleClose = () => {
    setError('');
    setCurrentPane(CreateTaskState.type);
    setTaskInput(new CreateTaskInput(new CreateTaskInput({ companyId: currentCompany.id })));
    setOpen(false);
  };

  const handleNext = () => {
    switch (currentPane) {
      case CreateTaskState.type:
        return setCurrentPane(CreateTaskState.stockLocation);
      case CreateTaskState.stockLocation:
        return setCurrentPane(CreateTaskState.entitySelect);
      case CreateTaskState.entitySelect:
        return setCurrentPane(CreateTaskState.validation);
      case CreateTaskState.validation:
        return setCurrentPane(CreateTaskState.assignees);
      case CreateTaskState.assignees:
        if (!taskSetting?.discrepancyThreshold) {
          return setCurrentPane(CreateTaskState.date);
        } else {
          return setCurrentPane(CreateTaskState.recountAssignees);
        }
      case CreateTaskState.recountAssignees:
        return setCurrentPane(CreateTaskState.date);
      case CreateTaskState.date:
        return setCurrentPane(CreateTaskState.reportReceivers);
    }
  };

  const handleFinish = async () => {
    try {
      taskInput.validate();

      await create({
        variables: {
          tasks: [taskInput],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleBack = () => {
    switch (currentPane) {
      case CreateTaskState.type:
        return;
      case CreateTaskState.stockLocation:
        return setCurrentPane(CreateTaskState.type);
      case CreateTaskState.entitySelect:
        return setCurrentPane(CreateTaskState.stockLocation);
      case CreateTaskState.validation:
        return setCurrentPane(CreateTaskState.entitySelect);
      case CreateTaskState.assignees:
        return setCurrentPane(CreateTaskState.validation);
      case CreateTaskState.recountAssignees:
        return setCurrentPane(CreateTaskState.assignees);
      case CreateTaskState.date:
        if (!taskSetting?.discrepancyThreshold) {
          return setCurrentPane(CreateTaskState.assignees);
        } else {
          return setCurrentPane(CreateTaskState.recountAssignees);
        }
      case CreateTaskState.reportReceivers:
        return setCurrentPane(CreateTaskState.date);
    }
  };

  const content = () => {
    switch (currentPane) {
      case CreateTaskState.type:
        return <CreateTaskTypePane task={taskInput} setTask={setTaskInput} next={handleNext} back={handleBack} />;
      case CreateTaskState.stockLocation:
        return (
          <CreateTaskStockLocationPane
            task={taskInput}
            setTask={setTaskInput}
            next={handleNext}
            back={handleBack}
            error={error}
            setError={setError}
          />
        );
      case CreateTaskState.entitySelect:
        return <CreateTaskEntityPane task={taskInput} setTask={setTaskInput} next={handleNext} back={handleBack} />;
      case CreateTaskState.validation:
        return <CreateTaskValidationPane task={taskInput} setTask={setTaskInput} next={handleNext} back={handleBack} />;
      case CreateTaskState.assignees:
        return <CreateTaskAssigneesPane task={taskInput} setTask={setTaskInput} next={handleNext} back={handleBack} />;
      case CreateTaskState.recountAssignees:
        return (
          <CreateTaskRecountAssigneesPane task={taskInput} setTask={setTaskInput} next={handleNext} back={handleBack} />
        );
      case CreateTaskState.date:
        return (
          <CreateTaskDueDatePane
            loading={taskLoading}
            task={taskInput}
            setTask={setTaskInput}
            next={handleNext}
            back={handleBack}
          />
        );
      case CreateTaskState.reportReceivers:
        return (
          <CreateTaskReportReceiversPane
            loading={taskLoading}
            task={taskInput}
            setTask={setTaskInput}
            next={handleFinish}
            back={handleBack}
          />
        );
    }
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      height='550px'
      title={t().createTask.singular.label}
      testId={testIds.createTaskModal}
    >
      {content()}
    </Modal>
  );
}
