import React, { useContext, useEffect, useState } from 'react';
import { EntityFilter } from '../../filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { FileContext } from '../../../../../context/FileContext';
import { StringUtil, toFilterString } from '../../../../../util/string.util';
import ScrollableFilterContent from './ScrollableFilterContent';

interface FileFilterContentProps {
  className?: string;
  filter: EntityFilter;
  setFilter: (filter: EntityFilter) => void;
  color: string;
}

const fileTypeCategoryFilter = (file: string, text: string, filter: EntityFilter) => {
  return toFilterString(file).includes(toFilterString(text));
};

export default function FileTypeCategoryFilterContent({ className, filter, color, setFilter }: FileFilterContentProps) {
  const { files } = useContext(FileContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterFileTypeCategories = () => {
    return new Set(
      [...files.values()]
        .map(file => file.fileTypeCategory)
        .filter(file => fileTypeCategoryFilter(file, textFilter, filter)),
    );
  };

  const [values, setValues] = useState<Set<string>>(filterFileTypeCategories());

  const handleChange = (selected: Set<string>) => {
    filter.fileTypeCategory = selected;
    if (!filter.fileTypeCategory?.size) filter.fileTypeCategory = undefined;
    setFilter({ ...filter });
  };

  const handleRemove = () => {
    filter.fileTypeCategory = undefined;
    setFilter({ ...filter });
  };

  useEffect(() => {
    setValues(filterFileTypeCategories());
  }, [textFilter]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().file.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filter.fileTypeCategory}
            values={values}
            onChange={handleChange}
            toText={fileTypeCategory => StringUtil.upperCaseFirstCharacter(fileTypeCategory)}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
