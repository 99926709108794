import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function ProductFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { productMasterData } = useContext(ProductMasterDataContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.product || [])]}
      text={item => productMasterData.get(item)?.productName || t().unknownProduct.singular.label}
      onRemove={i => {
        filter.product?.delete(i);
        if (!filter.product?.size) filter.product = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
