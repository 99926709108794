import React from 'react';
import { CustomField, CustomFieldType } from '../../types/customField';
import TextInput from './TextInput';
import { Grid } from '@mui/material';
import Checkbox from '../../VentoryUI/components/common/Checkbox/Checkbox';
import DatePicker from './DatePicker';
import DropdownSelect from './DropdownSelect';
import { t } from '../../types/translation/Translator';
import { TranslationPluralType } from '../../types/translation/common';

interface CustomFieldInputProps {
  item: CustomField;
  value: any;
  onChange: (item: CustomField, value: any) => void;
  disabled?: boolean;
}

export default function CustomFieldInput({ value, item, onChange, disabled = false }: CustomFieldInputProps) {
  const key = item.name.charAt(0).toLowerCase().concat(item.name.substring(1).replaceAll(' ', ''));
  const label = ((t() as any)[key] as TranslationPluralType)?.singular.label;

  switch (item.type) {
    case CustomFieldType.text:
      return (
        <TextInput
          dynamicUpdate
          disabled={disabled}
          label={label || item.name}
          placeholder={label || item.name}
          mandatory={item.mandatory}
          value={value}
          onChange={v => onChange(item, v)}
        />
      );
    case CustomFieldType.bool:
      return (
        <Grid container mt={1}>
          <Grid item xs={12} marginY={'auto'}>
            <Checkbox
              disabled={disabled}
              label={label || item.name}
              value={value === 'true' || false}
              onChange={v => onChange(item, v)}
            />
          </Grid>
        </Grid>
      );
    case CustomFieldType.date:
      return (
        <DatePicker
          dynamicUpdate
          disabled={disabled}
          label={label || item.name}
          placeholder={label || item.name}
          mandatory={item.mandatory}
          value={new Date(value)}
          onChange={(val?: Date | undefined) => onChange(item, val)}
        />
      );
    case CustomFieldType.listOfValues:
      return (
        <DropdownSelect
          mandatory={item.mandatory}
          label={label || item.name}
          placeholder={label || item.name}
          values={item.values}
          selectedValue={value || null}
          toText={item => item || ''}
          onChange={v => onChange(item, v)}
          disabled={disabled}
        />
      );
  }
}
