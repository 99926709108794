import { cloneDeep } from '@apollo/client/utilities';
import { forCreate, forDelete, forUpdate, StockLocationEntity } from './common/entity';

enum BinValidationError {
  name = 'Please enter a valid name',
  binStatus = 'Please select a bin status',
}

export class Bin extends StockLocationEntity {
  name!: string;
  binStatusId!: string;

  constructor(obj: any) {
    if (!obj.companyId || !obj.stockLocationId) return;
    super(obj.companyId, obj.stockLocationId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateBinInput {
    this.validate();
    return UpdateBinInput.from(this, UpdateBinInput);
  }

  forDelete(): DeleteBinInput {
    this.validate();
    return DeleteBinInput.from(this, DeleteBinInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof Bin)[], (field: keyof Bin) => {
      if (field === 'name' && !this.name) throw BinValidationError.name;
      if (field === 'binStatusId' && !this.binStatusId) throw BinValidationError.binStatus;
      return null;
    });
  }

  withName(name: string) {
    this.name = name;
    return this;
  }

  withBinStatusId(binStatusId?: string) {
    this.binStatusId = binStatusId || '';
    return cloneDeep(this);
  }
}

export class CreateBinInput extends forCreate(Bin) {}

export class UpdateBinInput extends forUpdate(Bin) {}

export class DeleteBinInput extends forDelete(Bin) {}
