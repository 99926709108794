import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function HashtagIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width='16' height='16' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          strokeWidth={2}
          d='M0.25 6C0.25 5.80109 0.329018 5.61032 0.46967 5.46967C0.610322 5.32902 0.801088 5.25 1 5.25H19C19.1989 5.25 19.3897 5.32902 19.5303 5.46967C19.671 5.61032 19.75 5.80109 19.75 6C19.75 6.19891 19.671 6.38968 19.5303 6.53033C19.3897 6.67098 19.1989 6.75 19 6.75H1C0.801088 6.75 0.610322 6.67098 0.46967 6.53033C0.329018 6.38968 0.25 6.19891 0.25 6Z'
          fill={color || '#667085'}
        />
        <path
          strokeWidth={2}
          d='M6 0.25C6.19891 0.25 6.38968 0.329018 6.53033 0.46967C6.67098 0.610322 6.75 0.801088 6.75 1V19C6.75 19.1989 6.67098 19.3897 6.53033 19.5303C6.38968 19.671 6.19891 19.75 6 19.75C5.80109 19.75 5.61032 19.671 5.46967 19.5303C5.32902 19.3897 5.25 19.1989 5.25 19V1C5.25 0.801088 5.32902 0.610322 5.46967 0.46967C5.61032 0.329018 5.80109 0.25 6 0.25ZM14 0.25C14.1989 0.25 14.3897 0.329018 14.5303 0.46967C14.671 0.610322 14.75 0.801088 14.75 1V19C14.75 19.1989 14.671 19.3897 14.5303 19.5303C14.3897 19.671 14.1989 19.75 14 19.75C13.8011 19.75 13.6103 19.671 13.4697 19.5303C13.329 19.3897 13.25 19.1989 13.25 19V1C13.25 0.801088 13.329 0.610322 13.4697 0.46967C13.6103 0.329018 13.8011 0.25 14 0.25Z'
          fill={color || '#667085'}
        />
        <path
          strokeWidth={2}
          d='M0.25 14C0.25 13.8011 0.329018 13.6103 0.46967 13.4697C0.610322 13.329 0.801088 13.25 1 13.25H19C19.1989 13.25 19.3897 13.329 19.5303 13.4697C19.671 13.6103 19.75 13.8011 19.75 14C19.75 14.1989 19.671 14.3897 19.5303 14.5303C19.3897 14.671 19.1989 14.75 19 14.75H1C0.801088 14.75 0.610322 14.671 0.46967 14.5303C0.329018 14.3897 0.25 14.1989 0.25 14Z'
          fill={color || '#667085'}
        />
      </svg>
    </Icon>
  );
}
