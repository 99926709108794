import React from 'react';
import { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { BinContext } from '../../../../../context/BinContext';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function BinFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  const { bins } = useContext(BinContext);

  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.bin || [])]}
      text={item => bins.get(item)?.name || t().unknownBin.singular.label}
      onRemove={i => {
        filter.bin?.delete(i);
        if (!filter.bin?.size) filter.bin = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
