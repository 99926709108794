import { Grid } from '@mui/material';
import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../context/CompanyContext';
import { handleKeyEvent } from '../../../../util/events.util';
import { classes } from '../../../../util/identifiers/identifiers.util';
import { Colors } from '../../../util/color.util';

export interface Tab {
  text: string;
  key: string;
  path: string;
}

interface TabBarProps {
  tabs: Tab[];
}

export default function TabBar({ tabs }: TabBarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentCompany } = useContext(CompanyContext);

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    const index = tabs.findIndex(i => path.includes(i.path));
    if (index === -1) setSelected(0);
    else setSelected(index);
  }, [location]);

  const handleTabSelected = (tab: Tab, index: number) => {
    setSelected(index);
    navigate(tab.path);
  };

  const style = (index: number) => {
    if (index === selected) {
      return {
        backgroundColor: Colors.companyLightBackground(currentCompany),
        color: Colors.companyDarkText(currentCompany),
      };
    }
  };

  return (
    <Grid
      container
      className='w-full flex bg-white border border-ventory-light-border h-[36px] px-[6px] py-[6px] rounded-4'
    >
      {tabs.map((tab, index) => (
        <Grid
          item
          key={tab.key}
          textAlign={'center'}
          onClick={() => handleTabSelected(tab, index)}
          data-testid={tab.key}
          className={`${classes.tab.name} flex cursor-pointer select-none text-ventory-grey-500 h-full pr-1`}
          justifyContent='center'
          alignItems={'center'}
        >
          <p
            tabIndex={0}
            onKeyDown={event => handleKeyEvent(event, 'Enter', () => handleTabSelected(tab, index))}
            className='text-[14px] py-1 px-2 flex items-center justify-center rounded-2 font-medium'
            style={{ ...style(index), lineHeight: '14px' }}
          >
            {tab.text}
          </p>
        </Grid>
      ))}
    </Grid>
  );
}
