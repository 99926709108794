import React from 'react';
import { SuperUser } from '../types/superUser';

export interface SuperUserContextProps {
  superUser?: SuperUser;
  setSuperUser: (superUser?: SuperUser) => void;
}

const defaultContext: SuperUserContextProps = {
  superUser: undefined,
  setSuperUser: () => {},
};

export const SuperUserContext = React.createContext<SuperUserContextProps>(defaultContext);
