import { Contact } from '../../../../types/contact';
import { toFilterString } from '../../../../util/string.util';
import { EntityFilter } from '../filter.util';
import { AddressFilter } from './AddressFilter';

export namespace ContactFilter {
  export function search(item: Contact, textFilter: string) {
    if (
      toFilterString(item.name).includes(textFilter) ||
      (item.address && AddressFilter.search(item.address, textFilter)) ||
      toFilterString(item.email).includes(textFilter) ||
      item.shippingLocations.some(
        sl =>
          toFilterString(sl.name).includes(textFilter) ||
          toFilterString(sl.email).includes(textFilter) ||
          (sl.address && AddressFilter.search(sl.address, textFilter)),
      )
    ) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: Contact[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      return onFilter(item, textFilter);
    });

    return result;
  }
}
