import { t } from '../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../util/identifiers/identifiers.util';
import SettingsIcon from '../../../../icons/Settings/SettingsIcon';
import { Button } from '../../Button/Button';
import { MenuItemProps, MenuItemTemplateProps } from '../MenuItem';

export function SettingsMenuItemTemplate(onClick: () => void, props?: MenuItemTemplateProps): MenuItemProps {
  return {
    testId: testIds.settings,
    onClick: onClick,
    text: t().settings.singular.label,
    icon: <SettingsIcon />,
  };
}

interface SettingsMenuItemProps extends MenuItemTemplateProps {
  onClick: () => void;
}

export default function SettingsMenuItem({ onClick, ...props }: SettingsMenuItemProps) {
  return <Button {...SettingsMenuItemTemplate(onClick, props)} />;
}
