import { gql } from '@apollo/client';
import { Bin, CreateBinInput, DeleteBinInput, UpdateBinInput } from '../types/bin';
import { IdArray } from '../types/common/entity';
import { forPaginated } from './common/paginated.graphql';

const bin = gql`
  fragment Bin on Bin {
    id
    version
    companyId
    stockLocationId
    binStatusId
    name
  }
`;

const get = gql`
  query Bins($companyId: String!, $page: Float) {
    bins(companyId: $companyId, page: $page) {
      data {
        ...Bin
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${bin}
`;

export interface GetBinsVariables {
  companyId: string;
  page?: number;
}

export interface GetBinsResponse {
  bins: PaginatedBins;
}

class PaginatedBins extends forPaginated<Bin>() {}

const create = gql`
  mutation CreateBin($bins: [CreateBinInput!]!) {
    createBin(bins: $bins) {
      ...Bin
    }
  }
  ${bin}
`;

export interface CreateBinVariables {
  bins: CreateBinInput[];
}

export interface CreateBinResponse {
  createBin: Bin[];
}

const update = gql`
  mutation UpdateBin($bins: [UpdateBinInput!]!) {
    updateBin(bins: $bins) {
      ...Bin
    }
  }
  ${bin}
`;

export interface UpdateBinVariables {
  bins: UpdateBinInput[];
}

export interface UpdateBinResponse {
  updateBin: Bin[];
}

const remove = gql`
  mutation DeleteBin($bins: [DeleteBinInput!]!) {
    deleteBin(bins: $bins) {
      ...Bin
    }
  }
  ${bin}
`;

export interface DeleteBinVariables {
  bins: DeleteBinInput[];
}

export interface DeleteBinResponse {
  deleteBin: IdArray;
}

export const BinQueries = {
  get,
};

export const BinMutations = {
  create,
  update,
  remove,
};

export const BinFragment = {
  bin,
};
