import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function FileUploadIcon({ className, color, height = 18, width = 20 }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width={width} height={height} viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.66669 12.3333L10 9M10 9L13.3334 12.3333M10 9V16.5M16.6667 12.9524C17.6846 12.1117 18.3334 10.8399 18.3334 9.41667C18.3334 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3976 4.73833 13.3051 4.58145C12.2184 2.73736 10.212 1.5 7.91669 1.5C4.46491 1.5 1.66669 4.29822 1.66669 7.75C1.66669 9.47175 2.36289 11.0309 3.48914 12.1613'
          stroke={color || 'currentColor'}
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
