import React from 'react';
import Icon, { IconInputProps } from '../Icon';

interface CollapseIconProps extends IconInputProps {
  collapsed: boolean;
}

export default function CollapseIcon({ onClick, className, collapsed, color }: CollapseIconProps) {
  return (
    <Icon onClick={onClick} className={className}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ transform: collapsed ? 'rotate(180deg)' : '' }}
      >
        <path
          d='M4.2 13C4.64183 13 5 12.6418 5 12.2V1.8C5 1.35817 4.64183 1 4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.0799 1 4.2V9.8C1 10.9201 1 11.4802 1.21799 11.908C1.40973 12.2843 1.71569 12.5903 2.09202 12.782C2.51984 13 3.0799 13 4.2 13Z'
          fill='#667085'
        />
        <path
          d='M4.2 1H9.8C10.9201 1 11.4802 1 11.908 1.21799C12.2843 1.40973 12.5903 1.71569 12.782 2.09202C13 2.51984 13 3.07989 13 4.2V9.8C13 10.9201 13 11.4802 12.782 11.908C12.5903 12.2843 12.2843 12.5903 11.908 12.782C11.4802 13 10.9201 13 9.8 13H4.2M4.2 1C3.07989 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2V9.8C1 10.9201 1 11.4802 1.21799 11.908C1.40973 12.2843 1.71569 12.5903 2.09202 12.782C2.51984 13 3.07989 13 4.2 13M4.2 1V1C4.64183 1 5 1.35817 5 1.8V12.2C5 12.6418 4.64183 13 4.2 13V13'
          stroke='#667085'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
