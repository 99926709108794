import { gql } from '@apollo/client';
import {
  CompanyRoleAssignment,
  CreateCompanyRoleAssignmentInput,
  DeleteCompanyRoleAssignmentInput,
  UpdateCompanyRoleAssignmentInput,
} from '../types/companyRoleAssignment';
import { forPaginated } from './common/paginated.graphql';

const companyRoleAssignment = gql`
  fragment CompanyRoleAssignment on CompanyRoleAssignment {
    id
    companyId
    userId
    email
    version
    role
    state
  }
`;

const get = gql`
  query CompanyRoleAssignments {
    companyRoleAssignments {
      data {
        ...CompanyRoleAssignment
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${companyRoleAssignment}
`;

export interface GetCompanyRoleAssignmentsVariables {}

export interface GetCompanyRoleAssignmentsResponse {
  companyRoleAssignments: PaginatedCompanyRoleAssignments;
}

const getForCompany = gql`
  query CompanyRoleAssignmentsForCompany($companyId: String!) {
    companyRoleAssignmentsForCompany(companyId: $companyId) {
      data {
        ...CompanyRoleAssignment
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${companyRoleAssignment}
`;

export interface GetCompanyRoleAssignmentsForCompanyVariables {
  companyId: string;
}

export interface GetCompanyRoleAssignmentsForCompanyResponse {
  companyRoleAssignmentsForCompany: PaginatedCompanyRoleAssignments;
}

class PaginatedCompanyRoleAssignments extends forPaginated<CompanyRoleAssignment>() {}

const create = gql`
  mutation CreateCompanyRoleAssignment($companyRoleAssignment: CreateCompanyRoleAssignmentInput!) {
    createCompanyRoleAssignment(companyRoleAssignment: $companyRoleAssignment) {
      id
    }
  }
`;

export interface CreateCompanyRoleAssignmentVariables {
  createCompanyRoleAssignment: CreateCompanyRoleAssignmentInput;
}

export interface CreateCompanyRoleAssignmentResponse {
  id: string;
}

const update = gql`
  mutation UpdateCompanyRoleAssignment($companyRoleAssignment: UpdateCompanyRoleAssignmentInput!) {
    updateCompanyRoleAssignment(companyRoleAssignment: $companyRoleAssignment) {
      ...CompanyRoleAssignment
    }
  }
  ${companyRoleAssignment}
`;

export interface UpdateCompanyRoleAssignmentVariables {
  companyRoleAssignment: UpdateCompanyRoleAssignmentInput;
}

export interface UpdateCompanyRoleAssignmentResponse {
  updateCompanyRoleAssignment: CompanyRoleAssignment;
}

const remove = gql`
  mutation DeleteCompanyRoleAssignment($companyRoleAssignment: DeleteCompanyRoleAssignmentInput!) {
    deleteCompanyRoleAssignment(companyRoleAssignment: $companyRoleAssignment) {
      ...CompanyRoleAssignment
    }
  }
  ${companyRoleAssignment}
`;

export interface DeleteCompanyRoleAssignmentVariables {
  companyRoleAssignment: DeleteCompanyRoleAssignmentInput;
}

export interface DeleteCompanyRoleAssignmentResponse {
  deleteCompanyRoleAssignment: CompanyRoleAssignment;
}

const leave = gql`
  mutation LeaveCompany($roleId: String!, $companyId: String!) {
    leaveCompany(roleId: $roleId, companyId: $companyId) {
      ...CompanyRoleAssignment
    }
  }
  ${companyRoleAssignment}
`;

export interface LeaveCompanyVariables {
  roleId: string;
  companyId: string;
}

export interface LeaveCompanyResponse {
  leaveCompany: CompanyRoleAssignment;
}

const accept = gql`
  mutation AcceptCompanyRoleAssignment($roleId: String!, $companyId: String!) {
    acceptCompanyRoleAssignment(roleId: $roleId, companyId: $companyId) {
      ...CompanyRoleAssignment
    }
  }
  ${companyRoleAssignment}
`;

export interface AcceptCompanyRoleAssignmentVariables {
  roleId: string;
  companyId: string;
}

export interface AcceptCompanyRoleAssignmentResponse {
  acceptCompanyRoleAssignment: CompanyRoleAssignment;
}

const decline = gql`
  mutation DeclineCompanyRoleAssignment($roleId: String!, $companyId: String!) {
    declineCompanyRoleAssignment(roleId: $roleId, companyId: $companyId) {
      ...CompanyRoleAssignment
    }
  }
  ${companyRoleAssignment}
`;

export interface DeclineCompanyRoleAssignmentVariables {
  roleId: string;
  companyId: string;
}

export interface DeclineCompanyRoleAssignmentResponse {
  acceptCompanyRoleAssignment: CompanyRoleAssignment;
}

export const CompanyRoleAssignmentQueries = {
  get,
  getForCompany,
};

export const CompanyRoleAssignmentMutations = {
  create,
  update,
  remove,
  accept,
  decline,
  leave,
};

export const CompanyRoleAssignmentFragment = {
  companyRoleAssignment,
};
