import { EntityFilter } from '../filter.util';
import { toFilterString } from '../../../../util/string.util';
import { FileEntity } from '../../../../types/file';
import dayjs from 'dayjs';

export namespace FileFilter {
  export function search(item: FileEntity, textFilter: string) {
    if (toFilterString(item.name).includes(textFilter) || toFilterString(item.type).includes(textFilter)) {
      return true;
    }

    return false;
  }

  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: FileEntity[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      if (
        filter.timeFrame &&
        (dayjs(filter.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filter.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (filter.user && !filter.user.has(item.createdBy)) return false;

      if (filter.fileTypeCategory && !filter.fileTypeCategory.has(item.fileTypeCategory)) return false;
      if (filter.fileType && !filter.fileType.has(item.fileType)) return false;

      return onFilter(item, textFilter);
    });

    return result;
  }
}
