import { EntityFilter } from '../filter.util';
import { ProductTransaction, ProductTransactionParentType } from '../../../../types/productTransaction';
import dayjs from 'dayjs';

export namespace ProductTransactionFilter {
  export function filter(
    filter: EntityFilter,
    textFilter: string,
    items: ProductTransaction[],
    onFilter: (item: any, text: string) => boolean,
  ) {
    const result = items.filter(item => {
      if (item.parentType === ProductTransactionParentType.transaction) return false;

      if (
        filter.timeFrame &&
        (dayjs(filter.timeFrame.start)
          .startOf('day')
          .isAfter(dayjs(item.processedAt || item.createdAt)) ||
          dayjs(filter.timeFrame.end)
            .endOf('day')
            .isBefore(dayjs(item.processedAt || item.createdAt)))
      ) {
        return false;
      }

      if (
        (filter.stockLocation &&
          filter.stockLocation.size &&
          item.product.fromStockLocationId &&
          !filter.stockLocation.has(item.product.fromStockLocationId)) ||
        (filter.stockLocation &&
          item.product.toStockLocationId &&
          !filter.stockLocation.has(item.product.toStockLocationId))
      ) {
        return false;
      }

      if (filter.product && filter.product.size && !filter.product.has(item.product.pmdId || '')) {
        return false;
      }

      if (filter.user && filter.user.size && !filter.user.has(item.processedBy || '')) {
        return false;
      }

      return onFilter(item, textFilter);
    });

    return result.sort((a, b) =>
      dayjs(new Date(a.processedAt || a.createdAt)).isBefore(new Date(b.processedAt || b.createdAt)) ? 1 : -1,
    );
  }
}
