import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function ChevronRightIcon({ className }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          strokeWidth={2}
          d='M6 12L10 8L6 4'
          shapeRendering='optimizeQuality'
          stroke='#D0D5DD'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
