import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity } from './common/entity';
import { TriggerEntityType } from './trigger';
import { ProductTransaction } from './productTransaction';
import { StockLocationAggregateType } from './common/stockLocationAggregateType';

export class Alert extends CompanyEntity {
  entityType!: TriggerEntityType;
  entityValue!: string;
  triggeredValue!: string;
  triggerId!: string;
  stockLocationAggregateType!: StockLocationAggregateType;
  stockLocationIds: string[] = [];
  triggeredStockLocation?: string;
  userId!: string;
  customFieldIds: string[] = [];
  alertString!: string;
  productTransaction?: ProductTransaction;
  triggerStateId?: string;
  processed: boolean = false;

  forUpdate() {
    throw new Error('Method not implemented.');
  }

  forDelete() {
    throw new Error('Method not implemented.');
  }

  validate(fields: any[]) {
    throw new Error('Method not implemented.');
  }

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
    if (!obj.customFieldIds) this.customFieldIds = [];
    if (this.productTransaction) this.productTransaction = new ProductTransaction(this.productTransaction);
  }
}

// export function getTextForAlert(
//   alert: Alert,
//   productMasterData: Map<string, ProductMasterData>,
//   stockLocations: Map<string, StockLocation>,
//   productTransactions: Map<string, ProductTransaction>,
//   orders: Map<string, Order>,
//   lots: Map<string, Lot>,
// ) {
//   switch (alert.entityType) {
//     case TriggerEntityType.pmd:
//       return getProductMasterDataText(alert, productMasterData, stockLocations);
//     case TriggerEntityType.lot:
//       return getLotText(alert, lots);
//     case TriggerEntityType.productTransaction:
//       return getTransactionText(alert, productTransactions, productMasterData, orders);
//   }
// }

// function getProductMasterDataText(
//   alert: Alert,
//   productMasterData: Map<string, ProductMasterData>,
//   stockLocations: Map<string, StockLocation>,
// ) {
//   return `Quantity for ${
//     productMasterData.get(alert.triggeredValue)?.productName || 'Unknown Product Reference'
//   } dropped below ${getQuantityText(alert, productMasterData.get(alert.triggeredValue))} ${getStockLocationText(
//     alert,
//     stockLocations,
//   )}`;
// }

// function getQuantityText(alert: Alert, productMasterData?: ProductMasterData) {
//   const reorderPoint = productMasterData?.reorderPoint || 0;
//   if (alert.entityValue.includes('${REORDER_POINT')) {
//     if (alert.entityValue === '${REORDER_POINT}') {
//       return reorderPoint.toString();
//     } else {
//       const suffix = alert.entityValue.split('${REORDER_POINT}');
//       if (suffix.length < 2) return '0';

//       const sign = suffix[1][0];
//       if (sign === '-') return `${reorderPoint} (${(parseFloat(suffix[1].substring(1)) * -1).toString()})`;
//       else if (sign === '+') return `${reorderPoint} ${parseFloat(suffix[1].substring(1)).toString()})`;
//       return '0';
//     }
//   } else if (alert.entityValue.includes('${VALUE}')) {
//     const suffix = alert.entityValue.split('${VALUE}');
//     if (suffix.length < 2) return '0';

//     return suffix[1];
//   }
//   return alert.entityValue;
// }

// function getLotText(alert: Alert, lots: Map<string, Lot>) {
//   return `Lot ${lots.get(alert.triggeredValue || '')?.number || 'Unknown Lot'} ${getExpiryText(
//     alert,
//     lots.get(alert.triggeredValue || ''),
//   )}`;
// }

// function getExpiryText(alert: Alert, lot?: Lot) {
//   if (alert.entityValue.includes('${EXPIRY_DATE}')) {
//     if (alert.entityValue === '${EXPIRY_DATE}') {
//       return `expired on ${new Date(lot?.expirationDate || '').toLocaleDateString()}`;
//     } else {
//       const suffix = alert.entityValue.split('${EXPIRY_DATE}');
//       if (suffix.length < 2) return '0';

//       return `will expire in ${suffix[1]} days`;
//     }
//   }
//   return alert.entityValue;
// }

// function getTransactionText(
//   alert: Alert,
//   productTransactions: Map<string, ProductTransaction>,
//   productMasterData: Map<string, ProductMasterData>,
//   orders: Map<string, Order>,
// ): string {
//   const productTransaction = alert.productTransaction ?? productTransactions.get(alert.triggeredValue);
//   if (!productTransaction) return 'Triggered product transaction not found';

//   if (!productTransaction.customFields.size) {
//     return 'Triggered product transaction with unknown custom field value';
//   }
//   const customFieldValue = productTransaction.customFields.get(alert.customFieldIds?.at(0) ?? '');
//   if (!customFieldValue) return 'Triggered product transaction with unknown custom field value';

//   const productName = productMasterData.get(productTransaction.product.pmdId ?? '')?.productName || 'Unknown Product';

//   if (alert.entityValue.includes('${UPDATED_FIELD}')) {
//     return `Field "${customFieldValue.name}" for product ${productName} ${getTransactionParentText(
//       productTransaction,
//       orders,
//     )} was updated to ${getTextForCustomField(customFieldValue)}`;
//   } else if (alert.entityValue.includes('${DUE_DATE}')) {
//     let suffix;
//     const dueDate = dayjs(customFieldValue.value);

//     if (!dueDate.isValid()) {
//       suffix = 'an invalid date';
//     } else {
//       const daysUntilDueDate = dayjs(dueDate).diff(new Date(), 'days');
//       if (daysUntilDueDate > 0) {
//         suffix = `${daysUntilDueDate} day${daysUntilDueDate > 1 ? 's' : ''} away`;
//       } else if (daysUntilDueDate < 0) {
//         suffix = `${daysUntilDueDate} day${daysUntilDueDate > 1 ? 's' : ''} overdue`;
//       } else {
//         suffix = 'today';
//       }
//     }

//     return `Due date field "${customFieldValue.name}" for product ${productName} ${getTransactionParentText(
//       productTransaction,
//       orders,
//     )} is ${suffix}`;
//   }

//   return '';
// }

// function getTransactionParentText(productTransaction: ProductTransaction, orders: Map<string, Order>): string {
//   let text = '';
//   if (productTransaction.parentType && productTransaction.parentId) {
//     switch (productTransaction.parentType) {
//       case ProductTransactionParentType.order:
//         const order = orders.get(productTransaction.parentId);
//         if (order) {
//           text += `on ${order.type} order`;
//           if (order.number) {
//             text += ` ${order.number}`;
//           }
//         }
//         return text;
//     }
//   }

//   return text;
// }

// function getStockLocationText(alert: Alert, stockLocations: Map<string, StockLocation>) {
//   if (alert.stockLocationAggregateType === StockLocationAggregateType.all) {
//     return 'in all stock locations';
//   } else if (alert.stockLocationAggregateType === StockLocationAggregateType.allOf) {
//     return `on a stock location wide level (for stock locations ${alert.stockLocationIds
//       .map(sl => stockLocations.get(sl)?.name)
//       .join(',')})`;
//   } else if (alert.stockLocationAggregateType === StockLocationAggregateType.any) {
//     return `in ${stockLocations.get(alert.triggeredStockLocation || '')?.name || 'Unknown stock location'}`;
//   } else if (alert.stockLocationAggregateType === StockLocationAggregateType.anyOf) {
//     return `in ${stockLocations.get(alert.triggeredStockLocation || '')?.name || 'Unknown stock location'}`;
//   }
// }
