import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function WarningTriangleIcon({}: IconInputProps) {
  return (
    <Icon>
      <svg
        width='20'
        height='17'
        viewBox='0 0 20 17'
        fill='none'
        stroke='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.99963 6.49995V9.83328M9.99963 13.1666H10.008M8.84573 2.24305L1.99166 14.0819C1.61149 14.7386 1.4214 15.0669 1.4495 15.3364C1.474 15.5714 1.59714 15.785 1.78828 15.924C2.00741 16.0833 2.38679 16.0833 3.14556 16.0833H16.8537C17.6125 16.0833 17.9919 16.0833 18.211 15.924C18.4021 15.785 18.5253 15.5714 18.5498 15.3364C18.5779 15.0669 18.3878 14.7386 18.0076 14.0819L11.1535 2.24305C10.7747 1.58875 10.5853 1.26159 10.3382 1.15172C10.1227 1.05587 9.8766 1.05587 9.66105 1.15172C9.41394 1.26159 9.22454 1.58875 8.84573 2.24305Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
