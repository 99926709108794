import React from 'react';
import { FileEntity, FileWithSas } from '../types/file';

export interface FileContextProps {
  setFileCache: (cache: Map<string, FileWithSas>) => void; // Using a file url cache because apollo caching is really bad, onCompleted is not fired when the cache is hit
  fileCache: Map<string, FileWithSas>;
  files: Map<string, FileEntity>;
  setFiles: (files: Map<string, FileEntity>) => void;
  filesLoading: boolean;
}

const defaultContext = {
  setFileCache: () => null,
  fileCache: new Map(),
  files: new Map(),
  setFiles: () => null,
  filesLoading: false,
};

export const FileContext = React.createContext<FileContextProps>(defaultContext);
