import { t } from '../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function BackButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.back,
    text: t().back.singular.label,
    onClick: onClick,
    ...props,
  };
}

interface BackButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function BackButton({ onClick, ...props }: BackButtonProps) {
  return <Button {...BackButtonTemplate(onClick, props)} />;
}
