import { gql } from '@apollo/client';
import { CompanyFragments, PaginatedCompanies } from './company.graphql';

const get = gql`
  query Customers {
    customers {
      data {
        ...Company
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${CompanyFragments.company}
`;

export interface GetCustomersVariables {}

export interface GetCustomersResponse {
  customers: PaginatedCompanies;
}

export const CustomerQueries = {
  get,
};
