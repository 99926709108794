import React from 'react';
import { OrderExportConfiguration } from '../types/orderExportConfiguration';

export interface OrderExportConfigurationContextProps {
  orderExportConfigurations: Map<string, OrderExportConfiguration>;
  setOrderExportConfigurations: (orderExportConfigurations: Map<string, OrderExportConfiguration>) => void;
  orderExportConfigurationsLoading: boolean;
}

const defaultContext: OrderExportConfigurationContextProps = {
  orderExportConfigurations: new Map(),
  setOrderExportConfigurations: () => {},
  orderExportConfigurationsLoading: false,
};

export const OrderExportConfigurationContext =
  React.createContext<OrderExportConfigurationContextProps>(defaultContext);
