import { t } from '../../../../types/translation/Translator';
import { SuperUser } from '../../../../types/superUser';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React from 'react';
import {
  DeleteSuperUserResponse,
  DeleteSuperUserVariables,
  SuperUserMutations,
  UpdateSuperUserResponse,
  UpdateSuperUserVariables,
} from '../../../../graphql/superUser.graphql';
import SuperUserInfoPane from '../Panes/SuperUserInfoPane';

import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import DeleteButton from '../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import CancelButton from '../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import SaveButton from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';

interface UpdateSuperUserModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  superUser: SuperUser;
  superUsers: Map<string, SuperUser>;
  setSuperUsers: (superUsers: Map<string, SuperUser>) => void;
}

export default function UpdateSuperUserModal({
  open,
  setOpen,
  superUser,
  superUsers,
  setSuperUsers,
}: UpdateSuperUserModalProps) {
  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<UpdateSuperUserResponse, UpdateSuperUserVariables>(
    SuperUserMutations.update,
    {
      onCompleted: res => {
        const superUser = res.updateSuperUser[0];
        setSuperUsers(new Map(superUsers).set(superUser.userId, new SuperUser(superUser)));
        setOpen(false);
      },
      onError: res => setError(res.message),
    },
  );

  const [remove, { loading: deleteLoading }] = useMutation<DeleteSuperUserResponse, DeleteSuperUserVariables>(
    SuperUserMutations.remove,
    {
      onCompleted: res => {
        const superUser = res.deleteSuperUser[0];
        superUsers.delete(superUser.userId);
        setSuperUsers(new Map(superUsers));
        setOpen(false);
      },
      onError: res => setError(res.message),
    },
  );

  const handleUpdate = async (superUser: SuperUser) => {
    try {
      await update({
        variables: {
          superUsers: [superUser],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleDelete = async (superUser: SuperUser) => {
    try {
      await remove({
        variables: {
          superUsers: [superUser.forDelete()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (user: SuperUser) => (
    <Grid container marginTop={'auto'}>
      <Grid item>
        <DeleteButton loading={deleteLoading} disabled={updateLoading} onClick={() => handleDelete(user)} />
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <CancelButton disabled={updateLoading || deleteLoading} onClick={handleClose} />
          </Grid>
          <Grid item>
            <SaveButton
              loading={updateLoading}
              disabled={!user.role || !user.email || deleteLoading}
              onClick={() => handleUpdate(user)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().updateSuperUser.singular.label}>
      <SuperUserInfoPane superUser={superUser} footer={footer} />
    </Modal>
  );
}
