import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../context/CompanyContext';
import { Grid } from '@mui/material';
import { storeCurrentCompanyId } from '../../../util/token.util';
import { useNavigate } from 'react-router-dom';
import CompanySelectFrame from '../../Common/CompanySelectFrame';
import { t } from '../../../types/translation/Translator';
import CreateCompanyModal from './Modals/CreateCompanyModal';
import CachedIcon from '@mui/icons-material/Cached';
import { useMutation } from '@apollo/client';
import { UtilQueries } from '../../../graphql/util.graphql';
import { SuperUserContext } from '../../../context/SuperUserContext';
import { SuperUserRole } from '../../../types/superUser';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import {
  CompanyMutations,
  CreateDemoCompanyResponse,
  CreateDemoCompanyVariables,
} from '../../../graphql/company.graphql';

import { Button } from '../../../VentoryUI/components/common/Button/Button';
import { AddIcon } from '../../../VentoryUI/icons/Add/AddIcon';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';

export default function AdministratorCompanyPane() {
  const navigate = useNavigate();
  const { companies, refreshCompany } = useContext(CompanyContext);
  const { superUser } = useContext(SuperUserContext);

  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const [refreshCompanyCache, { loading }] = useMutation(UtilQueries.refreshCompanyCache, {
    onError: err => console.log(err.message),
  });

  const [createDemoCompany, { loading: demoLoading }] = useMutation<
    CreateDemoCompanyResponse,
    CreateDemoCompanyVariables
  >(CompanyMutations.createDemo, {
    onCompleted: async res => {
      const company = res.createDemoCompany;
      storeCurrentCompanyId(company.id);
      await refreshCompany(company.id);
      navigate('/dashboard');
    },
  });

  return (
    <>
      <Paper>
        <Grid container rowSpacing={1} alignContent={'start'}>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} justifyContent={'flex-end'}>
              {superUser?.role === SuperUserRole.administrator ? (
                <Grid item>
                  <Button
                    loading={loading}
                    disabled={demoLoading}
                    startIcon={<CachedIcon />}
                    onClick={() => refreshCompanyCache()}
                    text={t().refreshCompanyCache.singular.label}
                  />
                </Grid>
              ) : null}
              {superUser?.role !== SuperUserRole.external ? (
                <Grid item>
                  <Button
                    loading={demoLoading}
                    disabled={loading}
                    startIcon={<AddBusinessIcon />}
                    onClick={() => createDemoCompany()}
                    text={t().createDemoCompany.singular.label}
                  />
                </Grid>
              ) : null}
              <Grid item>
                <Button
                  disabled={loading || demoLoading}
                  startIcon={<AddIcon />}
                  style='secondary'
                  onClick={() => setOpenCreateModal(true)}
                  text={t().createNewCompany.singular.label}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {[...companies.values()].map(company => (
                <CompanySelectFrame
                  key={company.id}
                  company={company}
                  onClick={async () => {
                    storeCurrentCompanyId(company.id);
                    await refreshCompany(company.id);
                    navigate('/dashboard');
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <CreateCompanyModal open={openCreateModal} setOpen={setOpenCreateModal} />
    </>
  );
}
