export const en = {
  accept: {
    singular: {
      lower: 'accept',
    },
  },
  acceptedFormats: {
    singular: {
      lower: 'accepted formats are .png: .jpg: .jpeg: .svg',
    },
  },
  actions: {
    singular: {
      lower: 'actions',
    },
  },
  add: {
    singular: {
      lower: 'add',
    },
  },
  addAdditionalOrderExportField: {
    singular: {
      lower: 'add order export field',
    },
  },
  addAdditionalProductExportField: {
    singular: {
      lower: 'add product export field',
    },
  },
  addAdditionalTaskExportField: {
    singular: {
      lower: 'add task export field',
    },
  },
  addDueDate: {
    singular: {
      lower: 'do you want to add a due date?',
    },
  },
  addFilter: {
    singular: {
      lower: 'add filter',
    },
  },
  additionalProductIdentificationAssignedByTheManufacturer: {
    singular: {
      lower: 'additional product identification assigned by the manufacturer',
    },
  },
  additionalSettings: {
    singular: {
      lower: 'additional settings',
    },
  },
  addOrderColumn: {
    singular: {
      lower: 'add order column (max: 12)',
    },
  },
  addProduct: {
    plural: {
      lower: 'add products',
    },
    singular: {
      lower: 'add product',
    },
  },
  addProductsToOrder: {
    singular: {
      lower: 'add products to order',
    },
  },
  addProductTransactionColumn: {
    singular: {
      lower: 'add product transaction column (max: 12)',
    },
  },
  addressLine1: {
    singular: {
      lower: 'address line 1',
    },
  },
  addressLine2: {
    singular: {
      lower: 'address line 2',
    },
  },
  administrator: {
    singular: {
      lower: 'administrator',
    },
  },
  advancedSettings: {
    singular: {
      lower: 'advanced settings',
    },
  },
  alert: {
    plural: {
      lower: 'alerts',
    },
    singular: {
      lower: 'alert',
    },
  },
  alertsAlertFeatureToggle: {
    singular: {
      lower: 'alerts',
    },
  },
  all: {
    singular: {
      lower: 'all',
    },
  },
  allOf: {
    singular: {
      lower: 'all of',
    },
  },
  alreadyHaveAccountQuestion: {
    singular: {
      lower: 'already have an account?',
    },
  },
  anAlertHasBeenTriggeredText: {
    singular: {
      lower: 'an alert has been triggered',
    },
  },
  any: {
    singular: {
      lower: 'any',
    },
  },
  anyOf: {
    singular: {
      lower: 'any of',
    },
  },
  apiKey: {
    singular: {
      lower: 'api key',
    },
  },
  apiKeyForUserAlreadyExistsWithinCompany: {
    singular: {
      lower: 'api key for user already exists within the company',
    },
  },
  applicationPartIncident: {
    singular: {
      lower: 'at what part of the application does the incident occur?',
    },
  },
  appliesToAllProducts: {
    singular: {
      lower: 'applies to all products',
    },
  },
  attachements: {
    singular: {
      lower: 'attachements',
    },
  },
  attributes: {
    singular: {
      lower: 'attributes',
    },
  },
  authentication: {
    singular: {
      lower: 'authentication',
    },
  },
  authenticationCodeForUserAlreadyExists: {
    singular: {
      lower: 'authentication code for user already exists',
    },
  },
  authenticationCodeHasExpired: {
    singular: {
      lower: 'this authentication code has expired',
    },
  },
  authenticationSettingsForUserAlreadyExists: {
    singular: {
      lower: 'authentication settings for user already exists',
    },
  },
  authenticationType: {
    singular: {
      lower: 'authentication type',
    },
  },
  back: {
    singular: {
      lower: 'back',
    },
  },
  barcode: {
    singular: {
      lower: 'barcode',
    },
  },
  barcodeConfigurations: {
    singular: {
      lower: 'barcode configurations',
    },
  },
  barcodeConfigurationType: {
    singular: {
      lower: 'type',
    },
  },
  barcodeFormat: {
    singular: {
      lower: 'barcode format',
    },
  },
  bestBeforeDate: {
    singular: {
      lower: 'best before date',
    },
  },
  billing: {
    singular: {
      lower: 'billing',
    },
  },
  billToInvoiceToGlobalLocationNumber: {
    singular: {
      lower: 'bill to – invoice to global location number',
    },
  },
  bin: {
    plural: {
      lower: 'bins',
    },
    singular: {
      lower: 'bin',
    },
  },
  binHasNoStockLocation: {
    singular: {
      lower: 'bin ${VALUE} has no stock location',
    },
  },
  binMetrics: {
    singular: {
      lower: 'bin metrics',
    },
  },
  binName: {
    singular: {
      lower: 'bin name',
    },
  },
  binStatus: {
    plural: {
      lower: 'bin statuses',
    },
    singular: {
      lower: 'bin status',
    },
  },
  binStatusWithNameAlreadyExists: {
    singular: {
      lower: "bin status with name '${VALUE}' already exists",
    },
  },
  binWithNameAlreadyExists: {
    singular: {
      lower: 'bin with name ${VALUE} already exists.',
    },
  },
  binWithNameAlreadyExistsForStockLocation: {
    singular: {
      lower: "bin with name '${VALUE}' already exists for this stock location",
    },
  },
  blindCount: {
    singular: {
      lower: 'blind count',
    },
  },
  browse: {
    singular: {
      lower: 'browse',
    },
  },
  businessCentralUnableToDetermineSourceStockLocation: {
    singular: {
      lower: '[business central] unable to determine source stock location',
    },
  },
  businessCentralUnableToFindIdentifierToCreateJournalEntry: {
    singular: {
      lower: '[business central] unable to find identifier to create journal entry',
    },
  },
  businessCentralUnableToFindParentOrder: {
    singular: {
      lower: '[business central] unable to find parent order',
    },
  },
  businessCentralUnableToFindProjektasKodas: {
    singular: {
      lower: '[business central] unable to find projektas kodas',
    },
  },
  businessCentralUnableToFindProjektasKodasValue: {
    singular: {
      lower: '[business central] unable to find ${VALUE}',
    },
  },
  businessCentralUnableToProcessWhenStockLocationIsNot: {
    singular: {
      lower:
        '[Business Central] Unable to process when stock location is not ${VALID_LOCATION_OPTION_ONE} or ${VALID_LOCATION_OPTION_TWO}',
    },
  },
  businessCentralUnableToUpdateTransactions: {
    singular: {
      lower: '[business central] unable to update transctions',
    },
  },
  cancel: {
    singular: {
      lower: 'cancel',
    },
  },
  cancelled: {
    singular: {
      lower: 'cancelled',
    },
  },
  cancelOrder: {
    singular: {
      lower: 'cancel order',
    },
  },
  cancelOrderValidation: {
    singular: {
      lower: 'are you sure you want to cancel this order?',
    },
  },
  cancelTransaction: {
    singular: {
      lower: 'cancel transaction',
    },
  },
  cannotAssignUserNotAssignedToStockLocation: {
    singular: {
      lower: 'cannot assign a user that is not assigned to the stock location',
    },
  },
  cannotAssignUserWithoutRoleInCompany: {
    singular: {
      lower: 'cannot assign a user that does not have a role in the company',
    },
  },
  cannotCreateTaskWithoutDueDate: {
    singular: {
      lower: 'cannot create a task without a due date, your settings are setup to make this field mandatory',
    },
  },
  cannotDeleteExistingProductsIfStockLocationNotUndefined: {
    singular: {
      lower: 'cannot delete existing products if stock location is not undefined',
    },
  },
  cellularMobileTelephoneIdentifier: {
    singular: {
      lower: 'cellular mobile telephone identifier',
    },
  },
  changePassword: {
    singular: {
      lower: 'change password',
    },
  },
  city: {
    singular: {
      lower: 'city',
    },
  },
  clearAll: {
    singular: {
      lower: 'clear all',
    },
  },
  clickToSelectAndDisplayProductImage: {
    singular: {
      lower: 'click to select and display a product image.',
    },
  },
  clientId: {
    singular: {
      lower: 'client id',
    },
  },
  clientSecret: {
    singular: {
      lower: 'client secret',
    },
  },
  cm: {
    singular: {
      lower: 'cm',
    },
  },
  code: {
    singular: {
      lower: 'code',
    },
  },
  collapse: {
    singular: {
      lower: 'collapse',
    },
  },
  color: {
    singular: {
      lower: 'color',
    },
  },
  comment: {
    plural: {
      lower: 'comments',
    },
    singular: {
      lower: 'comment',
    },
  },
  company: {
    singular: {
      lower: 'company',
    },
  },
  companyAlreadyHasSubscription: {
    singular: {
      lower: 'company already has a subscription',
    },
  },
  companyColors: {
    singular: {
      lower: 'company colors',
    },
  },
  companyDoesNotHaveAnyStockLocations: {
    singular: {
      lower: 'company ${VALUE} does not have stock locations',
    },
  },
  companyFavicon: {
    singular: {
      lower: 'company favicon',
    },
  },
  companyInternalInformation: {
    singular: {
      lower: 'company internal information',
    },
  },
  companyLevel: {
    singular: {
      lower: 'company level',
    },
  },
  companyLogo: {
    singular: {
      lower: 'company logo',
    },
  },
  companyName: {
    singular: {
      lower: 'company name',
    },
  },
  complete: {
    singular: {
      lower: 'complete',
    },
  },
  completed: {
    singular: {
      lower: 'completed',
    },
  },
  completedOn: {
    singular: {
      lower: 'completed on',
    },
  },
  completeOrder: {
    singular: {
      lower: 'complete order',
    },
  },
  completeOrderValidation: {
    singular: {
      lower: 'are you sure you want to complete this order?',
    },
  },
  componentPartIdentifier: {
    singular: {
      lower: 'component/part identifier (cpid)',
    },
  },
  componentPartIdentifierSerialNumber: {
    singular: {
      lower: 'component/part identifier serial number',
    },
  },
  confirm: {
    singular: {
      lower: 'confirm',
    },
  },
  confirmPassword: {
    singular: {
      lower: 'confirm password',
    },
  },
  consumerProductVariant: {
    singular: {
      lower: 'consumer product variant',
    },
  },
  contact: {
    singular: {
      lower: 'contact',
    },
  },
  contactEmail: {
    singular: {
      lower: 'contact email',
    },
  },
  contactLocationWithNameXNotPartOfContactY: {
    singular: {
      lower: 'Contact location with name ${CONTACT_LOCATION} is not a part of contact ${CONTACT}',
    },
  },
  contacts: {
    singular: {
      lower: 'contacts',
    },
  },
  contactWithNameAlreadyExists: {
    singular: {
      lower: "contact with name '${VALUE}' already exists",
    },
  },
  contactWithNameXNotFound: {
    singular: {
      lower: 'contact with name ${VALUE} not found',
    },
  },
  countedOnly: {
    singular: {
      lower: 'only show counted',
    },
  },
  countOfTradeItemsContained: {
    singular: {
      lower: 'count of trade items contained in a logistic unit',
    },
  },
  country: {
    singular: {
      lower: 'country',
    },
  },
  countryCoveringFullProcessChain: {
    singular: {
      lower: 'country covering full process chain',
    },
  },
  countryOfDisassembly: {
    singular: {
      lower: 'country of disassembly',
    },
  },
  countryOfInitialProcessing: {
    singular: {
      lower: 'country of initial processing',
    },
  },
  countryOfOrigin: {
    singular: {
      lower: 'country of origin',
    },
  },
  countryOfOriginOfTradeItem: {
    singular: {
      lower: 'country of origin of a trade item',
    },
  },
  countryOfProcessing: {
    singular: {
      lower: 'country of processing',
    },
  },
  couponCodeIdentificationNorthAmerica: {
    singular: {
      lower: 'coupon code identification for use in north america',
    },
  },
  create: {
    singular: {
      lower: 'create',
    },
  },
  createBarcodeConfiguration: {
    singular: {
      lower: 'create barcode configuration',
    },
  },
  createBin: {
    singular: {
      lower: 'create bin',
    },
  },
  createBinStatus: {
    singular: {
      lower: 'create bin status',
    },
  },
  createCompany: {
    singular: {
      lower: 'create company',
    },
  },
  createContact: {
    singular: {
      lower: 'create contact',
    },
  },
  createCustomField: {
    singular: {
      lower: 'create custom field',
    },
  },
  created: {
    singular: {
      lower: 'created',
    },
  },
  createdAt: {
    singular: {
      lower: 'created at',
    },
  },
  createdBy: {
    singular: {
      lower: 'created by',
    },
  },
  createDemoCompany: {
    singular: {
      lower: 'create demo company',
    },
  },
  createIntegration: {
    singular: {
      lower: 'create integration',
    },
  },
  createNewCompany: {
    singular: {
      lower: 'create new company',
    },
  },
  createOrder: {
    singular: {
      lower: 'create order',
    },
  },
  createProductMasterData: {
    singular: {
      lower: 'create product reference data',
    },
  },
  createReplenishOrderAuto: {
    singular: {
      lower: 'create a replenish order automatically when this rule triggers',
    },
  },
  createScanRule: {
    singular: {
      lower: 'create scan rule',
    },
  },
  createShippingLocation: {
    singular: {
      lower: 'create shipping location',
    },
  },
  createStockLocation: {
    singular: {
      lower: 'create stock location',
    },
  },
  createStockLocationRole: {
    singular: {
      lower: 'create stock location role',
    },
  },
  createSuperUser: {
    singular: {
      lower: 'create super user',
    },
  },
  createTask: {
    singular: {
      lower: 'create task',
    },
  },
  createTrigger: {
    singular: {
      lower: 'create trigger',
    },
  },
  creatingInboundOrdersNotAllowedForElteros: {
    singular: {
      lower: 'creating inbound orders is not allowed for elteros for stock locations other than ${VALUE}',
    },
  },
  csvUploadConfigurationNamePlaceholder: {
    singular: {
      lower: 'configuration name',
    },
  },
  currency: {
    singular: {
      lower: 'currency',
    },
  },
  currentMonth: {
    singular: {
      lower: 'current month',
    },
  },
  customer: {
    plural: {
      lower: 'customers',
    },
    singular: {
      lower: 'customer',
    },
  },
  customerPartNumber: {
    singular: {
      lower: 'customer part number',
    },
  },
  customerPurchaseOrderNumber: {
    singular: {
      lower: 'customer’s purchase order number',
    },
  },
  customFieldIsRequiredForProductTransactions: {
    singular: {
      lower: 'custom field ${VALUE} is mandatory for product transactions.',
    },
  },
  customFieldRequiesEntityParentType: {
    singular: {
      lower: 'custom field for entity type ${VALUE} requires an entity parent type',
    },
  },
  customFieldRequiresEntitySubtype: {
    singular: {
      lower: 'custom field for entity type ${VALUE} requires an entity subtype.',
    },
  },
  customFields: {
    singular: {
      lower: 'custom fields',
    },
  },
  customFieldWithIdNotFound: {
    singular: {
      lower: 'custom field with id ${VALUE} not found!',
    },
  },
  customFieldWithNameAlreadyExists: {
    singular: {
      lower: "custom field with name '${VALUE}' already exists",
    },
  },
  customFieldXUsedForValidationButDoesNotMatch: {
    singular: {
      lower: 'a custom field with name ${VALUE} was used for validation but does not match the id of the custom value',
    },
  },
  customValue: {
    singular: {
      lower: 'custom value',
    },
  },
  cycleCount: {
    singular: {
      lower: 'cycle count',
    },
  },
  dashboard: {
    singular: {
      lower: 'dashboard',
    },
  },
  dateAndTime: {
    singular: {
      lower: 'date & time',
    },
  },
  dateAndTimeOfProduction: {
    singular: {
      lower: 'date and time of production',
    },
  },
  dateInFuture: {
    singular: {
      lower: 'is in ${DAYS} ${DAY_TEXT}',
    },
  },
  dateInPast: {
    singular: {
      lower: 'was ${DAYS} ${DAY_TEXT} ago',
    },
  },
  day: {
    plural: {
      lower: 'days',
    },
    singular: {
      lower: 'day',
    },
  },
  decline: {
    singular: {
      lower: 'decline',
    },
  },
  default: {
    singular: {
      lower: 'default',
    },
  },
  defaultValue: {
    singular: {
      lower: 'default value',
    },
  },
  delete: {
    singular: {
      lower: 'delete',
    },
  },
  deleteAllExistingBins: {
    singular: {
      lower: 'delete all existing bins',
    },
  },
  delimiter: {
    singular: {
      lower: 'delimiter',
    },
  },
  depth: {
    singular: {
      lower: 'depth',
    },
  },
  destinationBin: {
    singular: {
      lower: 'destination bin',
    },
  },
  destinationNumber: {
    singular: {
      lower: 'destination bin',
    },
  },
  destinationStockLocation: {
    singular: {
      lower: 'destination stock location',
    },
  },
  developerOptions: {
    singular: {
      lower: 'developer options',
    },
  },
  discrepancyOnly: {
    singular: {
      lower: 'only show discrepancies',
    },
  },
  discrepancyThreshold: {
    singular: {
      lower: 'discrepancy threshold',
    },
  },
  dm: {
    singular: {
      lower: 'dm',
    },
  },
  done: {
    singular: {
      lower: 'done',
    },
  },
  downloadTemplate: {
    singular: {
      lower: 'download template',
    },
  },
  due: {
    singular: {
      lower: 'due date',
    },
  },
  dueDate: {
    singular: {
      lower: 'due date for amount on payment slip',
    },
  },
  dueDateMandatory: {
    singular: {
      lower: 'due date is mandatory',
    },
  },
  duplicateLPNFoundOnRows: {
    singular: {
      lower: 'Duplicate LPN ${LPN} found on rows ${FIRST_ROW_INDEX} and ${SECOND_ROW_INDEX}',
    },
  },
  edit: {
    singular: {
      lower: 'edit',
    },
  },
  email: {
    singular: {
      lower: 'email',
    },
  },
  employee: {
    singular: {
      lower: 'employee',
    },
  },
  entitiesNotFound: {
    singular: {
      lower: 'entities not found',
    },
  },
  entityAlreadyExistsInDatabase: {
    singular: {
      lower: 'entity ${VALUE} already exists in the database',
    },
  },
  entityAlreadyUpdatedInTheDatabase: {
    singular: {
      lower: 'entity ${VALUE} already updated in the database',
    },
  },
  entityNotFound: {
    singular: {
      lower: 'entity not found',
    },
  },
  entityParentType: {
    singular: {
      lower: 'entity parent type',
    },
  },
  entitySubtype: {
    singular: {
      lower: 'entity subtype',
    },
  },
  entityType: {
    singular: {
      lower: 'entity type',
    },
  },
  entityXNotFound: {
    singular: {
      lower: 'Entity ${ENTITY} (${ID}@${VERSION}) was not found',
    },
  },
  environment: {
    singular: {
      lower: 'environment',
    },
  },
  estimatedTimeOfArrival: {
    singular: {
      lower: 'estimated time of arrival',
    },
  },
  expectedQuantity: {
    singular: {
      lower: 'expected quantity',
    },
  },
  expirationDate: {
    singular: {
      lower: 'expiration date',
    },
  },
  expirationDateAndTime: {
    singular: {
      lower: 'expiration date and time',
    },
  },
  expiryInHours: {
    singular: {
      lower: 'expiry (in hours)',
    },
  },
  export: {
    singular: {
      lower: 'export',
    },
  },
  exportOrders: {
    singular: {
      lower: 'export orders',
    },
  },
  exportProductReferences: {
    singular: {
      lower: 'export product references',
    },
  },
  exportProducts: {
    singular: {
      lower: 'export products',
    },
  },
  exportProductTransactions: {
    singular: {
      lower: 'export product transactions',
    },
  },
  externalId: {
    singular: {
      lower: 'external id',
    },
  },
  externalReference: {
    singular: {
      lower: 'external reference',
    },
  },
  externalReferenceId: {
    singular: {
      lower: 'external reference id',
    },
  },
  featureCategory: {
    singular: {
      lower: 'category',
    },
  },
  featureToggles: {
    singular: {
      lower: 'feature toggles',
    },
  },
  field: {
    singular: {
      lower: 'field',
    },
  },
  fieldUser: {
    singular: {
      lower: 'field user',
    },
  },
  fileFormatCsv: {
    singular: {
      lower: 'to select a .csv file',
    },
  },
  fileName: {
    singular: {
      lower: 'file name',
    },
  },
  files: {
    singular: {
      lower: 'files',
    },
  },
  filesAndAssets: {
    singular: {
      lower: 'files & assets',
    },
  },
  filterAlerts: {
    singular: {
      lower: 'filter alerts',
    },
  },
  filterBarcodeConfigurations: {
    singular: {
      lower: 'filter barcode configurations',
    },
  },
  filterBins: {
    singular: {
      lower: 'filter bins',
    },
  },
  filterBinStatus: {
    singular: {
      lower: 'filter bin statuses',
    },
  },
  filterContacts: {
    singular: {
      lower: 'filter contacts',
    },
  },
  filterCountries: {
    singular: {
      lower: 'filter countries',
    },
  },
  filterCustomFields: {
    singular: {
      lower: 'filter custom fields',
    },
  },
  filterFiles: {
    singular: {
      lower: 'filter files',
    },
  },
  filterIntegrations: {
    singular: {
      lower: 'filter integrations',
    },
  },
  filterOrders: {
    singular: {
      lower: 'filter orders',
    },
  },
  filterOrderStatus: {
    singular: {
      lower: 'filter order status',
    },
  },
  filterProductReferenceData: {
    singular: {
      lower: 'filter product reference data',
    },
  },
  filterProducts: {
    singular: {
      lower: 'filter products',
    },
  },
  filterProductTransactions: {
    singular: {
      lower: 'filter product transactions',
    },
  },
  filterReorderRules: {
    singular: {
      lower: 'filter reorder rules',
    },
  },
  filter: {
    singular: {
      lower: 'filter',
    },
  },
  filterScanRules: {
    singular: {
      lower: 'filter scan rules',
    },
  },
  filterShippingLocations: {
    singular: {
      lower: 'filter shipping locations',
    },
  },
  filterStockLocations: {
    singular: {
      lower: 'filter stock locations',
    },
  },
  filterTasks: {
    singular: {
      lower: 'filter tasks',
    },
  },
  filterTaskStatus: {
    singular: {
      lower: 'filter task status',
    },
  },
  filterTriggers: {
    singular: {
      lower: 'filter triggers',
    },
  },
  filterUsers: {
    singular: {
      lower: 'filter users',
    },
  },
  finish: {
    singular: {
      lower: 'finish',
    },
  },
  firstName: {
    singular: {
      lower: 'first name',
    },
  },
  forgotPassword: {
    singular: {
      lower: 'forgot password',
    },
  },
  forTheFollowingStockLocations: {
    singular: {
      lower: 'for the following stock locations',
    },
  },
  foundTwoDifferentValuesForSameCustomFieldPropertyForOrderXOnRowY: {
    singular: {
      lower:
        'Found two different values for the same custom field property ${FIELD_NAME} for order number ${ORDER_NUMBER}. Second instance found on row ${ROW_INDEX}',
    },
  },
  foundTwoDifferentValuesForSamePropertyForOrderXOnRowY: {
    singular: {
      lower:
        'Found two different values for the same property ${FIELD_NAME} for order number ${ORDER_NUMBER}. Second instance found on row ${ROW_INDEX}',
    },
  },
  g: {
    singular: {
      lower: 'g',
    },
  },
  general: {
    singular: {
      lower: 'general',
    },
  },
  generateApiKey: {
    singular: {
      lower: 'generate api key',
    },
  },
  globalCouponNumber: {
    singular: {
      lower: 'global coupon number',
    },
  },
  globalDocumentTypeIdentifier: {
    singular: {
      lower: 'global document type identifier',
    },
  },
  globalIdentifierNumberForConsignment: {
    singular: {
      lower: 'global identifier number for consignment',
    },
  },
  globalIndividualAssetIdentifier: {
    singular: {
      lower: 'global individual asset identifier',
    },
  },
  globalLocationNumber: {
    singular: {
      lower: 'identification of a physical location - global location number',
    },
  },
  globalLocationNumberExtensionComponent: {
    singular: {
      lower: 'gln extension component',
    },
  },
  globalLocationNumberInvoicingParty: {
    singular: {
      lower: 'global location number of the invoicing party',
    },
  },
  globalModelNumber: {
    singular: {
      lower: 'global model number (gmn)',
    },
  },
  globalReturnableAssetIdentifier: {
    singular: {
      lower: 'global returnable asset identifier',
    },
  },
  globalServiceRelationNumberProvider: {
    singular: {
      lower: 'global service relation number',
    },
  },
  globalServiceRelationNumberRecipient: {
    singular: {
      lower: 'global service relation number',
    },
  },
  globalShipmentIdentificationNumber: {
    singular: {
      lower: 'global shipment identification number',
    },
  },
  globalTradeItemNumber: {
    singular: {
      lower: 'product number',
    },
  },
  globalTradeItemNumberOfTradeItemsContained: {
    singular: {
      lower: 'gtin of trade items contained in a logistic unit',
    },
  },
  grossWeight: {
    singular: {
      lower: 'gross weight',
    },
  },
  headerBackground: {
    singular: {
      lower: 'header background',
    },
  },
  height: {
    singular: {
      lower: 'height',
    },
  },
  hexColorCode: {
    singular: {
      lower: 'color code',
    },
  },
  history: {
    singular: {
      lower: 'history',
    },
  },
  hour: {
    plural: {
      lower: 'hours',
    },
    singular: {
      lower: 'hour',
    },
  },
  identificationOfIndividualTradeItem: {
    singular: {
      lower: 'identification of an individual trade item piece',
    },
  },
  ignoreAvailableQuantity: {
    singular: {
      lower: 'ignore available quantity when creating orders',
    },
  },
  implementMe: {
    singular: {
      lower: 'implement me!',
    },
  },
  import: {
    singular: {
      lower: 'import',
    },
  },
  importance: {
    singular: {
      lower: 'importance',
    },
  },
  importBins: {
    singular: {
      lower: 'import bins',
    },
  },
  importConfigurations: {
    singular: {
      lower: 'import configurations',
    },
  },
  importOrders: {
    singular: {
      lower: 'import orders',
    },
  },
  importProduct: {
    singular: {
      lower: 'import products',
    },
  },
  importProductMasterData: {
    singular: {
      lower: 'import product master data',
    },
  },
  importStockLocations: {
    singular: {
      lower: 'import stock locations',
    },
  },
  importUsers: {
    singular: {
      lower: 'import users',
    },
  },
  inbound: {
    singular: {
      lower: 'inbound',
    },
  },
  inboundAllowed: {
    singular: {
      lower: 'inbound allowed',
    },
  },
  inboundOrder: {
    singular: {
      lower: 'inbound order',
    },
  },
  incidentApplication: {
    singular: {
      lower: 'application',
    },
  },
  incidentApplicationPart: {
    singular: {
      lower: 'part of the application',
    },
  },
  incidentReportDescriptionMayNotBeEmptyOrUndefined: {
    singular: {
      lower: 'description may not be empty or undefined!',
    },
  },
  incidentReportDeviceMayNotBeEmptyOrUndefinedIfApplicationMobile: {
    singular: {
      lower: 'device may not be empty or undefined if incident platform is mobile!',
    },
  },
  incidentReportMobileElementMustBeSelectedIfApplicationMobile: {
    singular: {
      lower: 'a mobile element must be selected if incident platform is mobile!',
    },
  },
  incidentReportOperatingSystemMayNotBeEmptyOrUndefinedIfApplicationMobile: {
    singular: {
      lower: 'operating system version may not be empty or undefined if incident platform is mobile!',
    },
  },
  incidentReportWebElementMustBeSelectedIfApplicationWeb: {
    singular: {
      lower: 'a web element must be selected if incident platform is web!',
    },
  },
  incorrectCode: {
    singular: {
      lower: 'incorrect code',
    },
  },
  info: {
    singular: {
      lower: 'info',
    },
  },
  informationMutuallyAgreedBetweenTradingPartners: {
    singular: {
      lower: 'information mutually agreed between trading partners',
    },
  },
  inProgress: {
    singular: {
      lower: 'in progress',
    },
  },
  insufficientQuantityAvailableInBin: {
    singular: {
      lower: 'insufficient quantity available in bin',
    },
  },
  integrations: {
    singular: {
      lower: 'integrations',
    },
  },
  internalProductVariant: {
    singular: {
      lower: 'internal product variant',
    },
  },
  internationalBankAccountNumber: {
    singular: {
      lower: 'international bank account number',
    },
  },
  invalidDate: {
    singular: {
      lower: 'invalid date',
    },
  },
  invalidOrderStatusOnRowX: {
    singular: {
      lower: 'invalid order status on row ${VALUE}',
    },
  },
  invalidOrderTypeOnRowX: {
    singular: {
      lower: 'invalid order type on row ${VALUE}',
    },
  },
  invalidQuantity: {
    singular: {
      lower: 'invalid quantity',
    },
  },
  invalidQuantityX: {
    singular: {
      lower: 'invalid quantity ${VALUE}',
    },
  },
  invalidTriggerEntityValueText: {
    singular: {
      lower: 'alert triggered with an invalid trigger value',
    },
  },
  invite: {
    singular: {
      lower: 'invite',
    },
  },
  inviteUser: {
    singular: {
      lower: 'invite user',
    },
  },
  isToday: {
    singular: {
      lower: 'is today',
    },
  },
  isTomorrow: {
    singular: {
      lower: 'is tomorrow',
    },
  },
  isYesterday: {
    singular: {
      lower: 'was yesterday',
    },
  },
  itemDoesNotHaveProperty: {
    singular: {
      lower: '${NAME} does not have a property called ${PROPERTY}',
    },
  },
  jobForStockLocationIdentifierIsNotOpen: {
    singular: {
      lower: 'job for ${VALUE} is not open!',
    },
  },
  jobForTransactionIsNotOpen: {
    singular: {
      lower: 'job for ${VALUE} is not open!',
    },
  },
  kg: {
    singular: {
      lower: 'kg',
    },
  },
  language: {
    singular: {
      lower: 'language',
    },
  },
  lastmonth: {
    singular: {
      lower: 'last month',
    },
  },
  lastName: {
    singular: {
      lower: 'last name',
    },
  },
  lastweek: {
    singular: {
      lower: 'last week',
    },
  },
  lb: {
    singular: {
      lower: 'lb',
    },
  },
  length: {
    singular: {
      lower: 'length',
    },
  },
  location: {
    singular: {
      lower: 'location',
    },
  },
  locations: {
    singular: {
      lower: 'locations',
    },
  },
  lotHasExpiredAlertText: {
    singular: {
      lower: 'Lot ${LOT_NUMBER} has expired on ${LOT_EXPIRY}',
    },
  },
  lotManaged: {
    singular: {
      lower: 'lot managed',
    },
  },
  lotNumber: {
    singular: {
      lower: 'lot number',
    },
  },
  lotTriggerValueText: {
    singular: {
      lower: 'days before expiry',
    },
  },
  lotWillExpireAlertText: {
    singular: {
      lower: 'Lot ${LOT_NUMBER} will expire in ${LOT_EXPIRY_DELTA_TEXT}',
    },
  },
  lotWithNumberAlreadyExistsForProductReference: {
    singular: {
      lower: "lot with number '${VALUE}' already exists for this product reference",
    },
  },
  lpn: {
    singular: {
      lower: 'lpn',
    },
  },
  lpnManaged: {
    singular: {
      lower: 'lpn managed',
    },
  },
  m: {
    singular: {
      lower: 'm',
    },
  },
  madeToOrderVariationNumber: {
    singular: {
      lower: 'made-to-order variation number',
    },
  },
  manager: {
    singular: {
      lower: 'manager',
    },
  },
  mandatory: {
    singular: {
      lower: 'mandatory',
    },
  },
  manufacturer: {
    singular: {
      lower: 'manufacturer',
    },
  },
  manufacturerCode: {
    singular: {
      lower: 'manufacturer code',
    },
  },
  manyAlertsHaveBeenTriggeredText: {
    singular: {
      lower: '${VALUE} alerts have been triggered!',
    },
  },
  map: {
    singular: {
      lower: 'map',
    },
  },
  mapTheFollowingFields: {
    singular: {
      lower: 'please map the following fields:',
    },
  },
  mapUnavailable: {
    singular: {
      lower: 'map unavailable',
    },
  },
  mass: {
    singular: {
      lower: 'mass',
    },
  },
  max: {
    singular: {
      lower: 'max',
    },
  },
  missingMandatoryCustomFieldXOnRowY: {
    singular: {
      lower: 'Missing mandatory custom field ${FIELD_NAME} on row ${ROW_INDEX}',
    },
  },
  missingRequiredFieldMapping: {
    singular: {
      lower: '${FIELD} mapping is needed for a ${TYPE} upload config',
    },
  },
  missingValueForRequiredCustomFieldX: {
    singular: {
      lower: 'missing value for required custom field ${VALUE}',
    },
  },
  mm: {
    singular: {
      lower: 'mm',
    },
  },
  mobileStockLocation: {
    singular: {
      lower: 'mobile stock location',
    },
  },
  mustSelectAtLeastOneStockLocationRoleForInitialCount: {
    singular: {
      lower: 'must select at least one stock location role for initial count',
    },
  },
  mustSelectAtLeastOneStockLocationRoleForRecountIfDiscrepancyThresholdIsHigherThan0: {
    singular: {
      lower: 'must select at least one stock location role for recount if the discrepancy threshold is higher than 0',
    },
  },
  name: {
    singular: {
      lower: 'name',
    },
  },
  natoStockNumber: {
    singular: {
      lower: 'nato stock number',
    },
  },
  netWeight: {
    singular: {
      lower: 'net weight',
    },
  },
  networkConnection: {
    singular: {
      lower: 'network connection',
    },
  },
  networkConnectionOccurrence: {
    singular: {
      lower: 'on what network connection did the incident occur?',
    },
  },
  new: {
    singular: {
      lower: 'new',
    },
  },
  newComment: {
    singular: {
      lower: 'new comment',
    },
  },
  newPassword: {
    singular: {
      lower: 'new password',
    },
  },
  next: {
    singular: {
      lower: 'next',
    },
  },
  no: {
    singular: {
      lower: 'no',
    },
  },
  noAccountYetQuestion: {
    singular: {
      lower: 'no account yet?',
    },
  },
  noAddress: {
    singular: {
      lower: 'no address found',
    },
  },
  noCompaniesFound: {
    singular: {
      lower: 'no companies found!',
    },
  },
  noItemsFound: {
    singular: {
      lower: 'no items found',
    },
  },
  noJobsFound: {
    singular: {
      lower: 'no jobs found!',
    },
  },
  noLinesFoundForPurchaseOrder: {
    singular: {
      lower: 'no lines found for purchase order!',
    },
  },
  noLocationsFound: {
    singular: {
      lower: 'no locations found!',
    },
  },
  noProductReferenceFound: {
    singular: {
      lower: 'no product reference found!',
    },
  },
  noPurchaseOrdersFound: {
    singular: {
      lower: 'no purchase orders found!',
    },
  },
  noSettingsFoundForBusinessCentral: {
    singular: {
      lower: 'no settings found for business central',
    },
  },
  noStockLocationIdOrStockLocationMappingProvided: {
    singular: {
      lower: 'no stock location id or stock location mapping provided.',
    },
  },
  noStockLocationsGiven: {
    singular: {
      lower: 'no stock locations were given.',
    },
  },
  noValidTaskInputFound: {
    singular: {
      lower: 'no valid task input found!',
    },
  },
  nrOfDigits: {
    singular: {
      lower: 'number of digits',
    },
  },
  numberOfCounts: {
    singular: {
      lower: 'number of counts',
    },
  },
  numberOfOrders: {
    singular: {
      lower: 'number of orders',
    },
  },
  numberOfTransactions: {
    singular: {
      lower: 'number of transactions',
    },
  },
  oldPassword: {
    singular: {
      lower: 'old password',
    },
  },
  only1CustomFieldPerEntitySubtypeSupported: {
    singular: {
      lower: 'currently we only support 1 custom field per entity sub type on product transactions!',
    },
  },
  open: {
    singular: {
      lower: 'open',
    },
  },
  operatingSystemVersion: {
    singular: {
      lower: 'operating system version',
    },
  },
  optionalPhoneNumber: {
    singular: {
      lower: 'phone number (optional)',
    },
  },
  or: {
    singular: {
      lower: 'or',
    },
  },
  order: {
    plural: {
      lower: 'orders',
    },
    singular: {
      lower: 'order',
    },
  },
  orderEditProductsOnInbound: {
    singular: {
      lower: 'edit products on inbound',
    },
  },
  orderEditProductsOnOutbound: {
    singular: {
      lower: 'edit products on outbound',
    },
  },
  orderMetrics: {
    singular: {
      lower: 'order metrics',
    },
  },
  orderNumbers: {
    singular: {
      lower: 'order numbers',
    },
  },
  orderOrderFeatureToggle: {
    singular: {
      lower: 'orders',
    },
  },
  orderProductSelectionFirst: {
    singular: {
      lower: 'product selection first',
    },
  },
  orderReportConfigurationWithNameAlreadyExists: {
    singular: {
      lower: "order report configuration with name '${VALUE}' already exists",
    },
  },
  orderSettings: {
    singular: {
      lower: 'order settings',
    },
  },
  orderStatus: {
    singular: {
      lower: 'order status',
    },
  },
  orderType: {
    singular: {
      lower: 'type',
    },
  },
  orderWithNumberXMissingMandatoryCustomFieldY: {
    singular: {
      lower: 'Order with number ${ORDER_NUMBER} is missing the mandatory custom field ${FIELD_NAME}',
    },
  },
  orderWithNumberXMissingStockLocationNameOrIdentifier: {
    singular: {
      lower: 'order with number ${VALUE} is missing a stock location name or identifier',
    },
  },
  orderWithNumberXMissingType: {
    singular: {
      lower: 'order with number ${VALUE} is missing a type',
    },
  },
  originalEquipmentManufacturer: {
    singular: {
      lower: 'oem',
    },
  },
  originalEquipmentManufacturerCode: {
    singular: {
      lower: 'original equipment manufacturer code',
    },
  },
  originalEquipmentManufacturerCodePlacehoder: {
    singular: {
      lower: 'original equipment manufacturer (oem,',
    },
  },
  outbound: {
    singular: {
      lower: 'outbound',
    },
  },
  outboundAllowed: {
    singular: {
      lower: 'outbound allowed',
    },
  },
  outboundOrder: {
    singular: {
      lower: 'outbound order',
    },
  },
  overdue: {
    singular: {
      lower: 'overdue',
    },
  },
  overview: {
    singular: {
      lower: 'overview',
    },
  },
  oz: {
    singular: {
      lower: 'oz',
    },
  },
  pack: {
    singular: {
      lower: 'pack',
    },
  },
  packagingDate: {
    singular: {
      lower: 'packaging date',
    },
  },
  password: {
    singular: {
      lower: 'password',
    },
  },
  passwordRequirementInfo: {
    singular: {
      lower:
        'passwords must be at least eight characters long and be made up of three out of these four items: lowercase letters, uppercase letters, numbers and symbols',
    },
  },
  paymentSlipReferenceNumber: {
    singular: {
      lower: 'payment slip reference number',
    },
  },
  pleaseDefineDefaultBin: {
    singular: {
      lower: 'please define a default bin',
    },
  },
  pleaseSelectConfigurationsForProductReferenceExport: {
    singular: {
      lower: 'please select the configuration(s) you would like to use to export the selected product references',
    },
  },
  pmdQuantityAlertText: {
    singular: {
      lower:
        'Quantity for ${PRODUCT_NAME} dropped below ${TRIGGER_QUANTITY} ${STOCK_LOCATION_AGGREGATE_TEXT} (${REORDER_RULE_NAME})',
    },
  },
  postalCode: {
    singular: {
      lower: 'postal code',
    },
  },
  prefix: {
    singular: {
      lower: 'prefix',
    },
  },
  pricePerUnitOfMeasure: {
    singular: {
      lower: 'price per unit of measure',
    },
  },
  primarilyBenifitFeature: {
    singular: {
      lower: 'who would primarily benefit from this feature?',
    },
  },
  primaryColor: {
    singular: {
      lower: 'primary color',
    },
  },
  priority: {
    singular: {
      lower: 'priority',
    },
  },
  process: {
    singular: {
      lower: 'process',
    },
  },
  processed: {
    singular: {
      lower: 'processed',
    },
  },
  processedAt: {
    singular: {
      lower: 'processed at',
    },
  },
  processedBy: {
    singular: {
      lower: 'processed by',
    },
  },
  processedQuantityExceedsExpectedQuantity: {
    singular: {
      lower: 'processed quantity exceeds expected quantity!',
    },
  },
  processQuantity: {
    singular: {
      lower: 'quantity to process',
    },
  },
  processTransaction: {
    singular: {
      lower: 'process transaction',
    },
  },
  product: {
    plural: {
      lower: 'products',
    },
    singular: {
      lower: 'product',
    },
  },
  productAlreadyExists: {
    singular: {
      lower: 'product already exists',
    },
  },
  productImage: {
    singular: {
      lower: 'product image',
    },
  },
  productImportExistingConfigurationPlaceholder: {
    singular: {
      lower:
        'we did not find any configuration for this file, we did however find the following existing configurations',
    },
  },
  productionDate: {
    singular: {
      lower: 'manufacturing date',
    },
  },
  productMasterData: {
    singular: {
      lower: 'product master data',
    },
  },
  productMasterDataNotDefined: {
    singular: {
      lower: 'product master data not defined',
    },
  },
  productMetrics: {
    singular: {
      lower: 'product metrics',
    },
  },
  productName: {
    singular: {
      lower: 'product name',
    },
  },
  productNumber: {
    singular: {
      lower: 'product number',
    },
  },
  productReferenceWithNumberXNotFound: {
    singular: {
      lower: 'product reference with product number ${VALUE} not found',
    },
  },
  productReferenceWithProductNumberAlreadyExists: {
    singular: {
      lower: "product reference with product number '${VALUE}' already exists",
    },
  },
  productReportConfigurationWithNameAlreadyExists: {
    singular: {
      lower: "product report configuration with name '${VALUE}' already exists",
    },
  },
  productsGS1Toggle: {
    singular: {
      lower: 'gs1 barcodes',
    },
  },
  productsProductToggle: {
    singular: {
      lower: 'products',
    },
  },
  productTransactionAlertCustomFieldNotFoundText: {
    singular: {
      lower: 'Triggered product transaction for product ${PRODUCT_NAME} ${PARENT_TEXT} with unknown custom field value',
    },
  },
  productTransactionAlertOrderText: {
    singular: {
      lower: 'on ${ORDER_TYPE} order ${ORDER_NUMBER}',
    },
  },
  productTransactionAlertUnknownOrderText: {
    singular: {
      lower: 'on unknown order',
    },
  },
  productTransactionDueDateAlertText: {
    singular: {
      lower: '${FIELD_NAME} for product ${PRODUCT_NAME} ${PARENT_TEXT} ${DUE_DATE_DELTA_TEXT}',
    },
  },
  productTransactionDueDateTriggerText: {
    singular: {
      lower: 'days before due date',
    },
  },
  productTransactionFieldUpdatedAlertText: {
    singular: {
      lower: 'Field ${FIELD_NAME} for product ${PRODUCT_NAME} ${PARENT_TEXT} has been updated to ${UPDATED_VALUE}',
    },
  },
  productTransactionReportConfigurationWithNameAlreadyExists: {
    singular: {
      lower: "product transaction report configuration with name '${VALUE}' already exists",
    },
  },
  productTransactionsMobile: {
    singular: {
      lower: 'mobile',
    },
  },
  productTransactionsProductTransactionFeatureToggle: {
    singular: {
      lower: 'product transactions',
    },
  },
  productTransactionsQuickActions: {
    singular: {
      lower: 'quick actions',
    },
  },
  productTransactionsWeb: {
    singular: {
      lower: 'web',
    },
  },
  productTransactionTriggerRequiresAtLeastOneCustomField: {
    singular: {
      lower: 'product transaction trigger currently requires at least one custom field.',
    },
  },
  productWithLPNAlreadyExists: {
    singular: {
      lower: "product with lpn '${VALUE}' already exists",
    },
  },
  productWIthLPNOnRowAlreadyExists: {
    singular: {
      lower: 'Product with LPN ${LPN} on row ${ROW_INDEX} already exists',
    },
  },
  productWithLPNOnRowAlreadyExistsInOtherStockLocation: {
    singular: {
      lower: 'Product with LPN ${LPN} on row ${ROW_INDEX} already exists in another stock location',
    },
  },
  productWithSerialAlreadyExists: {
    singular: {
      lower: "product with serial '${VALUE}' already exists",
    },
  },
  productWithSerialOnRowAlreadyExists: {
    singular: {
      lower: 'Product with serial ${SERIAL} on row ${ROW_INDEX} already exists',
    },
  },
  profile: {
    singular: {
      lower: 'profile',
    },
  },
  purchasedFromGlobalLocationNumber: {
    singular: {
      lower: 'purchased from global location number',
    },
  },
  purchaseOrderNumber: {
    singular: {
      lower: 'purchase order number',
    },
  },
  purchasePrice: {
    singular: {
      lower: 'purchase price',
    },
  },
  pushNotification: {
    singular: {
      lower: 'push notification',
    },
  },
  quantity: {
    singular: {
      lower: 'quantity',
    },
  },
  raiseOnBehalfOf: {
    singular: {
      lower: 'raise this request on behalf of',
    },
  },
  reassignUsers: {
    singular: {
      lower: '(re)assign users',
    },
  },
  recount: {
    singular: {
      lower: 'recount',
    },
  },
  referenceData: {
    singular: {
      lower: 'reference data',
    },
  },
  referenceToSourceEntity: {
    singular: {
      lower: 'reference to source entity',
    },
  },
  refreshCompanyCache: {
    singular: {
      lower: 'refresh company cache',
    },
  },
  region: {
    singular: {
      lower: 'region',
    },
  },
  released: {
    singular: {
      lower: 'released',
    },
  },
  reorderPoint: {
    singular: {
      lower: 'reorder point',
    },
  },
  reorderRuleForProductStockLocationAndAggregateTypeAlreadyExists: {
    singular: {
      lower: 'reorder rule for this product, stock location & aggregate type already exists',
    },
  },
  reorderUserRuleForProductAndStockLocationAlreadyExists: {
    singular: {
      lower: 'reorder user rule for this product & stock location already exists',
    },
  },
  reorderUsers: {
    singular: {
      lower: 'reorder users',
    },
  },
  replenish: {
    singular: {
      lower: 'replenish',
    },
  },
  reportIncident: {
    singular: {
      lower: 'report incident',
    },
  },
  reporting: {
    singular: {
      lower: 'reporting',
    },
  },
  reportOnBehalfOf: {
    singular: {
      lower: 'report on behalf of',
    },
  },
  requestNewCode: {
    singular: {
      lower: 'request new code',
    },
  },
  requestType: {
    singular: {
      lower: 'request type',
    },
  },
  reset: {
    singular: {
      lower: 'reset',
    },
  },
  resetToDefault: {
    singular: {
      lower: 'reset to default',
    },
  },
  role: {
    plural: {
      lower: 'roles',
    },
    singular: {
      lower: 'role',
    },
  },
  rolesForInitialCount: {
    singular: {
      lower: 'which roles are allowed to execute initial tasks?',
    },
  },
  rolesForRecount: {
    singular: {
      lower: 'which roles are allowed to execute recount tasks?',
    },
  },
  rollback: {
    singular: {
      lower: 'rollbacked',
    },
  },
  rollProducts: {
    singular: {
      lower: 'roll products - width, length, core diameter, direction, splices',
    },
  },
  routingCode: {
    singular: {
      lower: 'routing code',
    },
  },
  rowMustContainOrderNumber: {
    singular: {
      lower: 'row must contain order number',
    },
  },
  save: {
    singular: {
      lower: 'save',
    },
  },
  scan: {
    singular: {
      lower: 'scan',
    },
  },
  scanRules: {
    singular: {
      lower: 'scan rules',
    },
  },
  secondarySerialNumber: {
    singular: {
      lower: 'secondary serial number',
    },
  },
  selectAll: {
    singular: {
      lower: 'select all',
    },
  },
  selectAnImage: {
    singular: {
      lower: 'select an image',
    },
  },
  selectAProduct: {
    singular: {
      lower: 'select a product',
    },
  },
  selectAssignedOrderUsers: {
    singular: {
      lower: 'assign (optional) users, that are supposed to pick/put the order',
    },
  },
  selectBins: {
    singular: {
      lower: 'select bins',
    },
  },
  selectBinsOrProducts: {
    singular: {
      lower: 'select bins or products',
    },
  },
  selectBinStatuses: {
    singular: {
      lower: 'select bin statuses',
    },
  },
  selectBinStatusesThatYouWantToExclude: {
    singular: {
      lower: 'select bin statuses that you want to exclude from the reorder rule',
    },
  },
  selectBinsThatYouWantToExclude: {
    singular: {
      lower: 'select bins that you want to exclude from the reorder rule',
    },
  },
  selectCompanyRolesThatShouldReceiveNotification: {
    singular: {
      lower: 'select company roles that should receive a notification',
    },
  },
  selectCsvUploadConfiguration: {
    singular: {
      lower: 'please select an upload configuration:',
    },
  },
  selectReportReceivers: {
    singular: {
      lower: 'select additional users that should receive a report',
    },
  },
  selectStockLocation: {
    singular: {
      lower: 'select stock location',
    },
  },
  selectStockLocationRole: {
    singular: {
      lower: 'select stock location role',
    },
  },
  selectStockLocationRoles: {
    singular: {
      lower: 'select the stock locations you want the user to access',
    },
  },
  selectStockLocationRolesThatShouldReceiveNotification: {
    singular: {
      lower: 'select stock location roles that should receive a notification',
    },
  },
  selectTaskAssignees: {
    singular: {
      lower: 'select the users that should execute this task',
    },
  },
  selectUsersThatShouldReceiveANotification: {
    singular: {
      lower: 'select specific users that should receive a notification',
    },
  },
  sellingPrice: {
    singular: {
      lower: 'selling price',
    },
  },
  send: {
    singular: {
      lower: 'send',
    },
  },
  sendMailOnAssigned: {
    singular: {
      lower: 'send mail on assigned',
    },
  },
  sendPushNotificationToAssignees: {
    singular: {
      lower: 'send push notification to assigned users',
    },
  },
  serial: {
    singular: {
      lower: 'serial',
    },
  },
  serialManaged: {
    singular: {
      lower: 'serial managed',
    },
  },
  serialNumber: {
    singular: {
      lower: 'serial number',
    },
  },
  serialShippingContainerCode: {
    singular: {
      lower: 'serial shipping container code',
    },
  },
  service: {
    singular: {
      lower: 'service',
    },
  },
  sessionStorageToggle: {
    singular: {
      lower: 'session storage',
    },
  },
  set: {
    singular: {
      lower: 'set',
    },
  },
  setCompanyRole: {
    singular: {
      lower: 'set the company role of the user',
    },
  },
  setDueDate: {
    singular: {
      lower: 'set a due date',
    },
  },
  settings: {
    singular: {
      lower: 'settings',
    },
  },
  sevenDays: {
    singular: {
      lower: '7 days',
    },
  },
  shipForDeliverForForwardToGlobalLocationNumber: {
    singular: {
      lower: 'ship for – deliver for – forward to global location number',
    },
  },
  shipToDeliverISOcountryCode: {
    singular: {
      lower: 'ship to – deliver to postal code with three-digit iso country code',
    },
  },
  shipToDeliverToGlobalLocationNumber: {
    singular: {
      lower: 'ship to – deliver to global location number',
    },
  },
  shipToDeliverToPostalCode: {
    singular: {
      lower: 'ship to – deliver to postal code within a single postal authority',
    },
  },
  signIn: {
    singular: {
      lower: 'sign in',
    },
  },
  signOut: {
    singular: {
      lower: 'sign out',
    },
  },
  signUp: {
    singular: {
      lower: 'sign up',
    },
  },
  size: {
    singular: {
      lower: 'size',
    },
  },
  softwareVersion: {
    singular: {
      lower: 'software version',
    },
  },
  sourceBin: {
    singular: {
      lower: 'source bin',
    },
  },
  sourceNumber: {
    singular: {
      lower: 'source bin',
    },
  },
  sourceStockLocation: {
    singular: {
      lower: 'source stock location',
    },
  },
  splitByOrder: {
    singular: {
      lower: 'split by order',
    },
  },
  status: {
    singular: {
      lower: 'status',
    },
  },
  stock: {
    singular: {
      lower: 'stock',
    },
  },
  stockExportConfigurationSelectPlaceholder: {
    singular: {
      lower: 'please select the configuration(s) you would like to use to export the stock',
    },
  },
  stockLocation: {
    singular: {
      lower: 'stock location',
    },
  },
  stockLocationAggregateTypeAllOfText: {
    singular: {
      lower: 'across ${VALUE}',
    },
  },
  stockLocationAggregateTypeAllText: {
    singular: {
      lower: 'across all stock locations',
    },
  },
  stockLocationAggregateTypeAnyOfText: {
    singular: {
      lower: 'in ${VALUE}',
    },
  },
  stockLocationAggregateTypeAnyText: {
    singular: {
      lower: 'in ${VALUE}',
    },
  },
  stockLocationCount: {
    singular: {
      lower: '${VALUE} stock locations',
    },
  },
  stockLocationIdentifier: {
    singular: {
      lower: 'stock location identifier',
    },
  },
  stockLocationLevel: {
    singular: {
      lower: 'stock location level',
    },
  },
  stockLocationMustBeDefinedToUploadBins: {
    singular: {
      lower: 'stock location must be defined to upload bins',
    },
  },
  stockLocationName: {
    singular: {
      lower: 'stock location name',
    },
  },
  stockLocationRolesForInitialCountCannotBeStockLocationViewer: {
    singular: {
      lower: 'the stock location roles for inital count cannot be stock_location_viewer!',
    },
  },
  stockLocationWithIdentifierOnRowDoesNotExist: {
    singular: {
      lower: 'Stock Location with identifier ${IDENTIFIER} on row ${ROW_INDEX} does not exist.',
    },
  },
  stockLocationWithIdentifierXNotFound: {
    singular: {
      lower: 'stock location with identifier ${VALUE}',
    },
  },
  stockLocationWithNameAlreadyExists: {
    singular: {
      lower: "stock location with name '${VALUE}' already exists",
    },
  },
  stockLocationWithNameXNotFound: {
    singular: {
      lower: 'stock location with name ${VALUE} not found',
    },
  },
  stockTriggers: {
    singular: {
      lower: 'stock triggers',
    },
  },
  submit: {
    singular: {
      lower: 'submit',
    },
  },
  submitIdea: {
    singular: {
      lower: 'submit idea',
    },
  },
  suffix: {
    singular: {
      lower: 'suffix',
    },
  },
  superUserWithEmailAlreadyExists: {
    singular: {
      lower: "super user with email '${VALUE}' already exists",
    },
  },
  superUserWithUserIdAlreadyExists: {
    singular: {
      lower: "super user with userid '${VALUE}' already exists",
    },
  },
  supervisor: {
    singular: {
      lower: 'supervisor',
    },
  },
  supplier: {
    plural: {
      lower: 'suppliers',
    },
    singular: {
      lower: 'supplier',
    },
  },
  support: {
    singular: {
      lower: 'support',
    },
  },
  supportedFilesCanBeImagesVideoOrDocuments: {
    singular: {
      lower: 'supported files can be images, videos or documents',
    },
  },
  syncAddress: {
    singular: {
      lower: 'sync',
    },
  },
  syncItems: {
    singular: {
      lower: 'synchronize items',
    },
  },
  syncJobs: {
    singular: {
      lower: 'synchronize jobs',
    },
  },
  syncJournals: {
    singular: {
      lower: 'synchronize journals',
    },
  },
  syncLocations: {
    singular: {
      lower: 'synchronize locations',
    },
  },
  syncPurchaseOrders: {
    singular: {
      lower: 'synchronize purchase orders',
    },
  },
  tableSettings: {
    singular: {
      lower: 'table settings',
    },
  },
  tapToViewThemInAppNotificationBody: {
    singular: {
      lower: 'tap here to view them.',
    },
  },
  targetUsers: {
    singular: {
      lower: 'target users',
    },
  },
  taskDueDateAlertText: {
    singular: {
      lower: 'The due date for a ${TASK_TYPE} in ${STOCK_LOCATION} ${DUE_DATE_DELTA_TEXT}',
    },
  },
  taskDueDateTriggerValueText: {
    singular: {
      lower: 'days before due date',
    },
  },
  taskMetrics: {
    singular: {
      lower: 'task metrics',
    },
  },
  taskNumbers: {
    singular: {
      lower: 'task numbers',
    },
  },
  taskReportConfigurationWithNameAlreadyExists: {
    singular: {
      lower: "task report configuration with name '${VALUE}' already exists",
    },
  },
  tasks: {
    singular: {
      lower: 'tasks',
    },
  },
  taskSettingsAlreadyExistsForThisEntity: {
    singular: {
      lower: 'task settings for this entity already exist',
    },
  },
  tasksStockUpdateFeatureToggle: {
    singular: {
      lower: 'allow stock update after finishing task',
    },
  },
  tasksTaskFeatureToggle: {
    singular: {
      lower: 'tasks',
    },
  },
  taskStatus: {
    singular: {
      lower: 'task status',
    },
  },
  taskType: {
    singular: {
      lower: 'task type',
    },
  },
  tellUsMore: {
    singular: {
      lower: 'tell us more...',
    },
  },
  tellUsMoreFeature: {
    singular: {
      lower: 'tell us all about the feature',
    },
  },
  tellUsMoreIncident: {
    singular: {
      lower: 'tell us all about the incident',
    },
  },
  tenantId: {
    singular: {
      lower: 'tenant id',
    },
  },
  theme: {
    singular: {
      lower: 'theme',
    },
  },
  thirtyDays: {
    singular: {
      lower: '30 days',
    },
  },
  thismonth: {
    singular: {
      lower: 'this month',
    },
  },
  thisweek: {
    singular: {
      lower: 'this week',
    },
  },
  threeMonths: {
    singular: {
      lower: '3 months',
    },
  },
  today: {
    singular: {
      lower: 'today',
    },
  },
  tokenAlreadyExists: {
    singular: {
      lower: 'token already exists',
    },
  },
  tokenForUserAlreadyExists: {
    singular: {
      lower: 'a notification token for this user already exists',
    },
  },
  total: {
    singular: {
      lower: 'total',
    },
  },
  transactionInfo: {
    singular: {
      lower: 'product transaction info',
    },
  },
  transactions: {
    singular: {
      lower: 'transactions',
    },
  },
  triggerCondition: {
    singular: {
      lower: 'trigger condition',
    },
  },
  triggerEntityIds: {
    singular: {
      lower: 'for the following items',
    },
  },
  triggerEntityType: {
    singular: {
      lower: 'trigger entity type',
    },
  },
  triggerInvalidAmountOfCustomFieldsProvided: {
    singular: {
      lower: 'An invalid amount of custom fields was provided. Provided: ${COUNT}, Valid: ${VALID_COUNT}',
    },
  },
  triggers: {
    singular: {
      lower: 'triggers',
    },
  },
  triggerTypes: {
    singular: {
      lower: 'trigger types',
    },
  },
  triggerTypeText: {
    singular: {
      lower: 'i would like to trigger an',
    },
  },
  triggerUserText: {
    singular: {
      lower: 'for the following users',
    },
  },
  triggerValueText: {
    singular: {
      lower: 'i would like to use ',
    },
  },
  twelveMonths: {
    singular: {
      lower: '12 months',
    },
  },
  type: {
    singular: {
      lower: 'type',
    },
  },
  unableToAddLPNToProductIfProductMasterDataNotLPNManaged: {
    singular: {
      lower: 'unable to add lpn on product, if product master data is not lpn managed',
    },
  },
  unableToAddProductWithQuantityOnRow: {
    singular: {
      lower: 'unable to add product without quantity on row ${VALUE}',
    },
  },
  unableToAddSerialOnProductIfProductMasterDataNotSerialManaged: {
    singular: {
      lower: 'unable to add serial on product, if product master data is not serial managed',
    },
  },
  unableToAddUserWithInvalidCompanyRoleOnRowX: {
    singular: {
      lower:
        'Unable to add User with invalid Company Role on row ${ROW_INDEX}, got: ${COMPANY_ROLE}, valid: ${VALID_VALUES}',
    },
  },
  unableToAddUserWithoutEmailOnRowX: {
    singular: {
      lower: 'unable to add user without email on row ${VALUE}',
    },
  },
  unableToCompleteOrderWithPendingItems: {
    singular: {
      lower: 'unable to complete order with pending transactions',
    },
  },
  unableToCompleteTaskThatHasNotBeenStarted: {
    singular: {
      lower: 'unable to complete task that has not been started',
    },
  },
  unableToCompleteTaskThatIsAlreadyCompleted: {
    singular: {
      lower: 'unable to complete task that is already completed',
    },
  },
  unableToCompleteTaskThatIsAlreadyProcessed: {
    singular: {
      lower: 'unable to complete task that is already processed',
    },
  },
  unableToCompleteTaskWhenThereAreProductsLeftToCount: {
    singular: {
      lower: 'unable to complete task when there are products left to count',
    },
  },
  unableToCountTaskProductsWhenTaskHasNotBeenStarted: {
    singular: {
      lower: 'unable to count task products when task (id: ${VALUE}) has not been started',
    },
  },
  unableToCreateAllEnties: {
    singular: {
      lower: 'Unable to create all entities (only ${ROW_COUNT} out of ${ENTITY_COUNT})',
    },
  },
  unableToCreateCompanyForDemo: {
    singular: {
      lower: 'unable to create company for demo',
    },
  },
  unableToCreateConfiguration: {
    singular: {
      lower: 'unable to create configuration',
    },
  },
  unableToCreateEntitiesForMultipleCompanies: {
    singular: {
      lower: 'unable to create entities for multiple companies using createentitiescommand',
    },
  },
  unableToCreateIntegrationSettings: {
    singular: {
      lower: 'unable to create integration settings',
    },
  },
  unableToCreateLotForNonLotManagedProductReferenceData: {
    singular: {
      lower: 'unable to create lot for product reference data that is not lot managed',
    },
  },
  unableToCreateTaskWithoutAssignedUsers: {
    singular: {
      lower: 'unable to create task without assigned users',
    },
  },
  unableToCreateTaskWithoutDefiningProductReferenceDataBinsLotsOrContainers: {
    singular: {
      lower: 'unable to create task without defining product reference data, bins, lots or containers',
    },
  },
  unableToCreateTaskWithoutUsersAssignedForRecount: {
    singular: {
      lower: 'unable to create task without users assigned for recount',
    },
  },
  unableToDeleteAllEntities: {
    singular: {
      lower: 'Unable to delete all entities (only ${ROW_COUNT} out of ${ENTITY_COUNT})',
    },
  },
  unableToExtractCustomerInformationFromPreviousJobs: {
    singular: {
      lower: 'unable to extract customer information from previous jobs, please create the job in business central',
    },
  },
  unableToFindAvailableBinStatus: {
    singular: {
      lower: 'unable to find available bin status',
    },
  },
  unableToFindBin: {
    singular: {
      lower: 'unable to find bin ${VALUE}',
    },
  },
  unableToFindCodeForUser: {
    singular: {
      lower: 'unable to find code for user ${VALUE}',
    },
  },
  unableToFindCostCenter: {
    singular: {
      lower: 'unable to find cost center',
    },
  },
  unableToFindDefaultBin: {
    singular: {
      lower: 'unable to find default bin ${VALUE}',
    },
  },
  unableToFindDefaultStockLocation: {
    singular: {
      lower: 'unable to find default stock location',
    },
  },
  unableToFindDefaultStockLocationPurchaseOrder: {
    singular: {
      lower: 'unable to find default stock location ${VALUE}',
    },
  },
  unableToFindDueDateCustomField: {
    singular: {
      lower: 'unable to find due date custom field',
    },
  },
  unableToFindExternalOrderId: {
    singular: {
      lower: 'unable to find external order id',
    },
  },
  unableToFindJobForStockLocationIdentifier: {
    singular: {
      lower: 'unable to find job for ${VALUE}',
    },
  },
  unableToFindJobForTransaction: {
    singular: {
      lower: 'unable to find job for transaction',
    },
  },
  unableToFindJournalStockLocation: {
    singular: {
      lower: 'unable to find journal stock location',
    },
  },
  unableToFindJournalStockLocationXYZ: {
    singular: {
      lower: 'unable to find journal stock location ${VALUE}',
    },
  },
  unableToFindOrderForLineId: {
    singular: {
      lower: 'unable to find order for ${VALUE}',
    },
  },
  unableToFindPlant: {
    singular: {
      lower: 'unable to find plant',
    },
  },
  unableToFindProductMasterDataForLine: {
    singular: {
      lower: 'unable to find product master data for ${VALUE}',
    },
  },
  unableToFindTaskForCounting: {
    singular: {
      lower: 'unable to find task ${VALUE} for counting',
    },
  },
  unableToFindTaskSettings: {
    singular: {
      lower: 'unable to find task settings',
    },
  },
  unableToFindTaskWithId: {
    singular: {
      lower: 'unable to find task with id ${VALUE}',
    },
  },
  unableToFindUploadConfigWithId: {
    singular: {
      lower: 'unable to find upload config with id ${VALUE}',
    },
  },
  unableToFindUser: {
    singular: {
      lower: 'unable to find user ${VALUE}',
    },
  },
  unableToFindUserWithEmail: {
    singular: {
      lower: 'unable to find user with email: ${VALUE}',
    },
  },
  unableToGetAccessTokenFromBusinessCentral: {
    singular: {
      lower: 'unable to get access token from business central',
    },
  },
  unableToGetEnvironmentFromSettings: {
    singular: {
      lower: 'unable to get environment from settings',
    },
  },
  unableToGetTenantIdAndOrEnvironmentFromSettings: {
    singular: {
      lower: 'unable to get tenantid and/or environment from settings',
    },
  },
  unableToGetTenantIdOrEnvironmentFromSettings: {
    singular: {
      lower: 'unable to get tenantid or environment from settings',
    },
  },
  unableToParseDateForLotExpiry: {
    singular: {
      lower: 'unable to parse date ${VALUE} for lot expiry',
    },
  },
  unableToParseHeaders: {
    singular: {
      lower: 'unable to parse headers',
    },
  },
  unableToRemoveBinStatusWhenBinsForItExist: {
    singular: {
      lower: 'unable to remove bin status when bins exist for that bin status',
    },
  },
  unableToRemoveLastAdministratorFromCompany: {
    singular: {
      lower: 'unable to remove last administrator from company',
    },
  },
  unableToRemoveLotWhenProductsExistForThatLot: {
    singular: {
      lower: 'unable to remove lot when products exist for that lot',
    },
  },
  unableToRemoveProductReferenceDataWhenProductsExist: {
    singular: {
      lower: 'unable to remove product reference data when products exist for that product reference data',
    },
  },
  unableToStartTaskThatIsAlreadyCompleted: {
    singular: {
      lower: 'unable to start task that is already completed',
    },
  },
  unableToStartTaskThatIsAlreadyInProgress: {
    singular: {
      lower: 'unable to start task that is already in progress',
    },
  },
  unableToStartTaskThatIsAlreadyProcessed: {
    singular: {
      lower: 'unable to start task that is already processed',
    },
  },
  unableToUpdateAllEntities: {
    singular: {
      lower: 'Unable to update all entities (only ${ROW_COUNT} out of ${ENTITY_COUNT})',
    },
  },
  unableToUpdateEntitiesForMultipleCompanies: {
    singular: {
      lower: 'unable to update entities for multiple companies using updateentitiescommand',
    },
  },
  unableToUpdateStockFeatureToggleDisabled: {
    singular: {
      lower: "unable to update stock because this feature is disabled in your company's settings",
    },
  },
  unableToUpdateStockWhenTaskIsNotComplete: {
    singular: {
      lower: 'unable to update stock when the task is not complete',
    },
  },
  unableToUpdateTaskWithoutAssignedUsers: {
    singular: {
      lower: 'unable to update task without assigned users',
    },
  },
  unableToUploadCsvWithoutConfig: {
    singular: {
      lower: 'unable to upload csv without config',
    },
  },
  unableToUserConfigForUploadedCsv: {
    singular: {
      lower: 'unable to use this config for the uploaded .csv file',
    },
  },
  unauthorizedUser: {
    singular: {
      lower: 'unauthorized user',
    },
  },
  uneceMeatCarcassesAndCutsClassification: {
    singular: {
      lower: 'un/ece meat carcasses and cuts classification',
    },
  },
  unit: {
    singular: {
      lower: 'unit',
    },
  },
  unitOfMeasure: {
    singular: {
      lower: 'unit of measure',
    },
  },
  unknown: {
    singular: {
      lower: 'unknown',
    },
  },
  unknownBin: {
    singular: {
      lower: 'unknown bin',
    },
  },
  unknownLot: {
    singular: {
      lower: 'unknown lot',
    },
  },
  unknownProduct: {
    singular: {
      lower: 'unknown product',
    },
  },
  unknownProductNumber: {
    singular: {
      lower: 'unknown product number',
    },
  },
  unknownProductReference: {
    singular: {
      lower: 'unknown product reference',
    },
  },
  unknownStockLocation: {
    singular: {
      lower: 'unknown stock location',
    },
  },
  unknownUser: {
    singular: {
      lower: 'unknown user',
    },
  },
  update: {
    singular: {
      lower: 'update',
    },
  },
  updateBarcodeConfiguration: {
    singular: {
      lower: 'update barcode configuration',
    },
  },
  updateBin: {
    singular: {
      lower: 'update bin',
    },
  },
  updateBinStatus: {
    singular: {
      lower: 'update bin status',
    },
  },
  updateCustomField: {
    singular: {
      lower: 'update custom field',
    },
  },
  updatedAt: {
    singular: {
      lower: 'updated at',
    },
  },
  updatedBy: {
    singular: {
      lower: 'updated by',
    },
  },
  updateProfile: {
    singular: {
      lower: 'update profile',
    },
  },
  updateScanRule: {
    singular: {
      lower: 'update scan rule',
    },
  },
  updateShippingLocation: {
    singular: {
      lower: 'update shipping location',
    },
  },
  updateStock: {
    singular: {
      lower: 'update stock',
    },
  },
  updateStockLocationRole: {
    singular: {
      lower: 'update stock location role',
    },
  },
  updateSuperUser: {
    singular: {
      lower: 'update super user',
    },
  },
  updateUserDetails: {
    singular: {
      lower: 'please update your user details',
    },
  },
  updatingYourProfileWillSignYouOut: {
    singular: {
      lower: 'updating your profile will sign you out',
    },
  },
  uploadConfigurationNotDefined: {
    singular: {
      lower: 'please define a upload configuration for the import',
    },
  },
  uploadConfigurationNotFound: {
    singular: {
      lower: 'upload configuration not found!',
    },
  },
  uploadConfigurationWithNameAlreadyExists: {
    singular: {
      lower: "upload configuration with name '${VALUE}' already exists",
    },
  },
  uploadedAt: {
    singular: {
      lower: 'uploaded at',
    },
  },
  uploadedBy: {
    singular: {
      lower: 'uploaded by',
    },
  },
  uploadFileHasDuplicateHeaderName: {
    singular: {
      lower: "found duplicate header name '${VALUE}'",
    },
  },
  uploadFileHasMissingRequiredFields: {
    singular: {
      lower: 'mapped value ${VALUE} is not a value of the header names',
    },
  },
  uploadProductImagesInTheFilesAndAssetsSection: {
    singular: {
      lower: "upload product images in the 'files & assets' section",
    },
  },
  urlInput: {
    singular: {
      lower: 'url',
    },
  },
  user: {
    plural: {
      lower: 'users',
    },
    singular: {
      lower: 'user',
    },
  },
  userAlreadyHasRoleForStockLocation: {
    singular: {
      lower: 'user already has a role for this stock location',
    },
  },
  userAlreadyPartOfCompany: {
    singular: {
      lower: 'user already is already part of the company',
    },
  },
  userAssignedToBothInitialCountAndRecount: {
    singular: {
      lower:
        'a user was assigned to both the initial count and recount however you have setup your task settings to block this',
    },
  },
  userDetailWithUserIdAlreadyExists: {
    singular: {
      lower: "user detail with userid '${VALUE}' already exists",
    },
  },
  userDoesNotHaveAnyAssignedStockLocations: {
    singular: {
      lower: 'user does not have any assigned stock locations',
    },
  },
  userInviteEmail: {
    singular: {
      lower: 'please enter the email address of the user you want to add',
    },
  },
  userNotFound: {
    singular: {
      lower: 'user not found',
    },
  },
  userWithEmailAlreadyExists: {
    singular: {
      lower: "user with email '${VALUE}' already exists",
    },
  },
  validateBarcodes: {
    singular: {
      lower: 'validate barcodes on serialized products?',
    },
  },
  validateQuantity: {
    singular: {
      lower: 'validate quantity on non-serialized products?',
    },
  },
  valueForCustomFieldXNotValidBooleanGotY: {
    singular: {
      lower: 'Value for custom field ${FIELD_NAME} is not a valid boolean, got: ${VALUE}',
    },
  },
  valueForCustomFieldXNotValidDateGotY: {
    singular: {
      lower: 'Value for custom field ${FIELD_NAME} is not a valid date, got: ${VALUE}',
    },
  },
  valueForCustomFieldXNotValidListValueGotYValidZ: {
    singular: {
      lower:
        'Value for custom field ${FIELD_NAME} is not one of the valid list values, got: ${VALUE}, valid: ${VALID_VALUES}',
    },
  },
  valueName: {
    singular: {
      lower: 'name of the value',
    },
  },
  variableCountOfItems: {
    singular: {
      lower: 'variable count of items',
    },
  },
  verifyDeleteCompany: {
    singular: {
      lower: 'are you sure you want to delete this company?',
    },
  },
  verifyDeleteFiles: {
    singular: {
      lower: 'are you sure you want to delete these files?',
    },
  },
  verifyDeleteImportConfiguration: {
    singular: {
      lower: 'are you sure you want to delete this import configuration?',
    },
  },
  verifyDeleteOrders: {
    singular: {
      lower: 'are you sure you want to delete these orders?',
    },
  },
  verifyDeleteProductMasterData: {
    singular: {
      lower: 'are you sure you want to delete these product references?',
    },
  },
  verifyDeleteProductMasterDataExportConfiguration: {
    singular: {
      lower: 'are you sure you want to delete this product reference export configuration?',
    },
  },
  verifyDeleteStockLocation: {
    singular: {
      lower: 'are you sure you want to delete this stock location?',
    },
  },
  verifyDeleteTasks: {
    singular: {
      lower: 'are you sure you want to delete these tasks?',
    },
  },
  verifyDeleteTriggers: {
    singular: {
      lower: 'are you sure you want to delete these triggers?',
    },
  },
  verifyEmail: {
    singular: {
      lower: 'verifying your email address',
    },
  },
  viewer: {
    singular: {
      lower: 'viewer',
    },
  },
  vnt: {
    singular: {
      lower: 'vnt',
    },
  },
  volume: {
    singular: {
      lower: 'volume',
    },
  },
  width: {
    singular: {
      lower: 'width',
    },
  },
  wrongClientIdentifier: {
    singular: {
      lower: 'wrong client identifier',
    },
  },
  yes: {
    singular: {
      lower: 'yes',
    },
  },
  yesterday: {
    singular: {
      lower: 'yesterday',
    },
  },
  singular: {
    singular: {
      lower: 'singular',
    },
  },
  plural: {
    singular: {
      lower: 'plural',
    },
  },
  uppercase: {
    singular: {
      lower: 'uppercase',
    },
  },
  lowercase: {
    singular: {
      lower: 'lowercase',
    },
  },
  lot: {
    singular: {
      lower: 'lot',
    },
  },
  manageType: {
    singular: {
      lower: 'manage type',
    },
  },
  fileTypeCategory: {
    singular: {
      lower: 'file type category',
    },
  },
  fileType: {
    singular: {
      lower: 'file type',
    },
  },
  file: {
    singular: {
      lower: 'file',
    },
  },
  updateStockRole: {
    singular: {
      lower: 'update stock role',
    },
  },
  stockLocationRole: {
    singular: {
      lower: 'stock location role',
    },
  },
  changeCompany: {
    singular: {
      lower: 'change company',
    },
  },
  drop: {
    singular: {
      lower: 'drop',
    },
  },
  click: {
    singular: {
      lower: 'click',
    },
  },
  dragAndDrop: {
    singular: {
      lower: 'drag and drop',
    },
  },
  initialCounter: {
    singular: {
      lower: 'initial counter',
    },
  },
  recounter: {
    singular: {
      lower: 'recounter',
    },
  },
  shipped: {
    singular: {
      lower: 'shipped',
    },
  },
  arrived: {
    singular: {
      lower: 'arrived',
    },
  },
  failed: {
    singular: {
      lower: 'failed',
    },
  },
  disabled: {
    singular: {
      lower: 'disabled',
    },
  },
  verifyDeleteTaskExportConfiguration: {
    singular: {
      lower: 'are you sure you want to delete this task export configuration?',
    },
  },
  noStockCycleCount: {
    singular: {
      lower: 'cannot create a cycle count task without stock',
    },
  },
  defaultConfiguration: {
    singular: {
      lower: 'default configuration',
    },
  },
  leave: {
    singular: {
      lower: 'leave',
    },
  },
  firstAndLastName: {
    singular: {
      lower: 'first and last name',
    },
  },
  stockAdjustment: {
    singular: {
      lower: 'stock adjustment',
    },
  },
  originalQuantity: {
    singular: {
      lower: 'original quantity',
    },
  },
  newQuantity: {
    singular: {
      lower: 'new quantity',
    },
  },
  move: {
    singular: {
      lower: 'move',
    },
  },
  regexInput: {
    singular: {
      lower: 'regex input',
    },
  },
  pleaseEnterCompanyNameToConfirm: {
    singular: {
      lower: 'please enter the company name to confirm',
    },
  },
  verifyLeaveCompany: {
    singular: {
      lower: 'are you sure you want to leave this company?',
    },
  },
  verifyRemoveUserFromCompany: {
    singular: {
      lower: 'are you sure you want to remove this user from the company?',
    },
  },
  addAdditionalProductMasterDataExportField: {
    singular: {
      lower: 'add product master data export field',
    },
  },
  orderTransaction: {
    singular: {
      lower: 'order transaction',
    },
  },
  triggerStockLocationText: {
    singular: {
      lower: 'trigger stock location text',
    },
  },
  filterStockLocationBins: {
    singular: {
      lower: 'filter stock location bins',
    },
  },
  addProducts: {
    singular: {
      lower: 'add products',
    },
  },
  ignoreValidation: {
    singular: {
      lower: 'ignore validation',
    },
  },
  scanRuleField: {
    singular: {
      lower: 'field',
    },
  },
  scanRuleStringValue: {
    singular: {
      lower: 'string value ',
    },
  },
  enterStockLocationName: {
    singular: {
      lower: 'enter stock location name',
    },
  },
  somethingUnexpectedHappened: {
    singular: {
      lower: 'something unexpected happened',
    },
  },
  meter: {
    singular: {
      lower: 'meter',
    },
  },
  gram: {
    singular: {
      lower: 'gram',
    },
  },
  liter: {
    singular: {
      lower: 'liter',
    },
  },
  hide: {
    singular: {
      lower: 'hide',
    },
  },
  show: {
    singular: {
      lower: 'show',
    },
  },
  reorderUserRule: {
    singular: {
      lower: 'reorder user rule',
    },
  },
  licenseAgreement: {
    singular: {
      lower: 'license agreement',
    },
  },
  privacyPolicy: {
    singular: {
      lower: 'privacy policy',
    },
  },
  termsOfUse: {
    singular: {
      lower: 'terms of use',
    },
  },
  veryifyDeleteReorderRules: {
    singular: {
      lower: 'verify delete reorder rules',
    },
  },
  appliesToAllLocations: {
    singular: {
      lower: 'applies to all locations',
    },
  },
  ruleStockLocationText: {
    singular: {
      lower: 'ruleStockLocationText',
    },
  },
  noRulesOrUsersSelected: {
    singular: {
      lower: 'no rules or users selected',
    },
  },
  unknownBinStatus: {
    singular: {
      lower: 'unknown bin status',
    },
  },
  ruleProductText: {
    singular: {
      lower: 'ruleProductText',
    },
  },
  enterNameOfReorderUserRule: {
    singular: {
      lower: 'enter name',
    },
  },
  veryifyDeleteReorderUserRules: {
    singular: {
      lower: 'are you sure you want to delete these reorder user rules?',
    },
  },
  reorderQuantity: {
    singular: {
      lower: 'reorder quantity',
    },
  },
  replenishQuantity: {
    singular: {
      lower: 'replenish quantity',
    },
  },
};
