import React from 'react';
import DynamicFilterInnerContent, { FilterInnerContentProps } from './DynamicFilterInnerContent';

export default function CountryFilterInnerContent({ item, filter, setFilter }: FilterInnerContentProps) {
  return (
    <DynamicFilterInnerContent
      item={item}
      items={[...(filter.country || [])]}
      onRemove={i => {
        filter.country?.delete(i);
        if (!filter.country?.size) filter.country = undefined;
        setFilter({ ...filter });
      }}
    />
  );
}
