import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

enum LotValidationError {
  number = 'Please enter a number',
}

export class Lot extends CompanyEntity {
  number!: string;
  productMasterDataId!: string;
  expirationDate?: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateLotInput {
    return UpdateLotInput.from(this, UpdateLotInput);
  }

  override forDelete(): DeleteLotInput {
    return DeleteLotInput.from(this, DeleteLotInput);
  }

  override validate(fields: (keyof Lot)[]) {
    return this.validateEntity(fields, field => {
      if (field === 'number' && !this.number) return LotValidationError.number;
      return null;
    });
  }

  withNumber(name: string) {
    this.number = name;
    return this;
  }
}

export class CreateLotInput extends forCreate(Lot) {}

export class UpdateLotInput extends forUpdate(Lot) {}

export class DeleteLotInput extends forDelete(Lot) {}
