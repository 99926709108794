import { t } from '../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../Button';

export function ProcessButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.process,
    text: t().process.singular.label,
    onClick: onClick,
    style: 'secondary',
    ...props,
  };
}

interface ProcessButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function ProcessButton({ onClick, ...props }: ProcessButtonProps) {
  return <Button {...ProcessButtonTemplate(onClick, props)} />;
}
