import React from 'react';
import { FileEntity } from '../../../../../types/file';
import CloseIcon from '../../../../icons/Close/CloseIcon';
import Modal from '../../Modal/Modal';
import FileViewLoader from '../FileViewLoader';

interface FileViewModalProps {
  file: FileEntity;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function FileViewModal({ open, setOpen, file }: FileViewModalProps) {
  if (!file.fileTypeCategory.includes('image') && !file.fileTypeCategory.includes('video')) return null;

  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      header={<></>}
      title={''}
      className='relative min-h-[200px] min-w-[200px] h-fit w-fit max-h-[80vh] max-w-[80vw] flex justify-center items-center overflow-hidden'
      width={'fit-content'}
      customLayout
    >
      <CloseIcon
        className='absolute right-2 top-2 text-gray-500 flex justify-center items-center hover:text-black cursor-pointer hover:bg-gray-100 rounded-[8px] h-[44px] w-[44px] z-10'
        onClick={() => setOpen(false)}
      />
      <FileViewLoader fileId={file.id} />
    </Modal>
  );
}
