import { Grid } from '@mui/material';
import './Header.scss';
import { HomeIcon } from '../../../icons/Home/HomeIcon';
import { ChevronRightIcon } from '../../../icons/ChevronRight/ChevronRightIcon';
import React from 'react';
import { useLocation } from 'react-router-dom';
import UserMenu from '../UserMenu/UserMenu';
import { t } from '../../../../types/translation/Translator';

interface HeaderProps {}

export function Header({}: HeaderProps) {
  const location = useLocation();

  const headerText = (path: string) => {
    if (path.includes('dashboard')) return t().dashboard.singular.label;
    if (path.includes('stock/')) return t().stock.singular.label;
    if (path.includes('reference_data')) return t().referenceData.singular.label;
    if (path.includes('orders')) return t().order.plural.label;
    if (path.includes('contacts')) return t().contacts.singular.label;
    if (path.includes('tasks')) return t().tasks.singular.label;
    if (path.includes('alerts')) return t().alert.plural.label;
    if (path.includes('settings')) return t().settings.singular.label;
    if (path.includes('admin')) return t().administrator.singular.label;
  };

  return (
    <Grid item xs={12} className='ventory-header'>
      <Grid className='w-full h-full select-none text-ventory-grey-500' container>
        <Grid item display={'flex'}>
          <HomeIcon className='pl-7 pr-2' />
          <ChevronRightIcon className='pr-3' />
          <p className='my-auto pt-[2px]'>{headerText(location.pathname)}</p>
        </Grid>
        <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} alignContent={'center'} className='w-fit'>
          <Grid item display='flex' alignContent={'center'}>
            <UserMenu />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
