import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { UserContext } from '../../../context/UserContext';
import UpdateProfileModal from './Modals/UpdateProfileModal';
import UpdatePasswordModal from './Modals/UpdatePasswordModal';
import { Button } from '../../../VentoryUI/components/common/Button/Button';
import Paper from '../../../VentoryUI/components/common/Paper/Paper';
import ProfileImage from '../../../VentoryUI/components/common/File/Image/ProfileImageViewLoader';

export default function ProfileSettingsPane() {
  const { currentUser } = useContext(UserContext);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);

  return (
    <Paper>
      <Grid container rowSpacing={2} alignContent={'start'}>
        <Grid item xs={6} marginY={'auto'}>
          <p className='text-lg font-bold'>{`${currentUser?.firstName} ${currentUser?.lastName}`}</p>
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'flex-end'} columnGap={1}>
          <Grid item>
            <Button onClick={() => setOpenPasswordModal(true)} text={t().changePassword.singular.label} />
          </Grid>
          <Grid item>
            <Button style='secondary' onClick={() => setOpenUpdateModal(true)} text={t().edit.singular.label} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2}>
            <Grid item>
              <ProfileImage className='w-24 h-24' fileId={currentUser?.profilePicture} />
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container alignContent={'center'} className='h-full'>
                <Grid item xs={12}>
                  <p className='font-semibold text-sm'>{`${currentUser?.firstName} ${currentUser?.lastName}`}</p>
                </Grid>
                <Grid item xs={12}>
                  <p className='text-sm text-gray-500'>{`${currentUser?.email}`}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <UpdatePasswordModal open={openPasswordModal} setOpen={setOpenPasswordModal} />
        <UpdateProfileModal open={openUpdateModal} setOpen={setOpenUpdateModal} />
      </Grid>
    </Paper>
  );
}
