import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { Contact, CreateContactInput, DeleteContactInput, UpdateContactInput } from '../types/contact';
import { AddressFragments } from './common/address.graphql';

const contact = gql`
  fragment Contact on Contact {
    id
    version
    companyId
    name
    contactType
    email
    address {
      ...Address
    }
    shippingLocations {
      name
      address {
        ...Address
      }
      email
      stockLocationId
    }
  }
  ${AddressFragments.address}
`;

const get = gql`
  query Contacts($companyId: String!) {
    contacts(companyId: $companyId) {
      data {
        ...Contact
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${contact}
`;

export interface GetContactsVariables {
  companyId: string;
  page?: number;
}

export interface GetContactsResponse {
  contacts: PaginatedContacts;
}

class PaginatedContacts extends forPaginated<Contact>() {}

const create = gql`
  mutation CreateContact($contacts: [CreateContactInput!]!) {
    createContact(contacts: $contacts) {
      ...Contact
    }
  }
  ${contact}
`;

export interface CreateContactVariables {
  contacts: CreateContactInput[];
}

export interface CreateContactResponse {
  createContact: Contact[];
}

const update = gql`
  mutation UpdateContact($contacts: [UpdateContactInput!]!) {
    updateContact(contacts: $contacts) {
      ...Contact
    }
  }
  ${contact}
`;

export interface UpdateContactVariables {
  contacts: UpdateContactInput[];
}

export interface UpdateContactResponse {
  updateContact: Contact[];
}

const remove = gql`
  mutation DeleteContact($contacts: [DeleteContactInput!]!) {
    deleteContact(contacts: $contacts) {
      ...Contact
    }
  }
  ${contact}
`;

export interface DeleteContactVariables {
  contacts: DeleteContactInput[];
}

export interface DeleteContactResponse {
  deleteContact: Contact[];
}

export const ContactQueries = {
  get,
};

export const ContactMutations = {
  create,
  update,
  remove,
};

export const ContactFragments = {
  contact,
};
