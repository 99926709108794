import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { OrderTableSettings } from '../types/orderTableSettings';

const orderTableSettings = gql`
  fragment OrderTableSettings on OrderTableSettings {
    id
    version
    companyId
    columns {
      index
      column
      label
    }
  }
`;

const get = gql`
  query OrderTableSettings($companyId: String!, $page: Float) {
    orderTableSettings(companyId: $companyId, page: $page) {
      data {
        ...OrderTableSettings
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${orderTableSettings}
`;

export interface GetOrderTableSettingsVariables {
  companyId: string;
  page?: number;
}

export interface GetOrderTableSettingsResponse {
  orderTableSettings: PaginatedOrderTableSettings;
}

class PaginatedOrderTableSettings extends forPaginated<OrderTableSettings>() {}

const create = gql`
  mutation CreateOrderTableSettings($orderTableSettings: [CreateOrderTableSettingsInput!]!) {
    createOrderTableSettings(orderTableSettings: $orderTableSettings) {
      ...OrderTableSettings
    }
  }
  ${orderTableSettings}
`;

export interface CreateOrderTableSettingsVariables {
  orderTableSettings: OrderTableSettings[];
}

export interface CreateOrderTableSettingsResponse {
  createOrderTableSettings: OrderTableSettings[];
}

const update = gql`
  mutation UpdateOrderTableSettings($orderTableSettings: [UpdateOrderTableSettingsInput!]!) {
    updateOrderTableSettings(orderTableSettings: $orderTableSettings) {
      ...OrderTableSettings
    }
  }
  ${orderTableSettings}
`;

export interface UpdateOrderTableSettingsVariables {
  orderTableSettings: OrderTableSettings[];
}

export interface UpdateOrderTableSettingsResponse {
  updateOrderTableSettings: OrderTableSettings[];
}

export const OrderTableSettingsQueries = {
  get,
};

export const OrderTableSettingsMutations = {
  create,
  update,
};
