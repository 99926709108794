import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function CalendarIcon({ className, color }: IconInputProps) {
  return (
    <Icon className={`${className} pb-[1px]`}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14 7.16659H2M10.6667 1.83325V4.49992M5.33333 1.83325V4.49992M5.2 15.1666H10.8C11.9201 15.1666 12.4802 15.1666 12.908 14.9486C13.2843 14.7569 13.5903 14.4509 13.782 14.0746C14 13.6467 14 13.0867 14 11.9666V6.36659C14 5.24648 14 4.68643 13.782 4.2586C13.5903 3.88228 13.2843 3.57632 12.908 3.38457C12.4802 3.16659 11.9201 3.16659 10.8 3.16659H5.2C4.0799 3.16659 3.51984 3.16659 3.09202 3.38457C2.71569 3.57632 2.40973 3.88228 2.21799 4.2586C2 4.68643 2 5.24648 2 6.36659V11.9666C2 13.0867 2 13.6467 2.21799 14.0746C2.40973 14.4509 2.71569 14.7569 3.09202 14.9486C3.51984 15.1666 4.0799 15.1666 5.2 15.1666Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
