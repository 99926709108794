import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function ListIcon({ className, color, width = 16, height = 17 }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg width={width} height={height} viewBox='0 0 16 17' stroke='currentColor' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14 8.49992L6 8.49992M14 4.49992L6 4.49992M14 12.4999L6 12.4999M3.33333 8.49992C3.33333 8.86811 3.03486 9.16659 2.66667 9.16659C2.29848 9.16659 2 8.86811 2 8.49992C2 8.13173 2.29848 7.83325 2.66667 7.83325C3.03486 7.83325 3.33333 8.13173 3.33333 8.49992ZM3.33333 4.49992C3.33333 4.86811 3.03486 5.16659 2.66667 5.16659C2.29848 5.16659 2 4.86811 2 4.49992C2 4.13173 2.29848 3.83325 2.66667 3.83325C3.03486 3.83325 3.33333 4.13173 3.33333 4.49992ZM3.33333 12.4999C3.33333 12.8681 3.03486 13.1666 2.66667 13.1666C2.29848 13.1666 2 12.8681 2 12.4999C2 12.1317 2.29848 11.8333 2.66667 11.8333C3.03486 11.8333 3.33333 12.1317 3.33333 12.4999Z'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
