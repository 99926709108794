import React, { useContext } from 'react';
import { ReorderUserRule } from '../../../../../../../types/reorderUserRule';
import { Grid } from '@mui/material';
import Selector from '../../../../../../Common/Selector';
import { t } from '../../../../../../../types/translation/Translator';
import { UserContext } from '../../../../../../../context/UserContext';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/ModalPane';
import { BackButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/NextButton';

interface ReorderUserRuleReplenishUsersPaneProps {
  rule: ReorderUserRule;
  setRule: (rule: ReorderUserRule) => void;
  next: () => void;
  back: () => void;
}

export default function ReorderUserRuleReplenishUsersPane({
  rule,
  setRule,
  next,
  back,
}: ReorderUserRuleReplenishUsersPaneProps) {
  const { companyUsers } = useContext(UserContext);

  const noneSelected =
    !rule.replenishOrderCompanyRoles.length &&
    !rule.replenishOrderStockLocationRoles.length &&
    !rule.replenishOrderUserIds.length;

  return (
    <ModalPane
      overflow='auto'
      footerButtons={[
        BackButtonTemplate(back),
        NextButtonTemplate(next, {
          disabled: noneSelected,
          tooltip: noneSelected ? t().noRulesOrUsersSelected.singular.upper : undefined,
        }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <p className='text-sm mb-1'>{'selectUsersThatShouldBeAssignedForReplenishment'}</p>
              <Selector
                checkedValues={rule.replenishOrderUserIds}
                values={[...companyUsers.values()].map(cU => cU.userId)}
                onChange={value => setRule(rule.withReplenishOrderUsers(value))}
                toText={value => {
                  const user = companyUsers.get(value);
                  if (!user) return t().unknownUser.singular.label;
                  return `${user.firstName} ${user.lastName}  (${user.email})`;
                }}
                placeholder={t().filterUsers.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
